<template>



  <div id="banner">
    
<br />
<div id="container" class="mt-5" style="margin:auto;">



  <Modal
      v-show="isModalVisible"
      @close="closeModal"
  />


  <div class="content1">
    <h2>LES THEMES</h2>
    <br />
    <template v-for="theme in Themes" :key="theme.id">
      
      <p>{{ theme.label}}</p>



    </template>
  </div>


 <div class="content2 mt-5">
    <table class="table" id="tableauEnregistre">
        <thead >
          <tr>
            <th style="color:black;">THEME X</th>
            <th class="leftborder" @click="showModal">CREER THEME</th>
            <th class="leftborder">CREER SOUS-THEME</th>
            <th class="leftborder">SERIE D'ENTRAINEMENT</th>
      
          </tr>
        </thead>
        <tbody>

          <template v-for="lycee in lycees" :key="lycee.id">
            <tr>
              <td id="rightborder"> {{ lycee.nom }}
              </td>
              <td>{{ lycee.adresse }}</td>
              <td>{{ lycee.codePostal }}</td>
              <td>{{ lycee.ville }}</td>
            </tr>

      
          </template>
        </tbody>
      </table>
  
  </div>

</div>

</div>



</template>


<script>
import { mapActions } from "vuex";
import Modal from '../../components/Admin/Modal.vue'
export default {
  name: "App",
  components: {
      Modal,
    },
  

  data() {
    return {
   isModalVisible: false
    };
  },

  mounted() {
    this.getLycee();
    this.getThemes();
  },

  methods: {
      ...mapActions([
			"getLycee",
      "getThemes"
		]),

      showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      }
    

 
  },


  computed: {
		lycees() {
			return this.$store.state.lycee;
		},

    Themes() {
			return this.$store.state.Themes;
		},
	}


};
</script>


<style scoped>
#banner {
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 87%,
      rgba(255, 255, 255, 1) 100%
    ),
    url(".././../assets/images/code.png");
  background-position: 0% 40%;
  background-size: cover;
  width: 100%;
  height: 30vh;
  max-width: 100%;
  min-width: 100%;
  display: block;
}

.content1 {
  display:flex;
  flex-direction: column;
  border-radius: 70px;
  height: auto;
  background-color: rgb(248, 239, 231);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 15%;
  margin-left: 5%;
  padding: 2%;
  flex-wrap: wrap;

}


thead th, tfoot th, tfoot td {
  
  border-bottom: 1px solid #ffaa71;
}

#box {
   height: 100px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 300px;
    
}


th {
  color: #d67610;
  font-weight: bold;
  font-size: 1.2em;

}



.leftborder {
 border-left: 1px solid #ffaa71;
}

#rightborder {
 border-right: 1px solid #ffaa71;
}

.content2 {
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 70px;
  height: auto ;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 65%;
  margin-left: 5%;
  padding: 2%;
  flex-wrap: wrap;
  background-color: #ffffff;
  

}

.content2 p {
margin: auto;
font-size :1.1em;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
border-radius: 70px;
padding: 5%;
width: 100%;
font-weight: bold;

}

.content1 p {

font-size :1.1em;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
border-radius: 70px;
padding: 4%;
width: 100%;
font-weight: bold;

}

.content1 h2 {
margin: auto;
}


#container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
</style>
