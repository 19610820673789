<template>
    <div>

    </div>
  </template>
  
  <script>

  
  export default {
  
    props: [

    ],
  
    components: {

    },
  
    data() {
      return {

      };
    },
  
    mounted() {
    },
  
    methods: {
  

    },
  };
  </script>
  