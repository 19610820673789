<template>
    <div>
        <OtherpaiementDeleted></OtherpaiementDeleted>
    </div>
</template>
<script>
import OtherpaiementDeleted from '../../components/Admin/OtherPaiementDeleted.vue';

export default {
    components: { OtherpaiementDeleted }
}
</script>
<style lang="">
    
</style>