<template>
  <div id="conteneur">
    <div id="inProgress">
      <router-link to="/home"
        ><Button class="mb-5 btnViolet">Retour</Button></router-link
      >
      <h2 class="mb-5">
        Cette fonctionnalitée est en cours de développement, Cette fonctionnalité sera disponible prochainement !
      </h2>

      <img id="iconLogo" :src="require('../assets/images/logoMonPermis.png')" />
    </div>
  </div>
</template>

<style scoped>
#iconLogo {
  width: 30%;
}

a {
    text-decoration: none;
}

.btnViolet {
  background: #de6fa8;
  border-radius: 13px;
  border: none;
}

#conteneur {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
}
</style>
