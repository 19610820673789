<template>
	<div class="code">
		<div id="banner">
			<center><h1 id="titre">CODE</h1></center>
		</div>

		<div class="container-fluid">
			<div class="row">
				<div class="col-md-9">
					<p style="text-align: justify; color: #d67610">
					Vous entrez dans l'espace de préparation à l'examen du code.
					<br />En réglant {{filterFonctionnalite.montant}} €, vous accédez à :
					</p>
					<p style="text-align: justify; color: #d67610">
						- Un livre de code
					</p>
					<p style="text-align: justify; color: #d67610">
						- Des séries de cours organisées par thème et sous thème qui vous permettrons d'assimiler les 10 thèmes du code de la route
					</p>
					<p style="text-align: justify; color: #d67610">
						- Des séries d'entrainement combinant tous les thèmes dans les mêmes conditions qu'à l'examen
					</p>
					<p style="text-align: justify; color: #d67610">
						- Vous pourrez suivre votre niveau au fur et à mesure que vous réalisez vos séries.
					</p>
				</div>

				<div class="col-md-3" >
					<img id="logo-feu" :src="require('../../../assets/images/feu.png')" />
					<button class="btn btn-warning btn-sm mt-5" id="goToPaiementBtn" @click="goToPaiement()">{{filterFonctionnalite.montant}} € - PAYER</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	name: 'CodeAccueil',
	data() {
		return {
			error: "",
		}
	},
	mounted() {
		this.getFonctionnalite();
	},
	methods: {
		...mapActions([
			"getFonctionnalite",
		]),
		goToPaiement() {
			this.$router.push({name: 'Paiement', params: {fonctionnalite: "code"}});
		}
	},
	computed: {
		...mapGetters([
			"fonctionnalite",
		]),
		filterFonctionnalite() {
			if (this.fonctionnalite !== null) {
				return this.fonctionnalite.filter(el => el.label === "code")[0];
			}
			return "";
		}
	}
}
</script>

<style scoped>
#banner {
	background:linear-gradient(to bottom, rgba(255,255,255,0) 87%, rgba(255,255,255,1) 100%),  url("../../../assets/images/a.jpg");
	background-position: 0% 40%;
	background-size: cover;
	width: 100%;
	height: 35vh;
	max-width : 100%;
	display: block;
}

#success {
	margin: auto;
	width: 20px;
	height: 30px;
}

p {
	font-size: 1.6em;
	font-weight: bold;
	margin-left: 3%;
	margin-right: 3%;
}

#logo-feu {
	margin:auto;
	max-width: 100%;
	max-height: 100%;
}

#goToPaiementBtn {
	color: black ;
	background-color:#ffaa71;
	font-size: 1.5em;
	font-weight: bold;
	border-radius: 10px;
}

#titre {
	padding-top: 7%;
	color: #d67610;
	font-weight: bold;
	font-size: 4rem;
}
</style>