<template>
  <div id="conteneur">
    <div>
       <router-link to="/TrameFront"><Button class="btnViolet">Retour</Button></router-link>
      <table class="trame">
        <thead>
          <tr>
            <th></th>
            <th>Competences</th>
            <th>Durée</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="competence in this.competences" :key="competence.id">
               <tr>
                <th
                  v-bind:style="{ 'background-color': competence.color }"
                ></th>
                <td style="font-size:1.3rem; padding-left: 2%;"  v-bind:style="{ 'color': competence.color }">{{ competence.label }}</td>
              </tr>

            <template v-for="(objectif, i) in competence['objectifs']" :key="i">
              <tr>
                <th
                  v-bind:style="{ 'background-color': competence.color }"
                ></th>
                <td style="padding-left: 100px">{{ objectif.label }}</td>
                <td>{{ objectif.duree }}</td>
              </tr>

              <template
                v-for="(sousObjectif, idx) in objectif['sousObjectifs']"
                :key="idx"
              >
                <tr>
                  <th
                    v-bind:style="{ 'background-color': competence.color }"
                  ></th>
                  <td style="padding-left: 150px">-{{ sousObjectif.label }}</td>
                  <td>{{ sousObjectif.duree }}</td>
                </tr>
              </template>
            </template>
            <br />
          </template>
          
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      competences: [],
    };
  },

  mounted() {
    this.getCompetences();
  },

  methods: {
    async getCompetences() {
      try {
        window.api
          .get("/competences", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((res) => {
            this.competences = res.data["hydra:member"];
            this.competences.sort((a, b) => a.numero - b.numero);
            console.log(this.agences);
            console.log(this.$store.state.token);
          });
      } catch (e) {
        // handle authentication error here
      }
    },
  },
};
</script>

<style scoped>

a {
  text-decoration: none;
}

.btnViolet {
  background: #de6fa8;
  border-radius: 13px;
  border: none;
}
table.trame {
  width: 90%;
  margin: auto;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  font-weight: bold;
  color: black;
  text-align: left;
}

table.trame thead {
  color: white;
}

#conteneur {
  padding-top: 2%;
  min-height: 100vh;
  height: auto;
  background-image: url("../../../assets/images/fondd.png");
  background-size: cover;
}
</style>
