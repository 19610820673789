<template>
  <body>
    <div class="container-fluid" id="archive">
      <Toast />

      <div id="tableArchive">
        <center>
          <h1 class="mb-4">
            <router-link to="/home"
              ><i
                class="pi pi-home me-3"
                id="iconHome"
                style="font-size: 2rem"
              ></i></router-link
            >Archive moniteur
          </h1>
        </center>
        <DataTable
          :value="this.monosArchived"
          :rows="10"
          v-model:filters="filters"
          filterDisplay="menu"
          :rowsPerPageOptions="[10, 20, 50]"
          responsiveLayout="scroll"
          dataKey="id"
        >
          <Column
            field="nom"
            header="Nom"
            :sortable="true"
            style="min-width: 12rem"
          >
          </Column>

          <Column
            field="prenom"
            header="Prenom"
            :sortable="true"
            style="min-width: 12rem"
          ></Column>

          <Column
            field="telephone"
            header="telephone"
            :sortable="true"
            style="min-width: 12rem"
          ></Column>

          <Column
            field="email"
            header="mail"
            :sortable="true"
            style="min-width: 12rem"
          ></Column>

          <Column
            header="Desarchiver"
            :exportable="false"
            style="min-width: 8rem"
          >
            <template #body="slotProps">
              <Checkbox
                :binary="true"
                @click="DesarchiveEleve(slotProps.data.id)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </body>
</template>

<script>
import { useToast } from "primevue/usetoast";

export default {
  name: "App",

  data() {
    return {
      toast: useToast(),
      utilisateurs: [],
      monosArchived: [],
    };
  },

  mounted() {
    this.getUtilisateurs();
  },

  methods: {
    getUtilisateurs() {
      
      window.api
        .get("/utilisateur", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.utilisateurs = response.data.utilisateurs;
          this.monosArchived = this.utilisateurs.filter(
            (el) => el.role.id === 4 && el.active === false
          );
          console.log('archived', this.monosArchived)
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    async DesarchiveEleve(id) {
      try {
        if (confirm("Voulez vous vraiment Desarchiver cette eleve ?")) {
          window.api
            .patch(
              "utilisateur/" + id,
              {
                active: true,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then((response) => {
              this.toast.add({
                severity: "success",
                summary: "Mono Désarchivé",
                detail: "Votre Mono à été Désarchivé avec succès",
                life: 3000,
              });
              this.getUtilisateurs();
              console.log(response);
            });
        }
      } catch (e) {
        // handle authentication error here
      }
    },
  },
};
</script>

<style scoped>
#archive {
  background-image: url("../../../assets/images/Fond_ordinateur.png");
  background-size: cover;
  width: 100%;
}

#tableArchive {
  display: flex;
  flex-direction: column;

  align-content: center;
  height: auto;
  min-height: 100vh;
  width: 100%;
}
</style>
