<template>
  <!-- The Modal -->
  <div
    id="myModalDescription"
    class="modal"
    :class="{ modalOpen: $store.state.descriptionPiece !== null }"
  >
    <!-- Modal content -->
    <div class="modal-content">
      <div class="modal-header">
        <span class="close" @click="closeModalDescription()">&times;</span>
        <h2>Description</h2>
      </div>
      <div class="modal-body">
        <p>{{ $store.state.descriptionPiece }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Description",
  data() {
    return {};
  },
  methods: {
    closeModalDescription() {
      // Ferme le modal
      this.$store.state.descriptionPiece = null;
    },
  },
};
</script>

<style>
.modalOpen {
  display: block !important;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */

  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

/* Modal Content */
.modal-content {
  margin: auto;
  margin-top: 5em;
  background-color: #fefefe;
  width: 50%;

  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #93c2ff;
  color: white;
}

.modal-body {
  padding: 2px 16px;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #ffffff;
  color: white;
}
</style>
