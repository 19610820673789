<template>
  <div class="trame">
    <h1>Trame</h1>

        <table class="trame-update">
          <thead>
            <tr>
              <th></th>
              <th>Objectifs</th>
              <th>Durée</th>
              <th>Traité</th>
              <th>Assimilé</th>
            </tr>
          </thead>
          <tbody>
          <template v-for="(competence, index) in trame['competences']" :key="index">
            <template v-for="(objectif, i) in competence['objectifs']" :key="i">
              <tr>
                <th v-bind:style="{ 'background-color': competence.couleur }"></th>
                <td>{{objectif.label}}</td>
                <td>{{objectif.duree}}</td>
                <template v-if="objectif.objacq == 'traite'">
                  <td><input @click="actionObj" :data-id="objectif.id" type="checkbox" name="traite" checked></td>
                  <td><input @click="actionObj" :data-id="objectif.id" type="checkbox" name="assimile"></td>
                </template>
                <template v-else-if="objectif.objacq === 'assimile'">
                  <td><input @click="actionObj" :data-id="objectif.id" type="checkbox" name="traite" checked></td>
                  <td><input @click="actionObj" :data-id="objectif.id" type="checkbox" name="assimile" checked></td>
                </template>
                <template v-else>
                  <td><input @click="actionObj" :data-id="objectif.id" type="checkbox" name="traite"></td>
                  <td><input @click="actionObj" :data-id="objectif.id" type="checkbox" name="assimile"></td>
                </template>

              </tr>
              <template v-for="(sousObjectif, idx) in objectif['sousObjectifs']" :key="idx">
                <tr>
                  <th v-bind:style="{ 'background-color': competence.couleur }"></th>
                  <td style="padding-left:50px;">-{{sousObjectif.label}}</td>
                  <td>{{sousObjectif.duree}}</td>

                  <template v-if="objectif.objacq == 'traite'">
                    <td><input id="ssobj" @click="actionSousObjectif" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="traite" checked></td>
                    <td><input id="ssobj" @click="actionSousObjectif" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="assimile"></td>
                  </template>
                  <template v-else-if="objectif.objacq === 'assimile'">
                    <td><input id="ssobj" @click="actionSousObjectif" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="traite" checked></td>
                    <td><input id="ssobj" @click="actionSousObjectif" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="assimile" checked></td>
                  </template>
                  <template v-else>
                    <template v-if="sousObjectif.acquis == 'null'">
                      <td><input id="ssobj" @click="actionSousObjectif" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="traite"></td>
                      <td><input id="ssobj" @click="actionSousObjectif" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="assimile"></td>
                    </template>
                    <template v-else>
                      <template v-if="sousObjectif.acquis[0].id == 1">
                        <td><input id="ssobj" @click="actionSousObjectif" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="traite" checked></td>
                        <td><input id="ssobj" @click="actionSousObjectif" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="assimile"></td>
                      </template>
                      <template v-else>
                        <td><input id="ssobj" @click="actionSousObjectif" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="traite" checked></td>
                        <td><input id="ssobj" @click="actionSousObjectif" :data-id="sousObjectif.id" :data-obj-id="objectif.id" type="checkbox" name="assimile" checked></td>
                      </template>
                    </template>
                  </template>

                </tr>
              </template>
            </template>
          </template>
          </tbody>
        </table>

        <button @click="endCours">Terminer le cours</button>
  </div>
</template>

<script>

export default {
  name: "Trame",
  data() {
    return {
      trame: [],
    }
  },

  mounted() {
    this.getTrame();
  },
  methods: {

    actionObj(e){
        let cbxs = document.querySelectorAll("#ssobj");
        
        cbxs.forEach(cbx => {
          if(e.target.name == cbx.name){
            let lessobj = false;
            this.trame.competences.forEach(comp => {
              comp.objectifs.forEach(obj => {
                obj.sousObjectifs.forEach(ssobj => {
                  if(e.target.attributes[0].nodeValue == cbx.attributes[2].nodeValue && cbx.attributes[1].nodeValue == ssobj.id){
                    lessobj = ssobj
                  }
                })
              })
            })

            if(lessobj != false){
              if(e.target.name == "traite"){
                if(e.target.checked){
                  if(lessobj.acquis == "null"){
                    window.api.post("acquisitions", {
                          etatAcquisition: "api/etat_acquisitions/1",
                          sousObjectif:"api/sous_objectifs/"+cbx.attributes[1].nodeValue,
                          eleve:"api/utilisateurs/"+this.$route.params.id
                        },
                        {
                          headers:{
                            "Authorization": "Bearer " + this.$store.state.token
                          },
                        }).then(() => {
                      this.getTrame();
                    }).catch(error => {
                      console.log(error)
                    })
                  }else{
                      window.api.put("acquisitions/"+lessobj.acquis[0].idacq, {
                            etatAcquisition: "api/etat_acquisitions/1",
                            sousObjectif:"api/sous_objectifs/"+cbx.attributes[1].nodeValue,
                            eleve:"api/utilisateurs/"+this.$route.params.id
                          },
                          {
                            headers:{
                              "Authorization": "Bearer " + this.$store.state.token
                            },
                          }).then(() => {
                        this.getTrame();
                      }).catch(error => {
                        console.log(error)
                      })
                  }
                }else{
                  window.api.delete("acquisitions/"+lessobj.acquis[0].idacq,
                      {
                        headers:{
                          "Authorization": "Bearer " + this.$store.state.token
                        },
                      }).then(() => {
                    this.getTrame();
                  }).catch(error => {
                    console.log(error)
                  })
                }

              }else{
                if(e.target.checked){
                  if(lessobj.acquis == "null"){
                    window.api.post("acquisitions", {
                          etatAcquisition: "api/etat_acquisitions/2",
                          sousObjectif:"api/sous_objectifs/"+cbx.attributes[1].nodeValue,
                          eleve:"api/utilisateurs/"+this.$route.params.id
                        },
                        {
                          headers:{
                            "Authorization": "Bearer " + this.$store.state.token
                          },
                        }).then(() => {
                      this.getTrame();
                    }).catch(error => {
                      console.log(error)
                    })
                  }else{
                      window.api.put("acquisitions/"+lessobj.acquis[0].idacq, {
                            etatAcquisition: "api/etat_acquisitions/2",
                            sousObjectif:"api/sous_objectifs/"+cbx.attributes[1].nodeValue,
                            eleve:"api/utilisateurs/"+this.$route.params.id
                          },
                          {
                            headers:{
                              "Authorization": "Bearer " + this.$store.state.token
                            },
                          }).then(() => {
                        this.getTrame();
                      }).catch(error => {
                        console.log(error)
                      })
                  }
                }else{
                  window.api.put("acquisitions/"+lessobj.acquis[0].idacq, {
                        etatAcquisition: "api/etat_acquisitions/1",
                        sousObjectif:"api/sous_objectifs/"+cbx.attributes[1].nodeValue,
                        eleve:"api/utilisateurs/"+this.$route.params.id
                      },
                      {
                        headers:{
                          "Authorization": "Bearer " + this.$store.state.token
                        },
                      }).then(() => {
                    this.getTrame();
                  }).catch(error => {
                    console.log(error)
                  })
                }

              }
            }
          }
        })
    },

    actionSousObjectif(e){
      let lessobj;

      this.trame.competences.forEach(comp => {
        comp.objectifs.forEach(obj => {
          obj.sousObjectifs.forEach(ssObj => {
            if(e.target.attributes[1].nodeValue == ssObj.id){
              lessobj = ssObj;
            }
          })
        })
      })
      if(e.target.name == "traite"){
        if(e.target.checked == true){
          if(lessobj.acquis == "null"){
            window.api.post("acquisitions", {
                  etatAcquisition: "api/etat_acquisitions/1",
                  sousObjectif:"api/sous_objectifs/"+e.target.attributes[1].nodeValue,
                  eleve:"api/utilisateurs/"+this.$route.params.id
                },
                {
                  headers:{
                    "Authorization": "Bearer " + this.$store.state.token
                  },
                }).then(() => {
              this.getTrame();
            }).catch(error => {
              console.log(error)
            })
          }else{

            window.api.put("acquisitions/"+lessobj.acquis[0].idacq, {
                  etatAcquisition: "api/etat_acquisitions/1",
                  sousObjectif:"api/sous_objectifs/"+e.target.attributes[1].nodeValue,
                  eleve:"api/utilisateurs/"+this.$route.params.id
                },
                {
                  headers:{
                    "Authorization": "Bearer " + this.$store.state.token
                  },
                }).then(() => {
              this.getTrame();
            }).catch(error => {
              console.log(error)
            })
          }
        }else{
          window.api.delete("acquisitions/"+lessobj.acquis[0].idacq,
              {
                headers:{
                  "Authorization": "Bearer " + this.$store.state.token
                },
              }).then(() => {
            this.getTrame();
          }).catch(error => {
            console.log(error)
          })
        }

      }else{
        if(e.target.checked){
          if(lessobj.acquis == "null"){
            window.api.post("acquisitions", {
                  etatAcquisition: "api/etat_acquisitions/2",
                  sousObjectif:"api/sous_objectifs/"+e.target.attributes[1].nodeValue,
                  eleve:"api/utilisateurs/"+this.$route.params.id
                },
                {
                  headers:{
                    "Authorization": "Bearer " + this.$store.state.token
                  },
                }).then(() => {
              this.getTrame();
            }).catch(error => {
              console.log(error)
            })
          }else{

            window.api.put("acquisitions/"+lessobj.acquis[0].idacq, {
                  etatAcquisition: "api/etat_acquisitions/2",
                  sousObjectif:"api/sous_objectifs/"+e.target.attributes[1].nodeValue,
                  eleve:"api/utilisateurs/"+this.$route.params.id
                },
                {
                  headers:{
                    "Authorization": "Bearer " + this.$store.state.token
                  },
                }).then(() => {
              this.getTrame();
            }).catch(error => {
              console.log(error)
            })
          }
        }else{
          window.api.put("acquisitions/"+lessobj.acquis[0].idacq, {
                etatAcquisition: "api/etat_acquisitions/1",
                sousObjectif:"api/sous_objectifs/"+e.target.attributes[1].nodeValue,
                eleve:"api/utilisateurs/"+this.$route.params.id
              },
              {
                headers:{
                  "Authorization": "Bearer " + this.$store.state.token
                },
              }).then(() => {
            this.getTrame();
          }).catch(error => {
            console.log(error)
          })          
        }

      }

    },

    getTrame(){
      window.api.get("/trame/"+ this.$route.params.id, {
        headers:{
          "Authorization": "Bearer " + this.$store.state.token
        },
      }).then( response => {
        this.trame = response.data.trame[0];
      }).catch(error => {
        console.log(error)
      })
    },

    endCours(){
      let now = new Date
      now.setHours(now.getHours() +1)
      window.api.patch("/apointments/"+this.$route.params.rdvId, {
            doneDate: now,
            done: true
          },
          {
            headers:{
              "Authorization": "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json"
            },
          }).then(() => {
        this.$router.push('/moniteur/planning')
      }).catch(error => {
        console.log(error)
      })
    }


  },

}
</script>

<style scoped>
  table.trame-update {
    width: 90%;
    margin:auto;
  }

  table.trame-update thead{
    background-color:black;
    color: white;
  }
  div.trame{
    margin-top: 2em;
  }
</style>