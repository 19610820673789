<template>
  <div v-if="this.$store.state.user.role.label == 'eleve'">
    <template v-if="checkLoad">
      <!----
      <div id="descriptif">


          <p id="textDescription">
            La première étape du permis de conduire est de constituer et déposer
            un dossier en Préfecture. Le montant de cette démarche est de
            {{ filterFonctionnaliteMontant }} €. Pour cela : <br />- compléter
            le formulaire ci-contre, <br />- puis procéder au règlement <br />-
            et enfin déposer les pièces du dossier en ligne
          </p>
          <div>
            <b-button
              @click="ordreVueMobile"
              class="btnForm mb-2"
              id="btnSuivantVueMobile"
              >Suivant</b-button
            >
          </div>

      </div>

      

      <Formulaire
        :userData="form"
        :showValidation="showValidation"
        :isWorking="isWorking"
        @update="update($event)"
        @updateCheckAll="updateCheckAll($event)"
        @ordreVueMobile="ordreVueMobile($event)"
        @saveDossier="saveDossier($event)"
      ></Formulaire>
----> 
        <Formulaires
            :userData="form"
            :showValidation="showValidation"
            :isWorking="isWorking"
            @update="update($event)"
            @updateCheckAll="updateCheckAll($event)"
            @ordreVueMobile="ordreVueMobile($event)"
            @saveDossier="saveDossier($event)"
          ></Formulaires>
</template>
    <template v-else>
      <Loader />
    </template>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
//import Formulaire from "@/components/dossier/Formulaire.vue";
import Formulaires from "@/components/dossier/Formulaires.vue";
// Formulaire est la partie de droite dans la page
//import BoutonPaiement from "@/components/eleve/BoutonPaiement.vue";

export default {
  name: "Dossier",
  beforeCreate() {
    // Si il n'y a pas le paramètre, on ne sait pas d'où l'utilisateur provient dans le site donc si il peut ou non payé.
    if (this.$route.params.personalData === undefined) {
      // Alors redirection
      this.$router.push({ name: "Home" });
    }
  },
  components: {
    Loader,
    //Formulaire,
    Formulaires,
    //BoutonPaiement,
  },
  data() {
    return {
      showForm : false,
      form: {
        nom: this.$store.state.user.nom,
        prenom: this.$store.state.user.prenom,
        telephone: this.$store.state.user.telephone,
        dossier: {},
      },
      isWorking: false,
      showValidation: true,
      checkAll: false,
      contractData: "",
      fileList: [],
      putFile: false,
      contract: null,
      questions: "",
    };
  },
  mounted() {
    this.loadDossier();
    this.getContract();

    console.log(this.$route.params.personalData);
  },
  methods: {

    showFormulaires(){
      this.showForm = true;
    },

    getContract() {
      window.api
        .get("/contract/" + this.$store.state.user.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.contract = response.data;

          if(this.contract.contractEleve != undefined){
            if(this.contract.contractEleve[0].piece != undefined){
              if(this.contract.contractEleve[0].piece.status == "valide"){
                this.contractStatus = true;
              }
            }
          }

        })
        .catch((error) => {
          console.log(error);
        });
    },

    async loadDossier() {
      try {
        // Charge les infos nécessaires à la fonctionnalité
        window.api
          .get("/dossier/info", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            // Place dans le store les informations récupérées
            this.$store.state.fonctionnalite = response.data.fonctionnalite;
            this.$store.state.permis = response.data.permis;
            this.$store.state.statutSocial = response.data.statutSocial;
            this.$store.state.lycees = response.data.lycee;
          });
        // Charge les types de pièces justificatives pour pouvoir recalculer les pièces de l'utilisateur
        window.api
          .get("/type_piece_justificative", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            // Place dans le store les types de pièce justificatives
            this.$store.state.typePieceJustificative =
              response.data["hydra:member"];
          });
        // Si le dossier n'est pas chargé ou qu'il provient de la base de donnée

          if (this.$store.state.user.dossier !== null) {
            console.log("oui")
            // Si l'utilisateur à un dossier en base de donnée
            window.api
              .get("/dossiers/" + this.$store.state.user.dossier.id, {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              })
              .then((response) => {
                // Place le dossier dans les data de la page
                this.form.dossier = response.data;
                // Place dans le store le dossier
                this.$store.commit("setDossier", response.data);
              });
          } else {
            console.log("non")
            // Sinon, on créé un objet vide
            let emptyDossier = {
              nomUsage: "",
              autresPrenom: "",
              telParent: "",
              mailParent: "",
              adresse: "",
              codePostal: "",
              ville: "",
              dateNaissance: "",
              paysNaissance: "",
              depNaissance: "",
              villeNaissance: "",
              nationaliteFR: "null",
              parlerFRCouramment: "null",
              vehiculeAmenage: "null",
              lunettes: "null",
              permis: "null",
              faitSuite: "null",
              motifAlcoolStup: "null",
              prevoitAac: "null",
              social: "null",
              metier: "",
              lycee: "null",
              autreLycee: "",
              numNeph: "",
            };
            // Place le dossier vide dans les data de la page
            this.form.dossier = emptyDossier;
            // Place dans le store le dossier
            this.$store.commit("setDossier", emptyDossier);
          }

      } catch (e) {
        // Si un des chargements a echoués, retour au menu home
        this.$router.push({ name: "Home" });
      }
    },
    saveDossier(mode) {
      // Lance la sauvegarde du dossier
      this.showValidation = true;
      // Si tout les champs sont valide
        this.isWorking = true;
        if(mode != 'null'){
          // Démarre les boutons de sauvegarde
          this.$store.dispatch("startSaveBouton", mode);
        }


        if (this.form.dossier.motifAlcoolStup === null) {
          this.form.dossier.motifAlcoolStup = false;
        }
        let tmpFormDossier = this.form.dossier;
        // Comparaison "true" ou true = true pour être sur d'envoyer correctement le true
        // Sinon, la comparaison = false
        tmpFormDossier.nationaliteFR =
          tmpFormDossier.nationaliteFR === "true" ||
          tmpFormDossier.nationaliteFR === true;
        tmpFormDossier.parlerFRCouramment =
          tmpFormDossier.parlerFRCouramment === "true" ||
          tmpFormDossier.parlerFRCouramment === true;
        tmpFormDossier.lunettes =
          tmpFormDossier.lunettes === "true" ||
          tmpFormDossier.lunettes === true;
        tmpFormDossier.vehiculeAmenage =
          tmpFormDossier.vehiculeAmenage === "true" ||
          tmpFormDossier.vehiculeAmenage === true;
        tmpFormDossier.prevoitAac =
          tmpFormDossier.prevoitAac === "true" ||
          tmpFormDossier.prevoitAac === true;
        tmpFormDossier.faitSuite =
          tmpFormDossier.faitSuite === "true" ||
          tmpFormDossier.faitSuite === true;
        tmpFormDossier.motifAlcoolStup =
          tmpFormDossier.motifAlcoolStup === "true" ||
          tmpFormDossier.motifAlcoolStup === true;
        if (tmpFormDossier.lycee === "null") {
          tmpFormDossier.lycee = null;
        }

        if (tmpFormDossier.permis === "null") {
          tmpFormDossier.permis = null;
        }

        if (tmpFormDossier.social === "null") {
          tmpFormDossier.social = null;
        }

        if (this.$store.state.dossier.id === undefined) {
          // Si pas de dossier en base de donnée alors post
          window.api
            .post(
              "/dossiers",
              {
                eleve: "/api/utilisateurs/" + this.$store.state.user.id,
                ...tmpFormDossier,
                ants: false,
                espaceQuestions: "",
                typeFabrication: null,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then((response) => {
              
              if(localStorage.getItem('user_info') != null){
                let user = JSON.parse(localStorage.getItem('user_info'));
                user.dossier = {"id" : response.data.id};
                localStorage.setItem("user_info", JSON.stringify(user));
              }


              // Place le dossier dans le store
              this.$store.state.dossier = response.data;
              // Initialisation de typePieces avec les pièces permanente
              let typePieces = this.$store.state.typePieceJustificative.filter(
                (el) => el.useCase === "dossier"
              );
              // Calcul de l'age
              let age =
                new Date().getUTCFullYear() -
                new Date(
                  this.$store.state.dossier.dateNaissance
                ).getUTCFullYear();

              // Suivant des conditions, différents type de pièce sont ajoutés à typePieces

              // Si l'élève a entre 17 ans inclus et 25 ans exclu
              if (age >= 17 && age < 25) {
                Array.prototype.push.apply(
                  typePieces,
                  this.$store.state.typePieceJustificative.filter(
                    (el) => el.useCase === "age"
                  )
                );
              }
              // Si la demande fait suite à une annulation/invalidation de permis
              if (this.$store.state.dossier.faitSuite) {
                Array.prototype.push.apply(
                  typePieces,
                  this.$store.state.typePieceJustificative.filter(
                    (el) => el.useCase === "faitSuite"
                  )
                );
                // Si l'annulation/invalidation est lié à l'alcool ou la drogue
                if (this.$store.state.dossier.motifAlcoolStup) {
                  Array.prototype.push.apply(
                    typePieces,
                    this.$store.state.typePieceJustificative.filter(
                      (el) => el.useCase === "faitSuite/oui"
                    )
                  );
                } else {
                  Array.prototype.push.apply(
                    typePieces,
                    this.$store.state.typePieceJustificative.filter(
                      (el) => el.useCase === "faitSuite/non"
                    )
                  );
                }
              }
              // Mise à jour des pièces relatives à l'utilisateur
              window.api
                .post(
                  "/dossier/piece",
                  {
                    dossier: this.$store.state.dossier,
                    typePieces: typePieces,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                    },
                  }
                )
                .then((response) => {
                  // Place dans le store le dossier après mise à jour des pièces
                  this.$store.state.dossier = response.data;
                })
                .catch((error) => {
                  // En cas d'échec de mise à jour des pièces, retour au menu home
                  this.$router.push({ name: "Home" });
                  console.log(error);
                });

              // Lance la mise à jour de l'utilisateur
              this.saveUser(mode);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          // Si dossier en base de donnée alors patch
          window.api
            .patch(
              "/dossiers/" + this.$store.state.dossier.id,
              {
                ...tmpFormDossier,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                  "Content-Type": "application/merge-patch+json",
                },
              }
            )
            .then((response) => {
              // Place le dossier dans le store
              this.$store.state.dossier = response.data;
              // Initialisation de typePieces avec les pièces permanente
              let typePieces = this.$store.state.typePieceJustificative.filter(
                (el) => el.useCase === "dossier"
              );
              // Calcul de l'age
              let age =
                new Date().getUTCFullYear() -
                new Date(
                  this.$store.state.dossier.dateNaissance
                ).getUTCFullYear();

              // Suivant des conditions, différents type de pièce sont ajoutés à typePieces

              // Si l'élève a entre 17 ans inclus et 25 ans exclu
              if (age >= 17 && age < 25) {
                Array.prototype.push.apply(
                  typePieces,
                  this.$store.state.typePieceJustificative.filter(
                    (el) => el.useCase === "age"
                  )
                );
              }
              // Si la demande fait suite à une annulation/invalidation de permis
              if (this.$store.state.dossier.faitSuite) {
                Array.prototype.push.apply(
                  typePieces,
                  this.$store.state.typePieceJustificative.filter(
                    (el) => el.useCase === "faitSuite"
                  )
                );
                // Si l'annulation/invalidation est lié à l'alcool ou la drogue
                if (this.$store.state.dossier.motifAlcoolStup) {
                  Array.prototype.push.apply(
                    typePieces,
                    this.$store.state.typePieceJustificative.filter(
                      (el) => el.useCase === "faitSuite/oui"
                    )
                  );
                } else {
                  Array.prototype.push.apply(
                    typePieces,
                    this.$store.state.typePieceJustificative.filter(
                      (el) => el.useCase === "faitSuite/non"
                    )
                  );
                }
              }
              // Mise à jour des pièces relatives à l'utilisateur
              window.api
                .post(
                  "/dossier/piece",
                  {
                    dossier: this.$store.state.dossier,
                    typePieces: typePieces,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                    },
                  }
                )
                .then((response) => {
                  // Place dans le store le dossier après mise à jour des pièces
                  this.$store.state.dossier = response.data;
                })
                .catch((error) => {
                  // En cas d'échec de mise à jour des pièces, retour au menu home
                  this.$router.push({ name: "Home" });
                  console.log(error);
                });

              // Lance la mise à jour de l'utilisateur
              this.saveUser(mode);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      
    },
    saveUser(mode) {
      // Update des données utilisateurs
      window.api
        .patch(
          "/utilisateur/" + this.$store.state.user.id,
          {
            nom: this.form.nom,
            prenom: this.form.prenom,
            telephone: this.form.telephone,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          if(mode != 'null'){
            // Place dans le store
            this.$store.commit("setUser", response.data.utilisateur[0]);
            let fonctionnalite = "dossier";
            let router = this.$router;
            // Stop les boutons de sauvegarde
            this.$store.dispatch("endSaveBouton", {
              mode,
              fonctionnalite,
              router,
            });
          }

          this.isWorking = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    ordreVueMobile(e) {
      if (e.target.id == "btnSuivantVueMobile") {
        // Permet le passage à la première étape du dossier sur la vue mobile
        document.getElementById("descriptif").style.display = "none";
        document.getElementById("conteneurDoss").style.background = "none";
        document.getElementById("dossierFormulaire").style.display = "initial";
      } else if (e.target.id == "btnRetourVueMobile") {
        // Permet le retour avant la première étape du dossier sur la vue mobile
        document.getElementById("conteneurDoss").style.background =
          "linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%)";
        document.getElementById("descriptif").style.display = "initial";
        document.getElementById("dossierFormulaire").style.display = "none";
      }
    },

    goToHome() {
      this.$router.push({ name: "Home" });
    },

    update(userData) {
      // update des infos du dossier depuis le composant Formulaire
      this.form = userData;
    },

    updateCheckAll(value) {
      // update du checkAll depuis le composant Formulaire
      this.checkAll = value;
    },

    addFile() {
      this.fileList = [];
      let input = document.getElementById("file_input_cont");
      const reader = new FileReader();
      reader.onloadend = () => {
        this.fileList.push({
          file_name: input.files[0].name,
          file_data: reader.result,
        });
      };
      reader.readAsDataURL(input.files[0]);

      this.putFile = true;
    },

    savePiece() {
      if (this.contract.contractEleve.length == 0) {
        window.api
          .post(
            "/contracts",
            {
              piece: this.fileList,
              status: "En Attente",
              eleve: "api/utilisateurs/" + this.$store.state.user.id,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.getContract();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        window.api
          .put(
            "/contracts/" + this.contract.contractEleve[0].id,
            {
              piece: this.fileList,
              status: "En Attente",
              eleve: "api/utilisateurs/" + this.$store.state.user.id,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.getContract();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  computed: {
    checkLoad() {
      return (
        this.$store.state.fonctionnalite !== null &&
        this.$store.state.permis !== null &&
        this.$store.state.statutSocial !== null &&
        this.$store.state.lycees !== null &&
        this.form.dossier.codePostal !== undefined &&
        this.contract !== null
      );
    },

    filterFonctionnaliteMontant() {
      // Si les fonctionnalités sont chargées
      if (this.$store.state.fonctionnalite !== null) {
        // Lecture de la valeur de la fonctionnalité dossier
        return this.$store.state.fonctionnalite.filter(
          (el) => el.label === "dossier"
        )[0].montant;
      }
      return "";
    },
  },
};
</script>

<style scoped>

div.btnChoiceDossier{
  margin-top: 4em;
}

div.btnChoiceDossier button.form{
  margin-right: 1em;
}

div.btnChoiceDossier button.home{
  margin-left: 1em;
}

div.left ul.ulDesc{
  text-align: left;
  font-size: 40px;
  padding-left: 2em;
  padding-top: 3em;
}

div.main {
  display: flex;
}
div.left {
  margin: auto;
  width: 100%;
  font-size: 25px;
  height: calc(100vh);
  background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
}

div.right {
  margin: auto;
  width: 100%;
  font-size: 25px;
  height: calc(100vh);
  background: rgb(255, 255, 255);
}
div.input label {
  display: flex;
}

div.input {
  display: flex;
  margin-top: 2em;
}

.title {
  text-align: center;
  font-size: 50pt;
}

h4.msgRefus {
  text-align: center;
}

div.container div.content button.btnVal {
  display: block;
  margin: auto;
  margin-top: 10em;
}

div.container div.content button.btnStatus {
  display: block;
  margin: auto;
  margin-top: 10em;
}

div.container div.content button.btnStatus a {
  color: white;
  text-decoration: none;
}

div.container div.content input {
  display: block;
  margin: auto;
}

div.container div.content button.contractBtn {
  display: block;
  margin: auto;
}

div.container div.content button.contractBtn a {
  color: white;
  text-decoration: none;
}

div.container div.content h3 {
  margin-top: 2em;
  text-align: center;
}

#descriptif {
  margin: auto;
  width: 100%;
  text-align: left;
  font-size: 25px;
  height: calc(100vh);
  background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
}

#descriptif img {
  height: 5rem;
  margin-top: 1rem;
  margin-left: 1rem;
  cursor: pointer;
}

#textDescription {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-family: "Galano Grotesque Alt", sans-serif;
  height: calc(100vh - 7rem);
  margin: 1rem;
}

#conteneurDoss {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  align-items: center;
}

.btnForm {
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  background-color: #de6fa8;
  /* Green */
  border: none;
  color: white;
  width: 15%;
  height: 30px;
  margin: auto;
  border-radius: 10px;
}

.btnForm:hover {
  background-color: #bd135f;
}

/* Desktop Styles */
@media only screen and (min-width: 961px) {
  #btnSuivantVueMobile {
    display: none;
  }
}

/* Mobile Styles */
@media only screen and (max-width: 400px) {
  #textDescription {
    font-size: 22pt;
  }

  .btnForm {
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    background-color: #de6fa8;
    /* Green */
    border: none;
    color: white;
    width: 40%;
    margin: auto;
    border-radius: 10px;
    cursor: pointer;
  }

  #btnSuivantVueMobile {
    display: initial;
  }

  #conteneurDoss {
    display: grid;
    grid-template-columns: none;
    justify-content: center;
    align-items: center;
    background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  }
}

/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  #textDescription {
    font-size: 22pt;
    width: 100%;
  }

  #descriptif {
    background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  }

  #conteneurDoss {
    display: grid;
    grid-template-columns: none;
    justify-content: center;
    align-items: center;
    background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  }

  #btnSuivantVueMobile {
    display: initial;
  }

  .btnForm {
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    background-color: #de6fa8;
    /* Green */
    border: none;
    color: white;
    width: 40%;
    margin: auto;
    border-radius: 10px;
    cursor: pointer;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  #textDescription {
    font-size: 20pt;
  }

  #conteneurDoss {
    display: grid;
    grid-template-columns: none;
    justify-content: center;
    align-items: center;
    background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  }

  #btnSuivantVueMobile {
    display: initial;
  }

  /* Styles */
}
</style>
