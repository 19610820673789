<template>
  <div id="conteneurDoss" v-if="this.$store.state.user.role.label == 'eleve'">
    <template v-if="checkLoad">
      <div id="descriptif">
        <p id="textDescription">
          Vous avez obtenu votre permis ?<br />
          FELICATATIONS !<br />
          <br />
          Confiez nous cete formalité administrative pour :<br />
          {{ filterFonctionnaliteMontant }}€<br /><br />
          1) Remplissez le formulaire ci-contre<br />
          2) Procédez au réglement<br />
          3) Insérez les pièces demandées
        </p>
        <div>
          <b-button
            @click="ordreVueMobile"
            class="btnForm mb-2"
            id="btnSuivantVueMobile"
            >Suivant</b-button
          >
        </div>
      </div>

      <div id="dossierFormulaire">
        <div class="container">
          <h1 class="titre">Fabrication de permis</h1>
          <p>Etape {{ etape }}/4</p>
          <template v-if="etape == 1">
            <div class="row">
              <div class="row mt-4">
                <div class="col-12">
                  <input
                    v-model="form.dossier.numNeph"
                    type="text"
                    id="numNeph"
                    name="numNeph"
                    class="form-control inputsize"
                    :class="{ isNotValid: showValidation }"
                    placeholder="NEPH"
                  />
                </div>
              </div>
              <div class="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12">
                <input
                  v-model="form.nom"
                  type="text"
                  id="nom"
                  name="nom"
                  class="form-control marge"
                  placeholder="Nom"
                  :class="{ isNotValid: showValidation && !checkNom }"
                />
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <input
                  v-model="form.prenom"
                  type="text"
                  id="prenom"
                  name="prenom"
                  class="form-control marge"
                  placeholder="Prenom"
                  :class="{ isNotValid: showValidation && !checkPrenom }"
                />
              </div>
            </div>

            <div class="row mt-4">
              <div class="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12">
                <input
                  v-model="form.dossier.autresPrenom"
                  type="text"
                  id="autresPrenom"
                  name="autresPrenom"
                  class="form-control marge"
                  placeholder="Autres prénoms"
                  :class="{ isNotValid: showValidation && !checkAutrePrenom }"
                />
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <input
                  v-model="form.dossier.nomUsage"
                  type="text"
                  id="nomUsage"
                  name="nomUsage"
                  class="form-control marge"
                  placeholder="Nom d'usage"
                  :class="{ isNotValid: showValidation && !checkNomUsage }"
                />
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12">
                <input
                  v-model="form.dossier.adresse"
                  type="text"
                  id="adresse"
                  name="adresse"
                  class="form-control inputsize"
                  :class="{ isNotValid: showValidation && !checkAdresse }"
                  placeholder="Adresse"
                />
              </div>
            </div>

            <div class="row mt-4">
              <div class="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12">
                <input
                  v-model="form.dossier.codePostal"
                  type="text"
                  id="codePostal"
                  name="codePostal"
                  class="form-control"
                  :class="{ isNotValid: showValidation && !checkCodePostal }"
                  placeholder="Code Postal"
                />
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <input
                  v-model="form.dossier.ville"
                  type="text"
                  id="ville"
                  name="ville"
                  class="form-control marge"
                  :class="{ isNotValid: showValidation && !checkVille }"
                  placeholder="Ville"
                />
              </div>
            </div>
          </template>

          <template v-if="etape == 2">
            <div class="row">
              <div class="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12">
                <input
                  v-model="form.dossier.dateNaissance"
                  type="date"
                  id="dateNaissance"
                  name="dateNaissance"
                  placeholder="Date de naissance"
                  class="form-control marge"
                  :class="{ isNotValid: showValidation && !checkDateNaissance }"
                />
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <input
                  v-model="form.dossier.paysNaissance"
                  type="text"
                  id="paysNaissance"
                  name="paysNaissance"
                  class="form-control marge"
                  placeholder="Pays de naissance"
                  :class="{ isNotValid: showValidation && !checkPaysNaissance }"
                />
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-md-12 col-xs-12 col-lg-12">
                <input
                  v-model="form.dossier.villeNaissance"
                  type="text"
                  id="villeNaissance"
                  name="villeNaissance"
                  class="form-control inputsize"
                  placeholder="Commune de naissance"
                  :class="{
                    isNotValid: showValidation && !checkVilleNaissance,
                  }"
                />
              </div>

              <div class="col-md-12 col-xs-12 col-lg-12 mt-4">
                <input
                  v-model="form.dossier.depNaissance"
                  type="text"
                  id="depNaissance"
                  name="depNaissance"
                  class="form-control inputsize"
                  placeholder="Département de naissance"
                  :class="{ isNotValid: showValidation && !checkDepNaissance }"
                />
              </div>

              <div class="col-md-12 col-xs-12 col-lg-12 mt-4">
                <select
                  v-model="form.dossier.faitSuite"
                  id="faitSuite"
                  name="faitSuite"
                  class="form-select inputsize"
                  placeholder="La présente demande fait-elle suite à une annulation /
                invalidation de permis ?"
                  :class="{ isNotValid: showValidation && !checkFaitSuite }"
                >
                  <option value="null" disabled>
                    La présente demande fait-elle suite à une annulation /
                    invalidation de permis ?
                  </option>
                  <option
                    v-for="(item, index) in faitSuite"
                    :value="'/api/fait_suites/' + item.id"
                    :key="index"
                  >
                    {{ item.motif }}
                  </option>
                </select>
              </div>

              <div
                class="col-md-12 col-xs-12 col-lg-12 mt-4"
                :class="{ isHide: isFaitSuite }"
              >
                <select
                  v-model="form.dossier.motifAlcoolStup"
                  id="motifAlcoolStup"
                  name="motifAlcoolStup"
                  class="form-select inputsize"
                  placeholder="En raison d'une conduite sous alcoolémie ou stupéfiant ?"
                  :class="{
                    isNotValid: showValidation && !checkMotifAlcoolStup,
                  }"
                >
                  <option value="null" disabled>
                    En raison d'une conduite sous alcoolémie ou stupéfiant ?
                  </option>
                  <option :value="true">OUI</option>
                  <option :value="false">NON</option>
                </select>
              </div>

              <div class="col-md-12 col-xs-12 col-lg-12">
                <select
                  v-model="form.dossier.permis"
                  id="permis"
                  name="permis"
                  class="form-select inputsize marge"
                  :class="{ isNotValid: showValidation && !checkPermis }"
                  placeholder="Catégorie de permis demandée"
                >
                  <option value="null" disabled>
                    Catégorie de permis demandée
                  </option>

                  <option
                    v-for="(item, index) in permis"
                    :value="'/api/permis/' + item.id"
                    :key="index"
                  >
                    {{ item.label }}
                  </option>
                </select>
              </div>

              <!--
              <div
                class="col-md-12 col-xs-12 col-lg-12 mt-4"
              >
                <select
                  v-model="form.dossier.typeFabrication"
                  id="typeFabrication"
                  name="typeFabrication"
                  class="form-select"
                  :class="{
                    isNotValid: showValidation,
                  }"
                >
                  <option value="null" disabled>Type de fabrication</option>
                  <option :value="'Première Réussite'">Première Réussite</option>
                  <option :value="'Renouvellement de permis'">Renouvellement de permis</option>
                </select>
              </div>
              -->
            </div>
          </template>

          <div class="mt-3" id="buttonContainer">
            <template v-if="etape == 1">
              <b-button
                id="btnRetourVueMobile"
                @click="ordreVueMobile"
                class="btnForm mt-3"
              >
                Retour
              </b-button>
            </template>
            <template v-if="etape != 1">
              <b-button @click="retourEtape" class="btnForm mt-3">
                Retour
              </b-button>
            </template>
            <template v-if="etape != 2">
              <b-button @click="suivantEtape" class="btnForm mt-3">
                Suivant
              </b-button>
            </template>
          </div>

          <template v-if="etape == 2">
            <template v-if="!isWorking">
              <button
                type="button"
                class="mt-4 btn btn-primary submit"
                @click="saveDossier()"
              >
                Enregistrer
              </button>
            </template>
            <template v-else>
              <button type="button" class="btn btn-primary submit" disabled>
                <i class="fa fa-spinner fa-spin"></i> Enregistrer
              </button>
            </template>
          </template>
        </div>
      </div>
    </template>

    <template v-else>
      <Loader />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader.vue";

export default {
  name: "Dossier",
  components: {
    Loader,
  },
  data() {
    return {
      etape: 1,
      form: {
        nom: this.$store.state.user.nom,
        prenom: this.$store.state.user.prenom,
        dossier: {},
      },
      checkPaiement: false,
      isWorking: false,
      showValidation: false,
    };
  },
  mounted() {
    this.loadPaiement();
    this.loadDossier();
    this.getFonctionnalite();
    this.getAnts();
    this.getFaitSuite();
    this.getPermis();
    this.getStatutSocial();
    this.getLycee();
  },
  methods: {
    ...mapActions([
      "getFonctionnalite",
      "getAnts",
      "getFaitSuite",
      "getPermis",
      "getStatutSocial",
      "getLycee",
    ]),
    async loadPaiement() {
      try {
        window.api
          .get("/utilisateur/" + this.$store.state.user.id + "/paiement", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            this.$store.commit("setPaiement", response.data["Paiement"]);
            if (
              response.data["Paiement"].filter(
                (el) => el.description === "dossier"
              ).length > 0 &&
              response.data["Paiement"].filter(
                (el) => el.description === "dossier"
              )[0].etat === "Payé"
            ) {
              this.$router.push({ name: "Dossier2" });
            } else {
              this.checkPaiement = true;
            }
          });
      } catch (e) {
        this.$router.push({ name: "Home" });
      }
    },
    async loadDossier() {
      try {
        if (this.$store.state.dossier === null) {
          if (this.$store.state.user.dossier !== null) {
            window.api
              .get("/dossiers/" + this.$store.state.user.dossier.id, {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              })
              .then((response) => {
                this.form.dossier = response.data;
                this.$store.commit("setDossier", response.data);
              });
          } else {
            let emptyDossier = {
              nomUsage: "",
              autresPrenom: "",
              telParent: "",
              mailParent: "",
              adresse: "",
              codePostal: "",
              ville: "",
              dateNaissance: "",
              paysNaissance: "",
              depNaissance: "",
              villeNaissance: "",
              nationaliteFR: null,
              parlerFRCouramment: null,
              vehiculeAmenage: null,
              lunettes: null,
              permis: null,
              faitSuite: null,
              motifAlcoolStup: null,
              prevoitAac: null,
              social: null,
              metier: "",
              lycee: null,
              autreLycee: "",
              numNeph: "",
            };
            this.form.dossier = emptyDossier;
            this.$store.commit("setDossier", emptyDossier);
          }
        } else {
          this.form.dossier = this.$store.state.dossier;
        }
      } catch (e) {
        this.$router.push({ name: "Home" });
      }
    },
    saveDossier() {
      this.showValidation = true;
      if (this.checkAll) {
        this.isWorking = true;
        if (this.form.dossier.motifAlcoolStup === null) {
          this.form.dossier.motifAlcoolStup = false;
        }
        if (this.$store.state.dossier.id === undefined) {
          window.api
            .post(
              "/dossiers",
              {
                eleve: "/api/utilisateurs/" + this.$store.state.user.id,
                ...this.form.dossier,
                ants: this.defaultAnts,
                espaceQuestions: "",
                typeFabrication: null,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then((response) => {
              this.$store.state.dossier = response.data;
              this.saveUser();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          window.api
            .patch(
              "/dossiers/" + this.$store.state.dossier.id,
              {
                ...this.form.dossier,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                  "Content-Type": "application/merge-patch+json",
                },
              }
            )
            .then((response) => {
              this.$store.state.dossier = response.data;
              this.saveUser();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    saveUser() {
      window.api
        .patch(
          "/utilisateur/" + this.$store.state.user.id,
          {
            nom: this.form.nom,
            prenom: this.form.prenom,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.$store.commit("setUser", response.data.utilisateur[0]);
          this.$router.push({
            name: "Paiement",
            params: { fonctionnalite: "dossier" },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    ordreVueMobile(e) {
      if (e.target.id == "btnSuivantVueMobile") {
        document.getElementById("descriptif").style.display = "none";
        document.getElementById("conteneurDoss").style.background = "none";
        document.getElementById("dossierFormulaire").style.display = "initial";
      } else if (e.target.id == "btnRetourVueMobile") {
        document.getElementById("conteneurDoss").style.background =
          "linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%)";
        document.getElementById("descriptif").style.display = "initial";
        document.getElementById("dossierFormulaire").style.display = "none";
      }
    },

    suivantEtape() {
      if (this.etape < 4) {
        this.etape++;
      }
    },

    retourEtape() {
      if (this.etape > 1) {
        this.etape--;
      }
    },
  },
  computed: {
    ...mapGetters([
      "fonctionnalite",
      "ants",
      "defaultAnts",
      "faitSuite",
      "permis",
      "statutSocial",
      "lycee",
    ]),

    checkLoad() {
      return (
        this.$store.state.dossier !== null &&
        this.checkPaiement &&
        this.fonctionnalite !== null &&
        this.ants !== null &&
        this.defaultAnts !== null &&
        this.faitSuite !== null &&
        this.permis !== null &&
        this.statutSocial !== null &&
        this.lycee !== null
      );
    },
    checkAll() {
      return (
        this.defaultAnts !== null &&
        this.checkNom &&
        this.checkPrenom &&
        this.checkNomUsage &&
        this.checkAutrePrenom &&
        this.checkAdresse &&
        this.checkCodePostal &&
        this.checkVille &&
        this.checkDateNaissance &&
        this.checkPaysNaissance &&
        this.checkDepNaissance &&
        this.checkVilleNaissance &&
        this.checkNationaliteFR &&
        this.checkParlerFRCouramment &&
        this.checkVehiculeAmenage &&
        this.checkLunettes &&
        this.checkPermis &&
        this.checkFaitSuite &&
        this.checkPrevoitAac &&
        this.checkSocial
      );
    },
    checkNom() {
      const match = this.form.nom.match(
        /[A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{1,250}/g
      );
      if (match !== null && match.length === 1) {
        return this.form.nom === match[0];
      }
      return false;
    },
    checkPrenom() {
      const match = this.form.prenom.match(
        /[A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{1,250}/g
      );
      if (match !== null && match.length === 1) {
        return this.form.prenom === match[0];
      }
      return false;
    },
    checkNomUsage() {
      const match = this.form.dossier.nomUsage.match(
        /[A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{1,250}/g
      );
      if (match !== null && match.length === 1) {
        return this.form.dossier.nomUsage === match[0];
      }
      return false;
    },
    checkAutrePrenom() {
      const match = this.form.dossier.autresPrenom.match(
        /[A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{1,250}/g
      );
      if (match !== null && match.length === 1) {
        return this.form.dossier.autresPrenom === match[0];
      }
      return false;
    },
    checkAdresse() {
      return this.form.dossier.adresse !== "";
    },
    checkCodePostal() {
      const match = this.form.dossier.codePostal.match(
        /[0-9]{5}|[1-9]{1}[0-9]{3}/g
      );
      if (match !== null && match.length === 1) {
        return this.form.dossier.codePostal === match[0];
      }
      return false;
    },
    checkVille() {
      return this.form.dossier.ville !== "";
    },
    checkDateNaissance() {
      return this.form.dossier.dateNaissance !== "";
    },
    checkPaysNaissance() {
      return this.form.dossier.paysNaissance !== "";
    },
    checkDepNaissance() {
      return this.form.dossier.depNaissance !== "";
    },
    checkVilleNaissance() {
      return this.form.dossier.villeNaissance !== "";
    },
    checkNationaliteFR() {
      return this.form.dossier.nationaliteFR !== null;
    },
    checkParlerFRCouramment() {
      return this.form.dossier.parlerFRCouramment !== null;
    },
    checkVehiculeAmenage() {
      return this.form.dossier.vehiculeAmenage !== null;
    },
    checkLunettes() {
      return this.form.dossier.lunettes !== null;
    },
    checkPermis() {
      return this.form.dossier.permis !== null;
    },
    checkFaitSuite() {
      return this.form.dossier.faitSuite !== null;
    },
    checkMotifAlcoolStup() {
      return this.form.dossier.motifAlcoolStup !== null;
    },
    checkPrevoitAac() {
      return this.form.dossier.prevoitAac !== null;
    },
    checkSocial() {
      return this.form.dossier.social !== null;
    },
    isFaitSuite() {
      if (this.checkFaitSuite && this.faitSuite !== null) {
        for (let i = 0; i < this.faitSuite.length; i++) {
          if (this.faitSuite[i].label !== "Non") {
            return (
              "/api/fait_suites/" + this.faitSuite[i].id ===
              this.form.dossier.faitSuite
            );
          }
        }
      }
      return true;
    },
    isSalarie() {
      if (this.checkSocial && this.statutSocial !== null) {
        for (let i = 0; i < this.statutSocial.length; i++) {
          if (this.statutSocial[i].label === "Salarié") {
            return (
              "/api/statut_socials/" + this.statutSocial[i].id ===
              this.form.dossier.social
            );
          }
        }
      }
      return false;
    },
    isLyceen() {
      if (this.checkSocial && this.statutSocial !== null) {
        for (let i = 0; i < this.statutSocial.length; i++) {
          if (this.statutSocial[i].label === "Lycéen") {
            return (
              "/api/statut_socials/" + this.statutSocial[i].id ===
              this.form.dossier.social
            );
          }
        }
      }
      return false;
    },
    isAutreLycee() {
      return this.isLyceen && this.form.dossier.lycee === null;
    },
    filterFonctionnaliteMontant() {
      if (this.fonctionnalite !== null) {
        return this.fonctionnalite.filter((el) => el.label === "dossier")[0]
          .montant;
      }
      return "";
    },
  },
};
</script>

<style scoped>
.isNotValid {
  border-color: red;
}
.isHide {
  display: none;
}

.titre {
  font-size: 50pt;
}

#descriptif {
  margin: auto;
  width: 100%;
  background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
}

#textDescription {
  color: white;
  text-align: left;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-family: "Galano Grotesque Alt", sans-serif;
  height: 98.4vh;
}

#conteneurDoss {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  align-items: center;
}

.btnForm {
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  background-color: #de6fa8; /* Green */
  border: none;
  color: white;
  width: 15%;
  height: 30px;
  margin: auto;
  border-radius: 10px;
}

.btnForm:hover {
  background-color: #bd135f;
}

#buttonContainer {
  display: flex;
}

.inputsize {
  width: 66%;
  margin: auto;
}

/* Desktop Styles */
@media only screen and (min-width: 961px) {
  #btnSuivantVueMobile {
    display: none;
  }

  #btnRetourVueMobile {
    display: none;
  }
}

/* Mobile Styles */
@media only screen and (max-width: 400px) {
  .marge {
    margin-top: 2%;
  }

  #dossierFormulaire {
    display: none;
  }

  #textDescription {
    font-size: 22pt;
  }

  .inputsize {
    width: 100%;
    margin: auto;
  }

  .btnForm {
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    background-color: #de6fa8; /* Green */
    border: none;
    color: white;
    width: 40%;
    margin: auto;
    border-radius: 10px;
    cursor: pointer;
  }

  #btnRetourVueMobile {
    display: initial;
  }

  #btnSuivantVueMobile {
    display: initial;
  }

  #conteneurDoss {
    display: grid;
    grid-template-columns: none;
    justify-content: center;
    align-items: center;
    background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  }
}

/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  .inputsize {
    width: 100%;
    margin: auto;
  }

  .marge {
    margin-top: 5%;
  }

  #dossierFormulaire {
    display: none;
  }

  #textDescription {
    font-size: 22pt;
    width: 100%;
  }

  #descriptif {
    background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  }

  #conteneurDoss {
    display: grid;
    grid-template-columns: none;
    justify-content: center;
    align-items: center;
    background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  }

  #btnRetourVueMobile {
    display: initial;
  }

  #btnSuivantVueMobile {
    display: initial;
  }

  .btnForm {
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    background-color: #de6fa8; /* Green */
    border: none;
    color: white;
    width: 40%;
    margin: auto;
    border-radius: 10px;
    cursor: pointer;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .inputsize {
    width: 100%;
    margin: auto;
  }

  #dossierFormulaire {
    display: none;
  }

  #textDescription {
    font-size: 20pt;
  }

  #conteneurDoss {
    display: grid;
    grid-template-columns: none;
    justify-content: center;
    align-items: center;
    background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  }

  #btnRetourVueMobile {
    display: initial;
  }

  #btnSuivantVueMobile {
    display: initial;
  }

  /* Styles */
}
</style>
