<template>
  <div v-if="load < 1">
    <Loader />
  </div>
  <div class="background" v-else>
    <HeaderConduite :eleve="eleve" />
    <HeaderConduitePlanning :eleve="eleve" :acc="eleve.acc" @setDisplayResaDispo="setDisplayResaDispo"/>
    <template v-if="this.$store.state.showMonoElevePage == true">
      <Moniteurs
        :eleve="eleve"
        :choixDispo="eleve.choixDispo[0]"
        @getEleveInfo="getEleveInfo"
        :modalChoixEleve="false"
      />
    </template>
    <template v-else>
      <PlanningConduite
        :eleve="eleve"
        @getEleveInfo="getEleveInfo"
        ref="headRef"
      />
    </template>
  </div>
</template>

<script>
import HeaderConduite from "@/components/eleve/conduite/HeaderConduite.vue";
import HeaderConduitePlanning from "@/components/eleve/conduite/HeaderConduitePlanning.vue";
import Moniteurs from "@/components/eleve/conduite/Moniteurs.vue";
import PlanningConduite from "@/components/eleve/conduite/PlanningConduite.vue";
import Loader from "@/components/Loader.vue";
import { ref } from "vue";

export default {
  setup() {
    const headRef = ref();
    const setDisplayResaDispo = (show) => headRef.value.setDisplayResaDispo(show);


    return {
      headRef,
      setDisplayResaDispo
    };
  },

  props: [],

  components: {
    HeaderConduite,
    HeaderConduitePlanning,
    Moniteurs,
    Loader,
    PlanningConduite,
  },

  watch: {},

  data() {
    return {
      load: 0,
      eleve: null,
    };
  },

  mounted() {
    this.getEleveInfo();
  },

  methods: {
    getEleveInfo() {
      window.api
        .get("/eleve/conduite/" + this.$store.state.user.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.eleve = response.data.eleve[0];
          this.load = this.load + 1;
          if (this.eleve.choixDispo == "null") {
            this.$store.commit("setShowMonoElevePage", true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
div.background {
  height: 100vh;
  width: 100%;
  background-image: url("../../../assets/images/Fond_ordinateur.png");
  background-size: cover;
}
</style>
