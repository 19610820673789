<template>
  <div class="box">
    <Toast />
    <div v-if="!showLog">
      <input type="text" v-model="code" @change="checkCode">
    </div>
    <div class="connexion" v-else>
      <router-link to="/"><h1>Monpermis.fr</h1></router-link>
      <router-link to="/">
        <img
          id="iconConnexion"
          :src="require('../assets/images/icon_connexion_.png')"
      /></router-link>

      <form @submit.prevent="login">
        <div class="field mt-5">
          <div class="control borderChamps">
            <input
              class="form-control"
              type="text"
              required
              id="fname"
              name="firstname"
              v-model="email"
              placeholder=" "
            />
            <label class="labelForm">ADRESSE MAIL</label>
          </div>
        </div>
        <br />
        <div  class="field mb-3">
          <div class="control borderChamps"  style="display:flex">
            <input
              class="form-control"
              type="password"
              required
              id="lname"
              name="lastname"
              v-model="password"
              placeholder=" "
            />
            
            <label class="labelForm">MOT DE PASSE</label>
            <i
              v-if="
                this.visibilityMdp === null ||
                this.visibilityMdp.type === 'password'
              "
              class="pi pi-eye-slash mt-3 me-2"
              style="font-size: 2.5rem; cursor: pointer"
              @click="changeMdpVisibility()"
            ></i>
            <i
              v-else-if="this.visibilityMdp.type === 'text'"
              class="pi pi-eye mt-3 me-2"
              style="font-size: 2.5rem; cursor: pointer"
              @click="changeMdpVisibility()"
            ></i>
          </div>
        </div>

        <template v-if="isWorking === false">
          <button
            class="btn btn-m mt-3 mb-5"
            type="submit"
            value="Connexion"
            id="btnConnexion"
          >
            <span> CONNEXION </span>
          </button>
        </template>
        <template v-else>
          <button
            class="btn btn-m mt-2 mb-5"
            type="submit"
            value="Connexion"
            id="btnConnexion"
            disabled
          >
            <span><i class="fa fa-spinner fa-spin"></i> CONNEXION </span>
          </button>
        </template>
      </form>

      <div id="footerConnexion">
        <a @click="this.displayMdpForm = true"> Mot de passe oublié ?</a>
        <router-link to="/Inscription">
          <a>Pas de compte ? Inscrivez-vous !</a>
        </router-link>
      </div>

      <b-alert variant="danger" v-if="error != ''" show>{{ error }}</b-alert>
    </div>
  </div>

  <Dialog
    :header="`Réinitialiser votre mot de passe !`"
    v-model:visible="this.displayMdpForm"
    :style="{ width: '50vw' }"
  >
    <div>
      <div class="field mt-5">
        <div class="control">
          <input
            class="form-control"
            type="text"
            required
            id="mail"
            name="mail"
            v-model="oublieForm.mail"
            placeholder=" "
          />
          <label class="labelForm">Mail</label>
        </div>
      </div>

      <center>
        <Button class="btnViolet mt-5" @click="checkIfUserExist()"
          >Suivant</Button
        >
      </center>
    </div>
  </Dialog>
</template>

<script>
import jwt_decode from "jwt-decode";
import { useToast } from "primevue/usetoast";

export default {
  data() {
    return {
      visibilityMdp: null,
      oublieForm: {
        mail: "",
      },
      isWorking: false,
      displayMdpForm: false,
      toast: useToast(),
      email: "",
      password: "",
      error: "",
      code : "",
      showLog : true
    };
  },

  methods: {


    checkCode(){
      if(this.code == "540827"){
        this.showLog = true;
      }
    },

    // Fonction permettant de changer la visibilité du mdp en modifiant le type du input en "text" ou "password"
    changeMdpVisibility() {
      this.visibilityMdp = document.getElementById("lname");
      if (this.visibilityMdp.type === "password") {
        this.visibilityMdp.type = "text";
      } else {
        this.visibilityMdp.type = "password";
        this.visibilityMdp = null;
      }
    },

    //permet de stocker dans le store le token et le user connecté
    login(event) {
      event.preventDefault();
      this.isWorking = true;
      window.api
        .post("/login_check", {
          username: this.email,
          password: this.password,
        })
        .then((response) => {
          this.error = "";
          let user = jwt_decode(response.data.token);
          this.$store.commit("setUser", user);
          this.$store.commit("setToken", response.data.token);
          this.$router.push("/home");
          this.isWorking = false;
        })
        .catch(() => {
          this.error = "Mot de passe ou mail incorrect";
          this.isWorking = false;
        });
    },

    async checkIfUserExist() {
      if (this.oublieForm.mail != "") {
        try {
          window.api
            .post("/checkIfUserExist", {
              email: this.oublieForm.mail,
            })
            .then((res) => {
              this.$emit("completed", res.data);
              this.toast.add({
                severity: "success",
                summary: "email envoyé",
                detail: "un email vous a été envoyé",
                life: 3000,
              });
              console.log("res", res);
            });
        } catch (e) {
          // handle authentication error here
        }
      }
    },
  },
};
</script>

<style scoped>
.btnViolet {
  background: #de6fa8;
  border-radius: 13px;
  border: none;
}

#footerConnexion {
  display: flex;
  justify-content: space-evenly;
}
.field {
  display: flex;
  justify-content: center;
}

.labelForm {
  position: absolute;
  pointer-events: none;
  left: 0.75rem !important;
  top: 18px !important;
  transition: 0.2s ease all;
  font-size: 25px !important;
  color: #444 !important;
}

.field input:focus ~ label,
input:not(:placeholder-shown) ~ label {
  top: -20px !important;
  font-size: 12px !important;
}

.control {
  position: relative;
  width: 60%;
}

.control input {
  width: 100%;
  border:none;
}


a {
  text-decoration: none;
  color: white !important;
  cursor: pointer;
}

.box {
  background: transparent linear-gradient(299deg, #fbbb5b 0%, #de6fa8 100%) 0%
    0% no-repeat padding-box !important;
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
}

.borderChamps {
  border-radius: 10px;
  border: solid 3px #fafafa;
}

#iconConnexion {
  width: 60%;
}

::placeholder {
  color: #f5f5f5;
  font-size: 20px;
  padding-left: 2%;
}

#btnConnexion {
  background: #2e9ea3 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 1;
  width: 60%;
  height: 70px;
}

#btnConnexion span {
  font-size: 170%;
  text-transform: uppercase;
  font: normal normal 800 30px/41px Galano Grotesque Alt;
  letter-spacing: 0px;
  color: #fbfbfb;
}

/* Mobile Styles */
@media only screen and (max-width: 400px) {
  input[type="text"],
  select {
    width: 100%;
    padding: 20px 20px;
    margin: 8px 0;
    display: inline-block;
    background: transparent;
    border-radius: 10px;
    box-sizing: border-box;
  }

  #iconConnexion {
    width: 60%;
  }

  select {
    width: 100%;
    padding: 20px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 30px;
    box-sizing: border-box;
  }

  #btnConnexion {
    background: #2e9ea3 0% 0% no-repeat padding-box;
    border-radius: 14px;
    opacity: 1;
    width: 90%;
    height: 70px;
  }

  h1 {
    color: #ffece0;
    font-size: 1.5rem;
    font-family: Galano Grotesque Alt Medium;
    word-wrap: break-word;
  }

  input[type="password"],
  select {
    width: 100%;
    padding: 20px 20px;
    margin: 8px 0;
    display: inline-block;
    background: transparent;
    border-radius: 10px;
    box-sizing: border-box;
  }

  div.connexion {
    border-radius: 10px;
    width: 90%;
    margin: auto;
    margin-top: 70px;
    padding: 60px;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  h1 {
    color: #ffece0;
    font-size: 2.5rem;
    word-wrap: break-word;
  }

  #iconConnexion {
    width: 100%;
  }

  input[type="text"],
  select {
    width: 100%;
    padding: 20px 20px;
    margin: 8px 0;
    display: inline-block;
    background: transparent;
    box-sizing: border-box;
  }

  #btnConnexion {
    background: #2e9ea3 0% 0% no-repeat padding-box;
    border-radius: 14px;
    opacity: 1;
    width: 90%;
    height: 70px;
  }

  input[type="password"],
  select {
    width: 100%;
    padding: 20px 20px;
    margin: 8px 0;
    display: inline-block;
    background: transparent;
    box-sizing: border-box;
  }

  div.connexion {
    border-radius: 10px;
    width: 80%;
    margin: auto;
    margin-top: 70px;
    padding: 60px;
  }
}

/* Desktop Styles */
@media only screen and (min-width: 961px) {
  h1 {
    color: #ffece0;
    font-size: 6rem;
    word-wrap: break-word;
  }

  div.connexion {
    border-radius: 10px;
    width: 60%;
    margin: auto;
    padding: 40px;
  }

  h2 {
    color: #ffece0;
    font-size: 2.5em;
    border-width: 0.1px;
    display: inline-block;
  }

  a {
    color: #ffece0;
  }

  .text-field,
  text {
    position: relative;
    margin: 10px 2.5px 20px 2.5px;
  }

  input {
    display: inline-block;
    color: #444;
    padding: 5px 5px 5px 5px;
    height: 75px;
    background: transparent;
    
  }

  input:focus {
    border: thin solid #ffffff;
    border-bottom: solid medium #ffffff;
    background: transparent;
  }

  label {
    color: #999;
    position: absolute;
    pointer-events: none;
    left: 10px;
    top: 10px;
    transition: 0.2s;
  }

  input:focus ~ label,
  input:valid ~ label {
    top: -10px;
    left: 15px;
    font-size: small;
    color: #32cd32;
    padding: 0 5px 0 5px;
  }
}
</style>
