<template>
  <div id="conteneurLycee">
    <Toast />
    <div id="lycee" class="container">
      <header id="head">
        <DropdownNav></DropdownNav>
        <router-link to="/home"
          ><i class="pi pi-home me-3" id="iconHome" style="font-size: 2rem"></i
        ></router-link>
        <h1 class="ms-3" id="titreLycee">Lycee</h1>
        <i
          class="pi pi-plus-circle ms-3 mt-2"
          id="iconHome"
          @click="openAddForm()"
          style="font-size: 1.5rem"
        ></i>
        <div class="flex justify-self-flex-end">
          <span class="p-input-icon-left">
            <input
              class="ms-5"
              v-model="filters['global'].value"
              placeholder="Rechercher"
            />
          </span>
        </div>
        <i class="pi pi-search me-2 ms-2 mt-3" />
      </header>
      <div>
        <DataTable
          :value="this.lycees"
          :rows="8"
          :paginator="true"
          :rowsPerPageOptions="[8, 10, 20, 50]"
          v-model:filters="filters"
          filterDisplay="menu"
          responsiveLayout="scroll"
          dataKey="id"
          v-model:selection="selectedLycee"
          selectionMode="single"
          :globalFilterFields="[
            'nom',
            'adresse',
            'codePostal',
            'ville',
            'nbrEleves',
          ]"
          @click="changeBackground"
        >
          <Column
            field="nom"
            header="Nom"
            :sortable="true"
            style="min-width: 6rem"
          >
          </Column>

          <Column
            field="adresse"
            header="Adresse"
            :sortable="true"
            style="min-width: 6rem"
          ></Column>

          <Column
            field="codePostal"
            header="Code postal"
            :sortable="true"
            style="min-width: 6rem"
          ></Column>

          <Column
            field="ville"
            header="ville"
            :sortable="true"
            style="min-width: 6rem"
          ></Column>

          <Column
            header="nbr Eleves"
            :exportable="false"
            style="min-width: 8rem"
          >
            <template #body="slotProps">
              <span @click="showElevesByLycee()">
                {{ slotProps.data.nbrEleves }}
              </span>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <div id="lyceeChoisi">
      <div
        v-if="this.selectedLycee == null && this.addForm == false"
        id="styleTitreChoix"
      >
        <p id="titreChoix">Cliquez sur un Lycee</p>
      </div>

      <template v-if="this.selectedLycee != null && this.showEleves === false">
        <div class="container">
          <h2 class="text-center">{{ this.selectedLycee.nom }}</h2>

          <div class="row pt-4">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="nom"
                    required
                    v-model="this.selectedLycee.nom"
                    @keydown="errors = ''"
                  />
                  <label>Nom</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="adresse"
                    required
                    v-model="this.selectedLycee.adresse"
                    @keydown="errors = ''"
                  />
                  <label>Adresse</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <br />
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="codePostal"
                    required
                    v-model="this.selectedLycee.codePostal"
                    @keydown="errors = ''"
                  />
                  <label>Code postal</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <br />
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="ville"
                    required
                    v-model="this.selectedLycee.ville"
                    @keydown="errors = ''"
                  />
                  <label>Ville</label>
                </div>
              </div>
            </div>
          </div>

          <div id="btnContainer">
            <Button class="btnRouge" @click="deleteLycee()">Supprimer</Button>
            <Button class="btnViolet" @click="putLycee()">Modifier</Button>
          </div>
        </div>
      </template>

      <template v-if="this.addForm === true && this.selectedLycee === null">
        <div class="container">
          <h2 class="mb-5">Ajoutez un lycée</h2>
          <div class="row pt-4">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="nom"
                    required
                    v-model="form.nom"
                    @keydown="errors = ''"
                  />
                  <label>Nom</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="adresse"
                    required
                    v-model="form.adresse"
                    @keydown="errors = ''"
                  />
                  <label>Adresse</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <br />
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="codePostal"
                    required
                    v-model="form.codePostal"
                    @keydown="errors = ''"
                  />
                  <label>Code postal</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <br />
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="ville"
                    required
                    v-model="form.ville"
                    @keydown="errors = ''"
                  />
                  <label>Ville</label>
                </div>
              </div>
            </div>
          </div>

          <div id="btnContainer">
            <Button class="btnViolet" @click="postLycee()">Valider</Button>
          </div>
        </div>
      </template>

      <template v-else-if="showEleves === true">
        <div class="container pt-4">
          <Button class="mb-3 btnViolet" @click="this.showEleves = false"
            >Retour</Button
          >
          <DataTable
            :value="this.elevesByLycee"
            :rows="7"
            v-model:filters="filters"
            filterDisplay="menu"
            :rowsPerPageOptions="[7, 10, 20, 50]"
            :paginator="true"
            responsiveLayout="scroll"
            dataKey="id"
          >
            <Column
              field="nom"
              header="Nom"
              :sortable="true"
              style="min-width: 5rem"
            >
            </Column>

            <Column
              field="prenom"
              header="Prenom"
              :sortable="true"
              style="min-width: 6rem"
            ></Column>

            <Column
              field="Dossier.social"
              header="Statut"
              :sortable="true"
              style="min-width: 6rem"
            ></Column>

            <Column
              field="Dossier.lycee"
              header="Info"
              :sortable="true"
              style="min-width: 6rem"
            >
            </Column>
          </DataTable>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { useToast } from "primevue/usetoast";
import { FilterMatchMode } from "primevue/api";
import { mapActions } from "vuex";
import DropdownNav from "@/components/DropdownNav.vue";


export default {
    name: "App",
    component: {
        DropdownNav
    },
    data() {
        return {
            selectedLycee: null,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            addForm: false,
            lycees: [],
            showEleves: false,
            lycee: {},
            toast: useToast(),
            form: { nom: "", adresse: "", codePostal: "", ville: "" },
        };
    },
    mounted() {
        this.getLycees();
        this.getEleveAdmin();
    },
    methods: {
        ...mapActions(["getEleveAdmin"]),
        showElevesByLycee() {
            (this.showEleves = true),
                (this.elevesByLycee = this.ElevesAdmin.filter((e) => e.Dossier.lyceeId === this.selectedLycee.id));
        },
        async getLycees() {
            try {
                window.api
                    .get("/lycees", {
                    headers: {
                        Authorization: "Bearer " + this.$store.state.token,
                    },
                })
                    .then((res) => {
                    this.lycees = res.data["hydra:member"];
                    console.log(this.agences);
                    console.log(this.$store.state.token);
                    this.nbrEleveByLycee();
                });
            }
            catch (e) {
                // handle authentication error here
            }
        },
        nbrEleveByLycee() {
          console.log("lycees" , this.lycees)
            this.lycees.forEach((l) => {
                let tableauEleve = [];
                l.dossiers.forEach((doss) => {
                    if (doss.eleve.active === true) {
                        tableauEleve.push(doss);
                    }
                });
                let nbrEleves;
                nbrEleves = tableauEleve.length;
                l["nbrEleves"] = nbrEleves;
            });
            console.log("nbr", this.lycees);
        },
        async deleteLycee() {
            try {
                if (confirm("Voulez vous vraiment supprimer ?")) {
                    window.api
                        .delete("lycees/" + this.selectedLycee.id, {
                        headers: {
                            Authorization: "Bearer " + this.$store.state.token,
                        },
                    })
                        .then((response) => {
                        this.toast.add({
                            severity: "success",
                            summary: "Lycée supprimé",
                            detail: "Votre Lycée à été supprimé avec succès",
                            life: 3000,
                        });
                        this.getLycees();
                        console.log(response);
                        console.log(this.$store.state.token);
                    });
                }
            }
            catch (e) {
                // handle authentication error here
            }
        },
        changeBackground() {
            if (this.selectedLycee != null) {
                document.getElementById("lyceeChoisi").style.background = "none";
            }
            this.nbrEleveByLycee();
        },
        openAddForm() {
            this.selectedLycee = null;
            this.addForm = true;
            document.getElementById("lyceeChoisi").style.background = "none";
        },
        putLycee() {
            window.api
                .put(`lycees/` + this.selectedLycee.id, this.selectedLycee, {
                headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                },
            })
                .then((response) => {
                this.getLycees();
                this.toast.add({
                    severity: "success",
                    summary: "Lycée modifié",
                    detail: "Votre Lycée à été modifié avec succès",
                    life: 3000,
                });
                this.$emit("completed", response.data);
            })
                .catch(function (error) {
                console.log(error.response);
            });
        },
        async postLycee() {
            window.api
                .post("/lycees", this.form, {
                headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                },
            })
                .then((response) => {
                this.toast.add({
                    severity: "success",
                    summary: "Lycée ajouté",
                    detail: "Votre Lycée à été ajouté avec succès",
                    life: 3000,
                });
                this.getLycees();
                this.$emit("completed", response.data);
            })
                .catch((error) => {
                // handle authentication and validation errors here
                console.log("ERRR::", error.response.data);
            });
        },
    },
    computed: {
        ElevesAdmin() {
            return this.$store.state.ElevesAdmin;
        },
    },
    components: { DropdownNav }
};
</script>

<style scoped>
#btnContainer {
  margin-top: 15%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

#iconHome {
  cursor: pointer;
}

.btnRouge {
  background: #ec2b22;
  border-radius: 13px;
  border: none;
}
.btnViolet {
  background: #de6fa8;
  border-radius: 13px;
  border: none;
}

a {
  text-decoration: none;
}

#formModif {
  margin: auto;
  width: 100%;
}

#head {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;
  width: 50%;
  margin-top: 5%;
}

#add {
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  top: 40px;
}

#titreChoix {
  flex-wrap: wrap;
  font-size: 2rem;
  color: white;
}

#styleTitreChoix {
  background-color: rgb(255, 255, 255, 0.47);
  padding: 1%;
  border-radius: 13px;
}

#lycee {
  background-image: url("../../../assets/images/fondd.png");
  background-size: cover;
  border-right: 1px solid black;
}

#lyceeChoisi {
  width: 100%;
  background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#titreLycee {
  font-size: 25pt;
  font-weight: bold;
  color: #2e9ea3;
}

#conteneurLycee {
  display: grid;
  grid-template-columns: 50% 50%;
}

#conteneurLycee div {
  justify-content: space-evenly;
}

.text-field,
text {
  position: relative;
  margin: 10px 2.5px 20px 2.5px;
}

input {
  display: inline-block;
  border: thin solid #fafafa;
  color: #444;
  background-color: #fafafa;
  padding: 10px 10px 10px 10px;
}

input:focus {
  border: thin solid #ffaa71;
  border-bottom: solid medium #ffaa71;
  background-color: #fff;
}

label {
  color: #999;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 10px;
  transition: 0.2s;
}

input:focus ~ label,
input:valid ~ label {
  top: -10px;
  left: 15px;
  font-size: small;
  color: #167794;
  background-color: #fff;
  padding: 0 5px 0 5px;
}
</style>
