<template>
  <template v-if="load == 2">
    <Toast />
    <template v-if="this.checkCodePaiement == false">
      <div id="conteneur">
        <Toast />
        <div id="eleve" class="container">
          <header id="head">
            <router-link to="/home"><i class="pi pi-home me-3" id="iconHome" style="font-size: 2rem"></i></router-link>
            <h1 class="ms-3" id="titre">Code</h1>
            <div class="ms-5 mt-2">
              <p class="mt-1">
                {{ this.$store.state.user.nom }} -
                {{ this.$store.state.user.prenom }}
              </p>
            </div>
          </header>
          <div id="code" class="mt-4">
            <p>
              Nous vous mettons à dispositions les outils qui assureront votre
              réussite.
            </p>
            <ul>
              <li>Un livre de code</li>
              <li>Un Prépacode: Accès internet d'un an à 150 tests</li>
            </ul>

            <p>Montant: {{ filterFonctionnalite.montant }} €</p>

            <BoutonPaiement @paiement="goToPaiement($event)"></BoutonPaiement>
          </div>
        </div>

        <div id="right">
          <div id="contentRight">
            <h1 class="mt-5" style="color: white">La Bonne méthode</h1>

            <h2>|) D’abord travailler les thèmes</h2>
            <h3>1) Livre de code: lire le 1er thème</h3>
            <h3>2) Prépacode : faire les tests du 1er thème</h3>
            <h3>3) Faire de même pour chaque thèmes</h3>

            <h2>||) Ensuite travailler les séries d'entrainement</h2>
            <h3>1) Faire plusieurs séries d'entrainement</h3>
            <h3>2) Vérifier votre niveau par thème pour chaque série et
          relire les thèmes affichant un mauvais score</h3>
            <h3>3) A la fin de chaque session, reporter le dernier score série d'entrainement (/40) dans le champ prévu sur la gauche</h3>
            <h2>|||) Enfin, travailler les examens blancs</h2>
            <h2>
              IV) Vous avez au moins 35/40 de manière régulière ? 
        => Réservez votre place d’examen
            </h2>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div id="conteneur">
        <Toast />
        <div id="eleve" class="container">
          <header id="head">
            <router-link to="/home"><i class="pi pi-home me-3" id="iconHome" style="font-size: 2rem"></i></router-link>
            <h1 class="ms-3" id="titre">Code</h1>
            <div class="ms-5 mt-2">
              <p class="mt-1">
                {{ this.$store.state.user.nom }} -
                {{ this.$store.state.user.prenom }}
              </p>
            </div>
          </header>
          <div id="code" class="mt-4">

            <div class="prepacode">
              <p class="subtitle">1. Préparation</p>
              
              <div class="content">
                <a href="https://www.prepacode-enpc.fr/connexion" class="link" target="_blank"><p>ACCES AU PREPACODE</p></a>
                <div class="form">
                  <template v-if="this.student.prepacode == null">
                    <p >Vous recevrez vos identifiants prépacode d'ici 48 / 72h</p>
                  </template>
                  <template v-if="this.student.prepacode != null">
                    <p >Les identifiants Prepacode vous ont été envoyés par mail</p>
                    <p v-if="this.student.prepacode[0].status == null">Vous recevrez vos identifiants prépacode d'ici 48 / 72h</p>
                  </template>

                    <template v-if="this.student.prepacode != null">
                      <template v-if="this.student.prepacode[0].status != null">
                        <button
                          class="btn mt-4 btn-primary"
                          v-if="student.prepacode[0].status == 'ok'"
                          @click="sendId(student)"
                        >
                        RECEVOIR À NOUVEAU MES IDENTIFIANTS PAR MAIL
                        </button>
                      </template>

                    </template>


                </div>
              </div>

              <div class="score" v-if="student.prepacode != null">
                <template v-if="student.prepacode[0].status == 'ok'">
                  <template v-if="student.score == null">
                    <select
                      name="score"
                      id="score"
                      class="selectInputStyle"
                      v-model="scoreprepa"
                      @change="changeScore(student)"
                      title="Dernier score série d'entrainement (/40) ?"
                    >
                      <option value="null" disabled>Dernier score</option>
                        
                      <option
                        v-for="(s, index) in score"
                        :value="s.value"
                        :key="index"
                      >
                        {{ s.value }} / 40
                      </option>
                    </select>
                  </template>
                  <template v-else>
                    <p>Score au dernier TEST : <button class="btn btn-danger" @click="deleteScore(student)">{{student.score}}/40</button></p> 
                  </template>
                </template>



                
              </div>
            </div>

            <div class="examen">
              <p class="subtitle">2. Examen de code</p>
              
              <div class="content">
                <a  class="link" target="_blank"><p>RESERVER VOTRE PLACE D'EXAMEN TUTO (À VENIR)</p></a>
                <div class="form">
                  <p>Sasir la date d'examen que vous avez réservé dans un centre agrée</p>

                  <template v-if="student.examen == null">
                    <input
                      type="date"
                      name="examen"
                      id="examen"
                      v-model="dateExamen"
                      @change="changedateExam(student)"
                    />
                  </template>
                  <template v-else>
                    <button class="btn btn-danger" @click="deletedateExam(student)">
                      {{ student.examen }}
                    </button>
                  </template>

                
                  <template v-if="student.resultat == null">
                    <select
                      name="score"
                      id="scoreExam"
                      v-model="resultatExam"
                      @change="changeResultat(student)"
                    >
                      <option value="null" disabled>Score réalisé à  l'examen ?</option>

                      <option
                        v-for="(s, index) in score"
                        :value="s.value"
                        :key="index"
                      >
                        {{ s.value }} / 40
                      </option>
                    </select>
                  </template>
                  <template v-else>
                      <p class="mt-3">Résultat à l'examen : <button class="btn btn-danger" @click="deleteResultat(student)">{{student.resultat}}/40</button></p> 
                  </template>
                </div>
              </div>
                <template v-if="student.resultat >= 35">
                  <p>Vous avez obtenu votre code. Passons à la conduite !</p>
                </template>
                <template v-else-if="student.resultat != null">
                  <p>Vous avez échoué à l'examen. Merci de réserver une nouvelle date</p>
                </template>
            </div>


          </div>
        </div>

        <div id="right">
          <div id="contentRight">
            <h1 class="mt-5" style="color: white">La Bonne méthode</h1>

            <h2>|) D’abord travailler les thèmes</h2>
            <h3>1) Livre de code: lire le 1er thème</h3>
            <h3>2) Prépacode : faire les tests du 1er thème</h3>
            <h3>3) Faire de même pour chaque thèmes</h3>

            <h2>||) Ensuite travailler les séries d'entrainement</h2>
            <h3>1) Faire plusieurs séries d'entrainement</h3>
            <h3>2) Vérifier votre niveau par thème pour chaque série et
          relire les thèmes affichant un mauvais score</h3>
            <h3>3) A la fin de chaque session, reporter le dernier score série d'entrainement (/40) dans le champ prévu sur la gauche</h3>
            <h2>|||) Enfin, travailler les examens blancs</h2>
            <h2>
              IV) Vous avez au moins 35/40 de manière régulière ? 
        => Réservez votre place d’examen
            </h2>
          </div>
        </div>
      </div>
    </template>
  </template>
  <template v-else>
    <Loader />
  </template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BoutonPaiement from "@/components/eleve/BoutonPaiement.vue";
import Loader from "@/components/Loader.vue";
import { useToast } from "primevue/usetoast";

export default {
  name: "CodeAccueil",
  components: {
    BoutonPaiement,
    Loader
  },
  data() {
    return {
      error: "",
      otherPaiement: null,
      paiement: [],
      haveAccount: false,
      student : [],
      visibilityMdp: null,
      prepacode : {
        identifiant : "",
        password : "",
      },
      showForm : false,
      score: null,
      scoreprepa: null,
      dateExamen : null,
      resultatExam: null,
      checkCodePaiement : false,
      load : 0,
      toast: useToast(),
    };
  },
  mounted() {
    this.checkOnePaiement("code");
    this.getFonctionnalite();
    this.getStudentCodeInfo();
  },
  methods: {
    ...mapActions(["getFonctionnalite"]),

    changeResultat(student) {
      window.api
        .patch(
          "/prepacodes/" + student.prepacode[0].id,
          {
            resultExamen: this.resultatExam,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          this.getStudentCodeInfo();
          
          if(this.resultatExam < 35){
            this.toast.add({
              severity: "warn",
              summary: "Dommage ! retentez votre chance !",
              detail: "Vous avez échoué à l'examen. Merci de réserver une nouvelle date",
              life: 5000,
            });
          }else{
            this.toast.add({
              severity: "success",
              summary: "Félicitaion",
              detail: "Vous avez obtenu votre code. Passons à la conduite !",
              life: 5000,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteResultat(student) {
      let res = confirm("Êtes-vous sûr de vouloir supprimer votre resultat à l'examen ?");
      if(res){
        window.api
        .patch(
          "/prepacodes/" + student.prepacode[0].id,
          {
            resultExamen: null,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          this.getStudentCodeInfo();
        })
        .catch((error) => {
          console.log(error);
        });
      }

    },

    changeScore(student) {
      window.api
        .patch(
          "/prepacodes/" + student.prepacode[0].id,
          {
            scorePrepaCode: this.scoreprepa,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          this.getStudentCodeInfo();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteScore(student) {
      let res = confirm("Êtes-vous sûr de vouloir supprimer votre score ?");
      if(res){
        window.api
        .patch(
          "/prepacodes/" + student.prepacode[0].id,
          {
            scorePrepaCode: null,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          this.getStudentCodeInfo();
        })
        .catch((error) => {
          console.log(error);
        });
      }

    },

    changedateExam(student) {
      window.api
        .patch(
          "/prepacodes/" + student.prepacode[0].id,
          {
            dateExamen: this.dateExamen,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          this.getStudentCodeInfo();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deletedateExam(student) {
      let res = confirm("Êtes-vous sûr de vouloir supprimer la date de votre examen ?");
      if(res){
        window.api
        .patch(
          "/prepacodes/" + student.prepacode[0].id,
          {
            dateExamen: null,
            resultExamen: null,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          this.resultatExam = null;
          this.student.resultat = null;
          this.getStudentCodeInfo();
        })
        .catch((error) => {
          console.log(error);
        });
      }

    },



    sendId(student){
      window.api
          .get("give/prepacode/" + student.id, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then(() => {
            this.getStudentCodeInfo();

            this.toast.add({
              severity: "success",
              summary: "ID Prepacode reçu dans votre boite mail",
              detail: "N'oubliez pas de regarder dans vos spams au cas où !",
              life: 6000,
            });

          })
          .catch((error) => {
            console.log(error);
          });
    },

    postPrepacode(student) {
      const date = new Date();
      // let id = "";
      // if (student.prepacode == null) {
      //   id = student.identifiant;
      // } else {
      //   id = student.prepacode[0].identifiant;
      // }

      window.api
        .post(
          "/code/prepacode/" + student.id,
          {
            identifiant: this.prepacode.identifiant,
            password: this.prepacode.password,
            datePrepaCode : date
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(() => {
          this.getStudentCodeInfo();
        })
        .catch((error) => {
          console.log(error);
        });
    },


    showPrepaForm() {
      if (this.showForm) {
        this.showForm = false;
      } else {
        this.showForm = true;
      }
    },

    getStudentCodeInfo(){
      window.api
        .get("/student/code/" + this.$store.state.user.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          console.log("info : ", response.data)
          this.showForm = false;
          this.student = response.data.student[0];

          let scoreTab = [];

          for (let index = 0; index <= 40; index++) {
            scoreTab.push({ value: index });
          }
          this.score = scoreTab;
          if(this.load != 2){
            this.load++;
          }
          

        })
        .catch((error) => {
          console.log(error);
        });
    },


    checkOnePaiement(label) {
      window.api
        .get("/student/"+this.$store.state.user.id+"/paiement/"+label, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.checkCodePaiement = response.data.paid;
          this.load++;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },


    async patchPrepaCode() {
      window.api
        .patch(
          "/prepacodes/" + this.prepacode.id,
          {
            identifiant: this.prepacode.identifiant,
            password: this.prepacode.password,
            utilisateur: "api/utilisateurs/" + this.$store.state.user.id,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then((response) => {
          console.log("completed", response.data);
        })
        .catch((error) => {
          // handle authentication and validation errors here
          console.log("ERRR::", error.response.data);
        });
    },

    goToPaiement(mode) {
      this.$store.dispatch("startSaveBouton", mode);
      let fonctionnalite = "code";
      let router = this.$router;
      this.$store.dispatch("endSaveBouton", { mode, fonctionnalite, router });
    },
  },
  computed: {
    ...mapGetters(["fonctionnalite"]),
    filterFonctionnalite() {
      if (this.fonctionnalite !== null) {
        return this.fonctionnalite.filter((el) => el.label === "code")[0];
      }
      return "";
    },
  },
};
</script>

<style scoped>



.selectInputStyle{
  width: 45%;
  background-color: white;
}

div.examen{
  border : 1px solid black;
  width: 80%;
  margin : auto;
  margin-top: 2em;
}

div.prepacode{
  border : 1px solid black;
  width: 80%;
  margin : auto;
}

div div.content{
  margin : auto;
  margin-bottom: 2em;
  display: flex;
  justify-content: space-evenly;
  height: 10em;
}

div div.content div.form{
  margin-right :5%;
  width: 40%;
}

div.prepacode div.content input, select{
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  padding-right: 10%;
  margin-top: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

div div.content a.link{
  border : 1px solid black;
  width: 40%;
  margin-left: 5%;
}

div.prepacode div.content a.link p{
  font-size: 30px;
  margin-top: 10%;
}

div.examen div.content a.link p{
  font-size: 26px;
  margin-top: 7%;
}

div.examen div.content div.form p{
  text-align: left;
}

div.examen div.content div.form input{
  display: block;
}

div p.subtitle{
  text-align: left;
  font-size: 30px;
  padding: 2%;
}

#formPrepacode {
  width: 50%;
  margin: auto;
}

#contentRight>h2 {
  text-align: left;
  color: white;
  margin-top: 7%;
}

#contentRight {
  width: 100%;
  margin-left: 5%;
}

#contentRight>h3 {
  text-align: left;
  margin-left: 10%;
  color: white;
}

#code>p {
  font-size: 1.5em;
  text-align: left;
}

#code>ul>li {
  font-size: 1.2em;
  text-align: left;
}

.btnViolet {
  background: #de6fa8;
  border-radius: 13px;
  border: none;
}

#containerInfoEleve {
  margin-top: 5%;
}

#iconHome {
  cursor: pointer;
}

a {
  text-decoration: none;
}

#head {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;
  width: 50%;
}

#right {
  width: 100%;
  background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  height: auto;
  justify-content: center !important;
  min-height: 100vh;
  display: flex;
}

#titre {
  font-size: 25pt;
  font-weight: bold;
  color: #2e9ea3;
}

#conteneur {
  display: grid;
  grid-template-columns: 50% 50%;
}

#conteneur div {
  justify-content: space-between;
}

.text-field,
text {
  position: relative;
  margin: 10px 2.5px 20px 2.5px;
}

input {
  display: inline-block;
  border: thin solid #fafafa;
  color: #444;
  background-color: #fafafa;
  padding: 10px 10px 10px 10px;
}

input:focus {
  border: thin solid #ffaa71;
  border-bottom: solid medium #ffaa71;
  background-color: #fff;
}

input:focus~label,
input:valid~label {
  top: -10px;
  left: 15px;
  font-size: small;
  color: #167794;
  background-color: #fff;
  padding: 0 5px 0 5px;
}
</style>
