<template>
  <div>
    <div class="header">
      <h3>CONTRAT DE FORMATION PROFESSIONNELLE</h3>
    </div>
    <h3 class="subTitre">ENTRE LES PARTIES</h3>
    <div class="header">
      <h3>VOUS</h3>
    </div>

    <div class="row">
      <p class="col-md-4">Nom / Prénom :</p>

      <p class="col-md-6">{{ user.nom }} {{ user.prenom }}</p>
    </div>
    <div class="row">
      <p class="col-md-2">Né(e) le :</p>
      <p class="col-md-4">
        {{ new Date(user.dossier.dateNaissance).toLocaleDateString("fr") }}
      </p>
      <p class="col-md-4">
        à :
        <span>{{ user.dossier.villeNaissance }}</span>
      </p>
    </div>

    <div class="row">
      <p class="col-md-2">Adresse :</p>
      <p class="col-md-4">{{ user.dossier.adresse }}</p>
    </div>

    <div class="row">
      <p class="col-md-3">Code Postal :</p>
      <p class="col-md-2">{{ user.dossier.codePostal }}</p>
      <p class="col-md-4">
        Ville :
        <span>{{ user.dossier.ville }}</span>
      </p>
    </div>

    <div class="row">
      <p class="col-md-3">Téléphone :</p>
      <p class="col-md-3">{{ user.telephone }}</p>
      <p class="col-md-2">
        Mail :
        <span>{{ this.$store.state.user.email }}</span>
      </p>
    </div>

    <div class="row">
      <p class="col-md-3">Status Social :</p>
      <p class="col-md-3">{{ social }}</p>
    </div>

    <div class="header">
      <h3>L'AUTO-ECOLE</h3>
    </div>

    <p>
      {{ agence.nom }}
      -
      {{ agence.adresse }}
      -{{ agence.codePostal }} {{ agence.ville }}
    </p>
    <p>
      Numéro Siret :
      {{ agence.siret }}
      - N°agrément :
      {{ agence.numAgrement }}
    </p>
    <p>
      N° de déclaration d'activité de formation : {{ agence.numDeclarActivite }}
    </p>
    <p>
      L’établissement de formation est assuré par : {{ agence.numAssurance }},
      N° police : {{ agence.numPoliceAssurance }}
    </p>
    <p>
      Souscripteur au Fond de garantie financière : par la caisse
      {{ agence.nomPoliceAssurance }}, sous le n°{{ agence.numFondGanrantie }}
      pour un montant maximum de
      {{ agence.montantFondGarantie }}€
    </p>
    <p>
      Cette souscription permet le remboursement des sommes trop perçues en cas
      de défaillance de l’Etablissement
    </p>
    <h3 class="subTitre">EST CONCLU LE PRESENT CONTRAT</h3>

    <div class="header">
      <h3>LA FORMATION</h3>
    </div>
    <h4 class="numeroTitle">I. INTITULE</h4>
    <p>Formation au Permis de Conduire catégorie "B"</p>
    <h4 class="numeroTitle">II. OBJET</h4>
    <p>
      En exécution du présent contrat, l’organisme de Formation s’engage à
      organiser l’action de formation intitulée « Formation au Permis de
      Conduire catégorie B ».
    </p>
    <h4 class="numeroTitle">
      III. NATURE ET FINALITE DE L’ACTION DE FORMATION
    </h4>
    <p>
      L’action de formation entre dans la 1 ère catégorie prévue à l’article
      L6313-1 de la sixième partie du Code du travail, à savoir les actions de
      formation. <br /><br />
      Elle a pour objet : la formation au Permis de catégorie B afin notamment
      de (conformément aux articles L6311-1 et L6313-3 du Code du travail) :
    </p>
    <ul>
      <li>
        permettre une évolution professionnelle au sein de l’entreprise actuelle
        ou en dehors de celle-ci ;
      </li>
      <li>
        développer une compétence professionnelle (en lien ou non avec le poste
        actuel) ;
      </li>
      <li>élargir la zone géographique de recherche d’emploi ;</li>
      <li>favoriser la mobilité professionnelle.</li>
    </ul>
    <p>Sa durée est définie ci-après.</p>
    <h4 class="numeroTitle">
      IV. NIVEAU DE CONNAISSANCES PREALABLES NECESSAIRE
    </h4>
    <p>L’élève est informé qu’aucun prérequis n’est nécessaire.</p>
    <h4 class="numeroTitle">V. DEMARCHES ADMINISTRATIVES</h4>
    <p>
      L’élève mandate l’établissement pour accomplir en son nom et place toutes
      les démarches et formalités nécessaires auprès de l’administration, en vue
      de l’enregistrement de son dossier d’inscription. Il atteste sur
      l&#39;honneur lors de la demande de permis de conduire qu&#39;il n&#39;est
      sous le coup d&#39;aucune restriction du droit de conduire ou d&#39;aucune
      interdiction de se présenter à nouveau. L’élève est avisé par
      l’établissement de la liste des documents à fournir pour constituer un
      dossier d’inscription. L&#39;établissement s’engage à déposer le dossier
      complet dans les meilleurs délais. <br /><br />
      Le dossier de l’élève appartient à l’élève et pourra lui être restitué à
      sa demande dès lors que l’élève sera en règle financière avec
      l’établissement.
    </p>
    <h4 class="numeroTitle">VI. ORGANISATION DE L’ACTION DE FORMATION</h4>
    <ul>
      <li>L’action de formation est organisée pour un effectif de 1 élève.</li>
      <li>
        Les conditions générales dans lesquelles la formation est dispensée,
        notamment les moyens pédagogiques et techniques, sont les suivantes :
      </li>
      <ul>
        <li>
          Moyens pédagogiques : livre de code, test de code sur internet,
          livrets d’apprentissage et de vérification, véhicules double commande
        </li>
        <li>Contrôle des connaissances : bilans</li>
        <li>Déroulement de la formation :</li>
        <ul>
          <li>Code : tests corrigés en ligne</li>
          <li>Conduite : en véhicule et sur simulateur</li>
        </ul>
        <li>
          Les diplômes, titres et références de(s) personne(s) chargée(s) de la
          formation sont : Diplôme du BEPECASER, titre professionnel
          d’enseignant de la conduite.
        </li>
      </ul>
    </ul>
    <h4 class="numeroTitle">VII. EVALUATION DU NIVEAU DU CANDIDAT</h4>
    <p>
      L&#39;établissement évalue le niveau du candidat en code et en conduite.
      Cette évaluation préalable a eu lieu le
      <template v-if="contract.contractEleve[0].piece.date == 'null'">
        {{ new Date().toLocaleDateString("fr") }}
      </template>
      <template v-else> {{ contract.contractEleve[0].piece.date }} </template>
      et a abouti à un nombre d’heures prévisionnel de formation. Ce volume de
      formation prévu est susceptible d&#39;être révisé par la suite, en
      fonction de la rapidité d’apprentissage de l’élève. Ci-dessous le résultat
      de votre évaluation :
    </p>

    <template v-if="questions != null">
      <table>
        <tr>
          <th>Compétences</th>
          <th>Score</th>
        </tr>
        <tr>
          <td>Code de la route</td>
          <td>{{ questions[0] }}</td>
        </tr>
        <tr>
          <td>Déjà Conduit</td>
          <td>{{ questions[1] }}</td>
        </tr>
        <tr>
          <td>Démarrage véhicule</td>
          <td>{{ questions[2] }}</td>
        </tr>
        <tr>
          <td>Carrefour à sens giratoire</td>
          <td>{{ questions[3] }}</td>
        </tr>
        <tr>
          <td>Autoroute</td>
          <td>{{ questions[4] }}</td>
        </tr>
        <tr>
          <td>Commandes</td>
          <td>{{ questions[5] }}</td>
        </tr>
        <tr>
          <td>Volant</td>
          <td>{{ questions[6] }}</td>
        </tr>
        <tr>
          <td>Démarrage</td>
          <td>{{ questions[7] }}</td>
        </tr>
        <tr>
          <td>Levier</td>
          <td>{{ questions[8] }}</td>
        </tr>
        <tr>
          <th>TOTAL</th>
          <th>
            {{
              questions[0] +
              questions[1] +
              questions[2] +
              questions[3] +
              questions[4] +
              questions[5] +
              questions[6] +
              questions[7] +
              questions[8]
            }}/10
          </th>
        </tr>
        <tr>
          <th>Nb d'heures prévisionnels</th>
          <th>
            Entre
            {{ questions[9][0] }}
            et
            {{ questions[9][1] }}
            h
          </th>
        </tr>
      </table>
    </template>

    <h4 class="numeroTitle">VII. PROGRAMME ET DEROULEMENT DE LA FORMATION</h4>
    <p>
      Le programme de l’action de formation est le suivant. <br /><br />
      Etape 1 : Maîtriser le maniement du véhicule dans un trafic faible ou nul
      <br /><br />
      Etape 2 : Appréhender la route et circuler dans des conditions normales
      <br /><br />
      Etape 3 : Circuler dans des conditions difficiles et partager la route
      avec les autres usagers
      <br /><br />
      Etape 4 : Pratiquer une conduite autonome, sûre et économique
      <br /><br />
      Le calendrier prévisionnel des séances de formation est établi par
      l&#39;établissement en concertation avec le candidat. Une heure de
      conduite en circulation se décompose généralement de la façon suivante :
    </p>
    <ul>
      <li>
        5 minutes : définition des objectifs en se référant au livret
        d&#39;apprentissage,
      </li>
      <li>
        45 à 50 minutes : conduite effective pour travailler les objectifs
        définis et évaluer les apprentissages,
      </li>
      <li>
        5 à 10 minutes : bilan et commentaires pédagogiques incluant la
        validation des objectifs et les annotations sur le livret
        d&#39;apprentissage.
      </li>
    </ul>

    <h4 class="numeroTitle">IX. RESPECT DU CALENDRIER DE FORMATION</h4>
    <p>
      Le candidat est tenu de respecter le calendrier prévisionnel de formation.
      Toute leçon ou cours non décommandé par l’élève sera dû et facturé, sans
      possibilité de report ou de remboursement, hormis pour le cas prévu
      ci-après. <br /><br />
      Toute annulation dans un délai supérieur à 48 heures avant le début du
      cours donnera automatiquement lieu à un re-crédit du solde permettant à
      l’élève de réserver un nouveau cours de conduite ou à un remboursement sur
      demande de l’élève par l’envoi d’un mail sur contact@monpermis.fr.
      <br /><br />
      L’établissement d’enseignement se réserve la possibilité d’annuler des
      cours ou leçons pour motif légitime dûment justifié, notamment dans tous
      les cas où la sécurité ne pourrait être assurée.
      <br /><br />
      Si un candidat décide de ne pas se présenter à l&#39;examen de code ou de
      conduite, il devra en avertir le centre de formation au minimum 5 jours
      ouvrés à l&#39;avance sous peine de perdre les frais afférents à cette
      prestation sauf motif légitime.
    </p>

    <h5>Concernant l&#39;examen théorique</h5>
    <p>
      En cas d&#39;ajournement, l&#39;élève sera représenté après avoir suivi
      une formation complémentaire destinée à corriger les causes de son
      ajournement et en fonction de la disponibilité de places d&#39;examens
      liée à l&#39;ordre de cette nouvelle présentation.
    </p>
    <h5>Concernant l&#39;examen de conduite</h5>
    <p>
      Pour être présenté à l&#39;examen de conduite, le candidat doit satisfaire
      à une évaluation de l’auto- école, pour déterminer son niveau. Si
      l&#39;élève a atteint le niveau requis, il sera présenté à l&#39;examen.
      <br /><br />
      Dans le cas où le niveau de l&#39;élève est jugé insuffisant, celui-ci
      sera invité à continuer sa formation en programmant des leçons de conduite
      supplémentaires.<br /><br />
      En cas de désaccord de l&#39;élève, une deuxième évaluation sera proposée.
      Si malgré tout, l&#39;élève persiste dans son refus de compléter sa
      formation, l&#39;élève pourra dans la mesure du possible être présenté à
      l&#39;examen, mais l&#39;établissement se réserve le droit de ne pas
      reprendre son dossier en cas d&#39;échec.
    </p>
    <h4 class="numeroTitle">X. OBLIGATIONS DES PARTIES</h4>
    <p>
      - L&#39;établissement : L&#39;établissement s&#39;engage à dispenser la
      formation et à présenter le candidat aux épreuves en fournissant les
      moyens nécessaires, sous réserve que le candidat ait atteint le niveau
      requis.<br /><br />
      - Le candidat : Le candidat doit respecter les recommandations
      pédagogiques des formateurs, le calendrier de formation et payer les
      prestations fournies.<br /><br />
      En cas de non-respect, l&#39;établissement se réserve la possibilité de
      surseoir à sa formation et/ou aux présentations aux épreuves du permis de
      conduire et ce sans remboursements des sommes versées.
    </p>
    <h4 class="numeroTitle">XI. REGLEMENT DES SOMMES DUES</h4>
    <p>
      Le candidat est tenu de régler les sommes dues. En cas de défaut de
      règlement, l&#39;établissement informera le candidat qu&#39;une
      résiliation du contrat aura lieu si celui-ci ne régularise pas sa
      situation dans les quinze jours suivants l&#39;avertissement de
      l&#39;établissement. Aucune présentation au code ou à la conduite ne
      pourra être demandée si le compte de l&#39;élève n&#39;est pas soldé. Si
      une présentation à l’examen est programmée, elle sera susceptible d’être
      annulée si l’élève n’est pas à jour de ses règlements une semaine avant la
      date de l’examen.
    </p>
    <h4 class="numeroTitle">XII. DELAI DE RETRACTATION</h4>
    <p>
      Conformément à l’article L 121-21-8 du Code la consommation, l’Elève
      dispose du droit de se rétracter dans un délai de quatorze jours, sans
      avoir à justifier de motifs ni à supporter de frais de résiliation.<br /><br />
      Le délai de rétractation expire dans un délai de quatorze jours à compter
      de la date à laquelle le Contrat a été conclu par les parties.
    </p>
    <h5>Modalités d’exercice du droit de rétractation</h5>
    <p>
      L’Elève qui souhaite exercer son droit de rétractation doit notifier à
      l’Auto-Ecole sa décision de rétractation au moyen d‘un mail de
      rétractation envoyé sur contact@monpermis.fr ou toute autre déclaration
      dénuée d’ambigüité sur sa volonté de faire valoir son droit de
      rétractation. L’Auto-Ecole adressera à l’Elève sans délai un accusé de
      réception de la rétractation par mail. Pour que le délai de rétractation
      rappelé dans le présent article soit respecté, l’Elève doit transmettre sa
      communication relative à l’exercice du droit de rétractation avant
      l’expiration du délai de rétractation.<br /><br />
      Conformément à la réglementation en vigueur, le droit à rétractation ne
      pourra être exercé dans les cas prévus à l’article 121-21-8 du Code de la
      consommation et notamment lorsque les prestations d’enseignement pratique
      de la conduite et de la sécurité routière ont été pleinement exécutées ou
      que leur exécution a commencé après accord préalable entre les parties et
      renoncement de l’Elève à faire valoir son droit de rétractation.<br /><br />
      Par conséquent, en réservant des prestations avant l’expiration du délai
      de rétractation, l’Elève accepte de bénéficier immédiatement des
      prestations de services et renonce expressément à son droit de
      rétractation.
    </p>
    <h5>Absence de droit de rétractation</h5>
    <p>
      Conformément à l’article L. 121-21-8 du Code de la Consommation,
      s’agissant d’un contenu numérique non fourni sur un support matériel, en
      confirmant sa souscription aux prestations proposées, l’Elève accepte de
      bénéficier immédiatement de l’accès aux outils de formation, et par
      conséquent, renonce expressément à son droit de rétractation.
    </p>
    <h4 class="numeroTitle">
      XIII. MOYENS PERMETTANT DE SUIVRE L’EXECUTION DE L’ACTION
    </h4>
    <p>Le suivi sera assuré :</p>
    <ul>
      <li>
        Pour la partie théorique : par la supervision des résultats à l’aide
        d’une application
      </li>
      <li>
        Pour la partie pratique : par la feuille récapitulative des heures de
        conduite
      </li>
    </ul>
    <h4 class="numeroTitle">
      XIV. MOYENS PERMETTANT D’APPRECIER LES RESULTATS DE L’ACTION
    </h4>
    <p>
      L’appréciation des résultats sera évaluée par l’intermédiaire de bilans
    </p>
    <h4 class="numeroTitle">XV. SANCTION DE LA FORMATION</h4>
    <p>
      En application de l’article L. 6353-1 du Code du travail, l’action de
      formation sera sanctionnée par la remise d’une attestation de fin de
      formation.
    </p>
    <h4 class="numeroTitle">XVI. DISPOSITIONS PRATIQUES</h4>
    <p>Lieu de formation : en ligne ou en véhicule ; Période de formation :</p>
    <ul>
      <li>
        Début : à la fin de la période de rétractation (ou à partir de la date
        de renonciation à la période de rétractation)
      </li>
      <li>Fin : au terme de la formation</li>
    </ul>
    <p>Prestations proposées par l’auto-école :</p>
    <table>
      <tr>
        <th>Prestations</th>
        <th>Montant</th>
        <th>Détail</th>
      </tr>
      <template v-for="f in fonc" :key="f.id">
        <tr>
          <td>{{ f.label }}</td>
          <td>{{ f.montant }} €</td>
          <td>{{ f.desc }}</td>
        </tr>
      </template>
    </table>
    <p>Les prix sont susceptibles de varier au 1er janvier de chaque année.</p>
    <h4 class="numeroTitle">XVII. ACTION DE FORMATION</h4>
    <p>
      En contrepartie des sommes reçues ou à percevoir, l&#39;organisme de
      formation s&#39;engage à réaliser l&#39;action de formation prévue dans le
      cadre du présent contrat.
    </p>
    <h4 class="numeroTitle">XVII. ACTION DE FORMATION</h4>
    <p>
      En cas de cessation anticipée de la formation du fait de l’organisme de
      formation ou l’abandon par l’élève pour un autre motif que la force
      majeure dûment reconnue, le présent contrat est résilié selon les
      modalités financières suivantes: paiement par l’élève de toutes les
      prestations qu’il a commencé à utiliser; paiement des heures réservées
      (incluant celles non décommandées au moins 48h à l’avance) sur la base du
      prix de l’heure à l’unité applicable au sein de l’organisme de formation
      au moment de la résiliation. Une attestation de formation sera remise par
      l’organisme de formation.
    </p>
    <h4 class="numeroTitle">XIX. NON-RESPECT DU REGLEMENT INTERIEUR</h4>
    <p>
      En cas en cas de non-respect du règlement intérieur par l’élève,
      l’organisme de formation en informe les différentes parties ainsi que
      l’élève.
      <br />
      Si aucune mesure corrective n’est apportée, l’organisme de formation est
      en droit d’interrompre la formation et de mettre un terme à la présente
      convention. Pour cela il en informe par lettre recommandé 15 jours à
      l’avance, les différentes parties, ainsi que l’élève. Dans ce cas les
      prestations fournies, entamées ou commandées demeurent dues.
    </p>
    <h4 class="numeroTitle">XX. CAS DE DIFFEREND</h4>
    <p>
      En cas de litige à raison de la présente convention, préalablement à toute
      action en justice, les parties peuvent recourir gratuitement, dans les
      conditions prévues aux articles L.612-1 et suivants et R.612-1 et suivants
      du code de la consommation, à un médiateur de la consommation en vue de la
      résolution amiable du litige.<br /><br />
      Si le litige n&#39;a pas pu être réglé à l’amiable, le tribunal de grande
      instance de Lyon sera seul compétent.<br /><br />
      Les coordonnées du médiateur de la consommation sont les suivantes:
    </p>

    <br />
    <p class="mention">Association des Médiateurs Européens (A.M.E.)</p>
    <p class="mention">
      197 boulevard Saint-Germain 75007 Paris - Téléphone : 09.53.01.02.69.
    </p>
    <p class="mention">
      Courriel : saisine@mediationconso-ame.com - Site internet :
      www.mediationconso-ame.com
    </p>
    <br />
    <p>
      A {{ user.dossier.ville }}, le
      <template v-if="contract.contractEleve[0].piece.date == 'null'">
        {{ new Date().toLocaleDateString("fr") }}
      </template>
      <template v-else> {{ contract.contractEleve[0].piece.date }} </template>
    </p>
    <br /><br />
    <div class="containerSign">
      <template v-if="contractStatus">
        <template v-if="ageE < 18">
          <div class="signatureDiv1">Signé par</div>

          <div class="signatureDiv2">Signé par</div>

          <div class="signatureDiv3">Signé par</div>

          <div class="signatureDiv1">Authentification par mail</div>

          <div class="signatureDiv2">Agence</div>

          <div class="signatureDiv3">Authentification par mail</div>
        </template>
        <template v-else>
          <div class="signatureDiv4">Signé par</div>
          <div class="signatureDiv5">Signé par</div>
          <div class="signatureDiv4">Authentification par mail</div>
          <div class="signatureDiv5">Agence</div>
        </template>
      </template>
      <template v-else>
        <template v-if="ageE < 18">
          <div class="signatureDiv1">Signature du candidat</div>

          <div class="signatureDiv2">Pour l&#39;établissement</div>

          <div class="signatureDiv3">Représentant légal</div>
        </template>
        <template v-else>
          <div class="signatureDiv3">Signature du candidat</div>

          <div class="signatureDiv4">Pour l&#39;établissement</div>
        </template>
      </template>
    </div>

    <div class="containerSign" v-if="contractStatus">
      <template template v-if="ageE < 18">
        <div class="signatureDiv1 signText">
          {{ user.nom }} {{ user.prenom }}
        </div>
        <div class="signatureDiv2 signText">{{ agence.nom }}</div>
        <div v-if="ageE < 18" class="signatureDiv3 signText">
          Représentant légal
        </div>
      </template>
      <template v-else>
        <div class="signatureDiv4 signText">
          {{ user.nom }} {{ user.prenom }}
        </div>

        <div class="signatureDiv5 signText">{{ agence.nom }}</div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "user",
    "fonc",
    "agence",
    "questions",
    "social",
    "contractStatus",
    "contract",
  ],
  data() {
    return {};
  },
};
</script>

<style scoped>
div.signText {
  font-family: cursive;
  font-style: oblique 50deg;
}

div.containerSign {
  width: 100%;
}

div.signatureDiv1 {
  display: inline-block;
  width: 32%;
  text-align: center;
}

div.signatureDiv2 {
  display: inline-block;
  width: 33%;
  text-align: center;
}

div.signatureDiv3 {
  display: inline-block;
  width: 32%;
  text-align: center;
}

div.signatureDiv4 {
  display: inline-block;
  width: 48%;
  text-align: center;
}

div.signatureDiv5 {
  display: inline-block;
  width: 48%;
  text-align: center;
}

div.header {
  border: 1px solid black;
}

div.header h3 {
  text-align: center;
}

p {
  text-align: left;
}

h4.numeroTitle {
  text-align: left;
  margin-left: 2em;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
}

td,
th {
  border: 1px solid black;
  text-align: left;
  padding: 8px;
}

p.mention {
  text-align: center;
}

h3.subTitre {
  text-align: left;
  margin-top: 1em;
  margin-bottom: 1em;
  font-weight: bold;
}

li {
  text-align: left;
}

p.signature {
  font-size: 20px;
  margin-bottom: 3em;
}
</style>
