<template>
  <body>
    <div class="container-fluid" id="archive">
      <Toast />

      <header>
        <DropdownNav></DropdownNav>
        <router-link to="/home"
          ><i
            class="pi pi-home me-3"
            id="iconHome"
            style="font-size: 2.2rem"
          ></i
        ></router-link>
        <h1 id="title" class="mb-4">
          Archive chez {{ this.$store.state.user.agence.nom }}
        </h1>
      </header>

      <main id="tableArchive">
        <DataTable
          :value="this.eleveArchived"
          :rows="10"
          v-model:filters="filters"
          filterDisplay="menu"
          :rowsPerPageOptions="[10, 20, 50]"
          responsiveLayout="scroll"
          dataKey="id"
        >
          <Column
            field="nom"
            header="Nom"
            :sortable="true"
            style="min-width: 12rem"
          >
          </Column>

          <Column
            field="prenom"
            header="Prenom"
            :sortable="true"
            style="min-width: 12rem"
          ></Column>

          <Column
            field="telephone"
            header="telephone"
            :sortable="true"
            style="min-width: 12rem"
          ></Column>

          <Column
            field="email"
            header="mail"
            :sortable="true"
            style="min-width: 12rem"
          ></Column>

          <Column
            header="Desarchiver"
            :exportable="false"
            style="min-width: 8rem"
          >
            <template #body="slotProps">
              <Checkbox
                :binary="true"
                @click="DesarchiveEleve(slotProps.data.id)"
              />
            </template>
          </Column>
        </DataTable>
      </main>
    </div>
  </body>
</template>

<script>
import { useToast } from "primevue/usetoast";
import DropdownNav from "@/components/DropdownNav.vue";

export default {
  name: "App",

  components: {
    DropdownNav,
  },

  data() {
    return {
      toast: useToast(),
      utilisateurs: [],
      eleveArchived: [],
    };
  },

  mounted() {
    this.getUtilisateurs();
  },

  methods: {
    getUtilisateurs() {
      window.api
        .get("/utilisateur", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.utilisateurs = response.data.utilisateurs;
          this.eleveArchived = this.utilisateurs.filter(
            (el) =>
              el.role.id === 1 &&
              el.active === false &&
              el.agence.id === this.$store.state.user.agence.id
          );
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    async DesarchiveEleve(id) {
      try {
        if (confirm("Voulez vous vraiment Desarchiver cette eleve ?")) {
          window.api
            .patch(
              "utilisateur/" + id,
              {
                active: true,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then((response) => {
              this.toast.add({
                severity: "success",
                summary: "Eleve Désarchivé",
                detail: "Votre eleve à été Désarchivé avec succès",
                life: 3000,
              });
              this.getUtilisateurs();
              console.log(response);
            });
        }
      } catch (e) {
        // handle authentication error here
      }
    },
  },
};
</script>

<style scoped>
#archive {
  background-image: url("../../assets/images/Fond_ordinateur.png");
  background-size: cover;
  width: 100%;
}

#title {
  font-size: 20pt;
}

header {
  display: flex;
  margin-top: 3%;
}

#tableArchive {
  display: flex;
  flex-direction: column;
  align-content: center;
  height: auto;
  min-height: 100vh;
  width: 100%;
}
</style>
