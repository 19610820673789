<template>
  <div id="conteneurExam">
      <div id="reservationExam" class="container">
        <h1 class="mt-4" id="titreReserv">Examen de <br />conduite</h1>

    
      </div>



      <div id="choixPageExam">

      </div>


  </div>
</template>




<script>

import '@fullcalendar/core/vdom' 
import "leaflet/dist/leaflet.css";


export default {
  name: 'ExamenConduite',
  components: {
   
  },
  
  data() {
      return {
       
       
      }
  },

  }

</script>

<style scoped>


#reservationExam p {
  display: flex;
  align-content:flex-start;
  font: normal normal 600 20px/27px Galano Grotesque Alt;
  font-size: 20pt;
}

#reservationExam  {
 background-image: url('../../../assets/images/fond.png');
 background-color: #f2f2f2;
 background-size: cover;
}


 

#choixPageExam {
  width: 100%;
  background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  height: 100vh;
}

#titreReserv {
    font-size: 45pt;
    font-weight: bold;
    color: black;
}



#conteneurExam {
  display: grid;
  grid-template-columns: 50% 50%;
  
}

#conteneurExam div {
 
 justify-content: space-evenly;
  
}






/* Mobile Styles */
@media only screen and (max-width: 400px) {

#choixPageExam {
  display : none;
  background: none;
          
}


#conteneurExam {
  display: grid;
  grid-template-columns: none;
  justify-content: center;
  align-items: center;
}


}


/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {

#choixPageExam {
  display : none;
  background: none;
}


#conteneurExam {
  display: grid;
  grid-template-columns: none;
  justify-content: center;
  align-items: center;
}

}


/* iPads (landscape) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {

#choixPageExam {
  display : none;
  background: none;
}



#conteneurExam {
  display: grid;
  grid-template-columns: none;
  justify-content: center;
  align-items: center;
}

/* Styles */
}

</style>









