<template>
  <div id="dossierFormulaire">
    <div class="container">
      <h1 class="titre">Dossier</h1>
      <p
        v-if="
          this.$store.state.user.role.label != 'eleve' ||
          this.$route.params.personalData === 'true'
        "
        id="etapeDossier"
      >
        Étape {{ etape }}/4
      </p>
      <p
        v-if="
          this.$store.state.user.role.label == 'eleve' &&
          this.$route.params.personalData === 'false'
        "
        id="etapeDossier"
      >
        Étape {{ etape }}/5
      </p>
      <template v-if="etape == 1">
        <div class="row">
          <div
            class="field offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12"
          >
            <input
              :value="userData.nom"
              @change="handleChangeNom($event.target.value)"
              type="text"
              id="nom"
              name="nom"
              class="form-control marge"
              :class="{ isNotValid: showValidation && !checkNom }"
              placeholder=" "
            />
            <label class="labelForm">Nom</label>
          </div>

          <div class="field col-lg-4 col-md-4 col-sm-12">
            <input
              :value="userData.prenom"
              @change="handleChangePrenom($event.target.value)"
              type="text"
              id="prenom"
              name="prenom"
              class="form-control marge"
              :class="{ isNotValid: showValidation && !checkPrenom }"
              placeholder=" "
            />
            <label class="labelForm">Prénom</label>
          </div>
        </div>

        <div class="row">
          <div
            class="field offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12 mt-4"
          >
            <input
              :value="userData.telephone"
              @change="handleChangeTelephone($event.target.value)"
              type="text"
              id="telephone"
              name="telephone"
              class="form-control inputsize"
              :class="{ isNotValid: showValidation && !checkTelephone }"
              placeholder=" "
            />
            <label class="labelForm">Téléphone</label>
          </div>

          <div class="field col-lg-4 col-md-4 col-sm-12 mt-4">
            <input
              :value="userData.mailEleve"
              type="text"
              id="email"
              name="email"
              class="form-control marge"
              disabled
              placeholder=" "
            />
            <label class="labelForm">mail</label>
          </div>

        </div>

        <div class="row">
          <div
            class="field offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12 mt-4"
          >
            <input
              :value="userData.dossier.nomUsage"
              @change="handleChangeNomUsage($event.target.value)"
              type="text"
              id="nomUsage"
              name="nomUsage"
              class="form-control marge"
              placeholder=" "
            />
            <label class="labelForm">Nom d'usage</label>
          </div>

          <div class="field col-lg-4 col-md-4 col-sm-12 mt-4">
            <input
              :value="userData.dossier.autresPrenom"
              @change="handleChangeAutrePrenom($event.target.value)"
              type="text"
              id="autresPrenom"
              name="autresPrenom"
              class="form-control marge"
              placeholder=" "
            />
            <label class="labelForm">Autres prénoms</label>
          </div>
        </div>

        <div class="row">
          <div
            class="field offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12 mt-4"
          >
            <input
              :value="userData.dossier.telParent"
              @change="handleChangeTelParent($event.target.value)"
              type="text"
              id="telParent"
              name="telParent"
              class="form-control marge"
              placeholder=" "
            />
            <label class="labelForm">Téléphone parent</label>
          </div>

          <div class="field col-lg-4 col-md-4 col-sm-12 mt-4">
            <input
              :value="userData.dossier.mailParent"
              @change="handleChangeMailParent($event.target.value)"
              type="text"
              id="mailParent"
              name="mailParent"
              class="form-control marge"
              placeholder=" "
            />
            <label class="labelForm">Mail parent</label>
          </div>
        </div>

        <div class="row">
          <div
            class="field offset-lg-2 offset-md-2 col-md-10 col-xs-10 col-lg-10 mt-4"
          >
            <input
              :value="userData.dossier.adresse"
              @change="handleChangeAdresse($event.target.value)"
              type="text"
              id="adresse"
              name="adresse"
              class="form-control inputsize"
              :class="{ isNotValid: showValidation && !checkAdresse }"
              placeholder=" "
            />
            <label class="labelForm">Adresse</label>
          </div>
        </div>

        <div class="row">
          <div
            class="field offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12 mt-4"
          >
            <input
              :value="userData.dossier.codePostal"
              @change="handleChangeCodePostal($event.target.value)"
              type="text"
              id="codePostal"
              name="codePostal"
              class="form-control"
              :class="{ isNotValid: showValidation && !checkCodePostal }"
              placeholder=" "
            />
            <label class="labelForm">Code Postal</label>
          </div>

          <div class="field col-lg-4 col-md-4 col-sm-12 mt-4">
            <input
              :value="userData.dossier.ville"
              @change="handleChangeVille($event.target.value)"
              type="text"
              id="ville"
              name="ville"
              class="form-control marge"
              :class="{ isNotValid: showValidation && !checkVille }"
              placeholder=" "
            />
            <label class="labelForm">Ville</label>
          </div>
        </div>
      </template>

      <template v-if="etape == 2">
        <div class="row">
          <div
            class="field offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12"
          >
            <input
              :value="dateNaissanceFormated"
              @change="handleChangeDateNaissance($event.target.value)"
              type="date"
              id="dateNaissance"
              name="dateNaissance"
              class="form-control marge"
              :class="{ isNotValid: showValidation && !checkDateNaissance }"
              placeholder=" "
            />
            <label class="labelForm">Date de naissance</label>
          </div>

          <div class="field col-lg-4 col-md-4 col-sm-12">
            <input
              :value="userData.dossier.paysNaissance"
              @change="handleChangePaysNaissance($event.target.value)"
              type="text"
              id="paysNaissance"
              name="paysNaissance"
              class="form-control marge"
              :class="{ isNotValid: showValidation && !checkPaysNaissance }"
              placeholder=" "
            />
            <label class="labelForm">Pays de naissance</label>
          </div>
        </div>

        <div class="row">
          <div
            class="field offset-lg-2 offset-md-2 col-md-10 col-xs-10 col-lg-10 mt-4"
          >
            <input
              :value="userData.dossier.villeNaissance"
              @change="handleChangeVilleNaissance($event.target.value)"
              type="text"
              id="villeNaissance"
              name="villeNaissance"
              class="form-control inputsize"
              :class="{
                isNotValid: showValidation && !checkVilleNaissance,
              }"
              placeholder=" "
            />
            <label class="labelForm">Ville de naissance</label>
          </div>

          <div
            class="field offset-lg-2 offset-md-2 col-md-10 col-xs-10 col-lg-10 mt-4"
          >
            <input
              :value="userData.dossier.depNaissance"
              @change="handleChangeDepNaissance($event.target.value)"
              type="text"
              id="depNaissance"
              name="depNaissance"
              class="form-control inputsize"
              :class="{ isNotValid: showValidation && !checkDepNaissance }"
              placeholder=" "
            />
            <label class="labelForm">Département de naissance</label>
          </div>

          <div
            class="field offset-lg-2 offset-md-2 col-md-10 col-xs-10 col-lg-10 mt-4"
          >
            <select
              :value="userData.dossier.nationaliteFR"
              @change="handleChangeNationaliteFR($event.target.value)"
              id="nationaliteFR"
              name="nationaliteFR"
              class="form-select inputsize"
              :class="{ isNotValid: showValidation && !checkNationaliteFR }"
              placeholder=" "
            >
              <option value="null" disabled selected>
                Nationalité française
              </option>
              <option :value="true">OUI</option>
              <option :value="false">NON</option>
            </select>
            <label class="labelForm">Nationalité française</label>
          </div>

          <div
            class="field offset-lg-2 offset-md-2 col-md-10 col-xs-10 col-lg-10 mt-4"
          >
            <select
              :value="userData.dossier.parlerFRCouramment"
              @change="handleChangeParlerFRCouramment($event.target.value)"
              id="parlerFRCouramment"
              name="parlerFRCouramment"
              class="form-select inputsize"
              :class="{
                isNotValid: showValidation && !checkParlerFRCouramment,
              }"
              placeholder=" "
            >
              <option value="null" disabled>
                Parlez-vous français couramment ?
              </option>
              <option :value="true">OUI</option>
              <option :value="false">NON</option>
            </select>
            <label class="labelForm">Parler vous français couramment ?</label>
          </div>
        </div>
      </template>

      <template v-if="etape == 3">
        <div class="row">
          <div
            class="field offset-lg-2 offset-md-2 col-md-10 col-xs-10 col-lg-10"
          >
            <select
              :value="userData.dossier.permis"
              @change="handleChangePermis($event.target.value)"
              id="permis"
              name="permis"
              class="form-select inputsize marge"
              :class="{ isNotValid: showValidation && !checkPermis }"
              placeholder=" "
            >
              <option value="null" disabled>
                Catégorie de permis demandée
              </option>

              <option
                v-for="(item, index) in permis"
                :value="'/api/permis/' + item.id"
                :key="index"
              >
                {{ item.label }}
              </option>
            </select>
            <label class="labelForm">Catégorie de permis demandée</label>
          </div>

          <div
            class="field offset-lg-2 offset-md-2 col-md-10 col-xs-10 col-lg-10 mt-4"
          >
            <select
              :value="userData.dossier.lunettes"
              @change="handleChangeLunettes($event.target.value)"
              id="lunettes"
              name="lunettes"
              class="form-select inputsize"
              :class="{ isNotValid: showValidation && !checkLunettes }"
              placeholder=" "
            >
              <option value="null" disabled>Portez-vous des lunettes ?</option>
              <option :value="true">OUI</option>
              <option :value="false">NON</option>
            </select>
            <label class="labelForm">Portez-vous des lunettes ?</label>
          </div>

          <div
            class="field offset-lg-2 offset-md-2 col-md-10 col-xs-10 col-lg-10 mt-4"
          >
            <select
              :value="userData.dossier.vehiculeAmenage"
              @change="handleChangeVehiculeAmenage($event.target.value)"
              id="vehiculeAmenage"
              name="vehiculeAmenage"
              class="form-select inputsize"
              :class="{
                isNotValid: showValidation && !checkVehiculeAmenage,
              }"
              placeholder=" "
            >
              <option value="null" disabled>
                Avez-vous besoin d'un véhicule aménagé ?
              </option>
              <option :value="true">OUI</option>
              <option :value="false">NON</option>
            </select>
            <label class="labelForm"
              >Avez-vous besoin d'un véhicule aménagé ?</label
            >
          </div>
        </div>

        <div class="row">
          <div
            class="field offset-lg-2 offset-md-2 col-md-10 col-xs-10 col-lg-10 mt-4"
          >
            <select
              :value="userData.dossier.prevoitAac"
              @change="handleChangePrevoitAac($event.target.value)"
              id="prevoitAac"
              name="prevoitAac"
              class="form-select inputsize"
              :class="{ isNotValid: showValidation && !checkPrevoitAac }"
              placeholder=" "
            >
              <option value="null" disabled>
                Prévoyez-vous une conduite accompagnée ?
              </option>
              <option :value="true">OUI</option>
              <option :value="false">NON</option>
            </select>
            <label class="labelForm"
              >Prévoyez-vous une conduite accompagnée ?</label
            >
          </div>

          <div
            class="field offset-lg-2 offset-md-2 col-md-10 col-xs-10 col-lg-10 mt-4"
          >
            <select
              :value="userData.dossier.faitSuite"
              @change="handleChangeFaitSuite($event.target.value)"
              id="faitSuite"
              name="faitSuite"
              class="form-select inputsize"
              :class="{ isNotValid: showValidation && !checkFaitSuite }"
              placeholder=" "
            >
              <option value="null" disabled>
                La présente demande fait-elle suite à une annulation /
                invalidation de permis ?
              </option>
              <option :value="true">OUI</option>
              <option :value="false">NON</option>
            </select>
            <label class="labelForm"
              >Fait suite à une annulation / invalidation de permis ?</label
            >
          </div>

          <div
            class="field offset-lg-2 offset-md-2 col-md-10 col-xs-10 col-lg-10 mt-4"
            :class="{
              isHide: !(
                userData.dossier.faitSuite === 'true' ||
                userData.dossier.faitSuite === true
              ),
            }"
          >
            <select
              :value="userData.dossier.motifAlcoolStup"
              @change="handleChangeMotifAlcoolStup($event.target.value)"
              id="motifAlcoolStup"
              name="motifAlcoolStup"
              class="form-select inputsize"
              :class="{
                isNotValid: showValidation && !checkMotifAlcoolStup,
              }"
              placeholder=" "
            >
              <option value="null" disabled>
                En raison d'une conduite sous alcoolémie ou stupéfiant ?
              </option>
              <option :value="true">OUI</option>
              <option :value="false">NON</option>
            </select>
            <label class="labelForm"
              >En raison d'une conduite sous alcoolémie ou stupéfiant ?</label
            >
          </div>
        </div>
      </template>

      <template v-if="etape == 4">
        <div class="row">
          <div
            class="field offset-lg-2 offset-md-2 col-md-10 col-xs-10 col-lg-10"
          >
            <select
              :value="userData.dossier.social"
              @change="handleChangeSocial($event.target.value)"
              id="social"
              name="social"
              class="form-select inputsize"
              :class="{ isNotValid: showValidation && !checkSocial }"
              placeholder=" "
            >
              <option value="null" disabled>Statut social</option>

              <option
                v-for="(item, index) in statutSocial"
                :value="'/api/statut_socials/' + item.id"
                :key="index"
              >
                {{ item.label }}
              </option>
            </select>
            <label class="labelForm">Statut social</label>
          </div>

          <div
            class="field offset-lg-2 offset-md-2 col-md-10 col-xs-10 col-lg-10 mt-4"
            :class="{ isHide: !isSalarie }"
          >
            <input
              :value="userData.dossier.metier"
              @change="handleChangeMetier($event.target.value)"
              type="text"
              id="metier"
              name="metier"
              class="form-control inputsize"
              placeholder=" "
            />
            <label class="labelForm">Métier</label>
          </div>

          <div
            class="field offset-lg-2 offset-md-2 col-md-10 col-xs-10 col-lg-10 mt-4"
            :class="{ isHide: !isLyceen }"
          >
            <select
              :value="userData.dossier.lycee"
              @change="handleChangeLycee($event.target.value)"
              id="lycee"
              name="lycee"
              class="form-select inputsize"
              placeholder=" "
            >
              <option value="null">Autre</option>
              <option
                v-for="(item, index) in lycees"
                :value="'/api/lycees/' + item.id"
                :key="index"
              >
                {{ item.nom }}
              </option>
            </select>
            <label class="labelForm">Lycée</label>
          </div>

          <div
            class="field offset-lg-2 offset-md-2 col-md-10 col-xs-10 col-lg-10 mt-4"
            :class="{ isHide: !isAutreLycee }"
          >
            <input
              :value="userData.dossier.autreLycee"
              @change="handleChangeAutreLycee($event.target.value)"
              type="text"
              id="autreLycee"
              name="autreLycee"
              class="form-control inputsize"
              placeholder=" "
            />
            <label class="labelForm">Autre lycée</label>
          </div>
        </div>
        <div
          class="row"
          v-if="
            etape == 4 &&
            (this.$store.state.user.role.label != 'eleve' ||
              this.$route.params.personalData === 'true')
          "
        >
          <div
            class="field offset-lg-2 offset-md-2 col-md-10 col-xs-10 col-lg-10 mt-4"
          >
            <input
              :value="userData.dossier.numNeph"
              @change="handleChangeNumNeph($event.target.value)"
              type="text"
              id="numNeph"
              name="numNeph"
              class="form-control inputsize"
              placeholder=" "
            />
            <label class="labelForm">Numéro NEPH</label>
          </div>
        </div>
      </template>

      <template
        v-if="
          etape == 5 &&
          this.$store.state.user.role.label == 'eleve' &&
          this.$route.params.personalData === 'false'
        "
      >
        <label>Avez-vous un numéro NEPH ?</label>
        <div>
          <button
            type="button"
            class="numNephQBtn btn"
            :class="{
              'btn-primary': numNephR === true,
              'btn-outline-primary': numNephR !== true,
            }"
            @click="numNephQ(true)"
          >
            OUI
          </button>
          <button
            type="button"
            class="numNephQBtn btn"
            :class="{
              'btn-primary': numNephR === false,
              'btn-outline-primary': numNephR !== false,
            }"
            @click="numNephQ(false)"
          >
            NON
          </button>
        </div>
        <div :class="{ isHide: numNephR === false || numNephR === null }">
          <label for="numNeph"
            >Si vous avez déjà un numéro NEPH, merci de le saisir ici :</label
          >
          <input
            :value="userData.dossier.numNeph"
            @change="handleChangeNumNeph($event.target.value)"
            type="text"
            id="numNeph"
            name="numNeph"
            style="margin: auto"
            class="form-control inputsize"
            placeholder="NEPH"
          />
          <template v-if="isWorking === false">
            <button
              type="button"
              class="mt-4 btn btn-outline-primary submit"
              @click="saveDossier('neph')"
            >
              Enregistrer
            </button>
          </template>
          <template v-else>
            <button
              type="button"
              class="mt-4 btn btn-outline-primary submit"
              disabled
            >
              <i class="fa fa-spinner fa-spin"></i> Enregistrer
            </button>
          </template>
        </div>
        <div :class="{ isHide: numNephR === true || numNephR === null }">
          <p>
            Montant : {{ filterFonctionnalite.montant }}€.
          </p>
          <p>Vous avez la possibilité de régler de 3 manières possibles :</p>
          <BoutonPaiement @paiement="saveDossier($event)"></BoutonPaiement>
        </div>
      </template>

      <div class="mt-3" id="buttonContainer">
        <template
          v-if="etape == 1 && this.$store.state.user.role.label == 'eleve'"
        >
          <div>
            <b-button
              id="btnRetourVueMobile"
              @click="$emit('ordreVueMobile')"
              class="btnForm mt-3"
            >
              Retour
            </b-button>
          </div>
        </template>
        <template v-if="etape != 1">
          <div>
            <b-button @click="retourEtape" class="btnForm mt-3">
              Retour
            </b-button>
          </div>
        </template>
        <template
          v-if="
            etape <= 4 &&
            (this.$store.state.user.role.label != 'eleve' ||
              this.$route.params.personalData === 'true')
          "
        >
          <template v-if="!isWorking">
            <div>
              <button
                type="button"
                class="btn btn-primary submit mt-3"
                @click="saveDossier('admin')"
              >
                Enregistrer
              </button>
            </div>
          </template>
          <template v-else>
            <div>
              <button
                type="button"
                class="btn btn-primary submit mt-3"
                disabled
              >
                <i class="fa fa-spinner fa-spin"></i> Enregistrer
              </button>
            </div>
          </template>
        </template>
        <template v-else><div></div></template>
        <template
          v-if="
            etape < 4 ||
            (etape == 4 &&
              this.$store.state.user.role.label == 'eleve' &&
              this.$route.params.personalData === 'false')
          "
        >
          <div>
            <b-button @click="suivantEtape" class="btnForm mt-3">
              Suivant
            </b-button>
          </div>
        </template>
        <template v-else><div></div></template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BoutonPaiement from "@/components/eleve/BoutonPaiement.vue";

export default {
  name: "Formulaire",
  props: {
    userData: { type: Object },
    showValidation: { type: Boolean },
    isWorking: { type: Boolean },
  },
  components: {
    BoutonPaiement,
  },
  data() {
    return {
      numNephR: null,
      etape: 1,
    };
  },
  mounted() {
    this.getFonctionnalite();
  },
  methods: {
    ...mapActions(["getFonctionnalite"]),

    numNephQ(rep) {
      // Récupère la reponse de l'utilisateur pour le numNEPH
      this.numNephR = rep;
    },

    suivantEtape() {
      // Passe à l'étape suivante
      if (this.etape < 5) {
        this.etape++;
      }
    },

    retourEtape() {
      // Passe à l'étape précédente
      if (this.etape > 1) {
        this.etape--;
      }
    },

    //
    // Les handleChange servent à l'update des infos du template parent
    //

    handleChangeNom(data) {
      let userDataTmp = this.userData;
      userDataTmp.nom = data;
      this.handleChange(userDataTmp);
    },

    handleChangePrenom(data) {
      let userDataTmp = this.userData;
      userDataTmp.prenom = data;
      this.handleChange(userDataTmp);
    },

    handleChangeTelephone(data) {
      let userDataTmp = this.userData;
      userDataTmp.telephone = data;
      this.handleChange(userDataTmp);
    },

    handleChangeNomUsage(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.nomUsage = data;
      this.handleChange(userDataTmp);
    },

    handleChangeAutrePrenom(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.autresPrenom = data;
      this.handleChange(userDataTmp);
    },

    handleChangeTelParent(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.telParent = data;
      this.handleChange(userDataTmp);
    },

    handleChangeMailParent(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.mailParent = data;
      this.handleChange(userDataTmp);
    },

    handleChangeAdresse(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.adresse = data;
      this.handleChange(userDataTmp);
    },

    handleChangeCodePostal(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.codePostal = data;
      this.handleChange(userDataTmp);
    },

    handleChangeVille(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.ville = data;
      this.handleChange(userDataTmp);
    },

    handleChangeDateNaissance(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.dateNaissance = data;
      this.handleChange(userDataTmp);
    },

    handleChangePaysNaissance(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.paysNaissance = data;
      this.handleChange(userDataTmp);
    },

    handleChangeVilleNaissance(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.villeNaissance = data;
      this.handleChange(userDataTmp);
    },

    handleChangeDepNaissance(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.depNaissance = data;
      this.handleChange(userDataTmp);
    },

    handleChangeNationaliteFR(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.nationaliteFR = data;
      this.handleChange(userDataTmp);
    },

    handleChangeParlerFRCouramment(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.parlerFRCouramment = data;
      this.handleChange(userDataTmp);
    },

    handleChangePermis(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.permis = data;
      this.handleChange(userDataTmp);
    },

    handleChangeLunettes(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.lunettes = data;
      this.handleChange(userDataTmp);
    },

    handleChangeVehiculeAmenage(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.vehiculeAmenage = data;
      this.handleChange(userDataTmp);
    },

    handleChangePrevoitAac(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.prevoitAac = data;
      this.handleChange(userDataTmp);
    },

    handleChangeFaitSuite(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.faitSuite = data;
      this.handleChange(userDataTmp);
    },

    handleChangeMotifAlcoolStup(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.motifAlcoolStup = data;
      this.handleChange(userDataTmp);
    },

    handleChangeSocial(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.social = data;
      this.handleChange(userDataTmp);
    },

    handleChangeMetier(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.metier = data;
      this.handleChange(userDataTmp);
    },

    handleChangeLycee(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.lycee = data;
      this.handleChange(userDataTmp);
    },

    handleChangeAutreLycee(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.autreLycee = data;
      this.handleChange(userDataTmp);
    },

    handleChangeNumNeph(data) {
      let userDataTmp = this.userData;
      userDataTmp.dossier.numNeph = data;
      this.handleChange(userDataTmp);
    },

    handleChange(userDataUpdated) {
      // Envoie l'update du formulaire
      this.$emit("update", userDataUpdated);
    },

    saveDossier(mode) {
      // Envoie l'update du checkAll
      this.$emit("updateCheckAll", this.checkAll);
      // Lance la sauvegarde du dossier
      this.$emit("saveDossier", mode);
    },
  },
  computed: {
    ...mapGetters(["permis", "statutSocial", "lycees", "fonctionnalite"]),

    checkAll() {
      return (
        this.checkNom &&
        this.checkPrenom &&
        this.checkTelephone &&
        this.checkAdresse &&
        this.checkCodePostal &&
        this.checkVille &&
        this.checkDateNaissance &&
        this.checkPaysNaissance &&
        this.checkDepNaissance &&
        this.checkVilleNaissance &&
        this.checkNationaliteFR &&
        this.checkParlerFRCouramment &&
        this.checkVehiculeAmenage &&
        this.checkLunettes &&
        this.checkPermis &&
        this.checkFaitSuite &&
        this.checkPrevoitAac &&
        this.checkSocial &&
        this.fonctionnalite !== null
      );
    },
    dateNaissanceFormated() {
      // Si le format de la date est "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      // Quand la date provient de la base de donnée
      if (this.userData.dossier.dateNaissance.match(/.+[T ]/g) !== null) {
        // Alors conversion en format "yyyy-MM-dd"
        return this.userData.dossier.dateNaissance
          .match(/.+[T ]/g)[0]
          .substring(
            0,
            this.userData.dossier.dateNaissance.match(/.+[T ]/g)[0].length - 1
          );
      }
      // Sinon la date reste comme elle est
      return this.userData.dossier.dateNaissance;
    },
    checkNom() {
      const match = this.userData.nom.match(
        /[A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{1,250}/g
      );
      if (match !== null && match.length === 1) {
        return this.userData.nom === match[0];
      }
      return false;
    },
    checkPrenom() {
      const match = this.userData.prenom.match(
        /[A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{1,250}/g
      );
      if (match !== null && match.length === 1) {
        return this.userData.prenom === match[0];
      }
      return false;
    },
    checkTelephone() {
      // Match le numéro de téléphone français
      const match = this.userData.telephone.match(
        /[0-9]{10}/g
      );
      // Si le numéro de téléphone a un match
      if (match !== null && match.length === 1) {
        // true quand le match est exactement égal au numéro de téléphone
        return this.userData.telephone === match[0];
      }
      return false;
    },
    checkAdresse() {
      return this.userData.dossier.adresse !== "";
    },
    checkCodePostal() {
      // Match les code postaux français (5) ou belge (4)
      const match = this.userData.dossier.codePostal.match(
        /[0-9]{5}|[1-9]{1}[0-9]{3}/g
      );
      // Si le code postal a un match
      if (match !== null && match.length === 1) {
        // true quand le match est exactement égal au code postal
        return this.userData.dossier.codePostal === match[0];
      }
      return false;
    },
    checkVille() {
      return this.userData.dossier.ville !== "";
    },
    checkDateNaissance() {
      return this.userData.dossier.dateNaissance !== "";
    },
    checkPaysNaissance() {
      return this.userData.dossier.paysNaissance !== "";
    },
    checkDepNaissance() {
      return this.userData.dossier.depNaissance !== "";
    },
    checkVilleNaissance() {
      return this.userData.dossier.villeNaissance !== "";
    },
    checkNationaliteFR() {
      return this.userData.dossier.nationaliteFR !== "null";
    },
    checkParlerFRCouramment() {
      return this.userData.dossier.parlerFRCouramment !== "null";
    },
    checkVehiculeAmenage() {
      return this.userData.dossier.vehiculeAmenage !== "null";
    },
    checkLunettes() {
      return this.userData.dossier.lunettes !== "null";
    },
    checkPermis() {
      return this.userData.dossier.permis !== "null";
    },
    checkFaitSuite() {
      return this.userData.dossier.faitSuite !== "null";
    },
    checkMotifAlcoolStup() {
      return this.userData.dossier.motifAlcoolStup !== "null";
    },
    checkPrevoitAac() {
      return this.userData.dossier.prevoitAac !== "null";
    },
    checkSocial() {
      return this.userData.dossier.social !== "null";
    },
    isSalarie() {
      // Si le statut social à été chargé
      if (this.checkSocial && this.statutSocial !== null) {
        // Boucle les statuts sociaux
        for (let i = 0; i < this.statutSocial.length; i++) {
          // Si le statut est "Salarié"
          if (this.statutSocial[i].label === "Salarié") {
            // retourne si le statut séléctionné est le statut "Salarié"
            return (
              "/api/statut_socials/" + this.statutSocial[i].id ===
              this.userData.dossier.social
            );
          }
        }
      }
      return false;
    },
    isLyceen() {
      // Si le statut social à été chargé
      if (this.checkSocial && this.statutSocial !== null) {
        // Boucle les statuts sociaux
        for (let i = 0; i < this.statutSocial.length; i++) {
          // Si le statut est "Lycéen"
          if (this.statutSocial[i].label === "Lycéen") {
            // retourne si le statut séléctionné est le statut "Lycéen"
            return (
              "/api/statut_socials/" + this.statutSocial[i].id ===
              this.userData.dossier.social
            );
          }
        }
      }
      return false;
    },
    isAutreLycee() {
      // Si le statut social séléctionné est le statut "Lycéen" et que le lycée séléctionné est "Autre Lycée"
      return this.isLyceen && this.userData.dossier.lycee === "null";
    },
    filterFonctionnalite() {
      // Si les fonctionnalités ont été téléchargés
      if (this.fonctionnalite !== null) {
        // Filtre puis retourne la fonctionnalité "dossier"
        return this.fonctionnalite.filter((el) => el.label === "dossier")[0];
      }
      // Sinon, retourne un objet avec un montant vide pour évité les erreurs lors du chargement des fonctionnalités
      return {
        montant: "",
      };
    },
  },
};
</script>

<style scoped>
.isNotValid {
  border-color: red;
}
.isHide {
  display: none;
}

.titre {
  font-size: 50pt;
}

.field {
  position: relative;
}

.labelForm {
  position: absolute;
  pointer-events: none;
  left: 1.25rem;
  top: 7.75px;
  transition: 0.2s ease all;
  font-size: 15px;
}

.field > input:focus ~ label,
input:not(:placeholder-shown) ~ label,
.field > select:focus ~ label,
select:not(:placeholder-shown) ~ label {
  top: -15px;
  font-size: 12px;
}

.btnForm {
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  background-color: #de6fa8; /* Green */
  border: none;
  color: white;
  height: 30px;
  margin: auto;
  border-radius: 10px;
}

.btnForm:hover {
  background-color: #bd135f;
}

#buttonContainer {
  display: flex;
  justify-content: space-between;
}

#buttonContainer > * {
  flex: 1;
}

.inputsize {
  width: 80%;
}

.numNephQBtn {
  margin-bottom: 1rem;
}

#etapeDossier {
  text-align: center !important;
}

#dossierFormulaire > .container > p {
  text-align: justify;
}

/* Desktop Styles */
@media only screen and (min-width: 961px) {
  #btnRetourVueMobile {
    display: none;
  }
}

/* Mobile Styles */
@media only screen and (max-width: 400px) {
  .marge {
    margin-top: 2%;
  }

  #dossierFormulaire {
    display: none;
  }

  .inputsize {
    width: 100%;
    margin: auto;
  }

  .btnForm {
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    background-color: #de6fa8; /* Green */
    border: none;
    color: white;
    margin: auto;
    border-radius: 10px;
    cursor: pointer;
  }

  #btnRetourVueMobile {
    display: initial;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  .inputsize {
    width: 100%;
    margin: auto;
  }

  .marge {
    margin-top: 5%;
  }

  #dossierFormulaire {
    display: none;
  }

  #btnRetourVueMobile {
    display: initial;
  }

  .btnForm {
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    background-color: #de6fa8; /* Green */
    border: none;
    color: white;
    margin: auto;
    border-radius: 10px;
    cursor: pointer;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .inputsize {
    width: 100%;
    margin: auto;
  }

  #dossierFormulaire {
    display: none;
  }

  #btnRetourVueMobile {
    display: initial;
  }

  /* Styles */
}
</style>
