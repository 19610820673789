<template>
  <br />
  <center>
    <h1>son Historique</h1>
  </center>

  <body>
    <br />
    <router-link to="/AjoutLycee"> </router-link>

    <div class="container">
      <table class="table">
        <thead>
          <tr>
            <th>date</th>
            <th>lieu</th>
            <th>numero</th>
            <th>moniteur</th>
            <th>resultat</th>
            <th>score</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="examen in examens" :key="examen.id">
            <tr>
              <td>{{ examen.date }}</td>
              <td>{{ examen.lieu }}</td>
              <td>{{ examen.numero }}</td>
              <td>{{ examen.moniteur }}</td>
              <td>{{ examen.resultat }}</td>
              <td>{{ examen.score }}</td>
       
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </body>
</template>

<script>
export default {
  name: "App",

  data() {
    return {

        examens:{}
    };
  },

  mounted() {
    this.HistoriqueExams();
  },

  methods: {

    async HistoriqueExams() {
      try {
        window.api
          .get(`historiqueExam/${this.$route.params.EleveId}`, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((result) => {
            this.examens = result.data.Examens;
            console.log(this.examens);
          });
      } catch (e) {
        // handle authentication error here
      }
    },

    print() {
      console.log();
    },
  },
};
</script>
