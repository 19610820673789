<template>
	<div class="code">
		<!--<h1>Code</h1>-->
		<div>
			<div id="code-main-container" v-if="serieSelected === null">
				<div>
					<h1 id="code-main-title">Code</h1>
					<div id="btn-theme-container">
						<template
							v-for="item in themeFull"
							:key="item.id"
						>
							<button class="btnTheme" @click="selectTheme(item)">
								<span>{{ item.label }}</span>
								<span> %</span>
							</button>
						</template>
					</div>
				</div>
				<template v-if="themeSelected === null">
					<div id="code-sub-home">
						<p>Cliquez sur un thème ou sur une Série d'entraînement</p>
					</div>
				</template>
				<template v-else>
					<div id="code-sub-theme">
						<button :class="{ hide: themeSelected === null }" @click="unselectTheme()">Retour</button>
						<h2>{{ themeSelected.label }}</h2>
						<template
							v-for="stItem in themeSelected.sousThemes"
							:key="stItem.id"
						>
							<div class="code-serie-container">
								<div>
									<p>{{ stItem.label }}</p>
								</div>
								<template
									v-for="sItem in stItem.series"
									:key="sItem.id"
								>
									<div>
										<p>{{ sItem.label }}</p>
										<button @click="lancerSerie(stItem, sItem)">LANCER</button>
									</div>
								</template>
							</div>
						</template>
					</div>
				</template>
			</div>
			<div v-else>
				<p>
					<span>Thème : {{themeSelected.label}}</span>
					<span>Sous-thème : {{sousThemeSelected.label}}</span>
					<span>Série : {{serieSelected.label}}</span>
				</p>
				<p>Q°{{serieSelected.questions[questionSelected].numero}}</p>
				<p>{{serieSelected.questions[questionSelected].question}}</p>
				<div>
					<template
						v-for="item in serieSelected.questions[questionSelected].propositions"
						:key="item.id"
					>
						<button
							@click="selectionnerReponse(item)"
							:class="{
								bonneReponse: ((propositionSelected !== null) && item.reponse),
								mauvaiseReponse: ((propositionSelected !== null) && !item.reponse && (propositionSelected === item.id))
							}"
						>
							<span>{{propositionRegionalIndicator(item.id)}}</span> {{item.texte}}
						</button>
					</template>
				</div>
				<div :class="{ hide: !(propositionSelected !== null) }">
					<p>{{serieSelected.questions[questionSelected].reponseTextuel}}</p>
					<template
						v-if="!lastQuestion"
					>
						<button @click="nextQuestion()">Suivant</button>
					</template>
					<template
						v-else
					>
						<button @click="finirSerie()">Suivant</button>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	name: 'Code',
	data() {
		return {
			themeFull: null,
			themeSelected: null,
			sousThemeSelected: null,
			serieSelected: null,
			questionSelected: null,
			propositionSelected: null,
			regionalIndicatorA: 65,
			testEleve: null,
			error: "",
		}
	},
	mounted() {
		this.getThemeFull();
	},
	methods: {
		...mapActions([]),
		getThemeFull() {
			window.api.get("/code/theme_full", {
				headers: {
					"Authorization": "Bearer " + this.$store.state.token
				}
			}).then(response => {
				this.themeFull = response.data;
			}).catch(error => {
				console.log(error);
			})
		},
		sortThemeFull() {
			// sort themes
			this.themeFull.sort(function(a, b) { return a.numero - b.numero });
			// boucle themes
			for (let i = 0; i < this.themeFull.length; i++) {
				// sort sousThemes
				this.themeFull[i].sousThemes.sort(function(a, b) { return a.numero - b.numero });
				// boucle sousThemes
				for (let j = 0; j < this.themeFull[i].sousThemes.length; j++) {
					// sort series
					this.themeFull[i].sousThemes[j].series.sort(function(a, b) { return a.numero - b.numero });
					// boucle series
					for (let k = 0; k < this.themeFull[i].sousThemes[j].series.length; k++) {
						// sort questions
						this.themeFull[i].sousThemes[j].series[k].questions.sort(function(a, b) { return a.numero - b.numero });
						// boucle questions
						for (let l = 0; l < this.themeFull[i].sousThemes[j].series[k].questions.length; l++) {
							// sort propositions
							this.themeFull[i].sousThemes[j].series[k].questions[l].sort(function(a, b) { return a.ordre - b.ordre });
						}
					}
				}
			}
		},
		propositionRegionalIndicator(propositionId) {
			return String.fromCodePoint(this.regionalIndicatorA + this.serieSelected.questions[this.questionSelected].propositions.findIndex(el => el.id === propositionId));
		},
		selectTheme(theme) {
			this.themeSelected = theme;
		},
		lancerSerie(sousTheme, serie) {
			this.sousThemeSelected = sousTheme;
			this.serieSelected = serie;
			this.questionSelected = 0;
			this.testEleve = {
				typeTest: "THEME",
				eleve: this.$store.state.user.id,
				reponses: []
			};
		},
		unselectTheme() {
			this.themeSelected = null;
		},
		selectionnerReponse(proposition) {
			if (this.propositionSelected === null) {
				this.testEleve.reponses.push({
					proposition: proposition.id,
					resultat: proposition.reponse
				});
				this.propositionSelected = proposition.id;
			}
		},
		nextQuestion() {
			this.propositionSelected = null;
			this.questionSelected++;
		},
		finirSerie() {
			window.api.post("/code/post_test", {
				testEleve: this.testEleve
			}, {
				headers: {
					"Authorization": "Bearer " + this.$store.state.token
				}
			}).then(() => {
				this.sousThemeSelected = null;
				this.serieSelected = null;
				this.questionSelected = null;
				this.propositionSelected = null;
				this.testEleve = null;
			}).catch(error => {
				console.log(error);
			})
		}
	},
	computed: {
		...mapGetters([]),
		lastQuestion() {
			return this.questionSelected >= this.serieSelected.questions.length - 1;
		}
	}
}
</script>


<style scoped>

#code-main-container {
	display: grid;
	grid-auto-flow: row;
	grid-template-columns: 50% 50%;
}

#code-main-title {
	font-size: 5em;
	text-align: left;
}

#code-sub-home {
	background: linear-gradient(to bottom right, #DE6FA8, #FBBB5B);
}

.code-serie-container {
	display: grid;
	grid-auto-flow: row;
	grid-template-columns: repeat(5, 20%);
}

.hide {
	display: none;
}

.bonneReponse {
	border-color: green;
}

.mauvaiseReponse {
	border-color: red;
}

#btn-theme-container {
	display: flex;
	flex-direction: column;
}

.btnTheme {
	border: none;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

</style>
