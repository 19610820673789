<template>
	<h1>RESERVATION DE VOTRE PLACE D'EXAMEN</h1>
	<div id="presta">
		<h2>Nous vous invitons à réserver votre place d'examen chez un prestataire agrée: </h2>
		<p><img class="success" :src="require('../../../assets/images/success.png')" />La poste</p>
		<p><img class="success" :src="require('../../../assets/images/success.png')" />Dekra</p>
		<p><img class="success" :src="require('../../../assets/images/success.png')" />SGS</p>
		<p><img class="success" :src="require('../../../assets/images/success.png')" />Point Code</p>
	</div>

	<hr />

	<div id="reserv">
		<p>Une fois votre place réservée, merci de saisir les renseignements ci-dessous. Votre résulat à l'examen vous sera envoyé par mail.</p>
		<div id="container">
			<img id="panneaux" :src="require('../../../assets/images/panneauxyes.png')" />
			<div id ="formulaire">
				<form>
					<div class="field">
						<div class="control">
							<input
								class="form-control"
								type="date"
								id="date"
								placeholder="dateMes"
								@keydown="errors = ''"
							/>
						</div>
					</div>
					<div class="form-group">
						<label class="form-label">ville</label>
						<input type="ville" rows="5" class="form-control" />
					</div>
					<div class="form-group">
						<label class="form-label">ville</label>
						<input type="ville" rows="5" class="form-control" />
					</div>
					<router-link to="/SerieCode">
						<button class="btn btn-warning btn-m">OK</button>
					</router-link>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	name: 'ExamenCode',
	data() {
		return {
			error: "",
		}
	},
	mounted() {},
	methods: {
		...mapActions([]),
	},
	computed: {
		...mapGetters([]),
	}
}
</script>

<style scoped>
input {
	background-color: rgb(248, 239, 231);
}

h1 {
	color: #d67610;
	font-size: 2.5em;
	font-weight: bold;
}

.btn {
	color: black;
	background-color: #ffaa71;
	font-size: 1.5em;
	font-weight: bold;
	border-radius: 10px;
	padding: 4%;
}

h2 {
	color: #d67610;
	text-align: start;
	font-weight: bold;
	margin-right: 4%;
	margin-left: 4%;
}

#container {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-around;
}

#formulaire {
	display: flex;
	flex-direction: column;
	align-self: flex-end;
	justify-content: space-around;
}

#panneaux {
	max-width: 100%;
	width: 500px;
	height: 240px;
}

#reserv {
	display: flex;
	flex-direction: column;
}

hr {
	margin: 100px auto;
	width: 95%;
	height: 2px;
	background-color: #d67610;
	border: none; /* reset default style */
}

p {
	font-size: 1.6em;
	font-weight: bold;
	margin-left: 4%;
	margin-right: 4%;
	color: #d67610;
	text-align: start;
}

#presta {
	display: flex;
	flex-direction: column;
	border-radius: 100px;
	background-color: #f5f5f5;
	height: auto;
	background-color: #ffffff;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	width: 80%;
	margin: auto;
	padding: 2%;
	flex-wrap: wrap;
}

.success {
	margin: auto;
	width: 20px;
	height: 30px;
}
</style>