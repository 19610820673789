<template>
	<div id="review">
		<template v-if="checkLoad">
			<div class="card text-center shadow-lg" :class="{ 'border-success': filterPaiement.etat === 'payé', 'border-danger': filterPaiement.etat !== 'payé',  }">
				<div class="card-header">
					Review
				</div>
				<div id="review-body" class="card-body">
					<div id="review-body-sub">
						<p>Référence : {{filterPaiement.number}}</p>
						<p>Date : {{filterPaiement.details.date}}</p>
						<p>Montant : {{filterPaiement.totalAmount / 100}} €</p>
						<template v-if="filterPaiement.etat === 'payé'">
							<p class="text-success">Paiement réussi</p>
						</template>
						<template v-else>
							<p class="text-danger">Erreur au cours du paiement</p>
						</template>
					</div>
					<div id="review-body-button">
						<button type="button" class="btn btn-primary" @click="goToHome()"><i class="fa fa-home"></i> Accueil</button>
						<template v-if="filterPaiement.etat === 'payé'">
							<button type="button" class="btn btn-primary" @click="goToNext()">Continuer <i class="fa fa-arrow-right"></i></button>
						</template>
						<template v-else>
							<button type="button" class="btn btn-primary" @click="goToRepeat()">Réessayer <i class="fa fa-repeat"></i></button>
						</template>
					</div>
				</div>
				<div class="card-footer text-muted"></div>
			</div>
		</template>

		<template v-else>
			<Loader/>
		</template>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from '@/components/Loader.vue';

export default {
	name: 'Review',
    components:{
        Loader
    },
	data() {
		return {}
	},
	mounted() {
		this.getPaiement();
	},
	methods: {
		...mapActions([
			"getPaiement",
		]),
		goToHome() {
			this.$router.push({name: 'Home'});
		},
        goToNext() {
			// Si la description du paiement
			// Alors redirection vers la route qui correspond à la partie payée

			// Partie Dossier
			if(this.filterPaiement.description === 'dossier') {
				if(!(this.filterPaiement.etat === 'payé')) {
					this.$router.push({name: 'Dossier'});
				} else {
					this.$router.push({name: 'Piece'});
				}
			}

			// Partie Code
			if(this.filterPaiement.description === 'code') {
				this.$router.push({name: 'CodeFront'});
			}

			// Partie Conduite
			if(this.filterPaiement.description === 'solde') {
				this.$router.push({name: 'Solde'});
			}
		},
		goToRepeat() {
			// Retour sur le paiement
			this.$router.push({
				name: "Paiement",
				params: { fonctionnalite: this.filterPaiement.description },
			});
		}
	},
	computed: {
		...mapGetters([
			"paiement",
		]),
		checkLoad() {
			return (
				this.paiement !== null
			);
		},
		filterPaiement() {
			// Recupère les paiements de l'utilisateur connecté
			// Si les paiements sont chargés
			if(this.paiement !== null) {
				// Si il y a au moins un paiement
				if(this.paiement.length !== 0) {
					// Récupère le paiement ayant le même identifiant unique
					return this.paiement.filter(el => el.number === this.$route.params.number)[0];
				}
			}
			// Retourne un objet vide pour éviter les erreurs
			return {details: {}};
		}
	}
}
</script>

<style>
#review {
	padding: 3rem 20% 0 20%;
}

#review-body {
	display: flex;
	flex-direction: column;
	align-items: center;
}

#review-body-sub {
	width: max-content;
}

#review-body-button {
	display: flex;
	justify-content: space-around;
	width: 100%;
}

</style>