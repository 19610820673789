<template>
    <div class="headerFlex">
        <img
            class="mt-3"
            :src="require('../assets/images/logoMonPermis.png')"
            @click="getBackToHome"
        />
        <p @click="deconnexion" class="user">{{this.$store.state.user.nom}} {{this.$store.state.user.prenom}}</p>
    </div>
  </template>
  
  <script>

  export default {
  
      props: [

      ],
  
    components: {

    },
  
    watch: {},
  
    data() {
      return {

      };
    },
  
    mounted() {

    },
  
    methods: {
      getBackToHome(){
        this.$router.push({name: 'Home'});
      },

      deconnexion() {
        this.$store.commit("setUser", null);
        this.$store.commit("setToken", null);
        localStorage.removeItem("user_info");
        localStorage.removeItem("user_access");
        this.$router.push({name: 'index'});
      },

    }
  };
  </script>
  
  <style scoped>
  
    div.headerFlex{
        display: flex;
        justify-content: space-between;
    }

    div.headerFlex img{
        width: 70px;
        cursor: pointer;
    }

    div.headerFlex p{
        font-size: 25px;
        margin-top: 1%;
    }

    div.headerFlex p.user{
      cursor: pointer;
    }
  
  </style>
  