<template>
  <div class="container">
    <Toast />
    <header id="head">
      <DropdownNav></DropdownNav>
      <h1>Fonctionnalité</h1>
      <router-link to="/home"
        ><i
          class="pi pi-home me-3 ms-3 mt-2"
          id="iconHome"
          style="font-size: 2rem"
        ></i
      ></router-link>
      <div>
        <label for="email">email :</label>
        <input
          type="text"
          class="ms-4"
          name="email"
          v-model="this.$store.state.user.email"
        />
        <label for="telephone" class="ms-3">telephone :</label>
        <input
          type="text"
          class="ms-4"
          name="telephone"
          v-model="this.$store.state.user.telephone"
        />
        <Button class="ms-4" @click="patchUtilisateur()">Modifier</Button>
      </div>
    </header>
    <DataTable :value="fonctionnalites" responsiveLayout="scroll">
      <template #header>
        <div class="table-header">
          Fonctionnalite :
          <input
            v-if="this.form.id == null"
            type="text"
            placeholder="Saisir le label"
            v-model="form.label"
          />
          <input
            type="text"
            placeholder="Saisir le montant"
            v-model="form.montant"
          />
          <Button
            v-if="this.form.id == null"
            class="p-button p-button-info"
            @click="CreateFonc"
            >Ajouter</Button
          >
          <Button
            v-if="this.form.id != null"
            class="p-button p-button-warning"
            @click="CreateFonc"
            >Modifier</Button
          >
          <Button
            v-if="this.form.id != null"
            class="p-button p-button-danger"
            @click="
              this.form.id = null;
              this.form.label = '';
              this.form.montant = '';
            "
            >Annuler</Button
          >
        </div>
      </template>
      <Column field="label" header="Label"></Column>
      <Column field="montant" header="Montant"></Column>
      <Column :exportable="false" style="min-width: 8rem">
        <template #body="slotProps">
          <Button
            class="p-button-rounded p-button-success mr-2"
            @click="editFonc(slotProps.data)"
            >Edit</Button
          >
          <Button
            class="p-button-rounded p-button-warning"
            @click="deleteFonc(slotProps.data)"
            >Delete</Button
          >
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import DropdownNav from "../../../components/DropdownNav.vue";
import { useToast } from "primevue/usetoast";

export default {
  name: "Fonctionnalité",
  component: {
    DropdownNav,
  },
  data() {
    return {
      fonctionnalites: null,
      toast: useToast(),
      form: {
        id: null,
        label: "",
        montant: "",
      },
    };
  },
  mounted() {
    this.getFonctionnalites();
  },
  methods: {
    getFonctionnalites() {
      window.api
        .get("/fonctionnalites/", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {

          this.fonctionnalites = response.data["hydra:member"];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    CreateFonc() {
      if (
        (this.form.label != "", this.form.montant != "", this.form.id == null)
      ) {
        window.api
          .post(
            "/fonctionnalites",
            {
              label: this.form.label,
              montant: parseInt(this.form.montant),
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.form.id = null;
            this.form.label = "";
            this.form.montant = "";
            this.getFonctionnalites();
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (
        (this.form.label == "", this.form.montant == "", this.form.id == null)
      ) {
        alert("Veuillez saisir les champs");
      } else if (this.form.id != null) {
        window.api
          .patch(
            "/fonctionnalites/" + this.form.id,
            {
              montant: parseInt(this.form.montant),
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            this.form.id = null;
            this.form.label = "";
            this.form.montant = "";
            this.getFonctionnalites();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    editFonc(info) {
      console.log(info);
      this.form.id = info.id;
      this.form.label = info.label;
      this.form.montant = info.montant;
    },
    deleteFonc(info) {
      console.log(info);
      let isExecuted = confirm(
        "Voulez vous vraiment supprimer la fonctionnalité : " + info.label
      );
      console.log(isExecuted); // OK = true, Cancel = false
      if (isExecuted) {
        window.api
          .delete("/fonctionnalites/" + info.id, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Success Message",
              detail: "Order submitted",
              life: 3000,
            });
            this.getFonctionnalites();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    patchUtilisateur() {
        if (confirm("Voulez vous vraiment modifier l'admin ?, vous serez redirigé vers la page de connexion pour que la modification sois prise en compte")) {
      window.api
        .patch(
          `utilisateur/` + this.$store.state.user.id,
          {
            email: this.$store.state.user.email,
            telephone: this.$store.state.user.telephone,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.$emit("completed", response.data);
          this.toast.add({
            severity: "success",
            summary: "Admin modifié",
            detail: "l'Admin à été modifié avec succès",
            life: 3000,
          });
          this.$store.commit("setUser", null);
          this.$store.commit("setToken", null);
          localStorage.removeItem("user_info");
          localStorage.removeItem("user_access");
          this.$router.push({ name: "Connexion" });
          
        })
        .catch(function (error) {
          console.log(error.response);
        });
     }
    },
  },
  computed: {},
  components: { DropdownNav },
};
</script>

<style scoped>
#head {
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  align-content: space-around;
}
</style>
