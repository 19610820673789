<template>
  <template v-if="checkLoad">
    <!-- The Modal -->
    <div
      id="myModalFormulaire"
      class="modal"
      :class="{ modalOpen: formModalSelected }"
    >
      <!-- Modal content -->
      <div class="modal-content">
        <div class="modal-header">
          <span class="close" @click="closeModalFormulaire()">&times;</span>
          <h2>Formulaire dossier</h2>
        </div>
        <div class="modal-body">
          <Formulaire
            :userData="eleveSelected"
            :showValidation="showValidation"
            :isWorking="isWorking"
            @update="update($event)"
            @updateCheckAll="updateCheckAll($event)"
            @ordreVueMobile="ordreVueMobile($event)"
            @saveDossier="saveDossier()"
          ></Formulaire>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Formulaire from "@/components/dossier/Formulaire.vue";

export default {
  name: "FormulaireModal",
  components: {
    Formulaire,
  },
  props: {
    formModalSelected: { type: Boolean },
    dossierFull: { type: Object },
  },
  data() {
    return {
      showValidation: false,
      isWorking: false,
      eleveSelected: null,
      checkAll: false,
    };
  },
  watch: {
    checkPreLoad(value) {
      // Quand le dossierFull est chargé, le computed change pour trigger le watch
      if (value) {
        // change l'élève séléctionné par le dossierFull
        this.eleveSelected = this.dossierFull;
      }
    },
  },
  mounted() {
    this.getPermis();
    this.getStatutSocial();
    this.getLycees();
  },
  methods: {
    ...mapActions(["getPermis", "getStatutSocial", "getLycees"]),

    closeModalFormulaire() {
      // Lance la fermeture du modal
      this.$emit("selectFormModal", false);
    },

    update(userData) {
      // Réceptionne l'update de l'élève
      this.eleveSelected = userData;
    },

    updateCheckAll(value) {
      // Réceptionne l'update du checkAll
      this.checkAll = value;
    },

    saveDossier() {
      // Sauvegarde le dossier
      // Indique via le bouton que la sauvegarde à démarrée
      this.isWorking = true;
      if (this.eleveSelected.dossier.motifAlcoolStup === null) {
        this.eleveSelected.dossier.motifAlcoolStup = false;
      }
      // Place le dossier dans une variable temporaire pour ne pas impacter les données affichées
      let tmpFormDossier = this.eleveSelected.dossier;
      // Ajuste le format des données pour l'enregistrement
      // Si la donnée traitée est "true" ou true alors le résultat de la comparaison sera true
      // Donc le format sera toujours Boolean
      tmpFormDossier.nationaliteFR =
        tmpFormDossier.nationaliteFR === "true" ||
        tmpFormDossier.nationaliteFR === true;
      tmpFormDossier.parlerFRCouramment =
        tmpFormDossier.parlerFRCouramment === "true" ||
        tmpFormDossier.parlerFRCouramment === true;
      tmpFormDossier.lunettes =
        tmpFormDossier.lunettes === "true" || tmpFormDossier.lunettes === true;
      tmpFormDossier.vehiculeAmenage =
        tmpFormDossier.vehiculeAmenage === "true" ||
        tmpFormDossier.vehiculeAmenage === true;
      tmpFormDossier.prevoitAac =
        tmpFormDossier.prevoitAac === "true" ||
        tmpFormDossier.prevoitAac === true;
      tmpFormDossier.faitSuite =
        tmpFormDossier.faitSuite === "true" ||
        tmpFormDossier.faitSuite === true;
      tmpFormDossier.motifAlcoolStup =
        tmpFormDossier.motifAlcoolStup === "true" ||
        tmpFormDossier.motifAlcoolStup === true;
      // Si le lycée séléctionné est "Autre Lycée"
      if (tmpFormDossier.lycee === "null") {
        // Alors, correction du format
        tmpFormDossier.lycee = null;
      }
      // Si le dossier de l'élève n'a pas d'id
      // Donc il n'a jamais été enregistré
      if (this.eleveSelected.dossier.id === undefined) {
        // Alors post le dossier
        window.api
          .post(
            "/dossiers",
            {
              eleve: "/api/utilisateurs/" + this.eleveSelected.id,
              ...tmpFormDossier,
              ants: false,
              typeFabrication: null,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            // Lance la sauvegarde des informations de l'utilisateur
            this.saveUser();
          })
          .catch((error) => {
            // Quand l'enregistrement cesse, le bouton redevient cliquable
            this.isWorking = false;
            console.log(error);
          });
      } else {
        // Sinon patch le dossier
        window.api
          .patch(
            "/dossiers/" + this.eleveSelected.dossier.id,
            {
              ...tmpFormDossier,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            // Lance la sauvegarde des informations de l'utilisateur
            this.saveUser();
          })
          .catch((error) => {
            // Quand l'enregistrement cesse, le bouton redevient cliquable
            this.isWorking = false;
            console.log(error);
          });
      }
    },

    saveUser() {
      // Sauvegarde les informations de l'utilisateur présent dans le formulaire
      window.api
        .patch(
          "/utilisateur/" + this.eleveSelected.id,
          {
            nom: this.eleveSelected.nom,
            prenom: this.eleveSelected.prenom,
            telephone: this.eleveSelected.telephone,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(() => {
          // Ferme le modal
          this.closeModalFormulaire();
          // Quand l'enregistrement est terminer, le bouton redevient cliquable
          this.isWorking = false;
        })
        .catch((error) => {
          // Quand l'enregistrement cesse, le bouton redevient cliquable
          this.isWorking = false;
          console.log(error);
        });
    },
  },
  computed: {
    ...mapGetters(["permis", "statutSocial", "lycees"]),

    checkLoad() {
      return (
        this.eleveSelected !== null &&
        this.permis !== null &&
        this.statutSocial !== null &&
        this.lycees !== null
      );
    },

    checkPreLoad() {
      // Vérifie le chargement du dossierFull
      return this.dossierFull !== null;
    },
  },
};
</script>

<style>
.modalOpen {
  display: block !important;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */

  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

/* Modal Content */
.modal-content {
  margin: auto;
  margin-top: 5em;
  background-color: #fefefe;
  width: 50%;

  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #93c2ff;
  color: white;
}

.modal-body {
  padding: 2px 16px;
  display: grid;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #ffffff;
  color: white;
}
</style>
