<template>
  <div id="conteneurAgence">
    <Toast />
    <div id="agence" class="container">
      <header id="head">
        <DropdownNav></DropdownNav>
        <router-link to="/home"
          ><i class="pi pi-home me-3 iconHome" style="font-size: 2rem"></i
        ></router-link>
        <h1 class="ms-3" id="titreAgence">Agence</h1>
        <i
          class="pi pi-plus-circle iconHome ms-3 mt-2"
          @click="add"
          style="font-size: 1.5rem"
        ></i>
        <div class="flex justify-self-flex-end">
          <span class="p-input-icon-left">
            <input
              class="ms-5"
              v-model="filters['global'].value"
              placeholder="Rechercher"
            />
          </span>
        </div>
        <i class="pi pi-search me-2 ms-2 mt-3" />
      </header>
      <div id="tableauAgence">
        <DataTable
          :value="this.agences"
          :rows="10"
          v-model:filters="filters"
          filterDisplay="menu"
          :loading="loading"
          :rowsPerPageOptions="[10, 20, 50]"
          v-model:selection="selectedItem"
          selectionMode="single"
          @click="activeModif"
          responsiveLayout="scroll"
          :globalFilterFields="[
            'nom',
            'NombreMonos',
            'NombreEleves',
            'montantTotalByAnneeN',
          ]"
          dataKey="id"
        >
          <Column
            field="nom"
            header="nom"
            :sortable="true"
            style="min-width: 6rem"
          >
            <template #filter="{ filterModel, filterCallback }">
              <input
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter"
                :placeholder="`Rechercher par nom - ${filterModel.matchMode}`"
              />
            </template>
          </Column>

          <Column
            header="NombreMonos"
            :exportable="false"
            style="min-width: 8rem"
          >
            <template #body="slotProps">
              <span @click="showMonosByAgence()">
                {{ slotProps.data.NombreMonos }}
              </span>
            </template>
          </Column>

          <Column
            header="NombreEleves"
            :exportable="false"
            style="min-width: 8rem"
          >
            <template #body="slotProps">
              <span @click="showElevesByAgence()">
                {{ slotProps.data.NombreEleves }}
              </span>
            </template>
          </Column>

          <Column
            header="montantTotalByAnneeN"
            :exportable="false"
            style="min-width: 8rem"
          >
            <template #body="slotProps">
              <span @click="showDateForCa()">
                {{ slotProps.data.montantTotalByAnneeN }}
              </span>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <div id="agenceChoisi">
      <div v-if="this.selectedItem === null" id="styleTitreChoix">
        <p id="titreChoix">Cliquez sur une agence</p>
      </div>

      <template
        v-else-if="
          this.showEleves === false &&
          this.showMoniteurs === false &&
          this.displayDateForCa === false
        "
      >
        <div id="formModif" class="container pt-4">
          <div class="row pb-4">
            <div class="col-12 text-center">
              <h1>{{ this.agence.nom }}</h1>
            </div>
          </div>

          <div class="row pt-4">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="nom"
                    required
                    v-model="this.agence.nom"
                    @keydown="errors = ''"
                  />
                  <label>Nom</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="adresse"
                    required
                    v-model="this.agence.adresse"
                    @keydown="errors = ''"
                  />
                  <label>Adresse</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="mail"
                    required
                    v-model="this.agence.mail"
                    @keydown="errors = ''"
                  />
                  <label>Mail</label>
                </div>
              </div>
            </div>

          </div>
          <div class="row">

            <div class="col-lg-4 col-md-4 col-sm-12">
              <br />
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="mail"
                    required
                    v-model="this.agence.mailContrat"
                    @keydown="errors = ''"
                  />
                  <label>Mail contrat</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <br />
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="codePostal"
                    required
                    v-model="this.agence.codePostal"
                    @keydown="errors = ''"
                  />
                  <label>Code postal</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <br />
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="ville"
                    required
                    v-model="this.agence.ville"
                    @keydown="errors = ''"
                  />
                  <label>Ville</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row pt-4">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="telephone"
                    required
                    v-model="this.agence.telephone"
                    @keydown="errors = ''"
                  />
                  <label>Telephone</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="siret"
                    required
                    v-model="this.agence.siret"
                    @keydown="errors = ''"
                  />
                  <label>Siret</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="ol-lg-4 col-md-4 col-sm-12">
              <br />
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="numAgrement"
                    required
                    v-model="this.agence.numAgrement"
                    @keydown="errors = ''"
                  />
                  <label>NumAgrement</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <br />
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="numDeclarActivite"
                    required
                    v-model="this.agence.numDeclarActivite"
                    @keydown="errors = ''"
                  />
                  <label>NumDeclarActivite</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row pt-4">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="numAssurance"
                    required
                    v-model="this.agence.numAssurance"
                    @keydown="errors = ''"
                  />
                  <label>numAssurance</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="numPoliceAssurance"
                    required
                    v-model="this.agence.numPoliceAssurance"
                    @keydown="errors = ''"
                  />
                  <label>NumPoliceAssurance</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <br />
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="nomPoliceAssurance"
                    required
                    v-model="this.agence.nomPoliceAssurance"
                    @keydown="errors = ''"
                  />
                  <label>NomPoliceAssurance</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <br />
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="numFondGanrantie"
                    required
                    v-model="this.agence.numFondGanrantie"
                    @keydown="errors = ''"
                  />
                  <label>NumFondGanrantie</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 m">
              <br />
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="number"
                    required
                    step="0.01"
                    v-model="this.agence.montantFondGarantie"
                    @keydown="errors = ''"
                  />
                  <label>MontantFondGarantie</label>
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 m">
              <br />
              <div class="control">
                <center>
                  <p>Auto Planning :</p>
                  <InputSwitch v-model="this.agence.autoPlanning" />
                </center>
              </div>
            </div>
            <div id="btnContainer">
              <Button class="btnRouge" @click="deleteAgence()"
                >Supprimer</Button
              >
              <Button class="btnTurquoise">
                <export-excel :data="this.allPaiements" :fields="jsonProperties">
                  Historique des Paiements
                </export-excel>
              </Button>
              <!-- <Button class="btnTurquoise">
                <export-excel :data="otherPaiements" :fields="jsonProperties">
                  Historique des Paiements en agence
                </export-excel>
              </Button> -->
              <Button class="btnViolet" @click="putAgence()">Modifier</Button>
            </div>
          </div>
        </div>
      </template>

      <template v-else-if="showEleves === true">
        <div class="container pt-4">
          <Button class="mb-3 btnViolet" @click="this.showEleves = false"
            >Retour</Button
          >
          <DataTable
            :value="this.elevesByAgence"
            :rows="7"
            v-model:filters="filters"
            filterDisplay="menu"
            :rowsPerPageOptions="[7, 10, 20, 50]"
            :paginator="true"
            responsiveLayout="scroll"
            dataKey="id"
          >
            <Column
              field="nom"
              header="Nom"
              :sortable="true"
              style="min-width: 5rem"
            >
            </Column>

            <Column
              field="prenom"
              header="Prenom"
              :sortable="true"
              style="min-width: 6rem"
            ></Column>

            <Column
              field="Dossier.social"
              header="Statut"
              :sortable="true"
              style="min-width: 6rem"
            ></Column>

            <Column
              field="Dossier.lycee"
              header="Info"
              :sortable="true"
              style="min-width: 6rem"
            >
            </Column>

            <Column
              header="Archiver"
              :exportable="false"
              style="min-width: 6rem"
            >
              <template #body="slotProps">
                <Checkbox
                  :binary="true"
                  @click="archiveEleve(slotProps.data.id)"
                />
              </template>
            </Column>

            <Column :exportable="false" style="min-width: 6rem">
              <template #body="slotProps">
                <i
                  class="pi pi-times me-3"
                  id="iconHome"
                  @click="deleteUtilisateur(slotProps.data)"
                  style="font-size: 1.7rem; cursor: pointer"
                ></i>
              </template>
            </Column>
          </DataTable>
        </div>
      </template>

      <template v-else-if="showMoniteurs === true">
        <div class="container pt-4">
          <Button class="mb-3 btnViolet" @click="this.showMoniteurs = false"
            >Retour</Button
          >
          <DataTable
            :value="this.moniteursByAgence"
            :rows="7"
            v-model:filters="filters"
            filterDisplay="menu"
            :rowsPerPageOptions="[7, 10, 20, 50]"
            :paginator="true"
            responsiveLayout="scroll"
            dataKey="id"
          >
            <Column
              field="nom"
              header="Nom"
              :sortable="true"
              style="min-width: 5rem"
            >
            </Column>

            <Column
              field="prenom"
              header="Prenom"
              :sortable="true"
              style="min-width: 6rem"
            ></Column>
            <Column
              field="telephone"
              header="telephone"
              :sortable="true"
              style="min-width: 6rem"
            ></Column>
            <Column
              field="codePostal"
              header="code postal"
              :sortable="true"
              style="min-width: 6rem"
            ></Column>
          </DataTable>
        </div>
      </template>

      <template v-else-if="this.displayDateForCa === true">
        <div class="container pt-4">
          <Button class="mb-3 btnViolet" @click="this.displayDateForCa = false"
            >Retour</Button
          >
          <input
            type="date"
            class="form-control mb-3"
            v-model="this.dateDepartCa"
          />

          <input type="date" class="form-control" v-model="this.dateFinCa" />

          <Button class="btnTurquoise" @click="calculCa()">
            <export-excel :data="this.allPaiements" :fields="jsonProperties">
              Historique des Paiements
            </export-excel>
          </Button>
        </div>
      </template>
    </div>

    <Dialog
      :header="`Ajouter une agence`"
      v-model:visible="displayPostAgence"
      :style="{ width: '80vw' }"
    >
      <span class="help is-danger" v-text="errors"></span>

      <div class="container pt-4">
        <div class="row pb-4">
          <div class="col-12 text-center">
            <h1>Je crée mon Agence</h1>
          </div>
        </div>

        <div class="row pt-4">
          <div class="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12">
            <div class="text-field">
              <div class="control">
                <input
                  class="form-control"
                  type="nom"
                  required
                  v-model="form.nom"
                  @keydown="errors = ''"
                />
                <label>Nom</label>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="text-field">
              <div class="control">
                <input
                  class="form-control"
                  type="adresse"
                  v-model="form.adresse"
                  @keydown="errors = ''"
                />
                <label>Adresse</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12">
            <br />
            <div class="text-field">
              <div class="control">
                <input
                  class="form-control"
                  type="codePostal"
                  v-model="form.codePostal"
                  @keydown="errors = ''"
                />
                <label>Code postal</label>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12">
            <br />
            <div class="text-field">
              <div class="control">
                <input
                  class="form-control"
                  type="ville"
                  v-model="form.ville"
                  @keydown="errors = ''"
                />
                <label>Ville</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-4">
          <div class="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12">
            <div class="text-field">
              <div class="control">
                <input
                  class="form-control"
                  type="telephone"
                  v-model="form.telephone"
                  @keydown="errors = ''"
                />
                <label>Telephone</label>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="text-field">
              <div class="control">
                <input
                  class="form-control"
                  type="siret"
                  v-model="form.siret"
                  @keydown="errors = ''"
                />
                <label>Siret</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12">
            <br />
            <div class="text-field">
              <div class="control">
                <input
                  class="form-control"
                  type="numAgrement"
                  v-model="form.numAgrement"
                  @keydown="errors = ''"
                />
                <label>NumAgrement</label>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12">
            <br />
            <div class="text-field">
              <div class="control">
                <input
                  class="form-control"
                  type="numDeclarActivite"
                  v-model="form.numDeclarActivite"
                  @keydown="errors = ''"
                />
                <label>NumDeclarActivite</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-4">
          <div class="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12">
            <div class="text-field">
              <div class="control">
                <input
                  class="form-control"
                  type="numAssurance"
                  v-model="form.numAssurance"
                  @keydown="errors = ''"
                />
                <label>numAssurance</label>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="text-field">
              <div class="control">
                <input
                  class="form-control"
                  type="numPoliceAssurance"
                  v-model="form.numPoliceAssurance"
                  @keydown="errors = ''"
                />
                <label>NumPoliceAssurance</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12">
            <br />
            <div class="text-field">
              <div class="control">
                <input
                  class="form-control"
                  type="nomPoliceAssurance"
                  v-model="form.nomPoliceAssurance"
                  @keydown="errors = ''"
                />
                <label>NomPoliceAssurance</label>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12">
            <br />
            <div class="text-field">
              <div class="control">
                <input
                  class="form-control"
                  type="numFondGanrantie"
                  v-model="form.numFondGanrantie"
                  @keydown="errors = ''"
                />
                <label>NumFondGanrantie</label>
              </div>
            </div>
          </div>

          <div class="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12 m">
            <br />
            <div class="text-field">
              <div class="control">
                <input
                  class="form-control"
                  type="number"
                  step="0.01"
                  v-model="form.montantFondGarantie"
                  @keydown="errors = ''"
                />
                <label>MontantFondGarantie</label>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12 m">
            <br />
            <div class="text-field">
              <div class="control">
                <input
                  class="form-control"
                  type="mail"
                  v-model="form.mail"
                  @keydown="errors = ''"
                />
                <label>Mail</label>
              </div>
            </div>
          </div>

          <div class="offset-lg-2 offset-md-2 col-lg-8 col-md-8 col-sm-8 m">
            <br />
            <div class="text-field">
              <div class="control">
                <input
                  class="form-control"
                  type="mail"
                  v-model="form.mailContrat"
                  @keydown="errors = ''"
                />
                <label>Mail contrat</label>
              </div>
            </div>
          </div>


          <div class="col-lg-12 col-md-12 col-sm-12 m">
            <br />
            <div class="control">
              <center>
                <p>Auto Planning :</p>
                <InputSwitch v-model="form.autoPlanning" />
              </center>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          label="Fermer"
          icon="pi pi-check"
          @click="closeBasic"
          class="p-button-text"
        />
        <Button
          label="Ajouter"
          icon="pi pi-check"
          @click="postAgence"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { useToast } from "primevue/usetoast";
import { FilterMatchMode } from "primevue/api";
import { mapActions } from "vuex";
import moment from "moment";
import DropdownNav from "@/components/DropdownNav.vue";

export default {
  name: "App",

  components: {
    DropdownNav,
  },

  data() {
    return {
      toast: useToast(),
      // l'objet agence
      agence: {},
      // la liste des agences
      agences: [],
      // la liste des utilisateurs
      utilisateurs: [],
      // on stock dedans tout les paiements effectués dans l'agence selectionné en ligne
      allPaiements: [],
      // on stock dedans tout les paiements effectués dans l'agence selectionné en agence
      // otherPaiements: [],
      // Boolen qui va permettre d'afficher le pop up d'ajout
      displayPostAgence: false,
      // Date de depart pour la generation de l'excel des paiements par agence
      dateDepartCa: null,
      // Date de fin pour la generation de l'excel des paiements par agence
      dateFinCa: null,
      // boolen permettent d'afficher les intervalles de date pour effectuer une extraction (ne fonctionne pas)
      displayDateForCa: false,
      // la liste d'eleve lié à agence sélectionné
      elevesByAgence: [],
      // Booleen qui va afficher les élèves lié à l'agence
      showEleves: false,
      // Booleen qui va afficher les moniteurs lié à l'agence
      showMoniteurs: false,
      // permet le filtre sur le tableau voir la documentation de prime vue
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },

      // Agence selectionné
      selectedItem: null,

      // Formulaire pour l'ajout d'agence
      form: {
        nom: "",
        adresse: "",
        codePostal: "",
        ville: "",
        telephone: "",
        siret: "",
        numAgrement: "",
        numDeclarActivite: "",
        numAssurance: "",
        nomPoliceAssurance: "",
        numPoliceAssurance: "",
        numFondGanrantie: "",
        mail: "",
        mailContrat: "",
        montantFondGarantie: 0,
        autoPlanning: false,
      },
      // les datas qui apparaitront dans l'excel generé pour les paiements
      jsonProperties: {
        date: "details.date",
        montant: "details.amount",
        email: "details.email",
      },
      json_fields: {
        date: "details.date",
        montant: "details.amount",
        email: "details.email",
      },
    };
  },

  mounted() {
    this.created();
    this.getEleveAdmin();
    this.getMoniteurs();
  },

  methods: {
    ...mapActions(["getEleveAdmin", "getMoniteurs"]),

    // On récup tout les paiements des utilisateurs lié à l'agence et on les stock dans allpaiements
    retrievePaiements() {
      this.allPaiements = [];
      this.utilisateurs.forEach((util) => {
        if (util["Montant"] !== "null") {
          util["Montant"].forEach((paiement) => {
            this.allPaiements.push(paiement);
          });
        }
      });
      // this.otherPaiements = [];
      // window.api
      //   .get("/otherpaiement", {
      //     headers: {
      //       Authorization: "Bearer " + this.$store.state.token,
      //     },
      //   })
      //   .then((response) => {
      //     console.log(response.data);
      //     this.otherPaiements = response.data.otherPaiements;
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },

    // On supprime un utilisateur seulement si il n'a aucun montant sinon on affiche un message d'avertissement
    async deleteUtilisateur(utilisateur) {
      if (utilisateur.Montant === "null") {
        try {
          if (confirm("Voulez vous vraiment supprimer ?")) {
            window.api
              .delete("utilisateurs/" + utilisateur.id, {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              })
              .then((response) => {
                this.toast.add({
                  severity: "success",
                  summary: "Eleve supprimé",
                  detail: "Votre eleve à été supprimé avec succès",
                  life: 3000,
                });
                // On enleve l'eleve supprimé de la liste et on affiche donc la liste réel
                this.elevesByAgence = this.elevesByAgence.filter(
                  (e) => e.id !== utilisateur.id
                );
                console.log(response);
              });
          }
        } catch (e) {
          // handle authentication error here
        }
      } else {
        this.toast.add({
          severity: "warn",
          summary: "Cette eleve ne peut pas être supprimé",
          detail: "Cette eleve a au moins un paiement",
          life: 3000,
        });
      }
    },

    // On archive l'eleve en patchant active ne false
    async archiveEleve(id) {
      try {
        if (confirm("Voulez vous vraiment Archiver cette eleve ?")) {
          window.api
            .patch(
              "utilisateur/" + id,
              {
                active: false,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then((response) => {
              this.toast.add({
                severity: "success",
                summary: "Eleve archivé",
                detail: "Votre eleve à été archivé avec succès",
                life: 3000,
              });
              this.elevesByAgence = this.elevesByAgence.filter(
                (e) => e.id !== id
              );
              console.log(response);
            });
        }
      } catch (e) {
        // handle authentication error here
      }
    },

    // Calcul du CA via les intervalles qui ne fonctionne pas (à ameliorer ou refaire carrement)
    calculCa() {
      this.allPaiements = [];
      this.utilisateurs.forEach((util) => {
        if (util["Montant"] !== "null") {
          util["Montant"].forEach((paiement) => {
            const changedDate = paiement.details.date.replace(
              /(..)\/(..)\/(....) (..):(..)/,
              "$3-$2-$1 $4:$5"
            );
            let date = new Date(changedDate);
            console.log(date.getTime());
            date = moment(String(date)).format("MM-DD-YYYY");
            this.allPaiements.push(paiement);
          });
        }
      });
      this.dateDepartCa = new Date();
      this.dateFinCa = new Date();

      this.dateDepartCa = moment(String(this.dateDepartCa)).format(
        "DD-MM-YYYY"
      );
      this.dateFinCa = moment(String(this.dateFinCa)).format("DD-MM-YYYY");
      this.allPaiements.filter(
        (paiement) =>
          this.dateDepartCa <= paiement.details.date <= this.dateFinCa
      );
    },

    // permet d'afficher les eleves par agences au moment de cliquer sur le nombres d'eleves
    showElevesByAgence() {
      (this.showEleves = true),
        (this.elevesByAgence = this.ElevesAdmin.filter(
          (e) => e.agence.id === this.selectedItem.id
        ));
    },

    // petmet d'afficher les champs d'intervalles pour le ca
    showDateForCa() {
      this.displayDateForCa = true;
    },

    // permet d'afficher les monos par agences au moment de cliquer sur le nombres de monos
    showMonosByAgence() {
      (this.showMoniteurs = true),
        (this.moniteursByAgence = this.moniteurs.filter(
          (m) => m.agence.id === this.selectedItem.id
        ));
    },

    // appelle à la liste agence stocké dans "agences"
    async created() {
      try {
        window.api
          .get("/getAllAgences", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((res) => {
            this.agences = res.data.Agences;
            this.agences.forEach((a) => {
              let totsMontant = [];
              var date = new Date();
              var annee = date.getFullYear();
              // On recup la somme des montants depuis le 01/01/annee actuel,
              // si la date du paiement est supérieur a annee actuel on
              // le format et le push dans tots montant, ensuite voir la fonction reduce qui calcul la somme,
              //  enfin on ajoute "montantTotalByAnneeN" pour chaques agences
              var anneeActuel = new Date("01/01/" + annee);
              anneeActuel = moment(String(anneeActuel)).format("DD-MM-YYYY");
              a.user.forEach((u) => {
                if (u.montant != 0 && u.montant.date >= anneeActuel) {
                  u.montant.date = moment(String(u.montant.date)).format(
                    "DD-MM-YYYY"
                  );
                  totsMontant.push(u.montant.amount);
                }
              });
              totsMontant = totsMontant.reduce((a, b) => +a + +b);
              a["montantTotalByAnneeN"] = totsMontant;
            });
          });
      } catch (e) {
        // handle authentication error here
      }
    },

    // fonction qui active la modif , il enleve aussi le fond coloré à droite
    activeModif() {
      if (this.selectedItem != null) {
        document.getElementById("agenceChoisi").style.background = "none";
      }
      this.getAgence();
      this.getElevesAgence();
      this.retrievePaiements();
    },

    // fonction permettant de recup une agence
    async getAgence() {
      try {
        window.api.get("/agences/" + this.selectedItem.id).then((res) => {
          this.agence = res.data;
        });
      } catch (e) {
        // handle authentication error here
      }
    },

    // fermer le pop up
    closeBasic() {
      this.displayPostAgence = false;
    },

    // Supprimer une agence
    async deleteAgence() {
      try {
        if (confirm("Voulez vous vraiment supprimer ?")) {
          window.api
            .delete("agences/" + this.agence.id, {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            })
            .then(() => {
              this.toast.add({
                severity: "success",
                summary: "Agence supprimé",
                detail: "Votre agence à été supprimé avec succès",
                life: 3000,
              });
              this.created();

            });
        }
      } catch (e) {
        // handle authentication error here
      }
    },

    async getElevesAgence() {
      const response = await window.api.get(
        "/eleves/agence/" + this.selectedItem.id,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        }
      );
      return (this.utilisateurs = response.data.utilisateurs);
    },

    add() {
      this.displayPostAgence = true;
    },

    // fonction permettant de post une agence
    async postAgence() {
      window.api
        .post("/agences", this.form)
        .then((response) => {
          this.toast.add({
            severity: "success",
            summary: "Agence ajouté",
            detail: "Votre agence à été ajouté avec succès",
            life: 3000,
          });
          this.displayPostAgence = false;
          this.$emit("completed", response.data);
          this.created();
        })
        .catch((error) => {
          // handle authentication and validation errors here
          console.log("ERRR::", error.response.data);
        });
    },

    // fonction permettant de modifier une agence
    putAgence() {
      window.api
        .put(`agences/` + this.selectedItem.id, this.agence, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.toast.add({
            severity: "success",
            summary: "Agence modifié",
            detail: "Votre agence à été modifié avec succès",
            life: 3000,
          });
          this.created();
          this.$emit("completed", response.data);
        })
        .catch(function (error) {
          console.log(error.response);
        });
    },
  },

  computed: {
    // tous les eleves
    ElevesAdmin() {
      return this.$store.state.ElevesAdmin;
    },

    // tous les monos
    moniteurs() {
      return this.$store.state.moniteurs;
    },
  },
};
</script>

<style scoped>
.btnTurquoise {
  background: #07989f;
  border-radius: 13px;
  border: none;
}

.btnRouge {
  background: #ec2b22;
  border-radius: 13px;
  border: none;
}
.btnViolet {
  background: #de6fa8;
  border-radius: 13px;
  border: none;
}

#btnContainer {
  margin-top: 1%;
  margin-bottom: 3%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.btnForm {
  background-color: #de6fa8; /* Green */
  border: none;
}

a {
  text-decoration: none;
}

#histo {
  width: 220px;
}
#formModif {
  margin: auto;
  width: 100%;
}

#head {
  display: flex;
  flex-direction: row;
  align-content: space-around;
  width: 50%;
  margin-top: 5%;
}
#add {
  width: 40px;
  height: 40px;
  margin-top: 5%;
  cursor: pointer;
}

#titreChoix {
  flex-wrap: wrap;
  font-size: 2rem;
  color: white;
}

#styleTitreChoix {
  background-color: rgb(255, 255, 255, 0.47);
  padding: 1%;
  border-radius: 13px;
}

#agence {
  background-image: url("../../../assets/images/fondd.png");
  background-size: cover;
  border-right: 1px solid black;
}

#agenceChoisi {
  width: 100%;
  background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#titreAgence {
  font-size: 25pt;
  font-weight: bold;
  color: #2e9ea3;
}

#conteneurAgence {
  display: grid;
  grid-template-columns: 50% 50%;
}

#conteneurAgence div {
  justify-content: space-evenly;
}

.text-field,
text {
  position: relative;
  margin: 10px 2.5px 20px 2.5px;
}

input {
  display: inline-block;
  border: thin solid #fafafa;
  color: #444;
  background-color: #fafafa;
  padding: 10px 10px 10px 10px;
}

input:focus {
  border: thin solid #ffaa71;
  border-bottom: solid medium #ffaa71;
  background-color: #fff;
}

label {
  color: #999;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 10px;
  transition: 0.2s;
}

input:focus ~ label,
input:valid ~ label {
  top: -10px;
  left: 15px;
  font-size: small;
  color: #167794;
  background-color: #fff;
  padding: 0 5px 0 5px;
}
</style>
