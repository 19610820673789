<template>
	<div class="loader"></div>
</template>

<script>
	export default {}
</script>

<style>
.loader {
	display: inline-block;
	border: 10px solid #f3f3f3;
	border-radius: 50%;
	border-top: 10px solid #555;
	width: 120px;
	height: 120px;
	animation: spin 1.5s linear infinite;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
</style>
