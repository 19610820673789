<template>
  <div class="container">
    <div class="row" v-if="load == true">
      <div class="col-md-12 col-sm-12 mt-4">
        <button class="btn btn-primary">SOLDE {{ solde }} €</button>
      </div>
      <div class="btnContainer row">
        <div class="col-md-2 col-sm-12 mt-4">
          <button class="btn btn-primary" @click="showPopupPaiement()">
            <img
              class="logoPaiement"
              src="../../assets/images/paiement/de-largent.png"
              alt=""
            />
          </button>
        </div>
        <div class="col-md-2 col-sm-12 mt-4">
          <template v-if="this.data.statutDossier != 'Enregistrer'">
            <button
              v-if="dossier.ok == 0"
              class="btn btn-primary"
              @click="checkDossier()"
              :disabled="this.dossier.mode"
            >
              <img
                class="logoPaiement"
                src="../../assets/images/paiement/dossier(1).png"
                alt=""
              />
            </button>
            <button
              v-if="dossier.ok == 1"
              class="btn btn-warning"
              @click="checkDossier()"
              :disabled="this.dossier.mode"
            >
              <img
                class="logoPaiement"
                src="../../assets/images/paiement/dossier(1).png"
                alt=""
              />
            </button>
            <button
              v-if="dossier.ok == 2"
              class="btn btn-success"
              @click="checkDossier()"
              :disabled="this.dossier.mode"
            >
              <img
                class="logoPaiement"
                src="../../assets/images/paiement/dossier(1).png"
                alt=""
              />
            </button>
          </template>
          <template v-else>
            <button
              class="btn btn-info"
              @click="checkDossier()"
              disabled
            >
              <img
                class="logoPaiement"
                src="../../assets/images/paiement/dossier(1).png"
                alt=""
              />
              
            </button>
          </template>

        </div>
        <div class="col-md-2 col-sm-12 mt-4">
          <template v-if="this.data.code == 'Obtenu'">
            <button
              class="btn btn-info"
              @click="checkCode()"
              disabled
            >
              <img
                class="logoPaiement"
                src="../../assets/images/paiement/limitation-de-vitesse.png"
                alt=""
              />
            </button>
          </template>
          <template v-else>
            <button
              v-if="code.ok == 0"
              class="btn btn-primary"
              @click="checkCode()"
              :disabled="this.code.mode"
              
            >
              <img
                class="logoPaiement"
                src="../../assets/images/paiement/limitation-de-vitesse.png"
                alt=""
              />
            </button>
            <button
              v-if="code.ok == 1"
              class="btn btn-warning"
              @click="checkCode()"
              :disabled="this.code.mode"
            >
              <img
                class="logoPaiement"
                src="../../assets/images/paiement/limitation-de-vitesse.png"
                alt=""
              />
            </button>
            <button
              v-if="code.ok == 2"
              class="btn btn-success"
              @click="checkCode()"
              :disabled="this.code.mode"
            >
              <img
                class="logoPaiement"
                src="../../assets/images/paiement/limitation-de-vitesse.png"
                alt=""
              />
            </button>
          </template>

        </div>
        <div class="col-md-2 col-sm-12 mt-4" v-if="droitCpf.length != 0">
          <button @click="showCpfDetail()" class="btn btn-primary">CPF</button>
        </div>

        <div class="col-md-2 col-sm-12 mt-4" v-if="droitPe.length != 0">
          <button @click="showPeDetail()" class="btn btn-primary">PE</button>
        </div>

        <div class="col-md-2 col-sm-12 mt-4" v-if="droitSubv.length != 0">
          <button @click="showSubvDetail()" class="btn btn-primary">
            SUBV
          </button>
        </div>
      </div>

      <div class="tabPaiement">
        <table id="paiement">
          <tr>
            <th v-if="sortDateValue">
              <button class="btnSortDate" @click="sortDate()">Date ▼</button>
            </th>
            <th v-if="!sortDateValue">
              <button class="btnSortDate" @click="sortDate()">Date ▲</button>
            </th>
            <th>
              <select
                class="selectTab"
                name="nature"
                id="nature"
                v-model="tabPaiement.nature"
                @change="changeNature()"
              >
                <option value="">Nature</option>
                <option value="Droits CPF">Droits CPF</option>
                <option value="Droits PE">Droits PE</option>
                <option value="Droits SUBV">Droits SUBV</option>
                <option value="Acpte CPF">Acpte CPF</option>
                <option value="Acpte PE">Acpte PE</option>
                <option value="Acpte SUBV">Acpte SUBV</option>
                <option value="dossier">Dossier</option>
                <option value="code">Code</option>
                <option value="Paiement">Paiement</option>
                <option value="conduite">Conduite</option>
              </select>
            </th>
            <th>C</th>
            <th>D</th>
            <th>
              <select
                class="selectTab"
                name="modes"
                id="modes"
                v-model="tabPaiement.mode"
                @change="changeMode()"
              >
                <option value="">Mode</option>
                <option value="ESP">ESP</option>
                <option value="CHQ">CHQ</option>
                <option value="CB">CB</option>
                <option value="VIR">VIR</option>
                <option value="PE">PE</option>
                <option value="SUBV">SUBV</option>
                <option value="CPF">CPF</option>
              </select>
            </th>
          </tr>
          <tr
            v-for="paiement in paiements"
            :key="paiement.id"
            :id="paiement.id"
            @click="editPaiement(paiement.id)"
          >
            <td v-if="new Date(paiement.date.date).toLocaleDateString() != 'Invalid Date'">{{ new Date(paiement.date.date).toLocaleDateString() }}</td>
            <td v-if="new Date(paiement.date.date).toLocaleDateString() == 'Invalid Date'">{{ paiement.date }}</td>
            <td>{{ paiement.description }}</td>
            <template v-if="paiement.mode != 'CB WEB'">
              <template v-if="paiement.description == 'code'">
                <td>{{ paiement.tot }}</td>
                <td>{{ paiement.tot }}</td>
              </template>
              <template v-else-if="paiement.description == 'dossier'">
                <td>{{ paiement.tot }}</td>
                <td>{{ paiement.tot }}</td>
              </template>
              <template v-else>
                <td>{{ paiement.tot }}</td>
                <td></td>
              </template>

            </template>
            <template v-else>
                <td>{{ paiement.tot }}</td>
                <td>{{ paiement.tot }}</td>
              </template>
            <template>

            </template>


            <td>{{ paiement.mode }}</td>
          </tr>
          <tr>
            <td>CONDUITE</td>
            <td>CONDUITE</td>
            <td></td>
            <td>{{ this.rdvSolde }}</td>
            <td></td>
          </tr>
        </table>
      </div>

      <Dialog
        v-model:visible="popupPaiement"
        modal
        header="PAIEMENT"
        :style="{ width: '50vw' }"
      >
        <div class="input row">
          <div class="col-md-2 col-sm-12">
            <label class="labelForm">Date Paiement</label>
            <input
              type="date"
              name="date"
              id="date"
              v-model="postPaiement.date"
            />
          </div>

          <div class="col-md-3 col-sm-12" v-if="postPaiement.id == ''">
            <label class="labelForm">Montant</label>
            <input
              type="number"
              name="price"
              id="price"
              v-model="postPaiement.price"
            />
          </div>

          <div class="col-md-3 col-sm-12" v-if="postPaiement.id != ''">
            <label class="labelForm">Montant</label>
            <input
              type="number"
              name="price"
              id="price"
              v-model="postPaiement.price"
              disabled
            />
          </div>

          <div class="col-md-3 col-sm-12">
            <label class="labelForm">Mode de paiement</label>
            <br />
            <select
              name="mode"
              id="mode"
              v-model="postPaiement.mode"
              @click="changeSelect()"
            >
              <option value="" disabled>Mode de paiement</option>
              <option value="ESP">ESP</option>
              <option value="CHQ">CHQ</option>
              <option value="CB">CB</option>
              <option value="VIR">VIR</option>
              <option value="PE">PE</option>
              <option value="SUBV">SUBV</option>
              <option value="CPF">CPF</option>
            </select>
          </div>

          <div class="col-md-2 col-sm-12" v-if="step == 1">
            <label class="labelForm">payeur</label>
            <br />
            <select name="payeur" id="payeur" v-model="postPaiement.payeur">
              <option value="" disabled>Payeur</option>
              <option value="client">client</option>
            </select>
          </div>

          <div class="col-md-2 col-sm-12" v-if="step == 2">
            <label class="labelForm">payeur</label>
            <br />
            <select name="payeur" id="payeur" v-model="postPaiement.payeur">
              <option value="" disabled>Payeur</option>
              <option value="client">client</option>
              <option value="CPF">CPF</option>
              <option value="SUBV">SUBV</option>
              <option value="PE">PE</option>
            </select>
          </div>

          <div class="col-md-2 col-sm-12" v-if="step == 3">
            <label class="labelForm">payeur</label>

            <input
              type="text"
              name="payeur"
              id="payeur"
              v-model="postPaiement.payeur"
            />
          </div>

          <div v-if="postPaiement.mode == 'VIR'">
            <div v-if="postPaiement.payeur == 'CPF'">
              <select
                class="col-md-2 mt-4 col-sm-12"
                name="choice"
                id="choice"
                v-model="postPaiement.choix"
              >
                <option value="" disabled>Droit CPF</option>
                <template v-for="droit in droitCpf" :key="droit.id">
                  <option :value="droit.id">{{ droit.payeur }}</option>
                </template>
              </select>
            </div>

            <div v-if="postPaiement.payeur == 'PE'">
              <select
                class="col-md-2 mt-4 col-sm-12"
                name="choice"
                id="choice"
                v-model="postPaiement.choix"
              >
                <option value="" disabled>Droit PE</option>
                <template v-for="droit in droitPe" :key="droit.id">
                  <option :value="droit.id">{{ droit.payeur }}</option>
                </template>
              </select>
            </div>

            <div v-if="postPaiement.payeur == 'SUBV'">
              <select
                class="col-md-2 mt-4 col-sm-12"
                name="choice"
                id="choice"
                v-model="postPaiement.choix"
              >
                <option value="" disabled>Droit SUBV</option>
                <template v-for="droit in droitSubv" :key="droit.id">
                  <option :value="droit.id">{{ droit.payeur }}</option>
                </template>
              </select>
            </div>
          </div>
        </div>

        <template #footer>
          <button
            class="btn btn-primary"
            @click="postPaiements()"
            v-if="postPaiement.id == ''"
          >
            OK - PAIEMENT REALISE
          </button>

          <button
            class="btn btn-primary"
            @click="putPaiements()"
            v-if="postPaiement.id != ''"
          >
            MODIFIER LE PAIEMENT
          </button>

          <button
            class="btn btn-danger"
            @click="deletePaiements()"
            v-if="postPaiement.id != ''"
          >
            SUPPRIMER LE PAIEMENT
          </button>
        </template>
      </Dialog>
      <template v-if="showCpf">
        <CpfPopup
          @cancelCpfDetail="cancelCpfDetail($event)"
          :boolean="showCpf"
          :droitCpf="droitCpf"
          :acpteCpf="acpteCpf"
        />
      </template>

      <template v-if="showPe">
        <PePopup
          @cancelPeDetail="cancelPeDetail($event)"
          :boolean="showPe"
          :droitPe="droitPe"
          :acptePe="acptePe"
        />
      </template>

      <template v-if="showSubv">
        <SubvPopup
          @cancelSubvDetail="cancelSubvDetail($event)"
          :boolean="showSubv"
          :droitSubv="droitSubv"
          :acpteSubv="acpteSubv"
        />
      </template>
    </div>
    <div class="row" v-else>
      <h1>Wait....</h1>
    </div>
  </div>
</template>

<script>
//import moment from "moment";
import CpfPopup from "../eleve/paiementComp/CpfPopup.vue";
import PePopup from "../eleve/paiementComp/PePopup.vue";
import SubvPopup from "../eleve/paiementComp/SubvPopup.vue";

export default {
  props: ["data"],

  components: {
    CpfPopup,
    PePopup,
    SubvPopup,
  },

  data() {
    return {
      load: false,
      popupPaiement: false,
      dossier: [],
      code: [],
      conduite: [],

      droitCpf: [],
      droitPe: [],
      droitSubv: [],

      acpteCpf: [],
      acptePe: [],
      acpteSubv: [],

      allPaiements: [],
      paiements: [],

      postPaiement: {
        id: "",
        date: "",
        price: 0,
        mode: "",
        payeur: "",
        choix: "",
        montantEdit: 0,
      },

      tabPaiement: {
        date: false,
        nature: "",
        mode: "",
      },

      fonc: {
        code: false,
        dossier: false,
        conduite: false,
      },
      step: 1,
      sortDateValue: false,

      solde: 0,
      rdvSolde: 0,
      showCpf: false,
      showPe: false,
      showSubv: false,
    };
  },

  beforeMount() {},

  mounted() {
    this.checkPaiement();
  },

  methods: {

    deletePaiements(){
      window.api
          .patch(
            "/other_paiements/" + this.postPaiement.id,
            {
              archive: true,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            this.checkPaiement();
          })
          .catch((error) => {
            console.log(error);
          });
    },

    editPaiement(id) {
      this.postPaiement.date = "";
      this.postPaiement.price = 0;
      this.postPaiement.mode = "";
      this.postPaiement.payeur = "";
      this.postPaiement.id = "";
      this.postPaiement.montantEdit = 0;
      this.step = 1;

      let paiement = [];
      paiement = this.allPaiements.find((p) => p.id == id);
      if (paiement.mode != "CB WEB" && paiement.description != "code" && paiement.description != "dossier") {
        if (paiement.mode == "ESP" || paiement.mode == "CB") {
          this.step = 1;
          this.payeur = "client";
        } else if (paiement.mode == "CHQ" || paiement.mode == "VIR") {
          this.step = 2;
        } else if (
          paiement.mode == "CPF" ||
          paiement.mode == "SUBV" ||
          paiement.mode == "PE"
        ) {
          this.step = 3;
        }

        this.postPaiement.date = new Date(
          paiement.date.date
        ).toLocaleDateString();
        this.postPaiement.price = paiement.tot;
        this.postPaiement.montantEdit = paiement.tot;
        this.postPaiement.mode = paiement.mode;
        this.postPaiement.payeur = paiement.payeur;
        this.postPaiement.id = id;

        this.popupPaiement = true;

      }
    },

    showSubvDetail() {
      this.showSubv = true;
    },

    cancelSubvDetail(value) {
      this.showSubv = value;
    },

    showCpfDetail() {
      this.showCpf = true;
    },

    cancelCpfDetail(value) {
      this.showCpf = value;
    },

    showPeDetail() {
      this.showPe = true;
    },

    cancelPeDetail(value) {
      this.showPe = value;
    },

    checkCode() {
      if (this.code.ok == 0 && this.code.mode == false ) {
        window.api
          .post(
            "/other_paiements",
            {
              eleve: "api/utilisateurs/" + this.data.id,
              description: "code",
              totalAmount: this.code.price * 100,
              mode: "VIR",
              date: new Date(),
              payeur: "Client",
              archive: false,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.checkPaiement();
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.code.ok == 1 && this.code.mode == false) {
        window.api
          .delete("/other_paiements/" + this.code.json[0].id, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then(() => {
            this.checkPaiement();
          })
          .catch((error) => {
            console.log("ERRR::", error.response.data);
          });
      }
    },

    checkDossier() {
      if (this.dossier.ok == 0 && this.dossier.mode == false) {
        window.api
          .post(
            "/other_paiements",
            {
              eleve: "api/utilisateurs/" + this.data.id,
              description: "dossier",
              totalAmount: this.dossier.price * 100,
              mode: "VIR",
              date: new Date(),
              payeur: "Client",
              archive: false,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.checkPaiement();
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.dossier.ok == 1 && this.dossier.mode == false) {
        window.api
          .delete("/other_paiements/" + this.dossier.json[0].id, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then(() => {
            this.checkPaiement();
          })
          .catch((error) => {
            console.log("ERRR::", error.response.data);
          });
      }
    },

    showPopupPaiement() {
      this.postPaiement.date = "";
      this.postPaiement.price = "";
      this.postPaiement.mode = "";
      this.postPaiement.payeur = "";
      this.postPaiement.id = "";

      if (this.popupPaiement) {
        this.popupPaiement = false;
      } else {
        this.popupPaiement = true;
      }
    },

    sortDate() {
      if (this.sortDateValue) {
        this.sortDateValue = false;
        this.paiements.sort(function (a, b) {
          return new Date(b.date.date) - new Date(a.date.date);
        });
      } else {
        this.sortDateValue = true;
        this.paiements.sort(function (a, b) {
          return new Date(a.date.date) - new Date(b.date.date);
        });
      }
    },

    changeNature() {
      this.paiements = this.allPaiements;
      if (this.tabPaiement.nature != "") {
        let changeTab = this.paiements.filter(
          (p) => p.description == this.tabPaiement.nature
        );
        this.paiements = changeTab;
      }
    },

    changeMode() {
      this.paiements = this.allPaiements;
      if (this.tabPaiement.mode != "") {
        let changeTab = this.paiements.filter(
          (p) => p.mode == this.tabPaiement.mode
        );
        this.paiements = changeTab;
      }
    },

    getSoldes() {
      window.api
        .get("/solde/" + this.data.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.solde = response.data.solde;
          this.rdvSolde = response.data.rdvSolde;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    checkPaiement() {
      window.api
        .get("/eleve/check/paiement/" + this.data.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.dossier = response.data.dossier;
          this.code = response.data.code;
          this.conduite = response.data.conduite;
          this.popupPaiement = false;

          this.droitCpf = response.data.droitCpf;
          this.droitPe = response.data.droitPe;
          this.droitSubv = response.data.droitSubv;

          this.acpteCpf = response.data.acpteCpf;
          this.acptePe = response.data.acptePe;
          this.acpteSubv = response.data.acpteSubv;

          this.paiements = response.data.jsonRes;

          // sort paiement by date
          this.paiements.sort(function (a, b) {
            return new Date(b.date.date) - new Date(a.date.date);
          });

          // sort paiement by date
          this.allPaiements = response.data.jsonRes;
          this.allPaiements.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });

          this.load = true;

          this.getSoldes();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    changeSelect() {
      let e = document.getElementById("mode");

      if (e.value == "ESP" || e.value == "CB") {
        this.step = 1;
        this.payeur = "client";
      } else if (e.value == "CHQ" || e.value == "VIR") {
        this.step = 2;
        this.payeur = "";
      } else if (e.value == "CPF" || e.value == "SUBV" || e.value == "PE") {
        this.step = 3;
        this.payeur = "";
      }
    },

    manageSolde() {},

    postPaiements() {
      if (this.postPaiement.mode == "CPF") {
        window.api
          .post(
            "/other_paiements",
            {
              eleve: "api/utilisateurs/" + this.data.id,
              description: "Droits CPF",
              totalAmount: this.postPaiement.price * 100,
              mode: this.postPaiement.mode,
              date: this.postPaiement.date,
              payeur: this.postPaiement.payeur,
              archive: false,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.checkPaiement();
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.postPaiement.mode == "PE") {
        window.api
          .post(
            "/other_paiements",
            {
              eleve: "api/utilisateurs/" + this.data.id,
              description: "Droits PE",
              totalAmount: this.postPaiement.price * 100,
              mode: this.postPaiement.mode,
              date: this.postPaiement.date,
              payeur: this.postPaiement.payeur,
              archive: false,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.checkPaiement();
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.postPaiement.mode == "SUBV") {
        window.api
          .post(
            "/other_paiements",
            {
              eleve: "api/utilisateurs/" + this.data.id,
              description: "Droits SUBV",
              totalAmount: this.postPaiement.price * 100,
              mode: this.postPaiement.mode,
              date: this.postPaiement.date,
              payeur: this.postPaiement.payeur,
              archive: false,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.checkPaiement();
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.postPaiement.mode == "VIR") {
        if (this.postPaiement.payeur == "CPF") {
          let droit = this.droitCpf.find(
            (e) => e.id == this.postPaiement.choix
          );
          let montant = droit.tot - this.postPaiement.price;
          if (montant < 0) {
            montant = 0;
          }
          window.api
            .put(
              "/other_paiements/" + droit.id,
              {
                eleve: "api/utilisateurs/" + this.data.id,
                description: "Droits CPF",
                totalAmount: montant * 100,
                mode: droit.mode,
                date: droit.date.date,
                payeur: droit.payeur,
                archive: false,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then(() => {
              window.api
                .post(
                  "/other_paiements",
                  {
                    otherPaiementC: "api/other_paiements/" + droit.id,
                    eleve: "api/utilisateurs/" + this.data.id,
                    description: "Acpte CPF",
                    totalAmount: this.postPaiement.price * 100,
                    mode: this.postPaiement.mode,
                    date: this.postPaiement.date,
                    payeur: this.postPaiement.payeur,
                    archive: false,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                    },
                  }
                )
                .then(() => {
                  this.checkPaiement();
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (this.postPaiement.payeur == "PE") {
          let droit = this.droitPe.find((e) => e.id == this.postPaiement.choix);
          let montant = droit.tot - this.postPaiement.price;
          if (montant < 0) {
            montant = 0;
          }
          window.api
            .put(
              "/other_paiements/" + droit.id,
              {
                eleve: "api/utilisateurs/" + this.data.id,
                description: "Droits PE",
                totalAmount: montant * 100,
                mode: droit.mode,
                date: droit.date.date,
                payeur: droit.payeur,
                archive: false,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then(() => {
              window.api
                .post(
                  "/other_paiements",
                  {
                    otherPaiementC: "api/other_paiements/" + droit.id,
                    eleve: "api/utilisateurs/" + this.data.id,
                    description: "Acpte PE",
                    totalAmount: this.postPaiement.price * 100,
                    mode: this.postPaiement.mode,
                    date: this.postPaiement.date,
                    payeur: this.postPaiement.payeur,
                    archive: false,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                    },
                  }
                )
                .then(() => {
                  this.checkPaiement();
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (this.postPaiement.payeur == "SUBV") {
          let droit = this.droitSubv.find(
            (e) => e.id == this.postPaiement.choix
          );
          let montant = droit.tot - this.postPaiement.price;
          if (montant < 0) {
            montant = 0;
          }
          window.api
            .put(
              "/other_paiements/" + droit.id,
              {
                eleve: "api/utilisateurs/" + this.data.id,
                description: "Droits SUBV",
                totalAmount: montant * 100,
                mode: droit.mode,
                date: droit.date.date,
                payeur: droit.payeur,
                archive: false,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then(() => {
              window.api
                .post(
                  "/other_paiements",
                  {
                    otherPaiementC: "api/other_paiements/" + droit.id,
                    eleve: "api/utilisateurs/" + this.data.id,
                    description: "Acpte SUBV",
                    totalAmount: this.postPaiement.price * 100,
                    mode: this.postPaiement.mode,
                    date: this.postPaiement.date,
                    payeur: this.postPaiement.payeur,
                    archive: false,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                    },
                  }
                )
                .then(() => {
                  this.checkPaiement();
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          window.api
            .post(
              "/other_paiements",
              {
                eleve: "api/utilisateurs/" + this.data.id,
                description: "paiement",
                totalAmount: this.postPaiement.price * 100,
                mode: this.postPaiement.mode,
                date: this.postPaiement.date,
                payeur: this.postPaiement.payeur,
                archive: false,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then(() => {
              this.checkPaiement();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        window.api
          .post(
            "/other_paiements",
            {
              eleve: "api/utilisateurs/" + this.data.id,
              description: "paiement",
              totalAmount: this.postPaiement.price * 100,
              mode: this.postPaiement.mode,
              date: this.postPaiement.date,
              payeur: this.postPaiement.payeur,
              archive: false,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.checkPaiement();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    putPaiements() {
      if (this.postPaiement.mode == "CPF") {
        window.api
          .put(
            "/other_paiements/" + this.postPaiement.id,
            {
              eleve: "api/utilisateurs/" + this.data.id,
              description: "Droits CPF",
              totalAmount: this.postPaiement.price * 100,
              mode: this.postPaiement.mode,
              date: this.postPaiement.date,
              payeur: this.postPaiement.payeur,
              archive: false,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.checkPaiement();
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.postPaiement.mode == "PE") {
        window.api
          .put(
            "/other_paiements/" + this.postPaiement.id,
            {
              eleve: "api/utilisateurs/" + this.data.id,
              description: "Droits PE",
              totalAmount: this.postPaiement.price * 100,
              mode: this.postPaiement.mode,
              date: this.postPaiement.date,
              payeur: this.postPaiement.payeur,
              archive: false,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.checkPaiement();
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.postPaiement.mode == "SUBV") {
        window.api
          .put(
            "/other_paiements/" + this.postPaiement.id,
            {
              eleve: "api/utilisateurs/" + this.data.id,
              description: "Droits SUBV",
              totalAmount: this.postPaiement.price * 100,
              mode: this.postPaiement.mode,
              date: this.postPaiement.date,
              payeur: this.postPaiement.payeur,
              archive: false,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.checkPaiement();
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.postPaiement.mode == "VIR") {
        if (this.postPaiement.payeur == "CPF") {
          let droit = this.droitCpf.find(
            (e) => e.id == this.postPaiement.choix
          );
          let montant =
            this.postPaiement.montantEdit +
            (droit.tot - this.postPaiement.price);
          if (montant < 0) {
            montant = 0;
          }
          window.api
            .put(
              "/other_paiements/" + droit.id,
              {
                eleve: "api/utilisateurs/" + this.data.id,
                description: "Droits CPF",
                totalAmount: montant * 100,
                mode: droit.mode,
                date: droit.date.date,
                payeur: droit.payeur,
                archive: false,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then(() => {
              window.api
                .put(
                  "/other_paiements/" + this.postPaiement.id,
                  {
                    otherPaiementC: "api/other_paiements/" + droit.id,
                    eleve: "api/utilisateurs/" + this.data.id,
                    description: "Acpte CPF",
                    totalAmount: this.postPaiement.price * 100,
                    mode: this.postPaiement.mode,
                    date: this.postPaiement.date,
                    payeur: this.postPaiement.payeur,
                    archive: false,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                    },
                  }
                )
                .then(() => {
                  this.checkPaiement();
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (this.postPaiement.payeur == "PE") {
          let droit = this.droitPe.find((e) => e.id == this.postPaiement.choix);
          let montant =
            this.postPaiement.montantEdit +
            (droit.tot - this.postPaiement.price);
          if (montant < 0) {
            montant = 0;
          }
          window.api
            .put(
              "/other_paiements/" + droit.id,
              {
                eleve: "api/utilisateurs/" + this.data.id,
                description: "Droits PE",
                totalAmount: montant * 100,
                mode: droit.mode,
                date: droit.date.date,
                payeur: droit.payeur,
                archive: false,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then(() => {
              window.api
                .put(
                  "/other_paiements/" + this.postPaiement.id,
                  {
                    otherPaiementC: "api/other_paiements/" + droit.id,
                    eleve: "api/utilisateurs/" + this.data.id,
                    description: "Acpte PE",
                    totalAmount: this.postPaiement.price * 100,
                    mode: this.postPaiement.mode,
                    date: this.postPaiement.date,
                    payeur: this.postPaiement.payeur,
                    archive: false,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                    },
                  }
                )
                .then(() => {
                  this.checkPaiement();
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (this.postPaiement.payeur == "SUBV") {
          let droit = this.droitSubv.find(
            (e) => e.id == this.postPaiement.choix
          );
          let montant =
            this.postPaiement.montantEdit +
            (droit.tot - this.postPaiement.price);
          if (montant < 0) {
            montant = 0;
          }
          window.api
            .put(
              "/other_paiements/" + droit.id,
              {
                eleve: "api/utilisateurs/" + this.data.id,
                description: "Droits SUBV",
                totalAmount: montant * 100,
                mode: droit.mode,
                date: droit.date.date,
                payeur: droit.payeur,
                archive: false,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then(() => {
              window.api
                .put(
                  "/other_paiements/" + this.postPaiement.id,
                  {
                    otherPaiementC: "api/other_paiements/" + droit.id,
                    eleve: "api/utilisateurs/" + this.data.id,
                    description: "Acpte SUBV",
                    totalAmount: this.postPaiement.price * 100,
                    mode: this.postPaiement.mode,
                    date: this.postPaiement.date,
                    payeur: this.postPaiement.payeur,
                    archive: false,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                    },
                  }
                )
                .then(() => {
                  this.checkPaiement();
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          window.api
            .put(
              "/other_paiements/" + this.postPaiement.id,
              {
                eleve: "api/utilisateurs/" + this.data.id,
                description: "paiement",
                totalAmount: this.postPaiement.price * 100,
                mode: this.postPaiement.mode,
                date: this.postPaiement.date,
                payeur: this.postPaiement.payeur,
                archive: false,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then(() => {
              this.checkPaiement();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        window.api
          .put(
            "/other_paiements/" + this.postPaiement.id,
            {
              eleve: "api/utilisateurs/" + this.data.id,
              description: "paiement",
              totalAmount: this.postPaiement.price * 100,
              mode: this.postPaiement.mode,
              date: this.postPaiement.date,
              payeur: this.postPaiement.payeur,
              archive: false,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.checkPaiement();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
div.fonc {
  margin-top: 2em;
}

div.tabPaiement {
  width: 80%;
  margin: auto;
  margin-top: 1em;
  overflow: scroll;
  height: 90vh;
}

button.btnSortDate {
  background-color: #00a3ac;
  border: none;
  color: white;
  width: 80%;
}

select.selectTab {
  background-color: #00a3ac;
  border: none;
  color: white;
  width: 80%;
}

#paiement {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: auto;
}

#paiement td,
#paiement th {
  border: 1px solid #ddd;
  padding: 8px;
}

#paiement tr:nth-child(even) {
  background-color: #f2f2f2;
}

#paiement tr:hover {
  background-color: #ddd;
}

#paiement th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #07989f;
  color: white;
}

img.logoPaiement {
  width: 25px;
}

div.btnContainer {
  width: 80%;
  margin: auto;
}
</style>
