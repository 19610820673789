<template>
    <div class="background">
        <headerMonPermis />
        <div class="table">
            <DataTable :value="apointments" tableStyle="width: 100%">
                <Column field="ladate" header="date"></Column>
                <Column field="heure" header="heure"></Column>
                <Column field="moniteur.fullname" header="Moniteur"></Column>
                <Column field="lieuDeb.nom" header="Début"></Column>
                <Column field="lieuFin.nom" header="Fin"></Column>
            </DataTable>
        </div>
    </div>
  </template>
  
  <script>
import headerMonPermis from "@/components/headerMonPermis.vue";
  export default {
  
      props: [

      ],
  
    components: {
        headerMonPermis
    },
  
    watch: {},
  
    data() {
      return {
        apointments: [],
      };
    },
  
    mounted() {
        this.getHeures();
    },
  
    methods: {

        getHeures(){
            window.api
            .get("/moniteur/heures/" + this.$store.state.user.id, {
            headers: {
                Authorization: "Bearer " + this.$store.state.token,
            },
            })
            .then((response) => {
                this.apointments = response.data.apointments;
            })
            .catch((error) => {
            console.log(error);
            });
        },

    }
  };
  </script>
  
  <style scoped>
  
  div.background {
    height: 100vh;
    width: 100%;
    background-image: url("../../assets/images/Fond_ordinateur.png");
    background-size: cover;
    }

    div.table{
        width: 80%;
        margin: auto;
    }
  
  </style>
  