<template>
  <div class="container">
    <transition name="modal-fade">
      <div class="modal-backdrop">
        <div
          class="modal"
          role="dialog"
          aria-labelledby="modalTitle"
          aria-describedby="modalDescription"
        >
          <header class="modal-header" id="modalTitle">
            <slot name="header"> This is the default tile! </slot>
            <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close modal"
            ></button>
          </header>

          <section class="modal-body" id="modalDescription">
            <slot name="body">
              <h1 style="color: black">Ajouter un Theme!</h1>

              <form @submit.prevent="onSubmit">
                <span class="help is-danger" v-text="errors"></span>

                <div class="container pt-4">
                  <div class="row pb-4">
                    <div class="col-12 text-center">
                      <h1>Je crée mon Thème</h1>
                    </div>
                  </div>

                  <div class="row pt-4">
                    <div
                      class="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12"
                    >
                      <div class="field">
                        <div class="control">
                          <input
                            class="form-control"
                            type="numero"
                            placeholder="numero"
                            v-model="numero"
                            @keydown="errors = ''"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="field">
                        <div class="control">
                          <input
                            class="form-control"
                            type="label"
                            placeholder="label"
                            v-model="label"
                            @keydown="errors = ''"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div
                      class="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12"
                    >
                      <br />
                      <div class="field">
                        <div class="control">
                          <input
                            class="form-control"
                            type="number"
                            required
                            step="0.01"
                            placeholder="ponderation"
                            v-model="ponderation"
                            @keydown="errors = ''"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <br />
                      <div class="field">
                        <div class="control">
                          <input
                            class="form-control"
                            type="couleur"
                            placeholder="couleur"
                            v-model="couleur"
                            @keydown="errors = ''"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <br />

                <button class="btn btn-primary btn-sm" @click="print()">
                  Ajouter Theme
                </button>
              </form>
            </slot>
          </section>

          <footer class="modal-footer">
            <slot name="footer"> </slot>
            <button
              type="button"
              class="btn-green"
              @click="close"
              aria-label="Close modal"
            >
              Close me!
            </button>
          </footer>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Modal",
  data() {
    return {
      numero: "",
      label: "",
      ponderation: "",
      couleur: "",
    };
  },

  mounted() {
    this.getThemes();
  },

  methods: {
    ...mapActions(["getThemes"]),

    onSubmit() {
      this.postTheme();
    },

    print() {
      console.log(this.code_postal);
    },

    close() {
      this.$emit("close");
    },

    async postTheme() {
      window.api
        .post("/themes", this.$data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.numero = "";
          this.label = "";
          this.ponderation = "";
          this.couleur = "";
          this.$emit("completed", response.data);
          this.getThemes();
          alert("Votre Theme à ete ajouté");
        })
        .catch((error) => {
          // handle authentication and validation errors here
          console.log("ERRR::", error.response.data);
        });
    },
  },

  computed: {
    Themes() {
      return this.$store.state.Themes;
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  display: flex;
  opacity: 0.9;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  display: flex;
  flex-direction: column;
  width: 800px;
  height: 700px;
  position: relative;
  border-radius: 30px;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #ffffff;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  opacity: 1;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 40px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
}

#modalDescription {
  color: white;
  font-size: 1.1em;
}
.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
