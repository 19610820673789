<template>
  <div class="container-fluid" id="conteneur">
    <div id="header">
      <DropdownNav></DropdownNav>
      <router-link to="/home"
        ><i class="pi pi-home iconHome  ms-3" style="font-size: 2rem"></i
      ></router-link>
    </div>
    <div id="formAgence">
      <div class="row">
        <div class="col-12 text-center">
          <h1>Modifier {{ this.$store.state.user.agence.nom }}</h1>
        </div>
      </div>

      <form @submit.prevent="onSubmit">
        <div class="row ">
          
          <div class="field offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12">
              <div class="control">
                <label class="labelForm">Date de naissance</label>
                <input
                  class="form-control marge"
                  type="nom"
                  placeholder="nom"
                  v-model="agence.nom"
                  @keydown="errors = ''"
                />
              </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="field">
              <div class="control">
                <label class="labelForm">Adresse</label>
                <input
                  class="form-control"
                  type="adresse"
                  placeholder="adresse"
                  v-model="agence.adresse"
                  @keydown="errors = ''"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12">
            <br />
            <div class="field">
              <div class="control">
                <label class="labelForm">Code Postal</label>
                <input
                  class="form-control"
                  type="codePostal"
                  placeholder="code_postal"
                  v-model="agence.codePostal"
                  @keydown="errors = ''"
                />
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12">
            <br />
            <div class="field">
              <div class="control">
                <label class="labelForm">Ville</label>
                <input
                  class="form-control"
                  type="ville"
                  placeholder="ville"
                  v-model="agence.ville"
                  @keydown="errors = ''"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-4">
          <div class="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12">
            <div class="field">
              <div class="control">
                <label class="labelForm">Téléphone</label>
                <input
                  class="form-control"
                  type="telephone"
                  placeholder="telephone"
                  v-model="agence.telephone"
                  @keydown="errors = ''"
                />
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="field">
              <div class="control">
                <label class="labelForm">Siret</label>
                <input
                  class="form-control"
                  type="siret"
                  placeholder="siret"
                  v-model="agence.siret"
                  @keydown="errors = ''"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12">
            <br />
            <div class="field">
              <div class="control">
                <label class="labelForm">Numéro Agrement</label>
                <input
                  class="form-control"
                  type="numAgrement"
                  placeholder="numAgrement"
                  v-model="agence.numAgrement"
                  @keydown="errors = ''"
                />
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12">
            <br />
            <div class="field">
              <div class="control">
                <label class="labelForm">Adresse</label>
                <input
                  class="form-control"
                  type="numDeclarActivite"
                  placeholder="numDeclarActivite"
                  v-model="agence.numDeclarActivite"
                  @keydown="errors = ''"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-4">
          <div class="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12">
            <div class="field">
              <div class="control">
                <label class="labelForm">numéro assurance</label>
                <input
                  class="form-control"
                  type="numAssurance"
                  placeholder="numAssurance"
                  v-model="agence.numAssurance"
                  @keydown="errors = ''"
                />
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="field">
              <div class="control">
                <label class="labelForm">numéro police assurance</label>
                <input
                  class="form-control"
                  type="numPoliceAssurance"
                  placeholder="numPoliceAssurance"
                  v-model="agence.numPoliceAssurance"
                  @keydown="errors = ''"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12">
            <br />
            <div class="field">
              <div class="control">
                <label class="labelForm">Nom police assurance</label>
                <input
                  class="form-control"
                  type="nomPoliceAssurance"
                  placeholder="nomPoliceAssurance"
                  v-model="agence.nomPoliceAssurance"
                  @keydown="errors = ''"
                />
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12">
            <br />
            <div class="field">
              <div class="control">
                <label class="labelForm">Numéro fond de garantie</label>
                <input
                  class="form-control"
                  type="numFondGanrantie"
                  placeholder="numFondGanrantie"
                  v-model="agence.numFondGanrantie"
                  @keydown="errors = ''"
                />
              </div>
            </div>
          </div>

          <div class=" offset-lg-2 offset-md-2 col-lg-8 col-md-8 col-sm-8 m">
            <br />
            <div class="field">
              <div class="control">
                <label class="labelForm">Montant fond de Garantie</label>
                <input
                  class="form-control"
                  type="number"
                  step="0.01"
                  placeholder="montantFondGarantie"
                  v-model="agence.montantFondGarantie"
                  @keydown="errors = ''"
                />
              </div>
            </div>
          </div>

          <div class=" offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-sm-12 m">
            <br />
            <div class="field">
              <div class="control">
                <label class="labelForm">Adresse mail</label>
                <input
                  class="form-control"
                  type="mail"
                  placeholder="mail"
                  v-model="agence.mail"
                  @keydown="errors = ''"
                />
              </div>
            </div>
          </div>

          <div class=" col-lg-4 col-md-4 col-sm-12 m">
            <br />
            <div class="field">
              <div class="control">
                <label class="labelForm">Adresse mail Contrat</label>
                <input
                  class="form-control"
                  type="mail"
                  placeholder="mail contrat"
                  v-model="agence.mailContrat"
                  @keydown="errors = ''"
                />
              </div>
            </div>
          </div>

        </div>
        <br />
        <button type="button" class="btn btn-primary" @click="updateAgence()">
          Update
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import DropdownNav from "../../components/DropdownNav.vue";
export default {
  data() {
    return {
      agence: {},
    };
  },
  mounted() {
    this.Champs();
  },
  methods: {
    print() {
      console.log(this.$route.params.agence);
    },
    async Champs() {
      try {
        window.api
          .get(`agences/${this.$store.state.user.agence.id}`, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((result) => {
            this.agence = result.data;
            console.log(this.agence);
          });
      } catch (e) {
        // handle authentication error here
      }
    },
    updateAgence() {
      window.api
        .put(`agences/${this.$store.state.user.agence.id}`, this.agence, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.$emit("completed", response.data);
        })
        .catch(function (error) {
          console.log(error.response);
        });
    },
  },
  components: { DropdownNav },
};
</script>

<style scoped>

label.labelForm{
  text-align: left;

  display: block;
}

#header {
  display: flex;
  padding-top: 2%;

}
#conteneur {
  background-image: url("../../assets/images/Fond_ordinateur.png");
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}
#formAgence {
  display: flex;
  flex-direction: column;
  margin-top: 4%;
  align-content: center;
  height: auto;
  width: 100%;
}
</style>
