<template>

    <h1>CP - CM - Cours annulés</h1>
    
    <div class="cpmono">

        <div class="cp">
            <h5>CP - CM</h5>
            <DataTable :value="cpCm" responsiveLayout="scroll">
                <Column field="monitor" header="Moniteur" :sortable="true"></Column>
                <Column field="motif" header="Motif" :sortable="true"></Column>
                <Column field="date.date" header="Date" :sortable="true"></Column>
            </DataTable>
        </div>

        <div class="rdv">
            <h5>Cours annulés</h5>
            <DataTable :value="cancels" responsiveLayout="scroll">
                <Column field="moniteur" header="Moniteur" :sortable="true"></Column>
                <Column field="eleve" header="eleve" :sortable="true"></Column>
                <Column field="lieu" header="Lieu" :sortable="true"></Column>
                <Column field="start.date" header="Début" :sortable="true"></Column>
                <Column field="end.date" header="Fin" :sortable="true"></Column>
                <Column field="lieu" header="Lieu" :sortable="true"></Column>
                <Column field="motif.type" header="Type annulation" :sortable="true"></Column>
                <Column field="motif.lemotif" header="Motif" :sortable="true"></Column>
            </DataTable>
        </div>

    </div>

</template>

<script>

export default {

  name: "CpCm",

  data(){
      return {
          cancels : [],
          cpCm : [],
      }
  },

  mounted() {
      this.getCancelRdv();
      this.getCpCm();
  },

  methods: {
      getCancelRdv(){
        window.api.get("/moniteurs/cancelrdv", {
            headers: {
            "Authorization": "Bearer " + this.$store.state.token
            },
        }).then(response => {
            console.log("les fonctionnalites", response.data);
            this.cancels = response.data.Moniteur;
        }).catch(error => {
            console.log(error)
        })
      },

      getCpCm(){
        window.api.get("/moniteurs/conge", {
            headers: {
            "Authorization": "Bearer " + this.$store.state.token
            },
        }).then(response => {
            console.log("les fonctionnalites", response.data);
            this.cpCm = response.data.moniteur;
        }).catch(error => {
            console.log(error)
        })
      },
  },

  computed: {

  },
};
</script>

<style scoped>
    div.cpmono{
        display:flex;
        justify-content: space-around;
    }
</style>
