<template>
  <div id="Container">
    <div>
      <div id="header">
        <DropdownNav></DropdownNav>
        <button
          class="btnHeader"
          @click="
            rdvPrea = true;
            rdvPedago = false;
            rdvPedago2 = false;
          "
        >
          RDV Préalable
        </button>
        <button
          class="btnHeader"
          @click="
            rdvPrea = false;
            rdvPedago = true;
            rdvPedago2 = false;
          "
        >
          RDV Pédago 1
        </button>
        <button
          class="btnHeader"
          @click="
            rdvPrea = false;
            rdvPedago = false;
            rdvPedago2 = true;
          "
        >
          RDV Pédago 2
        </button>
        <Dropdown v-if="this.$route.name == 'formationAac_admin'" v-model="selectedAgence" :options="agences" optionLabel="nom" @change="filterElevesByAgence()" placeholder="Selectionner une agence" />
        <p id="nameUser" class="mt-4" style="color: white">
          {{ this.$store.state.user.nom }}
          {{ this.$store.state.user.prenom }}
        </p>
      </div>
      <div class="content">
        <RdvPrealable :users="userRdvp" v-if="rdvPrea"></RdvPrealable>
        <RdvPedago :users="userRdvp1" v-if="rdvPedago"></RdvPedago>
        <RdvPedagos :users="userRdvp2" v-if="rdvPedago2"></RdvPedagos>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownNav from "@/components/DropdownNav.vue";
import RdvPrealable from "@/components/formationAac/RdvPrealable.vue";
import RdvPedago from "@/components/formationAac/RdvPedagoP.vue";
import RdvPedagos from "@/components/formationAac/RdvPedagoS.vue";

export default {
  name: "formationAac",
  components: {
    DropdownNav,
    RdvPrealable,
    RdvPedago,
    RdvPedagos,
  },
  data() {
    return {
      rdvPrea: true,
      rdvPedago: false,
      rdvPedago2: false,
      userRdvp: [],
      userRdvp1: [],
      userRdvp2: [],
      agences : [],
      selectedAgence : null,
    };
  },

  mounted() {
    if(this.$route.name == "formationAac_admin"){
        this.getAgences();
    }else{
        this.getUsersAac();
    }
  },

  methods: {
    getUsersAac() {
        let agence_id = 0;
        if(this.$route.name == "formationAac_admin"){
            agence_id = this.selectedAgence.id;
        }else{
            agence_id = this.$store.state.user.agence.id;
        }

      window.api
        .get("formationaac/agence/" + agence_id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.userRdvp = response.data.RDVP;
          this.userRdvp1 = response.data.RDVP1;
          this.userRdvp2 = response.data.RDVP2;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAgences() {
      window.api
        .get("/agences")
        .then((response) => {
          this.agences = response.data["hydra:member"];
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    filterElevesByAgence(){
        this.getUsersAac();
    }
  },
};
</script>

<style lang="css" scoped>
#header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1em;
}

#Container {
  min-height: 100vh;
  height: auto;
  background: transparent linear-gradient(299deg, #fbbb5b 0%, #de6fa8 100%) 0%
    0% no-repeat padding-box !important;
  width: 100%;
}

.btnHeader {
  background: transparent linear-gradient(299deg, #5b60fb 0%, #6f7ede 100%) 0%
    0% no-repeat padding-box !important;
  border: 1px black, solid;
  border-radius: 10%;
}
</style>
