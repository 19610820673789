<template>
  <div class="OtherPaiementDeleted">
    <header id="head">
      <DropdownNav></DropdownNav>
      <router-link to="/home"
        ><i class="pi pi-home me-3" id="iconHome" style="font-size: 2rem"></i
      ></router-link>
      <h1 class="titreT">Tableau Des Paiements Supprimés</h1>
    <div class="flex justify-self-flex-end">
      <span class="p-input-icon-left">
        <input
          class="ms-5"
          v-model="filters['global'].value"
          placeholder="Rechercher"
        />
      </span>
    </div>
    <i class="pi pi-search me-2 ms-2 mt-3" />
    </header>
    <div id="tableauAgence">
      <DataTable
        :value="this.paiements"
        :rows="10"
        v-model:filters="filters"
        filterDisplay="menu"
        :loading="loading"
        :rowsPerPageOptions="[10, 20, 50]"
        v-model:selection="selectedItem"
        selectionMode="single"
        @click="activeModif"
        responsiveLayout="scroll"
        :globalFilterFields="[
          'Eleve',
          'Description',
          'Mode de Paiement',
          'Totalamount',
          'DatedePaiement',
        ]"
        dataKey="id"
      >
        <Column
          field="Eleve"
          header="Eleve"
          :sortable="true"
          style="min-width: 6rem"
        >
          <template #filter="{ filterModel, filterCallback }">
            <input
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              :placeholder="`Rechercher par Eleve - ${filterModel.matchMode}`"
            />
          </template>
        </Column>

        <Column
          header="Description"
          :exportable="false"
          style="min-width: 8rem"
        >
          <template #body="slotProps">
            {{ slotProps.data.Description }}
          </template>
        </Column>

        <Column
          header="Mode"
          :exportable="false"
          style="min-width: 8rem"
        >
          <template #body="slotProps">
              {{ slotProps.data["Mode de Paiement"] }}
          </template>
        </Column>

        <Column
          header="Montant Total"
          :exportable="false"
          style="min-width: 8rem"
          
        >
          <template #body="slotProps">
            
              {{ slotProps.data.Totalamount }}
          </template>
        </Column>

        <Column
        header="DatedePaiement"
        :exportable="false"
        style="min-width: 8rem"
        dateformat="DD/MM/YYYY"
        >
        <template #body="slotProps">
          {{ slotProps.data["Date de Paiement"].date}}
        </template>
        </Column>
      </DataTable>
    </div>
    <div id="app"></div>
  </div>
</template>
<script>
import DropdownNav from "@/components/DropdownNav.vue";
import { FilterMatchMode } from "primevue/api";

export default {
  name: "OtherPaiementDeleted",

  components: {
    DropdownNav,
  },

  data() {
    return {
      paiements: [],
      
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  mounted() {
    this.getOtherPaiementDeleted();
  },
  methods: {
    getOtherPaiementDeleted() {
      window.api
        .get("/otherpaiement/deleted", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
         })
        .then((response) => {
          this.paiements = response.data.OtherPaiements;
        })
        .catch((error) => {
          console.log(error);
        });

    },
  },
};
</script>
<style scoped>
#tableauAgence {
  background-image: url("../../assets/images/Fond_ordinateur.png");
  background-size: cover;
}
.title {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
#head {
  display: flex;
  flex-direction: row;
  align-content: space-around;
  width: 50%;
  margin-top: 5%;
}

.titreT {
  font-size: 25pt;
  font-weight: bold;
  color: #2e9ea3;
}
</style>
