<template>
    
    <div>
        <Dialog v-model:visible="visible" modal header="SUBV" :style="{ width: '50vw' }">

            <table v-for="d in droitsSubv" :key="d.id">
                <tr>Subv - {{ d.tot }} € - {{ d.payeur }}</tr>
                <tr>
                    <th>Date</th>
                    <th>Nature</th>
                    <th>montant</th>
                </tr>
                <tr>
                    <td>{{ new Date(d.date.date).toLocaleDateString() }}</td>
                    <td>{{ d.description }}</td>
                    <td>{{ d.ini }} €</td>
                </tr>
                <tr v-for="a in d.acptes" :key="a.id">
                    <td>{{ new Date(a.date.date).toLocaleDateString() }}</td>
                    <td>{{ a.description }}</td>
                    <td>{{ a.tot }} €</td>
                </tr>
            </table>

            <template #footer>
                <button @click="close">CLOSE</button>
            </template>
        </Dialog>
    </div>
</template>

<script>
export default {
    props: ["boolean", "droitSubv", "acpteSubv"],

    whatch: {
        visible(newValue){
            this.$emit("cancelSubvDetail", newValue);
        }
    },

    data() {
        return {
            visible : this.boolean,
            droitsSubv : this.droitSubv,
            acptesSubv : this.acpteSubv,
        }
    },

    mounted() {
        this.calculTotDroits();
    },

    methods: {
        close(){
            this.visible = false;
            this.$emit("cancelSubvDetail", false);
        },

        calculTotDroits(){
            this.droitsSubv.forEach(d => {
                let totSubv = 0;
                totSubv = totSubv + d.tot;

                d.acptes = [];

                this.acptesSubv.forEach(a => {
                    if(d.id == a.doitsSubvId){
                        totSubv = totSubv + a.tot;
                        d.acptes.push(a);
                    }
                })

                d.ini = totSubv;

            });
        }
    },
}
</script>


<style scoped>
table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #07989f;
  color: white;
}
</style>