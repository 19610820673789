<template>
  <div id="examContainer">
    <header id="head">
      <DropdownNav></DropdownNav>
      <router-link to="/home"
        ><i class="pi pi-home me-3" id="iconHome" style="font-size: 2rem"></i
      ></router-link>
      <h1 class="ms-3" id="titreEleve">Examen de Code</h1>
      <button
        class="ms-5 btn btn-primary"
        v-if="!showArchive"
        @click="showArchives"
      >
        Archives
      </button>
      <button
        class="ms-5 btn btn-danger"
        v-if="showArchive"
        @click="showArchives"
      >
        Archives
      </button>
    </header>

    <div class="tableStudents">
      <Dropdown
        v-model="this.selectedAgence"
        :options="this.agences"
        @change="filterElevesByAgence()"
        optionLabel="nom"
        placeholder="Sélectionner une agence"
      />

      <input
        class="search"
        v-model="filters['global'].value"
        placeholder="Rechercher"
      />

      <select
        class="selectTab"
        name="modes"
        id="modes"
        v-model="dossier.statut"
        @change="changeMode()"
      >
        <option value="">Tous</option>
        <option value="Incomplet">Incomplet</option>
        <option value="Validées">Validées</option>
        <option value="À traiter">À traiter</option>
        <option value="Saisi">Saisi</option>
        <option value="Enregistrer">Enregistrer</option>
      </select>

      <DataTable
        :value="students"
        sortMode="multiple"
        :rows="7"
        v-model:filters="filters"
        v-model:selection="selectedItem"
        selectionMode="single"
        filterDisplay="menu"
        :rowsPerPageOptions="[7, 10, 20, 50]"
        :paginator="true"
        responsiveLayout="scroll"
        dataKey="id"
        @click="onSelected"
        :globalFilterFields="[
          'nom',
          'prenom',
          'score',
          'examen',
          'resultat',
          'solde',
        ]"
      >
        <Column field="nom" header="Nom" sortable>
          <template #body="slotProps">
            <p class="pTable">{{ slotProps.data.nom }}</p>
          </template>
        </Column>
        <Column field="prenom" header="Prénom" sortable>
          <template #body="slotProps">
            <p class="pTable">{{ slotProps.data.prenom }}</p>
          </template>
        </Column>

        <Column field="prepaDate" header="Prepacode" sortable>
          <template #body="slotProps">
            <p class="pTable">{{ slotProps.data.prepaDate }}</p>
          </template>
        </Column>

        <Column field="statutDossier" header="Dossier">
          <template #body="slotProps">
            <p class="pTable">{{ slotProps.data.statutDossier }}</p>
          </template>
        </Column>

        <Column field="score" header="Score 5t" sortable>
          <template #body="slotProps">
            <template v-if="slotProps.data.score == null">
              <select
                name="score"
                id="score"
                v-model="scoreprepa"
                @change="changeScore(slotProps.data)"
              >
                <option value="null" disabled>Score réalisé ?</option>

                <option
                  v-for="(s, index) in score"
                  :value="s.value"
                  :key="index"
                >
                  {{ s.value }} / 40
                </option>
              </select>
            </template>
            <template v-else>
              <p class="pTable action" @click="deleteScore(slotProps.data)">
                {{ slotProps.data.score }} / 40
              </p>
            </template>
          </template>
        </Column>
        <Column field="examen" header="Examen" sortable>
          <template #body="slotProps">
            <template v-if="slotProps.data.examen == null">
              <input
                type="date"
                name="examen"
                id="examen"
                v-model="dateExamen"
                @change="changedateExam(slotProps.data)"
              />
            </template>
            <template v-else>
              <p class="pTable action" @click="deletedateExam(slotProps.data)">
                {{ slotProps.data.examen }}
              </p>
            </template>
          </template>
        </Column>

        <Column field="resultat" header="Resultat" sortable>
          <template #body="slotProps">
            <template v-if="slotProps.data.resultat == null">
              <select
                name="score"
                id="score"
                v-model="resultatExam"
                @change="changeResultat(slotProps.data)"
              >
                <option value="null" disabled>Score réalisé ?</option>

                <option
                  v-for="(s, index) in score"
                  :value="s.value"
                  :key="index"
                >
                  {{ s.value }} / 40
                </option>
              </select>
            </template>
            <template v-else>
              <p class="pTable action" @click="deleteResultat(slotProps.data)">
                {{ slotProps.data.resultat }} / 40
              </p>
            </template>
          </template>
        </Column>

        <Column field="solde" header="Solde" sortable>
          <template #body="slotProps">
            <p class="pTable">{{ slotProps.data.solde }}</p>
          </template>
        </Column>

        <Column header="Archiver">
          <template #body="slotProps">
            <button
              @click="archiver(slotProps.data)"
              class="btn btn-primary"
              v-if="slotProps.data.archive == false"
            >
              X
            </button>
            <button
              @click="desarchiver(slotProps.data)"
              class="btn btn-danger"
              v-if="slotProps.data.archive == true"
            >
              X
            </button>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import DropdownNav from "@/components/DropdownNav.vue";
import { FilterMatchMode } from "primevue/api";

export default {
  name: "ExamCode",
  components: {
    DropdownNav,
  },
  data() {
    return {
      students: [],
      studentsArchive: [],
      studentsUnArchive: [],
      score: null,
      scoreprepa: null,
      resultatExam: null,
      dateExamen: null,
      showArchive: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      dossier: {
        statut: "",
      },
      agences: [],
      selectedAgence: [],
    };
  },

  mounted() {
    this.getStudentsExamCode();
    this.getAgences();
  },

  methods: {
    filterElevesByAgence() {
      let currentStudent = "unarchive";

      if (this.showArchive) {
        currentStudent = "archive";
      }

      if (this.selectedAgence.nom !== "Tous") {
        if (currentStudent == "unarchive") {
          this.students = this.studentsUnArchive;
          this.students = this.students.filter(
            (e) => e.agence.id === this.selectedAgence.id
          );
        } else if (currentStudent == "archive") {
          this.students = this.studentsArchive;
          this.students = this.students.filter(
            (e) => e.agence.id === this.selectedAgence.id
          );
        }
      } else {
        if (currentStudent == "unarchive") {
          this.students = this.studentsUnArchive;
        } else if (currentStudent == "archive") {
          this.students = this.studentsArchive;
        }
      }
    },

    async getAgences() {
      try {
        window.api
          .get("/getAllAgences", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((res) => {
            this.agences = res.data.Agences;
            let nonRatacher = { nom: "Non rattachés" };
            let Tous = { nom: "Tous" };
            this.agences.push(nonRatacher, Tous);
          });
      } catch (e) {
        // handle authentication error here
      }
    },

    changeMode() {
      if (this.showArchive) {
        this.students = this.studentsArchive;
      } else {
        this.students = this.studentsUnArchive;
      }

      if (this.dossier.statut != "") {
        let changeTab = this.students.filter(
          (p) => p.statutDossier == this.dossier.statut
        );
        this.students = changeTab;
      }
    },

    showArchives() {
        this.selectedAgence = [];
      if (!this.showArchive) {
        this.showArchive = true;
        this.students = this.studentsArchive;
      } else {
        this.showArchive = false;
        this.students = this.studentsUnArchive;
      }
    },

    changeScore(student) {
      window.api
        .patch(
          "/prepacodes/" + student.prapaId,
          {
            scorePrepaCode: this.scoreprepa,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          this.getStudentsExamCode();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteScore(student) {
      window.api
        .patch(
          "/prepacodes/" + student.prapaId,
          {
            scorePrepaCode: null,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          this.getStudentsExamCode();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    changedateExam(student) {
      window.api
        .patch(
          "/prepacodes/" + student.prapaId,
          {
            dateExamen: this.dateExamen,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          this.getStudentsExamCode();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deletedateExam(student) {
      window.api
        .patch(
          "/prepacodes/" + student.prapaId,
          {
            dateExamen: null,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          this.getStudentsExamCode();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    changeResultat(student) {
      let archive = false;

      if (this.resultatExam >= 35) {
        archive = true;
      }

      window.api
        .patch(
          "/prepacodes/" + student.prapaId,
          {
            resultExamen: this.resultatExam,
            archive: archive,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          this.getStudentsExamCode();
          this.resultatExam = null;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteResultat(student) {
      window.api
        .patch(
          "/prepacodes/" + student.prapaId,
          {
            resultExamen: null,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          this.resultatExam = null;
          this.getStudentsExamCode();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    archiver(student) {
      window.api
        .patch(
          "/prepacodes/" + student.prapaId,
          {
            archive: true,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          this.getStudentsExamCode();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    desarchiver(student) {
      window.api
        .patch(
          "/prepacodes/" + student.prapaId,
          {
            archive: false,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          this.getStudentsExamCode();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getStudentsExamCode() {
      if (this.$store.state.user.role.label == "secretaire") {
        window.api
          .get("/students/examen/" + this.$store.state.user.agence.id, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            
            this.showArchive = false;
            this.resultatExam = null;

            response.data.students.forEach(e => {
        
              if(e.archive){
                this.studentsArchive.push(e);
              }else{
                this.studentsUnArchive.push(e);
              }
            })





            this.students = this.studentsUnArchive;

            let scoreTab = [];

            for (let index = 0; index <= 40; index++) {
              scoreTab.push({ value: index });
            }
            this.score = scoreTab;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        window.api
          .get("/students/examen/0", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            
            this.showArchive = false;
            this.studentsArchive = [];
            this.studentsUnArchive = [];
            response.data.students.forEach(e => {
              if(e.archive || e.resultat >= 35){
                this.studentsArchive.push(e);
              }else{
                this.studentsUnArchive.push(e);
              }
            })

            this.students = [];

            let scoreTab = [];

            for (let index = 0; index <= 40; index++) {
              scoreTab.push({ value: index });
            }
            this.score = scoreTab;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
div.tableStudents .search {
  margin-bottom: 2em;
  margin-right: 20em;
}

div.tableStudents {
  width: 80%;
  margin: auto;
  margin-top: 4em;
}
p.pTable {
  margin-top: 1em;
}

p.action {
  cursor: pointer;
}

#examContainer {
  background-image: url("../../../assets/images/fondd.png");
  background-size: cover;
  height: 100vh;
}

#head {
  padding-top: 3%;
  padding-left: 3%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;
  width: 50%;
}

#titreEleve {
  font-size: 25pt;
  font-weight: bold;
  color: #2e9ea3;
}
</style>
