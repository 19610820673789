<template>
  <AdministrationDossier />
</template>

<script>
import AdministrationDossier from "@/components/dossier/AdministrationDossier.vue";

export default {
  name: "AgenceDossier",
  components: {
    AdministrationDossier,
  },
  data() {},
};
</script>
