<template>
  <div id="conteneur">
    <Toast />
    <div id="left" class="container">
      <header id="head">
        <DropdownNav></DropdownNav>
        <router-link to="/home"
          ><i class="pi pi-home me-3" id="iconHome" style="font-size: 2rem"></i
        ></router-link>
        <h1 class="ms-3" id="titreLeft">Lieus de rendez vous</h1>
        <i
          class="pi pi-plus-circle ms-3 mt-2"
          id="iconHome"
          @click="openAddForm()"
          style="font-size: 1.5rem"
        ></i>
        <div class="flex justify-self-flex-end">
          <span class="p-input-icon-left">
            <input
              class="ms-5 mb-3"
              v-model="filters['global'].value"
              placeholder="Rechercher"
            />
          </span>
        </div>
        <i class="pi pi-search me-2 ms-2 mt-3" />
      </header>
      <div>
        <DataTable
          :value="this.lieusRdv"
          :rows="10"
          :rowsPerPageOptions="[10, 20, 50]"
          :paginator="true"
          responsiveLayout="scroll"
          v-model:filters="filters"
          filterDisplay="menu"
          :globalFilterFields="['nom', 'adresse', 'codePostal', 'ville']"
          dataKey="id"
          v-model:selection="selectedItem"
          selectionMode="single"
          @click="changeBackground"
        >
          <Column
            field="nom"
            header="nom"
            :sortable="true"
            style="min-width: 6rem"
          ></Column>

          <Column
            field="adresse"
            header="adresse"
            :sortable="true"
            style="min-width: 6rem"
          ></Column>

          <Column
            field="codePostal"
            header="codePostal"
            :sortable="true"
            style="min-width: 6rem"
          ></Column>
          <Column
            field="ville"
            header="ville"
            :sortable="true"
            style="min-width: 6rem"
          ></Column>
        </DataTable>
      </div>
    </div>

    <div id="right">
      <div
        v-if="this.selectedItem == null && this.addForm == false"
        id="styleTitreRight"
      >
        <p id="titreRight">Cliquez sur un Lieu de rendez vous</p>
      </div>

      <template v-if="this.selectedItem != null">
        <div class="container">
          <h2 class="text-center">{{ this.selectedItem.nom }}</h2>

          <div class="row pt-4">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="nom"
                    required
                    v-model="this.selectedItem.nom"
                    @keydown="errors = ''"
                  />
                  <label>nom</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="adresse"
                    required
                    v-model="this.selectedItem.adresse"
                    @keydown="errors = ''"
                  />
                  <label>adresse</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="ville"
                    required
                    v-model="this.selectedItem.ville"
                    @keydown="errors = ''"
                  />
                  <label>ville</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="codePostal"
                    required
                    v-model="this.selectedItem.codePostal"
                    @keydown="errors = ''"
                  />
                  <label>code Postal</label>
                </div>
              </div>
            </div>
          </div>

          <div id="btnContainer">
            <Button class="btnRouge" @click="deleteLieu()">Supprimer</Button>
            <Button class="btnViolet" @click="putLieu()">Modifier</Button>
          </div>
        </div>
      </template>

      <template v-if="this.addForm === true && this.selectedItem === null">
        <div class="container">
          <h2 class="mb-5">Ajoutez un Lieu</h2>
          <div class="row pt-4">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="text"
                    required
                    v-model="form.nom"
                    @keydown="errors = ''"
                  />
                  <label>nom</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="text"
                    required
                    v-model="form.adresse"
                    @keydown="errors = ''"
                  />
                  <label>adresse</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="text"
                    required
                    v-model="form.ville"
                    @keydown="errors = ''"
                  />
                  <label>ville</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="text"
                    required
                    v-model="form.codePostal"
                    @keydown="errors = ''"
                  />
                  <label>code Postal</label>
                </div>
              </div>
            </div>
          </div>

          <div id="btnContainer">
            <Button class="btnViolet" @click="postLieu()">Valider</Button>
          </div>
        </div>
      </template>
    </div>

    <Dialog
      :header="`Il existe des moniteurs sur ce lieu`"
      v-model:visible="displayMonos"
      :style="{ width: '90vw' }"
    >
      <div class="row" style="place-content: center">
        <div class="col-12">
          <DataTable
            :value="this.filteredMonoByLieu"
            :rows="10"
            :paginator="true"
            :rowsPerPageOptions="[10, 20, 50]"
            responsiveLayout="scroll"
            dataKey="id"
            v-model:filters="filters"
            filterDisplay="row"
          >
            <Column
              field="nom"
              header="nom"
              :sortable="true"
              style="min-width: 12rem"
            >
            </Column>

            <Column
              field="prenom"
              header="prenom"
              :sortable="true"
              style="min-width: 6rem"
            ></Column>

            <Column
              field="telephone"
              header="telephone"
              :sortable="true"
              style="min-width: 6rem"
            ></Column>

            <Column
              field="codePostal"
              header="codePostal"
              :sortable="true"
              style="min-width: 6rem"
            ></Column>

            <Column
              field="agence.nom"
              header="agence"
              :sortable="true"
              style="min-width: 10rem"
            ></Column>
          </DataTable>

          <Button
            label="Fermer"
            icon="pi pi-check"
            @click="closeBasic"
            class="p-button-text"
          />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { useToast } from "primevue/usetoast";
import { FilterMatchMode } from "primevue/api";
import DropdownNav from "../../../components/DropdownNav.vue";

export default {
  name: "App",
  component: {
    DropdownNav,
  },
  data() {
    return {
      selectedItem: null,
      displayMonos: false,
      addForm: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filteredMonoByLieu: [],
      toast: useToast(),
      form: {
        nom: "",
        adresse: "",
        codePostal: "",
        ville: "",
        typeLieu: "api/type_lieus/1",
      },
    };
  },
  mounted() {
    this.getLieusRdv();
    this.getMoniteurs();
  },
  methods: {
    ...mapActions(["getLieusRdv", "getMoniteurs"]),
    async postLieu() {
      window.api
        .post("/lieus", this.form, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.$emit("completed", response.data);
          this.getLieusRdv();
          this.toast.add({
            severity: "success",
            summary: "Lieu supprimé",
            detail: "Votre lieu à été ajouté avec succès",
            life: 3000,
          });
        })
        .catch((error) => {
          // handle authentication and validation errors here
          console.log("ERRR::", error.response.data);
        });
    },
    async deleteLieu() {
      if (this.selectedItem.moniteurLieus.length > 0) {
        console.log("lieu", this.selectedItem);
        console.log("thismoniteurs", this.moniteurs);
        this.filteredMonoByLieu = this.moniteurs.filter((m) =>
          m.lieux.find((ml) => ml.lieuId === this.selectedItem.id)
        );
        this.displayMonos = true;
      } else {
        try {
          if (confirm("Voulez vous vraiment supprimer ?")) {
            window.api
              .delete("lieus/" + this.selectedItem.id, {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              })
              .then((response) => {
                this.getLieusRdv();
                this.toast.add({
                  severity: "success",
                  summary: "Lieu supprimé",
                  detail: "Votre lieu à été supprimé avec succès",
                  life: 3000,
                });
                this.displayMonos = false;
                console.log(response);
              });
          }
        } catch (e) {
          // handle authentication error here
        }
      }
    },
    changeBackground() {
      if (this.selectedItem != null) {
        document.getElementById("right").style.background = "none";
      }
      console.log("monlieu", this.selectedItem);
    },
    putLieu() {
      window.api
        .put(
          `lieus/` + this.selectedItem.id,
          {
            nom: this.selectedItem.nom,
            adresse: this.selectedItem.adresse,
            codePostal: this.selectedItem.codePostal,
            ville: this.selectedItem.ville,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.$emit("completed", response.data);
          this.getLieusRdv();
          this.toast.add({
            severity: "success",
            summary: "Lieu modifié",
            detail: "Votre lieu à été modifié avec succès",
            life: 3000,
          });
        })
        .catch(function (error) {
          console.log(error.response);
        });
    },
    closeBasic() {
      this.displayMonos = false;
    },
    openAddForm() {
      this.selectedItem = null;
      this.addForm = true;
      document.getElementById("right").style.background = "none";
    },
  },
  computed: {
    lieusRdv() {
      return this.$store.state.lieusRdv;
    },
    moniteurs() {
      return this.$store.state.moniteurs;
    },
  },
  components: { DropdownNav },
};
</script>

<style scoped>
#btnContainer {
  margin-top: 15%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

#iconHome {
  cursor: pointer;
}

.btnRouge {
  background: #ec2b22;
  border-radius: 13px;
  border: none;
}
.btnViolet {
  background: #de6fa8;
  border-radius: 13px;
  border: none;
}

a {
  text-decoration: none;
}

#formModif {
  margin: auto;
  width: 100%;
}

#head {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  align-content: space-around;
}

#add {
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  top: 40px;
}

#titreRight {
  flex-wrap: wrap;
  font-size: 2rem;
  color: white;
}

#styleTitreRight {
  background-color: rgb(255, 255, 255, 0.47);
  padding: 1%;
  border-radius: 13px;
}

#left {
  background-image: url("../../../assets/images/fondd.png");
  background-size: cover;
  border-right: 1px solid black;
}

#right {
  width: 100%;
  background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#titreLeft {
  font-size: 20pt;
  font-weight: bold;
  color: #2e9ea3;
}

#conteneur {
  display: grid;
  grid-template-columns: 50% 50%;
}

#conteneur div {
  justify-content: space-evenly;
}

.text-field,
text {
  position: relative;
  margin: 10px 2.5px 20px 2.5px;
}

input {
  display: inline-block;
  border: thin solid #fafafa;
  color: #444;
  background-color: #fafafa;
  padding: 10px 10px 10px 10px;
}

input:focus {
  border: thin solid #ffaa71;
  border-bottom: solid medium #ffaa71;
  background-color: #fff;
}

label {
  color: #999;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 10px;
  transition: 0.2s;
}

input:focus ~ label,
input:valid ~ label {
  top: -10px;
  left: 15px;
  font-size: small;
  color: #167794;
  background-color: #fff;
  padding: 0 5px 0 5px;
}
</style>
