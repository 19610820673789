<template>
  <div id="conteneur">
    <Toast />
    <div id="eleve" class="container">
      <header id="head">
        <router-link to="/home"
          ><i class="pi pi-home me-3" id="iconHome" style="font-size: 2rem"></i
        ></router-link>
        <h1 class="ms-3" id="titre">Dossier</h1>
        <div class="ms-5 mt-2">
          <p class="mt-1">
            {{ this.$store.state.user.nom }} -
            {{ this.$store.state.user.prenom }}
          </p>
        </div>
      </header>
      <div id="code" class="mt-4">
        <h3 class="infoP">
          Nous enregistrons votre dossier en préfecture en passant par ANTS.
        </h3>


        <h5 class="infoP">Montant: {{ filterFonctionnalite.montant }} €</h5>

        <BoutonPaiement @paiement="goToPaiement($event)"></BoutonPaiement>
      </div>
    </div>

    <div id="right">
      <div id="contentRight">
        <h1 class="mt-5" style="color: white">Formalité DOSSIER</h1>

        <h2>|) Après paiement, vous pourrez :</h2>
        <h3>1) Avoir accès à la liste des pièces.</h3>
        <h3>2) Insérer les pièces en ligne.</h3>
        <h3>3) Suivre l'avancement du traitement de votre dossier.</h3>
        <h3>4) Et enfin, disposer de votre NEPH nécessaire pour passer les examens du code et de la conduite.</h3>
      </div>
    </div>
  </div>
</template>

  <script>
    import { mapActions, mapGetters } from "vuex";
    import BoutonPaiement from "@/components/eleve/BoutonPaiement.vue";

    export default {
      name: "CodeAccueil",
      components: {
        BoutonPaiement,
      },
      data() {
        return {};
      },
      mounted() {
        //this.getOtherPaiement();
        this.getFonctionnalite();
      },
      methods: {
        ...mapActions(["getFonctionnalite"]),

        goToPaiement(mode) {
          this.$store.dispatch("startSaveBouton", mode);
          let fonctionnalite = "dossier";
          let router = this.$router;
          this.$store.dispatch("endSaveBouton", {
            mode,
            fonctionnalite,
            router,
          });
        },
      },
      computed: {
        ...mapGetters(["fonctionnalite"]),
        filterFonctionnalite() {
          if (this.fonctionnalite !== null) {
            return this.fonctionnalite.filter((el) => el.label === "dossier")[0];
          }
          return "";
        },
      },
    };
  </script>

  <style scoped>
    #contentRight > h2 {
      text-align: left;
      color: white;
      margin-top: 7%;
    }

    #contentRight {
      width: 100%;
      margin-left: 5%;
    }

    #contentRight > h3 {
      text-align: left;
      margin-left: 10%;
      color: white;
    }

    #titre {
      font-size: 25pt;
      font-weight: bold;
      color: #2e9ea3;
    }

    #right {
      width: 100%;
      background: linear-gradient(
        323deg,
        #fbbb5b 35%,
        #de6fa8 100%,
        #07989f 0%
      );
      height: auto;
      justify-content: center !important;
      min-height: 100vh;
      display: flex;
    }

    #iconHome {
      cursor: pointer;
    }

    #conteneur {
      display: grid;
      grid-template-columns: 50% 50%;
    }

    #conteneur div {
      justify-content: space-between;
    }

    #head {
      margin-top: 5%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: space-around;
      width: 50%;
    }


    .infoP:first-child{
        margin-top: 2em;
    }
    .infoP{
        text-align: left;
        margin-bottom: 2em;
    }
  </style>

