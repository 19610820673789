<template>
  <div id="conteneur">
    <Toast />
    <div id="left" class="container">
      <header id="head">
        <DropdownNav></DropdownNav>
        <router-link to="/home"
          ><i class="pi pi-home me-3" id="iconHome" style="font-size: 2rem"></i
        ></router-link>
        <h1 class="ms-3" id="titreLeft">Vehicule</h1>
        <i
          class="pi pi-plus-circle ms-3 mt-2"
          id="iconHome"
          @click="openAddForm()"
          style="font-size: 1.5rem"
        ></i>
      </header>
      <div>
        <DataTable
          :value="this.vehicules"
          :rows="10"
          :rowsPerPageOptions="[10, 20, 50]"
          :paginator="true"
          responsiveLayout="scroll"
          dataKey="id"
          v-model:filters="filters"
          filterDisplay="row"
          v-model:selection="selectedItem"
          selectionMode="single"
          @click="changeBackground"
        >
          <Column
            field="immat"
            header="immat"
            :sortable="true"
            style="min-width: 12rem"
          >
            <template #filter="{ filterModel, filterCallback }">
              <input
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter"
                :placeholder="`Rechercher par immatriculation - ${filterModel.matchMode}`"
              /> </template
          ></Column>

          <Column
            field="date_mes"
            header="date_mes"
            :sortable="true"
            style="min-width: 12rem"
          ></Column>

          <Column
            field="modeLabel"
            header="mode"
            :sortable="true"
            style="min-width: 12rem"
          ></Column>
        </DataTable>
      </div>
    </div>

    <div id="right">
      <div
        v-if="this.selectedItem == null && this.addForm == false"
        id="styleTitreRight"
      >
        <p id="titreRight">Cliquez sur un Vehicule</p>
      </div>

      <template v-if="this.selectedItem != null">
        <div class="container">
          <h2 class="text-center">{{ this.selectedItem.immat }}</h2>

          <div class="row pt-4">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="immat"
                    required
                    v-model="this.selectedItem.immat"
                    @keydown="errors = ''"
                  />
                  <label>immat</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 pt-2 col-sm-12">
              <Dropdown
                v-model="this.selectedItem.mode"
                :options="this.ModeVehicules"
                optionLabel="label"
                :placeholder="this.selectedItem.modeLabel"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <br />
              <div class="text-field">
                <div class="control">
                  <input
                    id="icon"
                    class="textbox-n"
                    type="text"
                    onfocus="(this.type='date')"
                    onblur="(this.type='text')"
                    :placeholder="this.selectedItem.date_mes"
                    v-model="this.selectedItem.dateMes"
                  />
                </div>
              </div>
            </div>
          </div>

          <div id="btnContainer">
            <Button class="btnRouge" @click="deleteVehicule()"
              >Supprimer</Button
            >
            <Button class="btnViolet" @click="putVehicule()">Modifier</Button>
          </div>
        </div>
      </template>

      <template v-if="this.addForm === true && this.selectedItem === null">
        <div class="container">
          <h2 class="mb-5">Ajoutez un Vehicule</h2>
          <div class="row pt-4">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    class="form-control"
                    type="immat"
                    required
                    v-model="form.immat"
                    @keydown="errors = ''"
                  />
                  <label>immat</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <Dropdown
                    v-model="this.form.mode"
                    :options="this.ModeVehicules"
                    optionLabel="label"
                    placeholder="Select a mode"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <br />
              <div class="text-field">
                <div class="control">
                  <div class="field col-12 md:col-4">
                    <input
                      id="icon"
                      class="textbox-n"
                      type="date"
                      v-model="this.form.dateMes"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="btnContainer">
            <Button class="btnViolet" @click="postVehicule()">Valider</Button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { useToast } from "primevue/usetoast";
import { FilterMatchMode } from "primevue/api";
import DropdownNav from "../../../components/DropdownNav.vue";

export default {
  name: "App",
  component: {
    DropdownNav,
  },
  data() {
    return {
      toast: useToast(),
      selectedItem: null,
      filters: {
        immat: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      },
      ModeVehicules: {},
      addForm: false,
      form: { immat: "", dateMes: "", mode: "" },
    };
  },
  mounted() {
    this.getVehicules();
    this.getModeVehicule();
  },
  methods: {
    ...mapActions(["getVehicules"]),
    changeBackground() {
      if (this.selectedItem != null) {
        document.getElementById("right").style.background = "none";
      }
    },
    openAddForm() {
      this.selectedItem = null;
      this.addForm = true;
      document.getElementById("right").style.background = "none";
    },
    async deleteVehicule() {
      try {
        if (confirm("Voulez vous vraiment supprimer ?")) {
          window.api
            .delete("vehicules/" + this.selectedItem.id, {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            })
            .then((response) => {
              this.toast.add({
                severity: "success",
                summary: "Vehicule Supprimé",
                detail: "Votre Vehicule à été Supprimé avec succès",
                life: 3000,
              });
              this.getVehicules();
              console.log(response);
            });
        }
      } catch (e) {
        // handle authentication error here
      }
    },
    getModeVehicule() {
      window.api
        .get("/mode_vehicules", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.ModeVehicules = response.data["hydra:member"];
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    async postVehicule() {
      this.form.mode = "api/mode_vehicules/" + this.form.mode.id;
      window.api
        .post("/vehicules", this.form, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.toast.add({
            severity: "success",
            summary: "Vehicule Ajouté",
            detail: "Votre Vehicule à été Ajouté avec succès",
            life: 3000,
          });
          this.$emit("completed", response.data);
          this.getVehicules();
        })
        .catch((error) => {
          // handle authentication and validation errors here
          console.log("ERRR::", error.response.data);
        });
    },
    putVehicule() {
      this.form.mode = "api/mode_vehicules/" + this.form.mode.id;
      console.log("selectedItem", this.selectedItem);
      window.api
        .put(
          `vehicules/` + this.selectedItem.id,
          {
            immat: this.selectedItem.immat,
            dateMes: this.selectedItem.dateMes,
            mode: this.selectedItem.mode["@id"],
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.toast.add({
            severity: "success",
            summary: "Vehicule Modifié",
            detail: "Votre Vehicule à été Modifié avec succès",
            life: 3000,
          });
          this.$emit("completed", response.data);
          this.getVehicules();
        })
        .catch(function (error) {
          console.log(error.response);
        });
    },
  },
  computed: {
    vehicules() {
      return this.$store.state.vehicules;
    },
  },
  components: { DropdownNav },
};
</script>

<style scoped>
#btnContainer {
  margin-top: 15%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

#iconHome {
  cursor: pointer;
}

.btnRouge {
  background: #ec2b22;
  border-radius: 13px;
  border: none;
}
.btnViolet {
  background: #de6fa8;
  border-radius: 13px;
  border: none;
}

a {
  text-decoration: none;
}

#formModif {
  margin: auto;
  width: 100%;
}

#head {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 5%;
  align-content: space-around;
  width: 50%;
}

#add {
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  top: 40px;
}

#titreRight {
  flex-wrap: wrap;
  font-size: 2rem;
  color: white;
}

#styleTitreRight {
  background-color: rgb(255, 255, 255, 0.47);
  padding: 1%;
  border-radius: 13px;
}

#left {
  background-image: url("../../../assets/images/fondd.png");
  background-size: cover;
  border-right: 1px solid black;
}

#right {
  width: 100%;
  background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#titreLeft {
  font-size: 25pt;
  font-weight: bold;
  color: #2e9ea3;
}

#conteneur {
  display: grid;
  grid-template-columns: 50% 50%;
}

#conteneur div {
  justify-content: space-evenly;
}

.text-field,
text {
  position: relative;
  margin: 10px 2.5px 20px 2.5px;
}

input {
  display: inline-block;
  border: thin solid #fafafa;
  color: #444;
  background-color: #fafafa;
  padding: 10px 10px 10px 10px;
}

input:focus {
  border: thin solid #ffaa71;
  border-bottom: solid medium #ffaa71;
  background-color: #fff;
}

label {
  color: #999;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 10px;
  transition: 0.2s;
}

input:focus ~ label,
input:valid ~ label {
  top: -10px;
  left: 15px;
  font-size: small;
  color: #167794;
  background-color: #fff;
  padding: 0 5px 0 5px;
}
</style>
