<template>
    <div class="headerFlex">
        <ul>
            <li><a @click="goToHome">Accueil</a></li>
            <li><a href="#home">Vidéo</a></li>
            <li><a class="active" href="#news">Planning</a></li>
            <li><a href="#contact">Examen</a></li>
            <li><a href="#contact">Messages</a></li>
            <li><a href="#contact">Formation</a></li>
            <li style="float:right"><a @click="deconnexion">Deconnexion</a></li>
        </ul>
    </div>
  </template>
  
  <script>

  export default {
  
      props: [

      ],
  
    components: {

    },
  
    watch: {},
  
    data() {
      return {

      };
    },
  
    mounted() {

    },
  
    methods: {

        goToHome(){
            this.$router.push({ name: "Home" });
        },

        deconnexion() {
            this.$store.commit("setUser", null);
            this.$store.commit("setToken", null);
            localStorage.removeItem("user_info");
            localStorage.removeItem("user_access");
            this.$router.push({name: 'index'});
        },

    }
  };
  </script>
  
  <style scoped>
  
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: #ffffff;
        border-bottom: 1px solid rgb(240, 240, 240);
    }

    li {
        float: left;
    }

    li a {
        display: block;
        color: black;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
    }

    li a:hover:not(.active) {
        background-color: rgb(240, 240, 240);
    }

    .active {
        background-color: #dfffe4;
    }
  
  </style>
  