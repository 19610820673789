<template>
  <div class="box">
    <div class="connexion">
      <Toast />
      <router-link to="/"><h1>Monpermis.fr</h1></router-link>
      <router-link to="/">
        <img
          id="iconConnexion"
          :src="require('../assets/images/icon_connexion_.png')"
      /></router-link>

      <div class="field">
        <div class="control mt-5">
          <input
            class="form-control"
            type="password"
            required
            id="lname"
            name="lastname"
            v-model="this.oublieForm.mdp"
            placeholder=" "
          />
          <label class="labelForm">MOT DE PASSE</label>
        </div>
      </div>
      <div class="field">
        <div class="control mt-5">
          <input
            class="form-control"
            type="password"
            required
            id="lname"
            name="lastname"
            v-model="this.oublieForm.mdpConfirm"
            placeholder=" "
          />
          <label class="labelForm">Confirmer mdp</label>
        </div>
      </div>
      <br />

      <Button
        class="btn btn-m mt-2 mb-5"
        type="submit"
        value="Connexion"
        id="btnConnexion"
        @click="patchUserMdp()"
      >
        Changer de mdp
      </Button>
    </div>
  </div>
</template>

<script>
import { useToast } from "primevue/usetoast";

export default {
  data() {
    return {
      oublieForm: {
        mdp: "",
        mdpConfirm: "",
      },
      toast: useToast(),
    };
  },

  methods: {
    patchUserMdp() {
      // l'id de l'utilisateur est contenue dans l'url après la cle generé aléatoirement, pour des raisons de sécurité ,
      // il faut effectuer un calcul pour récuperer le vrai id, le calcul est l'id qui est de base multiplié par la cle aleatoire / la cle aleatoire
      var link = this.$route.fullPath.split("/");
      let cle = link[2];
      let idMultplie = link[3];
      // on passe les params de l'url qui sont en hexadecimal de base -> decimal
      let idMultplieDecimal = parseInt(idMultplie, 16);
      let cleDecimal = parseInt(cle, 16);
      let id = idMultplieDecimal / cleDecimal;
      console.log("para", id);

      if (this.oublieForm.mdp === this.oublieForm.mdpConfirm) {
        window.api
          .patch(`utilisateurMdp/` + id, {
            password: this.oublieForm.mdp,
          })
          .then((res) => {
            this.toast.add({
              severity: "success",
              summary: "mot de passe modifié",
              detail: "votre mot de passe a été modifié avec succès",
              life: 3000,
            });
            this.$emit("completed", res.data);
            console.log("res", res);
          });
        this.$router.push({ name: "Connexion" });
      } else {
        this.toast.add({
          severity: "warn",
          summary: "confirmation  incorrecte",
          detail: "Votre confirmation est incorrecte",
          life: 3000,
        });
      }
    },
  },
};
</script>

<style scoped>
.btnViolet {
  background: #de6fa8;
  border-radius: 13px;
  border: none;
}

#footerConnexion {
  display: flex;
  justify-content: space-evenly;
}
.field {
  display: flex;
  justify-content: center;
}

.labelForm {
  position: absolute;
  pointer-events: none;
  left: 0.75rem !important;
  top: 18px !important;
  transition: 0.2s ease all;
  font-size: 25px !important;
  color: #444 !important;
}

.field input:focus ~ label,
input:not(:placeholder-shown) ~ label {
  top: -20px !important;
  font-size: 12px !important;
}

.control {
  position: relative;
  width: 60%;
}

.control input {
  width: 100%;
}

a {
  text-decoration: none;
  color: white !important;
  cursor: pointer;
}

.box {
  background: transparent linear-gradient(299deg, #fbbb5b 0%, #de6fa8 100%) 0%
    0% no-repeat padding-box !important;
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
}

#iconConnexion {
  width: 60%;
}

::placeholder {
  color: #f5f5f5;
  font-size: 20px;
  padding-left: 2%;
}

#btnConnexion {
  background: #2e9ea3 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 1;
  width: 60%;
  height: 70px;
}

#btnConnexion span {
  font-size: 170%;
  text-transform: uppercase;
  font: normal normal 800 30px/41px Galano Grotesque Alt;
  letter-spacing: 0px;
  color: #fbfbfb;
}

/* Mobile Styles */
@media only screen and (max-width: 400px) {
  input[type="text"],
  select {
    width: 100%;
    padding: 20px 20px;
    margin: 8px 0;
    display: inline-block;
    background: transparent;
    border-radius: 10px;
    box-sizing: border-box;
  }

  #iconConnexion {
    width: 60%;
  }

  select {
    width: 100%;
    padding: 20px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 30px;
    box-sizing: border-box;
  }

  #btnConnexion {
    background: #2e9ea3 0% 0% no-repeat padding-box;
    border-radius: 14px;
    opacity: 1;
    width: 90%;
    height: 70px;
  }

  h1 {
    color: #ffece0;
    font-size: 1.5rem;
    font-family: Galano Grotesque Alt Medium;
    word-wrap: break-word;
  }

  input[type="password"],
  select {
    width: 100%;
    padding: 20px 20px;
    margin: 8px 0;
    display: inline-block;
    background: transparent;
    border-radius: 10px;
    box-sizing: border-box;
  }

  div.connexion {
    border-radius: 10px;
    width: 90%;
    margin: auto;
    margin-top: 70px;
    padding: 60px;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  h1 {
    color: #ffece0;
    font-size: 2.5rem;
    word-wrap: break-word;
  }

  #iconConnexion {
    width: 100%;
  }

  input[type="text"],
  select {
    width: 100%;
    padding: 20px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    background: transparent;
    border-radius: 10px;
    box-sizing: border-box;
  }

  #btnConnexion {
    background: #2e9ea3 0% 0% no-repeat padding-box;
    border-radius: 14px;
    opacity: 1;
    width: 90%;
    height: 70px;
  }

  input[type="password"],
  select {
    width: 100%;
    padding: 20px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    background: transparent;
    border-radius: 10px;
    box-sizing: border-box;
  }

  div.connexion {
    border-radius: 10px;
    width: 80%;
    margin: auto;
    margin-top: 70px;
    padding: 60px;
  }
}

/* Desktop Styles */
@media only screen and (min-width: 961px) {
  h1 {
    color: #ffece0;
    font-size: 6rem;
    word-wrap: break-word;
  }

  div.connexion {
    border-radius: 10px;
    width: 60%;
    margin: auto;
    padding: 40px;
  }

  h2 {
    color: #ffece0;
    font-size: 2.5em;
    border-width: 0.1px;
    display: inline-block;
  }

  a {
    color: #ffece0;
  }

  .text-field,
  text {
    position: relative;
    margin: 10px 2.5px 20px 2.5px;
  }

  input {
    display: inline-block;
    border: solid 3px #fafafa;
    color: #444;
    padding: 5px 5px 5px 5px;
    height: 75px;
    background: transparent;
    border-radius: 10px;
  }

  input:focus {
    border: thin solid #ffffff;
    border-bottom: solid medium #ffffff;
    background: transparent;
  }

  label {
    color: #999;
    position: absolute;
    pointer-events: none;
    left: 10px;
    top: 10px;
    transition: 0.2s;
  }

  input:focus ~ label,
  input:valid ~ label {
    top: -10px;
    left: 15px;
    font-size: small;
    color: #32cd32;
    padding: 0 5px 0 5px;
  }
}
</style>
