<template>
  <body>
    <main id="conteneurStats">
      <div id="conteneurTop">
        <div class="mt-5 back">
          <h2 class="title"> AGENCE</h2>
          <p class="number" id="nbr">100</p>
        </div>

        <div class="mt-5 back">
          <h2 class="title"> ELEVES</h2>
          <p class="number">86 000</p>
        </div>

        <div class="mt-5 back">
          <h2 class="title">ENCAISSEMENT</h2>
          <p class="number">10 000</p>
        </div>
      </div>

      <div id="conteneurBottom">
        <div class="back">
          <h2 class="title"> MONITEURS</h2>
          <p class="number">250</p>
        </div>

        <div id="bottomMidContainer">
          <div id="nbrHeures">
            <h2> heures réservées</h2>
            <p class="number">37</p>
          </div>

          <router-link to="/home"><div><h2 class="title mt-3 " id="accueil">ACCUEIL</h2></div></router-link>
        </div>

        <div class="back">
          <h2 class="title">SOLDES</h2>
          <p class="number">10 000</p>
        </div>
      </div>
    </main>
  </body>
</template>


<script>

export default {
  name: "App",

  data() {
    return {


   
    };
  },


  methods: {
     
  }


};
</script>

<style scoped>

a {
  text-decoration: none;
}
#conteneurTop {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: auto;
  min-height: 50vh;
}

*.number {
  color: white;
  font-size: 4rem;
  font-weight: bold;
}

*.title {
  color: white;
  font-weight: bold;
}

#conteneurBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: auto;
  min-height: 50vh;
}

*.back {
  background: rgb(255, 255, 255, 0.4);
  width: 30%;
  height: 38vh;
  padding: 4%;
}

#nbrHeures {
  background: rgb(255, 255, 255, 0.4);
  padding: 4%;
  min-height: 23vh;

}

#nbrHeures > p {
  color: white;
  font-size: 4rem;
  font-weight: bold;
}

#nbrHeures > h2 {
  color: white;
  font-weight: bold;
}

#accueil {
  background: rgb(255, 255, 255, 0.4);
  padding: 8%;
}

#bottomMidContainer {
  background: none !important;
  width: 30%;
}

#bottomMidContainer > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  height: 19vh;
}

#conteneurStats {
  background: transparent
    linear-gradient(119deg, #fbbb5b 0%, #e075a2 93%, #de6fa8 100%) 0% 0%
    no-repeat padding-box;
}

/* Desktop Styles */
@media only screen and (min-width: 961px) {
  h2 {
    font-size: 2.5 rem;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  h2 {
    font-size: 1rem;
  }

  p {
    font-size: 3rem !important;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 2rem !important;
  }
}

/* Mobile Styles */
@media only screen and (max-width: 400px) {
  h2 {
    font-size: 1rem;
  }

  p {
    font-size: 2rem !important;
  }
}
</style>
