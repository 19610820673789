<template>
    <div class="cpfModal">


        <Dialog v-model:visible="showModalPaiementCpf" modal header="Paiements" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
          <div class="containername">
            <SimpleTypeahead
              id="typeahead_id"
              placeholder="Rechercher un élève..."
              :items="eleves"
              :minInputLength="1"
              :itemProjection="
                (item) => {
                  return item.fullname;
                }
              "
              @selectItem="selectItem"
            >
            </SimpleTypeahead>
            <paiement :data="eleve" v-if="this.eleve != null"/>
          </div>
        </Dialog>


    </div>
  </template>
  
  <script>
  import SimpleTypeahead from "vue3-simple-typeahead";
  import paiement from "@/components/eleve/Paiement.vue";
  export default {
  
      props: [
        "modalCpf",
        "closeModalPaiementCpf",
      ],
  
    components: {
      SimpleTypeahead,
      paiement
    },
  
    watch: {
      showModalPaiementCpf: function () {
      if (this.showModalPaiementCpf == false) {
        this.$emit("closeModalPaiementCpf");
      }
    },
  },
  
    data() {
      return {
        showModalPaiementCpf : false,
        eleves : [],
        eleve : null,
        load : 0,
      };
    },
  
    mounted() {
      this.showModalPaiementCpf = this.modalCpf;
      this.getEleves();
    },
  
    methods: {
      getEleves(){

        let agence = 0;

        if(this.$store.state.user.agence != null){
          agence = this.$store.state.user.agence.id;
        }

        window.api.get("/eleves/agence/planning/" + agence, {
            headers: {
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(response => {
            this.eleves = response.data.eleves;
            this.load++;
          }).catch(error => {
            console.log(error)
          })
      },

      selectItem(e){
        this.eleve = null;
        this.eleve = e;
      },  

    }
  };
  </script>
  
<style scoped>

</style>