<template>
    <div id="conteneur">
      <Toast />
      <div id="left" class="container">
        <header id="head">
          <DropdownNav></DropdownNav>
          <router-link to="/home"
            ><i class="pi pi-home" id="iconHome" style="font-size: 1.7rem"></i
          ></router-link>
          <h1 class="ms-3" id="titreLeft">Stage de code</h1>
          <i
            class="pi pi-plus-circle ms-3"
            @click="openAddForm()"
            style="font-size: 1.5rem"
          ></i>
        </header>
        <div id="filtreJournee">
          <div class="field-checkbox">
            <Checkbox
              id="binary"
              v-model="checkJourneeaVenir"
              @click="filtreJourneeaVenir()"
              :binary="true"
              class="me-2 mb-3"
            />
            <label class="mb-3 me-5" for="binary">A Venir</label>
          </div>
          <div class="field-checkbox">
            <Checkbox
              id="binary"
              v-model="checkJourneePasse"
              @click="filtreJourneeaPasse()"
              :binary="true"
              class="me-2 mb-3"
            />
            <label class="mb-3" for="binary">Passé</label>
          </div>
        </div>
  
        <DataTable
          :value="this.journees"
          :rows="6"
          :rowsPerPageOptions="[6, 10, 20, 50]"
          :paginator="true"
          responsiveLayout="scroll"
          dataKey="id"
          v-model:filters="filters"
          filterDisplay="row"
          v-model:selection="selectedItem"
          selectionMode="single"
          @click="changeBackground"
        >
          <Column
            field="libelle"
            header="libelle"
            :sortable="true"
            style="min-width: 12rem"
          ></Column>
  
          <Column
            
            header="date"
            :sortable="true"
            style="min-width: 12rem"
          >
            <template #body="slotProps">
                {{ slotProps.data.date }} à {{ slotProps.data.dateFin }}
            </template>
        
          </Column>
  
          <Column
            field="fullHoraire"
            header="horaires"
            :sortable="true"
            style="min-width: 12rem"
          ></Column>
  
          <Column
            field="nbrPlace"
            header="nbrPlaceDispo"
            :sortable="true"
            style="min-width: 12rem"
          ></Column>
        </DataTable>
      </div>
  
      <div id="right">
        <div
          v-if="this.selectedItem == null && this.addForm == false"
          id="styleTitreRight"
        >
          <p id="titreRight">Cliquez sur une journée</p>
        </div>
  
        <template v-if="this.selectedItem != null">
          <div class="container">
            <h2 class="text-center">
              {{ this.selectedItem.libelle }}
            </h2>
  
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="text-field">
                  <div class="control">
                    <input
                      v-model="this.selectedItem.libelle"
                      type="text"
                      id="libelle"
                      class="form-control"
                      name="libelle"
                      required
                    />
                    <label for="libelle">libelle</label>
                  </div>
                </div>
              </div>
  
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="text-field">
                  <div class="control">
                    <textarea
                      v-model="this.selectedItem.description"
                      type="textarea"
                      id="description"
                      class="form-control"
                      name="description"
                      required
                    />
                    <label for="description">description</label>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="text-field">
                  <label for="horairedebut">Selectionnez le début:</label>
                  <div class="control">
                    <input
                      type="time"
                      v-model="this.selectedItem.horaireDebut"
                      id="horairedebut"
                      name="horairedebut"
                    />
                  </div>
                </div>
              </div>
  
              <div class="field col-6 col-md-6">
                <div class="text-field">
                  <label for="horairefin">Selectionnez la fin:</label>
                  <div class="control">
                    <input
                      type="time"
                      id="horairefin"
                      name="horairefin"
                      v-model="this.selectedItem.horaireFin"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
  
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <label for="Date">Selectionnez la date:</label>
                <input
                  id="icon"
                  class="textbox-n"
                  type="text"
                  onfocus="(this.type='date')"
                  onblur="(this.type='text')"
                  placeholder="Date de l'évenement"
                  v-model="this.selectedItem.date"
                />
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <label for="Date">Selectionnez la date de fin:</label>
                <input
                  id="icon"
                  class="textbox-n"
                  type="text"
                  onfocus="(this.type='date')"
                  onblur="(this.type='text')"
                  placeholder="Date de fin de l'évenement"
                  v-model="this.selectedItem.dateFin"
                />
              </div>
  

            </div>

            <div class="field col-12 col-md-12">
                <div class="text-field">
                  <div class="control">
                    <label for="nbrPlace">entrez la date pour les livrets :</label>
                    <input
                      type="date"
                      id="icon"
                      placeholder="Date de fin de l'évenement"
                      v-model="this.selectedItem.dateLivret"
                    />
                  </div>
                </div>
            </div>

            <div class="field col-12 col-md-12">
                <div class="text-field">
                  <div class="control">
                    <label for="nbrPlace">entrez un nombre de place:</label>
                    <input
                      type="number"
                      id="nbrPlace"
                      name="nbrPlace"
                      v-model="this.selectedItem.nbrPlace"
                      required
                    />
                  </div>
                </div>
            </div>

            <button @click="setMailEleveStage" class="btn btn-primary">Envoyer les mails aux élèves</button>
            <br>
            <DataTable
              :value="this.selectedItem.journeeReservations"
              :rows="3"
              :paginator="true"
              :rowsPerPageOptions="[3, 10, 20, 50]"
              v-model:filters="filters"
              filterDisplay="row"
              responsiveLayout="scroll"
              dataKey="id"
              v-model:selection="selectedJourneeReservation"
            >
              <Column
                field="eleve.nom"
                header="Nom"
                :sortable="true"
                style="min-width: 12rem"
              >
              </Column>
  
              <Column
                field="eleve.prenom"
                header="prenom"
                :sortable="true"
                style="min-width: 12rem"
              ></Column>
  
              <Column
                field="nbrInscrits"
                header="nbrInscrits"
                :sortable="true"
                style="min-width: 12rem"
              ></Column>
  
              <Column :exportable="false" style="min-width: 8rem">
                <template #body="slotProps">
                  <i
                    class="pi pi-times me-3"
                    @click="deleteJourneeReservation(slotProps.data.id)"
                    style="font-size: 1.7rem; cursor: pointer"
                  ></i>
                </template>
              </Column>
            </DataTable>
  
            <div id="btnContainer">
              <Button class="btnViolet" @click="putJournee()">Modifier</Button>
              <Button class="btnRouge" @click="deleteJournee()">Supprimer</Button>
            </div>
          </div>
        </template>
  
        <template v-if="this.selectedItem == null && this.addForm == true">
          <div class="container">
            <h2>Ajouter un stage de code</h2>
            <br>
            <br>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="text-field">
                  <div class="control">
                    <select name="agence" id="agence" v-model="this.form.agence">
                        <option :value="null">Veuillez Choisir une agence</option>
                        <template v-for="(agence, index) in agences" :value="agence.id" :key="index">
                            <option :value="agence.id">{{ agence.nom }}</option>
                        </template>
                    </select>
                  </div>
                </div>
              </div>

            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="text-field">
                  <div class="control">
                    <input
                      v-model="this.form.libelle"
                      type="text"
                      id="libelle"
                      class="form-control"
                      name="libelle"
                      required
                    />
                    <label for="libelle">libelle</label>
                  </div>
                </div>
              </div>
  
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="text-field">
                  <div class="control">
                    <textarea
                      v-model="this.form.description"
                      type="textarea"
                      id="description"
                      class="form-control"
                      name="description"
                      required
                    />
                    <label for="description">description</label>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="text-field">
                  <label for="horairedebut">Selectionnez le début:</label>
                  <div class="control">
                    <input
                      type="time"
                      v-model="this.form.horaireDebut"
                      id="horairedebut"
                      name="horairedebut"
                    />
                  </div>
                </div>
              </div>
  
              <div class="field col-6 col-md-6">
                <div class="text-field">
                  <label for="horairefin">Selectionnez la fin:</label>
                  <div class="control">
                    <input
                      type="time"
                      id="horairefin"
                      name="horairefin"
                      v-model="this.form.horaireFin"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
  
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <input
                  type="date"
                  id="icon"
                  placeholder="Date de l'évenement"
                  v-model="this.form.date"
                />
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <input
                  type="date"
                  id="icon"
                  placeholder="Date de fin de l'évenement"
                  v-model="this.form.dateFin"
                />
              </div>
  

            </div>

            <div class="field col-12 col-md-12">
                <div class="text-field">
                  <div class="control">
                    <label for="nbrPlace">entrez la date pour les livrets :</label>
                    <input
                      type="date"
                      id="icon"
                      placeholder="Date de fin de l'évenement"
                      v-model="this.form.dateLivret"
                    />
                  </div>
                </div>
            </div>

            <div class="field col-12 col-md-12">
                <div class="text-field">
                  <div class="control">
                    <label for="nbrPlace">entrez un nombre de place:</label>
                    <input
                      type="number"
                      id="nbrPlace"
                      name="nbrPlace"
                      v-model="this.form.nbrPlace"
                      required
                    />
                  </div>
                </div>
            </div>

            <div id="btnContainer">
              <Button class="btnViolet" @click="postJourneeInfo()"
                >Valider</Button
              >
            </div>
          </div>
        </template>
      </div>
    </div>
  </template>
  
  <script>
  import { useToast } from "primevue/usetoast";
  import DropdownNav from "../../components/DropdownNav.vue";
  
  export default {
      name: "App",
      component: {
          DropdownNav,
      },
      data() {
          return {
              toast: useToast(),
              c: null,
              addForm: false,
              checkJourneeaVenir: false,
              checkJourneePasse: false,
              journees: [],
              selectedItem: null,
              form: {
                  agence : null,
                  journeeInfoId: null,
                  libelle: "",
                  description: "",
                  horaireDebut: "",
                  horaireFin: "",
                  date: "",
                  dateFin: "",
                  nbrPlace: null,
                  dateLivret : null
              },
              agences : [],
          };
      },
      mounted() {
          this.getJournees();
          this.getAgences();
      },
      methods: {

      setMailEleveStage(){

        this.selectedItem.journeeReservations.forEach(e => {
          window.api
          .get("/stage/code/mail/"+ e.eleve.id + "/" + this.selectedItem.id, {
            headers: {
                          Authorization: "Bearer " + this.$store.state.token,
                      },
          })
              .then(() => {

              })
              .catch((error) => {
                  console.log(error.response.data.message);
              });
        })


      },

            getAgences() {
                window.api
                    .get("/agences")
                        .then((response) => {
                            this.agences = response.data["hydra:member"];
                        })
                        .catch((error) => {
                            console.log(error.response.data.message);
                        });
            },

          async getJournees() {
              try {
                  window.api
                      .get("/AllStageCode", {
                      headers: {
                          Authorization: "Bearer " + this.$store.state.token,
                      },
                  })
                      .then((response) => {
                      this.journees = response.data.journees;
                  });
              }
              catch (e) {
                  // handle authentication error here
              }
          },
          filtreJourneeaVenir() {
              if (this.checkJourneeaVenir == false) {
                  this.checkJourneeaVenir = true;
                  let todayDate = new Date().toLocaleString("en-US");
                  this.journees = this.journees.filter((jr) => new Date(jr.dateEn).getTime() >= new Date(todayDate).getTime());
              }
              else {
                  this.checkJourneeaVenir = false;
              }
          },
          filtreJourneeaPasse() {
              if (this.checkJourneePasse == false) {
                  this.checkJourneePasse = true;
                  let todayDate = new Date().toLocaleString("en-US");
                  this.journees = this.journees.filter((jr) => new Date(jr.dateEn).getTime() <= new Date(todayDate).getTime());
              }
              else {
                  this.checkJourneePasse = false;
                  this.getJournees();
              }
          },
          async putJournee() {
            if(this.selectedItem.dateLivret == 'null'){
              try {
                  window.api.put("/stage_codes/" + this.selectedItem.id, {
                      libelle: this.selectedItem.libelle,
                      description: this.selectedItem.description,
                      dateDebut: this.selectedItem.date,
                      dateFin: this.selectedItem.dateFin,
                      horaireDebut: this.selectedItem.horaireDebut,
                      horaireFin: this.selectedItem.horaireFin,
                      nbrPlace: this.selectedItem.nbrPlace,
                  }, {
                      headers: {
                          Authorization: "Bearer " + this.$store.state.token,
                      },
                  }).then(() => {
                          this.toast.add({
                              severity: "success",
                              summary: "Stage de code changé",
                              detail: "L'etat de votre stage de code à été changé avec succès",
                              life: 3000,
                          });
                      });
              }
              catch (e) {
                  // handle authentication error here
              }
            }else{
              try {
                  window.api.put("/stage_codes/" + this.selectedItem.id, {
                      libelle: this.selectedItem.libelle,
                      description: this.selectedItem.description,
                      dateDebut: this.selectedItem.date,
                      dateFin: this.selectedItem.dateFin,
                      horaireDebut: this.selectedItem.horaireDebut,
                      horaireFin: this.selectedItem.horaireFin,
                      nbrPlace: this.selectedItem.nbrPlace,
                      dateLivret: this.selectedItem.dateLivret,
                  }, {
                      headers: {
                          Authorization: "Bearer " + this.$store.state.token,
                      },
                  }).then(() => {
                          this.toast.add({
                              severity: "success",
                              summary: "Stage de code changé",
                              detail: "L'etat de votre stage de code à été changé avec succès",
                              life: 3000,
                          });
                      });
              }
              catch (e) {
                  // handle authentication error here
              }
            }

          },
          async postJourneeInfo() {
            
            if(this.form.dateLivret == null){
              window.api
                  .post("/stage_codes", {
                  libelle: this.form.libelle,
                  description: this.form.description,
                  dateDebut: this.form.date,
                  dateFin: this.form.dateFin,
                  horaireDebut: this.form.horaireDebut,
                  horaireFin: this.form.horaireFin,
                  nbrPlace: this.form.nbrPlace,
                  agence : "/api/agences/" + this.form.agence,
                  
              }, {
                  headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                  },
              })
                  .then((response) => {
                  this.form.journeeInfoId = response.data.id;
                  this.$emit("completed", response.data);
                  this.toast.add({
                      severity: "success",
                      summary: "Stage de code Ajouté",
                      detail: "Votre stage de code à été Ajouté avec succès",
                      life: 3000,
                  });
                  this.getJournees();
              })
                  .catch((error) => {
                  // handle authentication and validation errors here
                  console.log("ERRR::", error.response.data);
              });
            }else{
              window.api
                  .post("/stage_codes", {
                  libelle: this.form.libelle,
                  description: this.form.description,
                  dateDebut: this.form.date,
                  dateFin: this.form.dateFin,
                  horaireDebut: this.form.horaireDebut,
                  horaireFin: this.form.horaireFin,
                  nbrPlace: this.form.nbrPlace,
                  agence : "/api/agences/" + this.form.agence,
                  dateLivret: this.form.dateLivret
              }, {
                  headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                  },
              })
                  .then((response) => {
                  this.form.journeeInfoId = response.data.id;
                  this.$emit("completed", response.data);
                  this.toast.add({
                      severity: "success",
                      summary: "Stage de code Ajouté",
                      detail: "Votre stage de code à été Ajouté avec succès",
                      life: 3000,
                  });
                  this.getJournees();
              })
                  .catch((error) => {
                  // handle authentication and validation errors here
                  console.log("ERRR::", error.response.data);
              });
            }


          },

          async deleteJournee() {
              if (confirm("Voulez vous vraiment supprimer ce stage de code?")) {
                  window.api
                      .delete("/stage_codes/" + this.selectedItem.id, {
                      headers: {
                          Authorization: "Bearer " + this.$store.state.token,
                      },
                  })
                      .then((response) => {
                      this.getJournees();
                      this.$emit("completed", response.data);
                  })
                      .catch((error) => {
                      // handle authentication and validation errors here
                      console.log("ERRR::", error.response.data);
                  });
              }
          },

          changeBackground() {
              if (this.selectedItem != null) {
                  document.getElementById("right").style.background = "none";
              }
          },
          openAddForm() {
              this.selectedItem = null;
              this.addForm = true;
              document.getElementById("right").style.background = "none";
          },
          deleteJourneeReservation(id) {
              try {
                  if (confirm("Voulez vous vraiment supprimer cette Reservation ?")) {
                      window.api
                          .delete("stagecode/" + id, {
                          headers: {
                              Authorization: "Bearer " + this.$store.state.token,
                          },
                      })
                          .then(() => {
                          this.toast.add({
                              severity: "success",
                              summary: "Reservation Supprimé",
                              detail: "La Reservation à été Supprimé avec succès",
                              life: 3000,
                          });
                          this.getJournees();
                      });
                  }
              }
              catch (e) {
                  // handle authentication error here
              }
          },
      },
      components: { DropdownNav }
  };
  </script>
  
  <style scoped>

    select{
        width: 100%;
        padding: 16px 20px;
        border: none;
        border-radius: 4px;
        background-color: #f1f1f1;
    }

  #btnContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  
  #iconHome {
    cursor: pointer;
    position: relative;
    bottom: 15%;
  }
  
  #filtreJournee {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .btnRouge {
    background: #ec2b22;
    border-radius: 13px;
    border: none;
  }
  .btnViolet {
    background: #de6fa8;
    border-radius: 13px;
    border: none;
  }
  
  a {
    text-decoration: none;
  }
  
  #formModif {
    margin: auto;
    width: 100%;
  }
  
  #head {
    display: flex;
    margin-top: 5%;
    flex-direction: row;
    justify-content: flex-start;
    align-content: space-around;
    width: 50%;
  }
  
  #add {
    width: 50px;
    height: 50px;
    position: relative;
    cursor: pointer;
    top: 40px;
  }
  
  #titreRight {
    flex-wrap: wrap;
    font-size: 2rem;
    color: white;
  }
  
  #styleTitreRight {
    background-color: rgb(255, 255, 255, 0.47);
    padding: 1%;
    border-radius: 13px;
  }
  
  #left {
    background-image: url("../../assets/images/fondd.png");
    background-size: cover;
    border-right: 1px solid black;
  }
  
  #right {
    width: 100%;
    background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
    height: auto;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #titreLeft {
    font-size: 16pt;
    font-weight: bold;
    color: #2e9ea3;
  }
  
  #conteneur {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  
  #conteneur div {
    justify-content: space-evenly;
  }
  
  .text-field,
  text {
    position: relative;
    margin: 10px 2.5px 20px 2.5px;
  }
  
  input {
    display: inline-block;
    border: thin solid #fafafa;
    color: #444;
    background-color: #fafafa;
    padding: 10px 10px 10px 10px;
  }
  
  input:focus {
    border: thin solid #ffaa71;
    border-bottom: solid medium #ffaa71;
    background-color: #fff;
  }
  
  input:focus ~ label,
  input:valid ~ label {
    top: -10px;
    left: 15px;
    font-size: small;
    color: #167794;
    background-color: #fff;
    padding: 0 5px 0 5px;
  }
  </style>
  