<template>
    <div class="background">
        <div class="header">
            <img src="../../assets/images/logoMonPermis.png" alt="" @click="goToHome">
            <h1>Liste des élèves</h1>
        </div>

        <div class="content">

            <SimpleTypeahead
                id="typeahead_id"
                placeholder="Rechercher un élève..."
                :items="elevesName"
                :minInputLength="1"
                :itemProjection="
                        (item) => {
                          return item.fullname;
                        }
                      "
                @selectItem="selectItem"
                @onInput="searchEleve"
            >
            </SimpleTypeahead>

            <button class="btn btn-danger" @click="cancelSearch" v-if="cancelS == true">X</button>
            <br><br>
            <template v-if="eleveSelected == null">
                <DataTable :value="Elevesagence" tableStyle="min-width: 50rem">
                    <Column field="dateInsc" header="Inscription" sortable style="width: 25%"></Column>
                    <Column field="email" header="email" sortable style="width: 25%"></Column>
                    <Column field="nom" header="Nom" sortable style="width: 25%"></Column>
                    <Column field="prenom" header="Prenom" sortable style="width: 25%"></Column>
                    <Column field="telephone" header="Telephone" sortable style="width: 25%"></Column>
                    <Column field="lycee" header="Lycéen?" sortable style="width: 25%"></Column>
                    <Column field="archive" header="Archive" sortable style="width: 25%"></Column>
                    <Column field="" header="Supprimer" :sortable="true">
                        <template #body="{ data }">
                            <button @click="deleteEleve(data)" class="btn btn-danger">X</button>
                        </template>
                    </Column>
                </DataTable>
                <Paginator :rows="10" :totalRecords="countEleves" @page="onPaginate"></Paginator>
            </template>
            <template v-else>
                <DataTable :value="eleveSelected" tableStyle="min-width: 50rem">
                    <Column field="dateInsc" header="Inscription" sortable style="width: 25%"></Column>
                    <Column field="email" header="email" sortable style="width: 25%"></Column>
                    <Column field="nom" header="Nom" sortable style="width: 25%"></Column>
                    <Column field="prenom" header="Prenom" sortable style="width: 25%"></Column>
                    <Column field="telephone" header="Telephone" sortable style="width: 25%"></Column>
                    <Column field="lycee" header="Lycéen?" sortable style="width: 25%"></Column>
                    <Column field="archive" header="Archive" sortable style="width: 25%"></Column>
                    <Column field="" header="Supprimer" :sortable="true">
                        <template #body="{ data }">
                            <button @click="deleteEleve(data)" class="btn btn-danger">X</button>
                        </template>
                    </Column>
                </DataTable>
            </template>

        </div>
        
    </div>
</template>

<script>

import SimpleTypeahead from 'vue3-simple-typeahead'

export default{
    name: "App",

    components: {

        SimpleTypeahead
    },

    data() {
        return {
            Elevesagence : [],
            elevesName : [],
            countEleves: 0,
            eleveSelected : null,
            cancelS : false
        }
    },

    mounted() {
        this.getEleveAgence(0);
        //this.getNameEleve();
    },

    methods: {

        goToHome(){
            this.$router.push({name: 'Home'});
        },

        selectItem(e){
            this.cancelS = true;
            this.itemS = e;
            window.api
                .get("/eleve/admin/"+e.id, {
                headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                },
                })
                .then((response) => {
                // Séléctionne l'élève
                this.eleveSelected = response.data.utilisateurs;
                // Si l'élève est séléctionné

                })
                .catch((error) => {
                console.log(error.response.data.message);
                });
        },  

        cancelSearch(){
            this.getEleveAgence(0);
            this.cancelS = false;
            this.itemS = "";
            this.eleveSelected = null;
        },


        onPaginate(e){
            let offset = e.first;
            this.getEleveAgence(offset);
        },

        searchEleve(e){
            if(e.input.length == 3 || e.input.length == 6 || e.input.length == 9 || e.input.length == 12 || e.input.length == 15 || e.input.length == 18 || e.input.length == 21){
                window.api
                .get("/admin/name/eleves/"+e.input, {
                    headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                    },
                })
                .then((response) => {
                    this.elevesName = response.data.utilisateurs;
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        },

        deleteEleve(eleve){
            if(confirm("Êtes-vous sûr de vouloir supprimer cet élève ?")){
                console.log("eleve :", eleve)

                window.api
                .delete("/utilisateurs/" + eleve.id, {
                    headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                    },
                })
                .then(() => {
                    this.getEleveAgence(0);
                    
                })
                .catch((error) => {
                    console.log("ERRR::", error.response.data);
                    alert("Il est impossible de supprimer cet élève")
                });

            }
        },

        getEleveAgence(offset) {
            window.api
                .get("/eleves/admin/"+offset, {
                headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                },
                })
                .then((response) => {
                    this.Elevesagence = response.data.utilisateurs;
                    this.countEleves = response.data.count;
                })
                .catch((error) => {
                    console.log(error.response.data.message);
                });
        },

        getNameEleve(){
            window.api
                .get("/eleves/agence/name/"+ this.$store.state.user.agence.id, {
                headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                },
                })
                .then((response) => {
                    this.elevesName = response.data.utilisateurs;
                })
                .catch((error) => {
                    console.log(error);
                });
        },


    }
}

</script>

<style scoped>

div.background {
  height: 100vh;
  width: 100%;
  background-image: url("../../assets/images/Fond_ordinateur.png");
  background-size: cover;
}

div.header{
    padding: 2em;
}

div.header img{
    float: left;
    width: 70px;
}

div.header h1{
    text-align: center;
}


</style>