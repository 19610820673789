<template>
  <div id="conteneur">
    <Toast />
    <div id="left" class="container">
      <Toast />
      <header id="head">
        <div style="margin-top: 5%; display: flex">
          <DropdownNav></DropdownNav>
          <router-link to="/home"
            ><i
              class="pi pi-home me-3"
              id="iconHome"
              style="font-size: 2rem"
            ></i
          ></router-link>
          <router-link to="/VueEnsembleTrame"
            ><h1 class="ms-3" id="titreLeft">Trame</h1></router-link
          >
        </div>

        <div class="mb-3" style="display: flex; margin-top: 5%; width: 75%">
          <i
            class="pi pi-sort ms-3 mt-2"
            @click="patchCompetenceOrdre()"
            style="font-size: 1.3rem; cursor: pointer"
          ></i>

          <input
            class="form-control ms-5"
            type="text"
            style="max-width: 14rem"
            v-model="formCompetence.label"
            placeholder="competence"
            required
            @keydown="errors = ''"
          />

          <input
            class="form-control ms-5"
            type="color"
            style="max-width: 10rem"
            v-model="formCompetence.color"
            required
            @keydown="errors = ''"
          />

          <button
            type="button"
            @click="postCompetence()"
            class="input-group-text btnFleche ms-4"
            data-v-7a64c06e=""
          >
            <i
              class="fa fa-plus"
              style="font-size: 1rem; cursor: pointer"
              data-v-7a64c06e=""
            ></i>
          </button>
        </div>
      </header>
      <div>
        <DataTable
          :value="this.competences"
          :rows="4"
          :paginator="true"
          :rowsPerPageOptions="[4, 10, 20, 50]"
          responsiveLayout="scroll"
          dataKey="id"
          v-model:filters="filters"
          filterDisplay="row"
          v-model:selection="selectedItem"
          selectionMode="single"
          @click="changeBackground"
        >
          <Column
            field="numero"
            header="numero"
            :sortable="true"
            style="min-width: 6rem"
          >
            ></Column
          >

          <Column
            header="Competence"
            :exportable="false"
            style="min-width: 4rem; max-width: 10rem"
          >
            <template #body="slotProps">
              <div class="inputLabel">
                <input
                  class="form-control"
                  type="text"
                  required
                  v-model="slotProps.data.label"
                  @keydown="errors = ''"
                />

                <button
                  type="button"
                  @click="patchCompetence(slotProps.data)"
                  class="input-group-text btnFleche"
                  data-v-7a64c06e=""
                >
                  <i class="fa fa-arrow-right arrow" data-v-7a64c06e=""> </i>
                </button>
              </div>
            </template>
          </Column>

          <Column :exportable="false" style="min-width: 8rem">
            <template #body="slotProps">
              <input
                class="form-control"
                type="color"
                @change="patchCompetence(slotProps.data)"
                required
                v-model="slotProps.data.color"
                @keydown="errors = ''"
              />
            </template>
          </Column>

          <Column :exportable="false" style="max-width: 2rem">
            <template #body="slotProps">
              <i
                class="pi pi-arrow-up me-3"
                @click="changePlaceDown(slotProps.data)"
                style="font-size: 1.2rem"
              ></i>
            </template>
          </Column>

          <Column :exportable="false" style="max-width: 2rem">
            <template #body="slotProps">
              <i
                class="pi pi-arrow-down me-3"
                @click="changePlaceUp(slotProps.data)"
                style="font-size: 1.2rem"
              ></i>
            </template>
          </Column>

          <Column :exportable="false" style="min-width: 8rem">
            <template #body="slotProps">
              <center>
                <i
                  class="pi pi-times me-3"
                  @click="deleteCompetence(slotProps.data)"
                  style="font-size: 1.7rem"
                ></i>
              </center>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <div id="right">
      <div v-if="this.selectedItem == null" id="styleTitreRight">
        <p id="titreRight">Cliquez sur une competence</p>
      </div>

      <template v-if="this.selectedItem != null">
        <div class="container-fluid" id="conteneurModif">
          <div class="headObjectif">
            <h2
              :style="'color:' + selectedItem.color"
              style="font-weight: bold"
            >
              {{ this.selectedItem.label }}
            </h2>
            <i
              class="pi pi-sort ms-3 mt-2"
              @click="patchObjectifOrdre()"
              style="font-size: 1.3rem; cursor: pointer"
            ></i>
            <input
              class="form-control ms-5"
              type="number"
              style="max-width: 14rem"
              v-model="formObjectif.numero"
              placeholder="numero"
              required
              @keydown="errors = ''"
            />

            <input
              class="form-control ms-5"
              type="text"
              style="max-width: 14rem"
              v-model="formObjectif.label"
              placeholder="objectif"
              required
              @keydown="errors = ''"
            />

            <button
              type="button"
              @click="postObjectif()"
              class="input-group-text btnFleche ms-4"
              data-v-7a64c06e=""
            >
              <i
                class="fa fa-plus"
                style="font-size: 1rem; cursor: pointer"
                data-v-7a64c06e=""
              ></i>
            </button>
          </div>
          <DataTable
            :value="this.objectifs"
            :rows="4"
            :paginator="true"
            :rowsPerPageOptions="[4, 10, 20, 50]"
            responsiveLayout="scroll"
            dataKey="id"
            filterDisplay="row"
            v-model:selection="selectedObjectif"
            selectionMode="single"
          >
            <Column
              field="numero"
              header="numero"
              :sortable="true"
              style="min-width: 6rem"
            >
              ></Column
            >

            <Column header="label" :exportable="false" style="min-width: 6rem">
              <template #body="slotProps">
                <div class="inputLabel">
                  <input
                    class="form-control"
                    type="text"
                    required
                    v-model="slotProps.data.label"
                    @keydown="errors = ''"
                  />

                  <button
                    type="button"
                    @click="patchObjectif(slotProps.data)"
                    class="input-group-text btnFleche"
                    data-v-7a64c06e=""
                  >
                    <i class="fa fa-arrow-right arrow" data-v-7a64c06e=""></i>
                  </button>
                </div>
              </template>
            </Column>

            <Column :exportable="false" style="max-width: 2rem">
              <template #body="slotProps">
                <i
                  class="pi pi-arrow-up me-3"
                  @click="changePlaceDownObjectif(slotProps.data)"
                  style="font-size: 1.2rem"
                ></i>
              </template>
            </Column>

            <Column :exportable="false" style="max-width: 2rem">
              <template #body="slotProps">
                <i
                  class="pi pi-arrow-down me-3"
                  @click="changePlaceUpObjectif(slotProps.data)"
                  style="font-size: 1.2rem"
                ></i>
              </template>
            </Column>

            <Column :exportable="false" style="min-width: 6rem">
              <template #body="slotProps">
                <Button @click="displaySousObjectifs(slotProps.data)"
                  >Sous Objectifs</Button
                >
              </template>
            </Column>

            <Column :exportable="false" style="min-width: 8rem">
              <template #body="slotProps">
                <i
                  class="pi pi-times me-3"
                  id="iconHome"
                  @click="deleteObjectif(slotProps.data)"
                  style="font-size: 1.7rem"
                ></i>
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </div>
  </div>

  <Dialog
    :header="`Sous Objectifs`"
    v-model:visible="displaySousObjectif"
    :style="{ width: '80vw' }"
  >
    <span class="help is-danger" v-text="errors"></span>

    <div class="container pt-4">
      <div class="headObjectif">
        <h2 style="font-weight: bold">{{ this.objectif.label }}</h2>
        <i
          class="pi pi-sort ms-3 mt-2"
          @click="patchSousObjectifOrdre()"
          style="font-size: 1.3rem; cursor: pointer"
        ></i>
        <input
          class="form-control ms-5"
          type="number"
          style="max-width: 14rem"
          v-model="formSousObjectif.numero"
          placeholder="numero"
          required
          @keydown="errors = ''"
        />

        <input
          class="form-control ms-5"
          type="text"
          style="max-width: 14rem"
          v-model="formSousObjectif.label"
          placeholder="sous objectif"
          required
          @keydown="errors = ''"
        />

        <input
          class="form-control ms-5"
          type="number"
          style="max-width: 14rem"
          v-model="formSousObjectif.duree"
          placeholder="duree"
          required
          @keydown="errors = ''"
        />

        <button
          type="button"
          @click="postSousObjectif()"
          class="input-group-text btnFleche ms-4"
          data-v-7a64c06e=""
        >
          <i
            class="fa fa-plus"
            style="font-size: 1rem; cursor: pointer"
            data-v-7a64c06e=""
          ></i>
        </button>
      </div>

      <DataTable
        :value="this.sousobjectifs"
        :rows="10"
        :paginator="true"
        :rowsPerPageOptions="[10, 20, 50]"
        responsiveLayout="scroll"
        dataKey="id"
        filterDisplay="row"
        v-model:selection="selectedObjectif"
        selectionMode="single"
      >
        <Column
          field="numero"
          header="numero"
          :sortable="true"
          style="min-width: 6rem"
        >
          ></Column
        >

        <Column header="label" :exportable="false" style="min-width: 6rem">
          <template #body="slotProps">
            <div class="inputLabel">
              <input
                class="form-control"
                type="text"
                required
                v-model="slotProps.data.label"
                @keydown="errors = ''"
              />

              <button
                type="button"
                @click="patchSousObjectif(slotProps.data)"
                class="input-group-text btnFleche"
                data-v-7a64c06e=""
              >
                <i class="fa fa-arrow-right arrow" data-v-7a64c06e=""></i>
              </button>
            </div>
          </template>
        </Column>

        <Column header="duree" :exportable="false" style="min-width: 6rem">
          <template #body="slotProps">
            <div class="inputLabel">
              <input
                class="form-control"
                type="number"
                required
                v-model="slotProps.data.duree"
                @keydown="errors = ''"
              />

              <button
                type="button"
                @click="patchSousObjectif(slotProps.data)"
                class="input-group-text btnFleche"
                data-v-7a64c06e=""
              >
                <i class="fa fa-arrow-right arrow" data-v-7a64c06e=""></i>
              </button>
            </div>
          </template>
        </Column>

        <Column :exportable="false" style="max-width: 2rem">
          <template #body="slotProps">
            <i
              class="pi pi-arrow-down me-3"
              @click="changePlaceUpSousObjectif(slotProps.data)"
              style="font-size: 1.2rem"
            ></i>
          </template>
        </Column>

        <Column :exportable="false" style="max-width: 2rem">
          <template #body="slotProps">
            <i
              class="pi pi-arrow-up me-3"
              @click="changePlaceDownSousObjectif(slotProps.data)"
              style="font-size: 1.2rem"
            ></i>
          </template>
        </Column>
        <Column :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <i
              class="pi pi-times me-3"
              @click="deleteSousObjectif(slotProps.data)"
              style="font-size: 1.7rem"
            ></i>
          </template>
        </Column>
      </DataTable>
    </div>
    <template #footer>
      <Button
        label="Fermer"
        icon="pi pi-check"
        @click="closeBasic"
        class="p-button-text"
      />
    </template>
  </Dialog>
</template>

<script>
import { useToast } from "primevue/usetoast";
import DropdownNav from "../../../components/DropdownNav.vue";

export default {
  name: "App",
  component: {
    DropdownNav,
  },
  data() {
    return {
      active: 0,
      // selectedItem = la competence selectionné
      selectedItem: null,
      // selectedObjectif = l'objectif selectionné
      selectedObjectif: null,
      // Booleen permettant l'affichage du pop up des sous objectifs
      displaySousObjectif: false,
      toast: useToast(),
      // l'objectif selectionné
      objectif: null,
      // numero qui sera autoincrementé pour les comp ou objectifs
      numeroAutoIncrementComp: null,
      // liste des competences à decrementer lors d'une suppression
      lesCompetenceAdecrementer: [],
      // liste des competences
      competences: [],
      // liste des objectifs
      objectifs: [],
      // liste des sousobjectifs
      sousobjectifs: [],

      // Ensuite il y a les formulaires , force Hugo tu vas réussir
      formCompetence: {
        label: "",
        color: "",
      },
      formObjectif: {
        numero: "",
        label: "",
        competence: null,
      },
      formSousObjectif: {
        numero: "",
        label: "",
        duree: "",
        objectif: null,
      },
    };
  },
  mounted() {
    this.getCompetences();
  },
  methods: {
    // On récup toutes les competences en les triant par numéro
    async getCompetences() {
      try {
        window.api
          .get("/competences", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((res) => {
            this.competences = res.data["hydra:member"];
            this.competences.sort((a, b) => a.numero - b.numero);
          });
      } catch (e) {
        // handle authentication error here
      }
    },

    // Fonction permettant de valider l'ordre de la liste des competences
    patchCompetenceOrdre(mesCompetences) {
      mesCompetences = [];
      this.competences.forEach((cp) => {
        find(mesCompetences.push(cp));
      });
      window.api
        .patch("/competence_ordre", mesCompetences, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
            "Content-Type": "application/merge-patch+json",
          },
        })
        .then((response) => {
          this.toast.add({
            severity: "success",
            summary: "ordre modifié",
            detail: "Votre ordre à ete modifié avec succès",
            life: 3000,
          });
          this.getCompetences();
          console.log("completed", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Fonction permettant de valider l'ordre de la liste des objectifs
    patchObjectifOrdre(mesObjectifs) {
      mesObjectifs = [];
      this.objectifs.forEach((cp) => {
        find(mesObjectifs.push(cp));
      });
      window.api
        .patch("/objectif_ordre", mesObjectifs, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
            "Content-Type": "application/merge-patch+json",
          },
        })
        .then((response) => {
          this.toast.add({
            severity: "success",
            summary: "ordre modifié",
            detail: "Votre ordre à ete modifié avec succès",
            life: 3000,
          });
          console.log("completed", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Fonction permettant de valider l'ordre de la liste des sous objectifs
    patchSousObjectifOrdre(mesSousObjectifs) {
      mesSousObjectifs = [];
      this.sousobjectifs.forEach((cp) => {
        find(mesSousObjectifs.push(cp));
      });
      window.api
        .patch("/sous_objectif_ordre", mesSousObjectifs, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
            "Content-Type": "application/merge-patch+json",
          },
        })
        .then((response) => {
          this.toast.add({
            severity: "success",
            summary: "ordre modifié",
            detail: "Votre ordre à ete modifié avec succès",
            life: 3000,
          });
          console.log("completed", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Fonction permettant de modifier l'ordre du sous objectif sélectionné en l'incrementant de 1
    changePlaceUpSousObjectif(sousObjectif) {
      console.log("lesobj", this.objectifs);
      let SousobjectifSuivant = this.sousobjectifs.find(
        (o) => o.numero === sousObjectif.numero + 1
      );
      SousobjectifSuivant.numero = SousobjectifSuivant.numero - 1;
      sousObjectif.numero = sousObjectif.numero + 1;
      this.sousobjectifs.sort((a, b) => a.numero - b.numero);
    },
    // Fonction permettant de modifier l'ordre du sous objectif sélectionné en le décrementant de 1
    changePlaceDownSousObjectif(sousObjectif) {
      console.log("lesobj", this.objectifs);
      let SousobjectifAvant = this.sousobjectifs.find(
        (o) => o.numero === sousObjectif.numero - 1
      );
      SousobjectifAvant.numero = SousobjectifAvant.numero + 1;
      sousObjectif.numero = sousObjectif.numero - 1;
      this.sousobjectifs.sort((a, b) => a.numero - b.numero);
    },

    // Fonction permettant de modifier l'ordre de l'objectif sélectionné en l'incrementant de 1
    changePlaceUpObjectif(objectif) {
      console.log("lesobj", this.objectifs);
      let objectifSuivant = this.objectifs.find(
        (o) => o.numero === objectif.numero + 1
      );
      objectifSuivant.numero = objectifSuivant.numero - 1;
      objectif.numero = objectif.numero + 1;
      this.objectifs.sort((a, b) => a.numero - b.numero);
    },
    // Fonction permettant de modifier l'ordre de l'objectif sélectionné en le décrementant de 1
    changePlaceDownObjectif(objectif) {
      console.log("lesobj", this.objectifs);
      let objectifAvant = this.objectifs.find(
        (o) => o.numero === objectif.numero - 1
      );
      objectifAvant.numero = objectifAvant.numero + 1;
      objectif.numero = objectif.numero - 1;
      this.objectifs.sort((a, b) => a.numero - b.numero);
    },

    // Fonction permettant de modifier l'ordre de la competence sélectionné en l'incrementant  de 1
    changePlaceUp(competence) {
      let competenceSuivant = this.competences.find(
        (c) => c.numero === competence.numero + 1
      );
      competenceSuivant.numero = competenceSuivant.numero - 1;
      competence.numero = competence.numero + 1;
      this.competences.sort((a, b) => a.numero - b.numero);
    },
    // Fonction permettant de modifier l'ordre de la competence sélectionné en le decrementant  de 1
    changePlaceDown(competence) {
      let competenceAvant = this.competences.find(
        (c) => c.numero === competence.numero - 1
      );
      competenceAvant.numero = competenceAvant.numero + 1;
      competence.numero = competence.numero - 1;
      this.competences.sort((a, b) => a.numero - b.numero);
    },

    // On modifie le background quand on sélectionne une competence et on recup les objectifs lié a la comp
    changeBackground() {
      if (this.selectedItem != null) {
        document.getElementById("right").style.background = "none";
      }
      this.getObjectifs();
    },

    // Fonction permettant d'afficher les sous objectifs lié à un objectif selectionné
    displaySousObjectifs(objectif) {
      (this.displaySousObjectif = true), (this.objectif = objectif);
      this.getSousobjectifs(objectif);
    },
    // Fonction permettant de recup les sous objectifs trié par le numéro
    getSousobjectifs(objectif) {
      window.api
        .get("/SousObjectifsByCompetence/" + objectif.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.sousobjectifs = response.data.sousobjectifs;
          this.sousobjectifs.sort((a, b) => a.numero - b.numero);
          console.log(response);
        })
        .catch((error) => {
          console.log(error.response.data.sousobjectifs.message);
        });
    },

    // Fonction permettant de patch une competence
    patchCompetence(competence) {
      window.api
        .patch(
          "/competences/" + competence.id,
          {
            label: competence.label,
            color: competence.color,
            numero: competence.numero,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then((response) => {
          this.getCompetences();
          console.log("completed", response.data);
          this.toast.add({
            severity: "success",
            summary: "Competence modifié",
            detail: "Votre Competence a été modifié avec succès",
            life: 3000,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    closeBasic() {
      this.displaySousObjectif = false;
    },

     // Fonction permettant de patch un objectif
    patchObjectif(objectif) {
      window.api
        .patch(
          "/objectifs/" + objectif.id,
          {
            label: objectif.label,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then((response) => {
          this.toast.add({
            severity: "success",
            summary: "Objectif modifié",
            detail: "Votre Objectif à ete modifié avec succès",
            life: 3000,
          });
          this.getObjectifs();
          console.log("completed", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Fonction permettant de post une competence , si il y a des competences on incremente par rapport a la comp d'avant 
    // sinon son numéro est égale à 1
    postCompetence() {
      if (this.competences[this.competences.length - 1] !== undefined) {
        this.numeroAutoIncrementComp =
          this.competences[this.competences.length - 1].numero + 1;
      } else {
        this.numeroAutoIncrementComp = 1;
      }
      window.api
        .post(
          "/competences",
          {
            numero: this.numeroAutoIncrementComp,
            label: this.formCompetence.label,
            color: this.formCompetence.color,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          console.log("completed", response.data);
          this.toast.add({
            severity: "success",
            summary: "Competence ajouté",
            detail: "Votre Competence a été ajouté avec succès",
            life: 3000,
          });
          this.getCompetences();
        })
        .catch((error) => {
          // handle authentication and validation errors here
          console.log("ERRR::", error.response.data);
        });
    },

    // on supprime une comp est on réarrange toute la liste en patchant 
    // l'ordre avec tout les competence a decrementer (les competences ayant un num superieur à celui qui est selectionné)
    async deleteCompetence(competence) {
      if (confirm("Voulez vous vraiment supprimer ?")) {
        if (
          this.competences.find(
            (c) => (c.numero === competence.numero + 1) !== undefined
          )
        ) {
          this.lesCompetenceAdecrementer = this.competences.filter(
            (cp) => competence.numero < cp.numero
          );
          this.lesCompetenceAdecrementer.forEach((cp) => {
            cp.numero = cp.numero - 1;
          });
          this.patchCompetenceOrdre(this.lesCompetenceAdecrementer);
          window.api
            .delete("competences/" + competence.id, {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            })
            .then((response) => {
              this.toast.add({
                severity: "success",
                summary: "Competence supprimé",
                detail: "Votre Competence a été supprimé avec succès",
                life: 3000,
              });
              this.getCompetences();
              console.log(response);
              console.log(this.$store.state.token);
            })
            .catch((error) => {
              // handle authentication and validation errors here
              console.log("ERRR::", error.response.data);
            });
        }
      }
    },

    // Recup tout les objectifs par competence
    getObjectifs() {
      window.api
        .get("/ObjectifsByCompetence/" + this.selectedItem.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.objectifs = response.data.objectifs;
          this.objectifs.sort((a, b) => a.numero - b.numero);
          console.log(response);
        })
        .catch((error) => {
          console.log(error.response.data.objectifs.message);
        });
    },

    // post de l'objectif
    postObjectif() {
      if (this.objectifs[this.objectifs.length - 1] !== undefined) {
        this.numeroAutoIncrementComp =
          this.objectifs[this.objectifs.length - 1].numero + 1;
      } else {
        this.numeroAutoIncrementComp = 1;
      }
      window.api
        .post(
          "/objectifs",
          {
            numero: this.numeroAutoIncrementComp,
            label: this.formObjectif.label,
            competence: "api/competences/" + this.selectedItem.id,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          console.log("completed", response.data);
          this.toast.add({
            severity: "success",
            summary: "Objectif ajouté",
            detail: "Votre Objectif a été ajouté avec succès",
            life: 3000,
          });
          this.getObjectifs();
        })
        .catch((error) => {
          // handle authentication and validation errors here
          console.log("ERRR::", error.response.data);
        });
    },

    // delete de l'objectif
    async deleteObjectif(objectif) {
      if (confirm("Voulez vous vraiment supprimer ?")) {
        if (
          this.objectifs.find(
            (o) => (o.numero === objectif.numero + 1) !== undefined
          )
        ) {
          this.lesObjectifAdecrementer = this.objectifs.filter(
            (cp) => objectif.numero < cp.numero
          );
          this.lesObjectifAdecrementer.forEach((ob) => {
            ob.numero = ob.numero - 1;
          });
          this.patchObjectifOrdre(this.lesObjectifAdecrementer);
          window.api
            .delete("objectifs/" + objectif.id, {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            })
            .then((response) => {
              this.toast.add({
                severity: "success",
                summary: "Objectif supprimé",
                detail: "Votre objectif a été supprimé avec succès",
                life: 3000,
              });
              this.getObjectifs();
              console.log(response);
              console.log(this.$store.state.token);
            })
            .catch((error) => {
              // handle authentication and validation errors here
              console.log("ERRR::", error.response.data);
            });
        }
      }
    },


    // parch sous obj
    patchSousObjectif(sousObjectif) {
      window.api
        .patch(
          `sous_objectifs/` + sousObjectif.id,
          {
            objectif: "api/objectifs/" + this.objectif.id,
            numero: sousObjectif.numero,
            label: sousObjectif.label,
            duree: sousObjectif.duree,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then((response) => {
          this.toast.add({
            severity: "success",
            summary: "Sous objectif modifié",
            detail: "Sous objectif a été modifié avec succès",
            life: 3000,
          });
          this.getSousobjectifs(this.objectif);
          console.log("completed", response.data);
        })
        .catch((error) => {
          // handle authentication and validation errors here
          console.log("ERRR::", error.response.data);
        });
    },
    async deleteSousObjectif(sousObjectif) {
      if (confirm("Voulez vous vraiment supprimer ?")) {
        if (
          this.sousobjectifs.find(
            (o) => (o.numero === sousObjectif.numero + 1) !== undefined
          )
        ) {
          this.lesSousObjectifAdecrementer = this.sousobjectifs.filter(
            (cp) => sousObjectif.numero < cp.numero
          );
          this.lesSousObjectifAdecrementer.forEach((ob) => {
            ob.numero = ob.numero - 1;
          });
          this.patchSousObjectifOrdre(this.lesSousObjectifAdecrementer);
          window.api
            .delete("sous_objectifs/" + sousObjectif.id, {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            })
            .then((response) => {
              this.toast.add({
                severity: "success",
                summary: "sous objectif supprimé",
                detail: "Votre sous objectif a été supprimé avec succès",
                life: 3000,
              });
              this.getSousobjectifs(this.objectif);
              console.log(response);
              console.log(this.$store.state.token);
            })
            .catch((error) => {
              // handle authentication and validation errors here
              console.log("ERRR::", error.response.data);
            });
        }
      }
    },
    postSousObjectif() {
      if (this.sousobjectifs[this.sousobjectifs.length - 1] !== undefined) {
        this.numeroAutoIncrementComp =
          this.sousobjectifs[this.sousobjectifs.length - 1].numero + 1;
      } else {
        this.numeroAutoIncrementComp = 1;
      }
      window.api
        .post(
          "/sous_objectifs",
          {
            objectif: "api/objectifs/" + this.objectif.id,
            numero: this.numeroAutoIncrementComp,
            label: this.formSousObjectif.label,
            duree: this.formSousObjectif.duree,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.toast.add({
            severity: "success",
            summary: "Sous objectif ajouté",
            detail: "Votre Sous objectif a été ajouté avec succès",
            life: 3000,
          });
          this.getSousobjectifs(this.objectif);
          console.log("completed", response.data);
        })
        .catch((error) => {
          // handle authentication and validation errors here
          console.log("ERRR::", error.response.data);
        });
    },
  },
  components: { DropdownNav },
};
</script>

<style scoped>
.headObjectif {
  display: flex;
  margin-bottom: 2%;
}
.btnFleche {
  border: 1px solid black !important;
  border-radius: 0 10px 10px 0 !important;
}

.inputLabel {
  display: flex;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
#lieus {
  border-top: 1px solid black;
}

#conteneurModif {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
}

#btnContainer {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

#iconHome {
  cursor: pointer;
}

.btnRouge {
  background: #ec2b22;
  border-radius: 13px;
  border: none;
}
.btnTurquoise {
  background: #07989f;
  border-radius: 13px;
  border: none;
}
.btnViolet {
  background: #de6fa8;
  border-radius: 13px;
  border: none;
}

a {
  text-decoration: none;
}

#formModif {
  margin: auto;
  width: 100%;
}

#head {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;
  width: 100%;
}

#add {
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  top: 40px;
}

#titreRight {
  flex-wrap: wrap;
  font-size: 2rem;
  color: white;
}

#styleTitreRight {
  background-color: rgb(255, 255, 255, 0.47);
  padding: 1%;
  border-radius: 13px;
  align-self: center;
}

#left {
  background-image: url("../../../assets/images/fondd.png");
  background-size: cover;
  border-right: 1px solid black;
}

#right {
  width: 100%;
  background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  height: auto;
  justify-content: center !important;
  min-height: 100vh;
  display: flex;
}

#titreLeft {
  font-size: 25pt;
  font-weight: bold;
  color: #2e9ea3;
}

#conteneur {
  display: grid;
  grid-template-columns: 50% 50%;
}

.text-field,
text {
  position: relative;
  margin: 10px 2.5px 20px 2.5px;
}

input {
  display: inline-block;
  border: thin solid #fafafa;
  color: #444;
  background-color: #fafafa;
  padding: 10px 10px 10px 10px;
}

input:focus {
  border: thin solid #ffaa71;
  border-bottom: solid medium #ffaa71;
  background-color: #fff;
}

label {
  color: #999;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 10px;
  transition: 0.2s;
}

input:focus ~ label,
input:valid ~ label {
  top: -10px;
  left: 15px;
  font-size: small;
  color: #167794;
  background-color: #fff;
  padding: 0 5px 0 5px;
}
</style>
