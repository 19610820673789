<template>
  <div id="conteneur">
    <Toast />
    <div id="left" class="container">
      <header id="head">
        <DropdownNav></DropdownNav>
        <router-link to="/home"
          ><i class="pi pi-home me-3" id="iconHome" style="font-size: 2rem"></i
        ></router-link>
        <h1 class="ms-3" id="titreLeft">Moniteur</h1>
        <i
          class="pi pi-plus-circle ms-3 mt-2"
          id="iconHome"
          @click="openAddForm()"
          style="font-size: 1.5rem"
        ></i>
      </header>
      <div>
        <div style="display: flex; justify-content: space-between">
          <div>
            <select
              v-model="this.selectedLieu"
              id="lieus"
              @change="filterMoniteursByLieuRdv()"
              name="lieus"
              class="form-select mb-4"
              placeholder=" "
            >
              <option :value="this.Tous">Tous</option>
              <option
                v-for="(item, index) in this.lieusRdv"
                :value="item.id"
                :key="index"
              >
                {{ item.nom }}
              </option>
            </select>
          </div>
          <div class="flex justify-self-flex-end">
            <span class="p-input-icon-left">
              <input
                v-model="filters['global'].value"
                placeholder="Rechercher"
              />
            </span>
            <i class="pi pi-search me-2 ms-2" />
          </div>
          <router-link to="/ArchiveMonosAgence"
            ><Button class="btnViolet">Archive</Button></router-link
          >
        </div>
        <DataTable
          :value="this.moniteursFilteredByLieuRdv"
          :rows="10"
          :paginator="true"
          :rowsPerPageOptions="[10, 20, 50]"
          responsiveLayout="scroll"
          dataKey="id"
          v-model:filters="filters"
          filterDisplay="menu"
          v-model:selection="selectedItem"
          :globalFilterFields="[
            'nom',
            'prenom',
            'telephone',
            'codePostal',
            'agence.nom',
          ]"
          selectionMode="single"
          @click="changeBackground"
        >
          <template #empty> Pas de moniteurs. </template>
          <template #loading>
            Les moniteurs sont en train de charger.
          </template>
          <Column
            field="nom"
            header="nom"
            :sortable="true"
            style="min-width: 12rem"
          >
          </Column>

          <Column
            field="prenom"
            header="prenom"
            :sortable="true"
            style="min-width: 6rem"
          ></Column>

          <Column
            field="telephone"
            header="telephone"
            :sortable="true"
            style="min-width: 6rem"
          ></Column>

          <Column
            field="codePostal"
            header="codePostal"
            :sortable="true"
            style="min-width: 6rem"
          ></Column>

          <Column
            field="agence.nom"
            header="agence"
            :sortable="true"
            style="min-width: 10rem"
          ></Column>
        </DataTable>
      </div>
    </div>

    <div id="right">
      <div
        v-if="this.selectedItem == null && this.addForm == false"
        id="styleTitreRight"
      >
        <p id="titreRight">Cliquez sur un Moniteur</p>
      </div>

      <template v-if="this.selectedItem != null">
        <div class="container-fluid" id="conteneurModif">
          <div id="formAjout">
            <h2 class="text-center">{{ this.selectedItem.nom }}</h2>

            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="text-field">
                  <div class="control">
                    <input
                      class="form-control"
                      type="nom"
                      required
                      v-model="this.selectedItem.nom"
                      @keydown="errors = ''"
                    />
                    <label>nom</label>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="text-field">
                  <div class="control">
                    <input
                      class="form-control"
                      type="prenom"
                      required
                      v-model="this.selectedItem.prenom"
                      @keydown="errors = ''"
                    />
                    <label>prenom</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <br />
                <div class="text-field">
                  <div class="control">
                    <input
                      class="form-control"
                      type="email"
                      required
                      v-model="this.selectedItem.email"
                      @keydown="errors = ''"
                    />
                    <label>email</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <br />
                <div class="text-field">
                  <div class="control">
                    <Dropdown
                      v-model="this.selectedItem.agence"
                      class="mb-3"
                      :options="this.agences"
                      optionLabel="nom"
                      :placeholder="this.selectedItem.agence.nom"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <br />
                <div class="text-field">
                  <div class="control">
                    <input
                      class="form-control"
                      type="telephone"
                      required
                      v-model="this.selectedItem.telephone"
                      @keydown="errors = ''"
                    />
                    <label>telephone</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <br />
                <div class="text-field">
                  <div class="control">
                    <input
                      class="form-control"
                      type="adresse"
                      required
                      v-model="this.selectedItem.adresse"
                      @keydown="errors = ''"
                    />
                    <label>adresse</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <br />
                <div class="text-field">
                  <div class="control">
                    <input
                      class="form-control"
                      type="codePostal"
                      required
                      v-model="this.selectedItem.codePostal"
                      @keydown="errors = ''"
                    />
                    <label>codePostal</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <br />
                <div class="text-field">
                  <div class="control">
                    <input
                      class="form-control"
                      type="ville"
                      required
                      v-model="this.selectedItem.ville"
                      @keydown="errors = ''"
                    />
                    <label>ville</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="lieus">
            <h3 class="mt-3 mb-3">
              Liste des lieux pour {{ this.selectedItem.nom }}
              <Button class="btnViolet ms-5" @click="filterLieuxForAddToMoni()"
                >Ajouter</Button
              >
            </h3>
            <DataTable
              :value="this.selectedItem.lieux"
              :paginator="true"
              :rows="10"
              :rowsPerPageOptions="[10, 20, 50]"
              v-model:selection="selectedMoniteurLieu"
              responsiveLayout="scroll"
              dataKey="id"
            >
              <Column selectionMode="single"></Column>
              <Column
                field="nom"
                header="nom"
                :sortable="true"
                style="min-width: 12rem"
              ></Column>
              <Column
                field="adresse"
                header="adresse"
                :sortable="true"
                style="min-width: 12rem"
              ></Column>
              <Column
                header="visible par les eleves"
                :exportable="false"
                style="min-width: 8rem"
              >
                <template #body="slotProps">
                  <InputSwitch
                    @change="putMoniteurLieu(slotProps.data)"
                    v-model="slotProps.data.visibleEleve"
                  />
                </template>
              </Column>
            </DataTable>

            <div id="btnContainer" class="mb-5">
              <Button class="btnRouge" @click="deleteMoniteur()"
                >Supprimer</Button
              >
              <Button
                class="btnTurquoise"
                id="btnRetirer"
                @click="checkIfCoursExist"
                >Retirer Lieu</Button
              >
              <Button
                class="btnOrange"
                id="btnArchivage"
                @click="checkIfCoursExist"
                >Archiver</Button
              >
              <Button class="btnViolet" @click="patchUtilisateur()"
                >Modifier</Button
              >
            </div>
          </div>
        </div>
      </template>

      <template v-if="this.addForm === true && this.selectedItem === null">
        <div class="container-fluid">
          <h2 class="mb-5">Ajoutez un Moniteur</h2>
          <div class="row pt-4">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    type="email"
                    class="form-control"
                    id="fmail"
                    name="mail"
                    v-model="form.email"
                    required
                  />
                  <label>Email</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    type="text"
                    id="lpnom"
                    class="form-control"
                    name="pnom"
                    v-model="form.prenom"
                    required
                  />
                  <label>Prenom</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row pt-4">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    type="text"
                    id="lnom"
                    class="form-control"
                    name="nom"
                    v-model="form.nom"
                    required
                  />
                  <label for="lnom">Nom</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    type="text"
                    id="ltel"
                    name="tel"
                    class="form-control"
                    v-model="form.telephone"
                    required
                  />
                  <label for="ltel">Téléphone</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row pt-4">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    type="text"
                    id="adresse"
                    name="adresse"
                    class="form-control"
                    v-model="form.adresse"
                    required
                  />
                  <label for="adresse">Adresse</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    type="text"
                    id="codePostal"
                    class="form-control"
                    name="codePostal"
                    v-model="form.codePostal"
                    required
                  />
                  <label for="codePostal">codePostal</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row pt-4">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    type="text"
                    id="v"
                    name="ville"
                    class="form-control"
                    v-model="form.ville"
                    required
                  />
                  <label for="ville">ville</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    type="password"
                    id="lpass"
                    name="pass"
                    class="form-control"
                    v-model="form.password"
                  />
                  <label for="lpass">Password</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row pt-4">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <input
                    type="password"
                    id="lpass2"
                    class="form-control"
                    name="pass2"
                    v-model="form.passwordTest"
                    required
                  />
                  <label for="lpass2">Saisissez votre mdp à nouveau</label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="text-field">
                <div class="control">
                  <select
                    id="agence"
                    name="agence"
                    v-model="form.agence"
                    required
                  >
                    <option
                      v-for="(agence, index) in agences"
                      :value="agence.id"
                      :key="index"
                    >
                      {{ agence.nom }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div id="lieus">
            <DataTable
              :value="this.lieusRdv"
              :paginator="true"
              :rows="10"
              :rowsPerPageOptions="[10, 20, 50]"
              v-model:selection="selectedAddLieus"
              responsiveLayout="scroll"
              dataKey="id"
            >
              <Column selectionMode="multiple"></Column>
              <Column
                field="nom"
                header="nom"
                :sortable="true"
                style="min-width: 12rem"
              ></Column>
              <Column
                field="adresse"
                header="adresse"
                :sortable="true"
                style="min-width: 12rem"
              ></Column>
            </DataTable>
          </div>

          <Button class="btnViolet" @click="postUtilisateur()">Valider</Button>
        </div>
      </template>
    </div>

    <Dialog
      :header="this.titleModal"
      v-model:visible="displayCours"
      :style="{ width: '95vw' }"
    >
      <div class="row" style="place-content: center">
        <div class="col-12">
          <div>
            <template v-if="this.isArchive === true">
              <p>Choisir une date de départ pour {{ this.selectedItem.nom }}</p>
            </template>
            <template v-else>
              <p>
                A partir de quand ce moniteur ne sera plus disponible sur ce
                lieu ?
              </p>
            </template>

            <input
              type="date"
              onfocus="(this.type='date')"
              onblur="(this.type='text')"
              class="form-control"
              v-model="this.dateDepart"
            />

            <template v-if="this.isArchive === true">
              <Button
                class="btnOrange mt-3 mb-3"
                @click="filtreCoursPrevuByDate"
                >Valider</Button
              >
            </template>
            <template v-else>
              <Button
                class="btnOrange mt-3 mb-3"
                id="btnFiltreForRetirer"
                @click="filtreCoursPrevuByDate"
                >Valider</Button
              >
            </template>

            <DataTable
              :value="this.coursPrevu"
              :paginator="true"
              :rows="10"
              :rowsPerPageOptions="[10, 20, 50]"
              responsiveLayout="scroll"
              dataKey="id"
              v-model:selection="selectedCour"
              selectionMode="single"
            >
              <Column
                field="startFormated"
                header="debut"
                :sortable="true"
                style="min-width: 12rem"
              ></Column>

              <Column
                field="endFormated"
                header="fin"
                :sortable="true"
                style="min-width: 12rem"
              ></Column>

              <Column
                field="eleve"
                header="eleve"
                :sortable="true"
                style="max-width: 7rem"
              ></Column>
              <Column
                :exportable="false"
                header="mono actuel"
                style="min-width: 8rem"
              >
                <template #body="slotProps">
                  {{ slotProps.data.moniteur }}
                </template>
              </Column>
              <Column
                :exportable="false"
                header="lieu actuel"
                style="min-width: 8rem"
              >
                <template #body="slotProps">
                  {{ slotProps.data.lieu }}
                </template>
              </Column>
              <Column
                header="moniteur"
                :exportable="false"
                style="min-width: 8rem"
              >
                <template #body="slotProps">
                  <Dropdown
                    v-model="slotProps.data.moniteurObject"
                    :options="this.moniteursDispoForApointment"
                    @change="refreshLieuxForMoni(slotProps.data)"
                    @click="moniDisponible(slotProps.data)"
                    optionLabel="nom"
                    :placeholder="slotProps.data.moniteur"
                  />
                </template>
              </Column>
              <Column :exportable="false" header="lieu" style="min-width: 8rem">
                <template #body="slotProps">
                  <Dropdown
                    v-model="slotProps.data.lieuObject"
                    @click="LieusForMoniteurBase(slotProps.data)"
                    :options="this.lieuxFilteredForApointments"
                    optionLabel="nom"
                    :placeholder="slotProps.data.lieuObject.nom"
                  />
                </template>
              </Column>
              <Column
                field="telephoneEleve"
                header="telephone"
                :sortable="true"
                style="min-width: 12rem"
              ></Column>
              <Column
                :exportable="false"
                header="modifier"
                style="min-width: 8rem"
              >
                <template #body="slotProps">
                  <i
                    class="pi pi-arrow-right me-3"
                    @click="patchApointment(slotProps.data)"
                    style="font-size: 1.7rem"
                  ></i>
                </template>
              </Column>
              <Column :exportable="false" style="min-width: 8rem">
                <template #body="slotProps">
                  <i
                    class="pi pi-times me-3"
                    @click="getApointmentForDelete(slotProps.data)"
                    style="font-size: 1.7rem"
                  ></i>
                </template>
              </Column>
            </DataTable>
          </div>

          <div>
            <Button
              label="Fermer"
              icon="pi pi-check"
              @click="closeBasic"
              class="p-button-text"
            />
            <Button
              label="Supprimer"
              icon="pi pi-check"
              v-if="isArchive === true"
              @click="archiveMono()"
              class="p-button-text"
            />
            <Button
              v-else
              label="Supprimer"
              icon="pi pi-check"
              @click="deleteMoniteurLieu()"
              class="p-button-text"
            />
          </div>
        </div>
      </div>
    </Dialog>

    <Dialog
      :header="`Ajouter des lieux au mono`"
      v-model:visible="this.displayLieuxRdv"
      :style="{ width: '95vw' }"
    >
      <div class="row" style="place-content: center">
        <div class="col-12">
          <DataTable
            :value="this.lieusRdvForAjout"
            :rows="10"
            :rowsPerPageOptions="[10, 20, 50]"
            :paginator="true"
            responsiveLayout="scroll"
            v-model:filters="filters"
            filterDisplay="row"
            dataKey="id"
            v-model:selection="selectedLieuxModifMoni"
            @click="changeBackground"
          >
            <Column selectionMode="multiple"></Column>
            <Column
              field="nom"
              header="nom"
              :sortable="true"
              style="min-width: 6rem"
            ></Column>

            <Column
              field="adresse"
              header="adresse"
              :sortable="true"
              style="min-width: 6rem"
            ></Column>

            <Column
              field="codePostal"
              header="codePostal"
              :sortable="true"
              style="min-width: 6rem"
            ></Column>
            <Column
              field="ville"
              header="ville"
              :sortable="true"
              style="min-width: 6rem"
            ></Column>
          </DataTable>
          <Button
            class="btnViolet"
            @click="
              this.postMoniteursLieu(
                this.selectedItem.idMonos,
                this.selectedLieuxModifMoni
              )
            "
            >Ajouter</Button
          >
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { useToast } from "primevue/usetoast";
import moment from "moment";
import { FilterMatchMode } from "primevue/api";
import DropdownNav from "../../components/DropdownNav.vue";
export default {
  name: "App",
  component: {
    DropdownNav,
  },
  data() {
    return {
      toast: useToast(),
      selectedItem: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      // lieus sélectionné
      selectedLieu: null,
      // élement qui apparaitra dans la liste déroulante des lieux qui filtre les monos
      Tous: "Tous",
      // Titre de la modal des cours lorsqu'on archive un mono ou supprime un lieu
      titleModal: "",
      // Le lieu lié au mono sélectionné
      selectedMoniteurLieu: null,
      // Les lieus sélectionner pour l'ajout au mono
      selectedAddLieus: null,
      selectedLieuxModifMoni: null,
      // les monos filtrer par lieu
      moniteursFilteredByLieuRdv: [],
      // les lieux lié au mono sélectionner lors de la modif des cours
      lieuxFilteredForApointments: [],
      // Tout les cours prévu après la date d'aujourd'hui ou apres this.dateDepart
      mesCoursprevu: [],
      displayCours: false,
      displayLieuxRdv: false,
      // Booleen qui va me permettre de savoir si on se situe dans l'archivage d'un mono
      isArchive: false,
      // Les monos filtrer dispo pour un cours qui va être annulé ou modifié
      moniteursDispoForApointment: [],
      moniteur: {},
      addForm: false,
      agences: {},
      // La date qui va filtrer les cours prévues
      dateDepart: Date(),
      form: {
        utilisateurid: 0,
        email: "",
        nom: "",
        telephone: "",
        password: "",
        passwordTest: "",
        adresse: "",
        codePostal: "",
        ville: "",
        agence: null,
        active: 1,
        role_id: 4,
      },
      error: "",
    };
  },
  mounted() {
    this.getMoniteurs();
    this.getAgences();
    this.getLieusRdv();
  },
  methods: {
    ...mapActions(["getMoniteurs", "getLieusRdv"]),
    filterLieuxForAddToMoni() {
      this.displayLieuxRdv = true;
    },
    // on récupère les lieus qu'on va filtrer par lieu de RDV

    // Le filtre des moniteurs par lieu
    filterMoniteursByLieuRdv() {
      console.log("mono", this.moniteurs);
      if (this.selectedLieu === "Tous") {
        this.moniteursFilteredByLieuRdv = this.moniteurs;
      } else {
        this.moniteursFilteredByLieuRdv = this.moniteurs.filter((m) =>
          m.lieux.find((ml) => ml.lieuId === this.selectedLieu)
        );
      }
    },
    // vérifie la disponibilité d'un mono pour un cours prévu
    moniDisponible(apoitment) {
      // Je transforme les dates du rdv en date
      apoitment.start.date = new Date(apoitment.startFormated);
      apoitment.end.date = new Date(apoitment.endFormated);
      // Je transforme les dates du rdv et des indispos des moniteurs en date pour pouvoir comparer
      this.moniteurs.forEach((moniteur) => {
        if (moniteur.rdv != []) {
          moniteur.rdv.forEach((rdv) => {
            rdv.start.date = new Date(rdv.startFormated);
            rdv.end.date = new Date(rdv.endFormated);
          });
        }
        if (moniteur.indispos != []) {
          moniteur.indispos.forEach((indis) => {
            indis.start.date = new Date(indis.startFormated);
            indis.end.date = new Date(indis.endFormated);
          });
        }
      });
      console.log("momant", apoitment.start.date.getTime());
      console.log("leslieux", this.lieusRdv);
      // Je crée un tableau dans lequel je stock mes moniteurs non dispo pour le rdv
      let moniteursNonDispo = [];
      // Pour qu'un mono ne sois pas dispo, il faut qu'un cours ou une insispo se passe au meme moment voici la logique dessous
      this.moniteurs.forEach((m) => {
        if (
          m.rdv.find(
            (rdv) =>
              (rdv.start.date.getTime() >= apoitment.start.date.getTime() &&
                rdv.start.date.getTime() < apoitment.end.date.getTime()) ||
              (rdv.end.date.getTime() > apoitment.start.date.getTime() &&
                rdv.end.date.getTime() <= apoitment.end.date.getTime())
          ) ||
          m.indispos.find(
            (indis) =>
              (indis.start.date.getTime() >= indis.start.date.getTime() &&
                indis.start.date.getTime() < indis.end.date.getTime()) ||
              (indis.end.date.getTime() > indis.start.date.getTime() &&
                indis.end.date.getTime() <= indis.end.date.getTime())
          )
        ) {
          moniteursNonDispo.push(m);
        }
      });
      // On trie les monos dispo par agences
      this.moniteursDispoForApointment = this.moniteurs.filter(
        (m) =>
          m.agence == "null" || m.agence.id === apoitment.eleveObjet.agence.id
      );
      // On récup les monos dispo en enlevant les monos non dispo de ceux qui sont dispos
      this.moniteursDispoForApointment =
        this.moniteursDispoForApointment.filter(
          (md) => !moniteursNonDispo.find((ts) => ts.idMonos === md.idMonos)
        );
      console.log("test2", this.moniteursDispoForApointment);
    },
    refreshLieuxForMoni(moniteur) {
      // Je recherche les lieux lié au mono sélectionné
      let tab = this.lieusRdv.filter((l) =>
        l.moniteurLieus.find(
          (ml) =>
            ml.moniteur !== undefined &&
            ml.moniteur.id === moniteur.moniteurObject.idMonos
        )
      );
      this.lieuxFilteredForApointments = tab;
    },
    // la modification d'un cours
    patchApointment(apointment) {
      window.api
        .patch(
          `apointments/` + apointment.id,
          {
            moniteur: "api/moniteurs/" + apointment.moniteurObject.idMonos,
            lieu: "api/lieus/" + apointment.lieuObject.id,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then((response) => {
          this.toast.add({
            severity: "success",
            summary: "cours modifié",
            detail: "ce cours a été modifié avec succès",
            life: 3000,
          });
          console.log("completed", response.data);
        })
        .catch((error) => {
          // handle authentication and validation errors here
          console.log("ERRR::", error.response.data);
        });
    },

    // je récup les agences et j'ajoute un élement non ratacher dans la liste pour qu'il aparraisse dans la liste déroulante
    getAgences() {
      window.api
        .get("/agences")
        .then((response) => {
          this.agences = response.data["hydra:member"];
          let nonRatacher = { nom: "non ratacher" };
          this.agences.push(nonRatacher);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    // je filtre les lieus par lieus de RDV
    filterLieuxOnlyRDV() {
      this.lieusRdvForAjout = this.lieusRdv.filter((l) => l.nom !== "Tous");
    },
    closeBasic() {
      this.displayCours = false;
      this.displayDateDepart = false;
    },
    changeBackground() {
      if (this.selectedItem != null) {
        document.getElementById("right").style.background = "none";
        this.filterLieuxOnlyRDV();
      }
    },
    openAddForm() {
      this.selectedItem = null;
      this.addForm = true;
      document.getElementById("right").style.background = "none";
    },
    // ajout d'un moniteur
    async postUtilisateur() {
      if (this.form.telephone.length > 10) {
        this.form.error =
          "Veuillez saisir un numéro de téléhone inférieur à 10";
      } else if (this.form.password != this.form.passwordTest) {
        this.error =
          "Veuillez saisir les mêmes mots de passe dans les champs correspondants";
      } else {
        this.error = "";
        window.api
          .post("/utilisateur", this.form, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            this.form.utilisateurid = response.data.utilisateur[0].id;
            this.postmoniteur();
            console.log("completed", response.data);
          })
          .catch((error) => {
            // handle authentication and validation errors here
            console.log("ERRR::", error.response.data);
          });
      }
    },
    postmoniteur() {
      window.api
        .post(
          "/moniteurs",
          {
            utilisateur: "/api/utilisateurs/" + this.form.utilisateurid,
            adresse: this.form.adresse,
            codePostal: this.form.codePostal,
            ville: this.form.ville,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.toast.add({
            severity: "success",
            summary: "Moniteur ajouté",
            detail: "Votre Moniteur à été ajouté avec succès",
            life: 3000,
          });
          this.postMoniteursLieu(response.data.id, this.selectedAddLieus);
          console.log("moniteur", response.data);
        })
        .catch((error) => {
          // handle authentication and validation errors here
          console.log("ERRR::", error.response.data);
        });
    },
    async postMoniteursLieu(id, selectedLieux) {
      try {
        window.api
          .post("/moniteurLieus/" + id, selectedLieux, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((res) => {
            this.toast.add({
              severity: "success",
              summary: "Lieu ajouté",
              detail: "Vos Lieu ont été ajouté avec succès",
              life: 3000,
            });
            this.$emit("completed", res.data);

            console.log("res", res.data);
          });
      } catch (e) {
        // handle authentication error here
      }
      this.displayCours = false;
    },

    // Suppression d'un mono
    async deleteMoniteur() {
      try {
        if (confirm("Voulez vous vraiment supprimer ?")) {
          window.api
            .delete("moniteurs/" + this.selectedItem.idMonos, {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            })
            .then((response) => {
              this.toast.add({
                severity: "success",
                summary: "Moniteur supprimé",
                detail: "Votre Moniteur à été supprimé avec succès",
                life: 3000,
              });
              this.moniteursFilteredByLieuRdv =
                this.moniteursFilteredByLieuRdv.filter(
                  (m) => m.idMonos !== this.selectedItem.idMonos
                );
              console.log(response);
            });
        }
      } catch (e) {
        // handle authentication error here
      }
    },

    async filtreCoursPrevuByDate(e) {
      this.dateDepart = moment(String(this.dateDepart)).format("MM-DD-YYYY");

      if (e.target.id == "btnFiltreForRetirer") {
        this.coursPrevu = this.selectedItem.rdv.filter(
          (cp) =>
            cp.end.date >= this.dateDepart &&
            cp.motif === "null" &&
            cp.lieu === this.selectedMoniteurLieu.nom
        );
      } else {
        this.coursPrevu = this.selectedItem.rdv.filter(
          (cp) => cp.end.date >= this.dateDepart && cp.motif === "null"
        );
      }

      this.dateDepart = moment(String(this.dateDepart)).format("DD-MM-YYYY");
      console.log("dateDep", this.dateDepart);
    },



    async LieusForMoniteurBase(moniteur) {
      console.log("moniii", moniteur)
      let tab = this.lieusRdv.filter((l) =>
        l.moniteurLieus.find(
          (ml) =>
            ml.moniteur !== undefined &&
            ml.moniteur.id === moniteur.moniteurObject.idMonos
        )
      );
      this.lieuxFilteredForApointments = tab;
    },

    async checkIfCoursExist(e) {
      // je fais cette condition pour que lorsque l'admin ferme le pop up et le réouvre il ne retrouve pas la même chose car c'est le meme pop up pour retirer les lieus et archiver un mono
      if (this.displayCours === false) {
        this.coursPrevu = null;
      }
      this.dateDepart = new Date();
      this.dateDepart = moment(String(this.dateDepart)).format("MM-DD-YYYY");
      // Lorsque l'utilisateur clique sur le bouton archiver
      // Si il clique sur le bouton archiver , que le moniteur a plus de un rdv et qu'il a au moins un cours apres la date de départ qui est la date d'aujourd'hui
      if (
        e.target.id == "btnArchivage" &&
        this.selectedItem.rdv.length > 0 &&
        this.selectedItem.rdv.find(
          (rdv) => new Date(rdv.end.date) >= new Date(this.dateDepart)
        )
      ) {
        // titre de la modal qui change en archiver, on affiche le pop up,
        this.titleModal = "Archiver moniteur";
        this.isArchive = true;
        this.displayCours = true;
      } else if (e.target.id == "btnArchivage") {
        // Sinon on archive directement
        this.archiveMono();
      }
      // Lorsque l'utilisateur clique sur le bouton retirer , qu'il a au moins un rdv prévue et que le lieu correspond au lieu sélectionné
      if (
        e.target.id == "btnRetirer" &&
        this.selectedItem.rdv.find(
          (rdv) =>
            new Date(rdv.end.date) >= new Date(this.dateDepart) &&
            rdv.lieu === this.selectedMoniteurLieu.nom
        )
      ) {
        // Le titre change, le booleen is archive devient faux et on affiche le pop up
        this.titleModal = "Retirer Moniteur";
        this.isArchive = false;
        this.displayCours = true;
      } else if (e.target.id == "btnRetirer") {
        // Sinon on retire
        this.deleteMoniteurLieu(this.selectedMoniteurLieu.id);
      }
      // On reformate pour pouvoir refaire un trie
      this.selectedItem.rdv.forEach((cp) => {
        cp.start.date = moment(String(cp.start.date)).format(
          "MM-DD-YYYY hh:mm"
        );
        cp.end.date = moment(String(cp.end.date)).format("MM-DD-YYYY hh:mm");
      });

    },

    async deleteMoniteurLieu() {
      if (
        this.selectedItem.rdv.length > 0 &&
        this.selectedItem.rdv.find(
          (rdv) =>
            new Date(rdv.end.date) >= new Date(this.dateDepart) &&
            rdv.lieu === this.selectedMoniteurLieu.nom
        )
      ) {
        let mesCoursprevu = [];
        this.coursPrevu.forEach((cp) => {
          find(mesCoursprevu.push(cp));
        });
        this.postMailApointmentDisabled(mesCoursprevu);

        try {
          if (confirm("voulez vous vraiment supprimer ce lieu?")) {
            window.api
              .delete("moniteur_lieus/" + this.selectedMoniteurLieu.id, {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              })
              .then((response) => {
                this.toast.add({
                  severity: "success",
                  summary: "Lieu retiré",
                  detail: "Votre lieu à été retiré de ce moniteur avec succès",
                  life: 3000,
                });
                this.selectedItem.lieux = this.selectedItem.lieux.filter(
                  (l) => l.lieuId !== this.selectedMoniteurLieu.lieuId
                );
                this.displayCours = false;
                console.log(response);
              });
          }
        } catch (e) {
          // handle authentication error here
        }
      } else {
        try {
          if (
            confirm(
              "Ce lieu ne contient pas de rendez-vous, voulez vous vraiment supprimer ?"
            )
          ) {
            window.api
              .delete("moniteur_lieus/" + this.selectedMoniteurLieu.id, {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              })
              .then((response) => {
                this.toast.add({
                  severity: "success",
                  summary: "Lieu retiré",
                  detail: "Votre lieu à été retiré de ce moniteur avec succès",
                  life: 3000,
                });
                this.selectedItem.lieux = this.selectedItem.lieux.filter(
                  (l) => l.lieuId !== this.selectedMoniteurLieu.lieuId
                );

                this.getLieusRdv();
                console.log("asa", this.selectedMoniteurLieu);
                console.log("dzdz", this.selectedItem.lieux);
                this.displayCours = false;
                console.log(response);
              });
          }
        } catch (e) {
          // handle authentication error here
        }
      }
    },
    async postMailApointmentDisabled(mesCoursprevu) {
      if (confirm("Voulez vous supprimer ce/ces cours?")) {
        try {
          console.log("idcours", mesCoursprevu);
          window.api
            .post("/mailIfLieuDisabled", mesCoursprevu, {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            })
            .then((res) => {
              this.deleteApointments(mesCoursprevu);
              this.$emit("completed", res.data);
              console.log("res", res);
            });
        } catch (e) {
          // handle authentication error here
        }
      }
    },
    async getApointmentForDelete(apointment) {
      console.log("apo", apointment);
      console.log("apoa", this.coursPrevu);
      this.mesCoursprevu.push(apointment);
      this.coursPrevu = this.coursPrevu.filter((cp) => cp.id !== apointment.id);
      console.log("apoa", this.coursPrevu);
      this.postMailApointmentDisabled(this.mesCoursprevu);
    },
    archiveMono() {
      if (
        this.selectedItem.rdv.length > 0 &&
        this.selectedItem.rdv.find(
          (rdv) => new Date(rdv.end.date) >= new Date(this.dateDepart)
        )
      ) {
        this.coursPrevu.forEach((cp) => {
          find(this.mesCoursprevu.push(cp));
        });
        this.postMailApointmentDisabled(this.mesCoursprevu);
      }
      if (confirm("voulez vous archiver ce mono ?")) {
        window.api
          .patch(
            `utilisateur/` + this.selectedItem.id,
            {
              active: 0,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then((response) => {
            this.$emit("completed", response.data);
            this.moniteursFilteredByLieuRdv =
              this.moniteursFilteredByLieuRdv.filter(
                (m) => m.idMonos !== this.selectedItem.idMonos
              );
            this.toast.add({
              severity: "success",
              summary: "Moniteur archivé",
              detail: "Votre Moniteur à été archivé avec succès",
              life: 3000,
            });
          })
          .catch(function (error) {
            console.log(error.response);
          });
      }
    },
    async deleteApointments(mesCoursprevu) {
      console.log("aa", this.coursPrevu);
      console.log("mescc", mesCoursprevu);

      try {
        window.api
          .post("/deleteApointments", mesCoursprevu, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            this.toast.add({
              severity: "success",
              summary: "rendez-vous supprimé",
              detail: "les rendez vous ont été supprimé avec succès",
              life: 3000,
            });
            this.coursPrevu = this.coursPrevu.filter((c) =>
              mesCoursprevu.find((mc) => c.id !== mc.id)
            );
            console.log(response);
          });
      } catch (e) {
        // handle authentication error here
      }
    },
    async putMoniteurLieu(MoniteurLieu) {
      try {
        console.log("selectedMo", MoniteurLieu);
        window.api
          .put("/moniteur_lieus/" + MoniteurLieu.id, MoniteurLieu, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((res) => {
            this.toast.add({
              severity: "success",
              summary: "Lieu changé",
              detail: "L'etat de votre lieu à été changé avec succès",
              life: 3000,
            });
            this.$emit("completed", res.data);
          });
      } catch (e) {
        // handle authentication error here
      }
    },

    patchUtilisateur() {
      if (this.selectedItem.agence.nom === "non ratacher") {
        this.selectedItem.agence.id = "";
      }
      window.api.patch(
        `utilisateur/` + this.selectedItem.id,
        {
          email: this.selectedItem.email,
          nom: this.selectedItem.nom,
          prenom: this.selectedItem.prenom,
          agence: this.selectedItem.agence.id,
          telephone: this.selectedItem.telephone,
        },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        }
      ) &&
        window.api
          .put(`moniteurs/` + this.selectedItem.idMonos, this.selectedItem, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            this.$emit("completed", response.data);
            this.toast.add({
              severity: "success",
              summary: "Moniteur modifié",
              detail: "Votre Moniteur à été modifié avec succès",
              life: 3000,
            });
          })
          .catch(function (error) {
            console.log(error.response);
          });
    },
  },
  computed: {
    moniteurs() {
      return this.$store.state.moniteurs.filter(
        (m) =>
          m.active === true && m.agence.id == this.$store.state.user.agence.id
      );
    },
    lieusRdv() {
      return this.$store.state.lieusRdv;
    },
  },
  components: { DropdownNav },
};
</script>

<style scoped>
#lieus {
  border-top: 1px solid black;
}
#conteneurModif {
  display: flex;
  flex-direction: column;
}
#btnContainer {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
#iconHome {
  cursor: pointer;
}
.btnRouge {
  background: #ec2b22;
  border-radius: 13px;
  border: none;
}
.btnOrange {
  background: lightsalmon;
  border-radius: 13px;
  border: none;
}
.btnTurquoise {
  background: #07989f;
  border-radius: 13px;
  border: none;
}
.btnViolet {
  background: #de6fa8;
  border-radius: 13px;
  border: none;
}
a {
  text-decoration: none;
}
#formModif {
  margin: auto;
  width: 100%;
}
#head {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 5%;
  align-content: space-around;
  width: 50%;
}
#add {
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  top: 40px;
}
#titreRight {
  flex-wrap: wrap;
  font-size: 2rem;
  color: white;
}
#styleTitreRight {
  background-color: rgb(255, 255, 255, 0.47);
  padding: 1%;
  border-radius: 13px;
}
#left {
  background-image: url("../../assets/images/fondd.png");
  background-size: cover;
  border-right: 1px solid black;
}
#right {
  width: 100%;
  background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#titreLeft {
  font-size: 25pt;
  font-weight: bold;
  color: #2e9ea3;
}
#conteneur {
  display: grid;
  grid-template-columns: 50% 50%;
}
#conteneur div {
  justify-content: space-evenly;
}
.text-field,
text {
  position: relative;
  margin: 10px 2.5px 20px 2.5px;
}
input {
  display: inline-block;
  border: thin solid #fafafa;
  color: #444;
  background-color: #fafafa;
  padding: 10px 10px 10px 10px;
}
input:focus {
  border: thin solid #ffaa71;
  border-bottom: solid medium #ffaa71;
  background-color: #fff;
}
label {
  color: #999;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 10px;
  transition: 0.2s;
}
input:focus ~ label,
input:valid ~ label {
  top: -10px;
  left: 15px;
  font-size: small;
  color: #167794;
  background-color: #fff;
  padding: 0 5px 0 5px;
}
</style>
