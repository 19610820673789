<template>
  <div id="btnGroupDossierPaiement" class="btn-group">
    <template v-if="$store.state.isWorkingCB === false">
      <button type="button" class="mt-4 btn btn-outline-primary submit" @click="paiement('cb')">
        Paiement par CB
      </button>
    </template>
    <template v-else-if="$store.state.isWorkingCB">
      <button type="button" class="mt-4 btn btn-outline-primary submit" disabled>
        <i class="fa fa-spinner fa-spin"></i> Paiement par CB
      </button>
    </template>
    <template v-else-if="
      $store.state.isWorkingCB === null &&
      !$store.state.isWorkingCPF &&
      !$store.state.isWorkingAutre
    ">
      <button type="button" class="mt-4 btn btn-primary submit" disabled>
        Paiement par CB
      </button>
    </template>
    <template v-else>
      <button type="button" class="mt-4 btn btn-outline-primary submit" disabled>
        Paiement par CB
      </button>
    </template>

    <template v-if="!messageAutre">
      <template v-if="$store.state.isWorkingCPF === false">
        <button type="button" class="mt-4 btn btn-outline-primary submit" @click="paiement('cpf')">
          Paiement par CPF ou Pole Emploi
        </button>
      </template>
      <template v-else-if="$store.state.isWorkingCPF">
        <button type="button" class="mt-4 btn btn-outline-primary submit" disabled>
          <i class="fa fa-spinner fa-spin"></i> Paiement par CPF ou Pole Emploi
        </button>
      </template>
      <template v-else-if="
        $store.state.isWorkingCPF === null &&
        !$store.state.isWorkingCB &&
        !$store.state.isWorkingAutre
      ">
        <button type="button" class="mt-4 btn btn-primary submit" disabled>
          Paiement par CPF ou Pole Emploi
        </button>
      </template>
      <template v-else>
        <button type="button" class="mt-4 btn btn-outline-primary submit" disabled>
          Paiement par CPF ou Pole Emploi
        </button>
      </template>

      <template v-if="$store.state.isWorkingAutre === false">
        <button type="button" class="mt-4 btn btn-outline-primary submit" @click="paiement('autre')">
          Autres modes de paiement
        </button>
      </template>
      <template v-else-if="$store.state.isWorkingAutre">
        <button type="button" class="mt-4 btn btn-outline-primary submit" disabled>
          <i class="fa fa-spinner fa-spin"></i> Autres modes de paiement
        </button>
      </template>
      <template v-else-if="
        $store.state.isWorkingAutre === null &&
        !$store.state.isWorkingCB &&
        !$store.state.isWorkingCPF
      ">
        <button type="button" class="mt-4 btn btn-primary submit" disabled>
          Autres modes de paiement
        </button>
      </template>
      <template v-else>
        <button type="button" class="mt-4 btn btn-outline-primary submit" disabled>
          Autres modes de paiement
        </button>
      </template>
    </template>
  </div>
  <template v-if="!messageAutre">
    <p>{{ this.$store.state.messagePaiement }}</p>
  </template>
  <template v-else-if="this.$store.state.messagePaiement.length != 0">
    <p>
      {{
  "Si vous souhaitez effectuer le payement par CPF ou par un autre moyen, m" +
    this.$store.state.messagePaiement.slice(1)
      }}
    </p>
  </template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

// this.$store.dispatch("startSaveBouton", mode);
//
// let router = this.$router;
// this.$store.dispatch("endSaveBouton", { mode, fonctionnalite, router });


// Pour chaque bouton

// Si isWorking === false
//// outline cliquable

// Si isWorking === true
//// outline spinner

// Si isWorking === null && les autres isWorking === false
//// disabled

// Sinon
//// outline disabled


export default {
  name: "BoutonFormulaire",
  props: {
    messageAutre: { type: Boolean },
  },
  data() {
    return {};
  },
  mounted() {
    // this.checkEtatBoutons();
  },
  methods: {
    ...mapActions([]),
    checkEtatBoutons() {
      console.log(this.$store.state.isWorkingAutre, this.$store.state.isWorkingCB, this.$store.state.isWorkingCPF);
    },

    paiement(mode) {
      // Lance la fonction de la template précédente qui ammène au paiement
      this.$emit("paiement", mode);
    },
  },
  computed: {
    ...mapGetters([]),
  },
};
</script>

<style scoped>
#btnGroupDossierPaiement>button {
  flex: 1;
}
</style>
