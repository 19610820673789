<template>
  <div v-if="load < 2">
    <Loader/>
  </div>
  <div v-else class="background">
    <Header :eleves="eleves" :moniteurs="moniteurs" @loadEleve="testCallEmit" @setupDispo="setupDispo" @changeDisplayApointment="changeDisplayApointment" @getEleves="getEleves" @cancelEleveChoix = "cancelEleveChoix"/>
    <div class="calendar">
        <Calendar :eleves="eleves" :moniteurs="moniteurs" ref="headRef" @getEleves="getEleves" @getMoniteurs="getMoniteurs"/>
    </div>
    
  </div>
  
</template>

<script>
import Calendar from "@/components/agence/planning/Calendar.vue";
import Header from "@/components/agence/planning/Header.vue";
import Loader from "@/components/Loader.vue";
import { ref } from 'vue'


export default {

  setup(){
    const headRef = ref();
    const setupDispo = (test) => headRef.value.displayDispo(test);
    const changeDisplayApointment = (display) => headRef.value.changeDisplayApointment(display);
    const cancelEleveChoix = () => headRef.value.cancelEleveChoix();

    return{
      headRef,
      setupDispo,
      changeDisplayApointment,
      cancelEleveChoix
    }
  },

  components: {
    Calendar,
    Header,
    Loader
  },

  mounted() {
    this.getEleves();
    this.getMoniteurs();
  },

  data() {
    return {
      eleves : [],
      load : 0,
      moniteurs: [],
    }
  },
  methods: {

    testCallEmit(){
      console.log("premier console")
      
      
      //this.Calendar.testCs();
    },

    getEleves(){
      window.api.get("/eleves/agence/planning/" + this.$store.state.user.agence.id, {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token
          },
        }).then(response => {
          this.eleves = response.data.eleves;
          this.load++;
        }).catch(error => {
          console.log(error)
        })
    },

    getMoniteurs(){
      window.api.get("/moniteurs/agence/planning/" + this.$store.state.user.agence.id + "/" + this.$store.state.user.id, {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token
          },
        }).then(response => {
          this.moniteurs = response.data.moniteurs;
          this.$store.commit("setMoniteursPlanningAgence", this.moniteurs);
          this.$store.commit("setMoniteursPlanningAgenceArchive", response.data.unMoniteurs);
          this.$store.commit("setLieuxMonosPlanning", response.data.lieux);
          this.$store.commit("setGroupeLieuPlanning", response.data.groupe);
          this.load++;
        }).catch(error => {
          console.log(error)
        })
    }

  },
};
</script>

<style scoped>
div.background {
  height: 100vh;
  width: 100%;
  background-image: url("../../assets/images/Fond_ordinateur.png");
  background-size: cover;
}

div.calendar{
    width: 90%;
    margin: auto;
}
</style>
