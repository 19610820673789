<template>
	<div id="paiement">
		<template v-if="checkLoad">
			<div class="card text-center shadow-lg">
				<div class="card-header">
					<h1>Paiement</h1>
				</div>
				<div id="paiement-body" class="card-body">
					<div id="paiement-body-sub">
						<button v-on:click="reloadPagePaiement()" class="btn btn-primary">RETOUR</button>
						<h2>Montant : {{ filterFonctionnalite.montant }} €</h2>
						<div id="adresseFacturation">
							<div>
								<div>
									<label for="adresse" class="form-label">Adresse de facturation :</label>
									<input v-model="adresseFacturation.adresse" type="text" id="adresse" name="adresse"
										class="form-control">
								</div>
								<div>
									<label for="cp" class="form-label">Code Postal :</label>
									<input v-model="adresseFacturation.cp" type="text" id="cp" name="cp"
										class="form-control">
								</div>
								<div>
									<label for="ville" class="form-label">Ville :</label>
									<input v-model="adresseFacturation.ville" type="text" id="ville" name="ville"
										class="form-control">
								</div>
							</div>
						</div>
					</div>
					<div id="paiement-body-button">
						<template v-if="!redirectBtn">
							<template v-if="!validerPaiementClick">
								<button type="button" class="btn btn-primary"
									@click="validerPaiement()">Valider</button>
							</template>
							<template v-else>
								<button type="button" class="btn btn-primary" disabled><i
										class="fa fa-spinner fa-spin"></i> Valider</button>
							</template>
						</template>
						<template v-else>
							<div v-html="redirectBtn"></div>
						</template>
					</div>
				</div>
				<div class="card-footer text-muted"></div>
			</div>
		</template>

		<template v-else>
			<Loader />
		</template>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from '@/components/Loader.vue';

export default {
	name: 'Paiement',
	beforeCreate() {
		// Vérifie qu'il y a bien l'information de la fonctionnalité à payer dans la route
		// Cela empeche l'utilisateur de revenir via l'historique pour repayer une fonctionnalité déjà payée
		// Si l'information n'existe pas
		if (this.$route.params.fonctionnalite === undefined) {
			// Alors retour au menu home
			this.$router.push({ name: 'Home' });
		}
	},
	components: {
		Loader
	},
	data() {
		return {
			adresseFacturation: {},
			validerPaiementClick: false,
			redirectBtn: "",
		}
	},
	mounted() {
		this.loadAdresseFacturation();
		this.getFonctionnalite();
	},
	methods: {
		...mapActions([
			"getFonctionnalite",
		]),
		async loadAdresseFacturation() {
			// Télécharge l'adresse de facturation
			try {
				// Si l'adresse de facturation n'est pas téléchargée
				if (this.$store.state.adresseFacturation === null) {
					// Lance le téléchargement
					window.api.get("/utilisateur/adresse_facturation/" + this.$store.state.user.id, {
						headers: {
							"Authorization": "Bearer " + this.$store.state.token
						}
					}).then(response => {
						// Enregistre l'adresse de facturation dans les données local
						this.adresseFacturation = response.data.AdresseFacturation[0];
						// Enregistre l'adresse de facturation dans le store
						this.$store.commit("setAdresseFacturation", response.data.AdresseFacturation[0]);
					});
				} else {
					// Sinon met l'adresse de facturaction du store dans les données local
					this.adresseFacturation = this.$store.state.adresseFacturation;
				}
			} catch (e) {
				// En cas d'erreur, retourne au menu home
				this.$router.push({ name: 'Home' });
			}
		},
		reloadPagePaiement() {
			// window.onpopstate = (event) => {
			// 	this.$router.push({
			// 		path: "/paiement"
			// 	});
			// }
			window.location.reload()
			this.$router.push({ name: 'Home' })


		},
		validerPaiement() {
			// Si les fonctionnalités sont chargées
			if (this.fonctionnalite !== null) {
				// Indique qu'un paiement est en cours de validation
				this.validerPaiementClick = true;
				// Si l'adresse de facturation n'a pas d'id, donc elle n'existe pas en base de données
				if (this.adresseFacturation.id === undefined) {
					// Enregistre l'adresse en base de données
					window.api.post("/adresse_facturations", {
						adresse: this.adresseFacturation.adresse,
						ville: this.adresseFacturation.ville,
						cp: this.adresseFacturation.cp,
						utilisateur: "/api/utilisateurs/" + this.$store.state.user.id,
					}, {
						headers: {
							"Authorization": "Bearer " + this.$store.state.token,
						}
					}).then(response => {
						// Place l'adresse dans le store
						this.$store.state.adresseFacturation = response.data;
						// Lance la création du paiement
						this.postPaiement();
					}).catch(error => {
						// Indique qu'un paiement n'est plus en cours de validation
						this.validerPaiementClick = false;
						console.log(error);
					})
				} else {
					// Met à jour l'adresse en base de données
					window.api.patch("/adresse_facturations/" + this.$store.state.adresseFacturation.id, {
						adresse: this.adresseFacturation.adresse,
						ville: this.adresseFacturation.ville,
						cp: this.adresseFacturation.cp,
					}, {
						headers: {
							"Authorization": "Bearer " + this.$store.state.token,
							"Content-Type": "application/merge-patch+json"
						}
					}).then(response => {
						// Place l'adresse dans le store
						this.$store.state.adresseFacturation = response.data;
						// Lance la création du paiement
						this.postPaiement();
					}).catch(error => {
						// Indique qu'un paiement n'est plus en cours de validation
						this.validerPaiementClick = false;
						console.log(error);
					})
				}
			}
		},
		postPaiement() {
			// Lance la création du paiement
			window.api.post("/paiement/" + this.$store.state.user.id, {
				montant: this.filterFonctionnalite.montant,
				description: this.filterFonctionnalite.label,
			}, {
				headers: {
					"Authorization": "Bearer " + this.$store.state.token
				}
			}).then(response => {
				// Place le bouton retourner en html dans les données local qui sont affichées dans la page
				this.redirectBtn = response.data;
			}).catch(error => {
				// Indique qu'un paiement n'est plus en cours de validation
				this.validerPaiementClick = false;
				console.log(error);
			})
		}
	},
	computed: {
		...mapGetters([
			"fonctionnalite",
			"dossier",
		]),
		checkLoad() {
			return (
				this.fonctionnalite !== null
			);
		},
		filterFonctionnalite() {
			// Vérifie qu'il y a bien l'information de la fonctionnalité à payer dans la route
			// Cela empeche l'utilisateur de revenir via l'historique pour repayer une fonctionnalité déjà payée
			// Si l'information n'existe pas
			if (this.$route.params.fonctionnalite !== undefined) {
				// Si les fonctionnalités sont chargées
				if (this.fonctionnalite !== null) {
					// Si le paramètre de route est le solde
					if (this.$route.params.fonctionnalite === "solde") {
						// Récupère la fonctionnalité dans la liste des fonctionnalités
						let tmpFonctionnalite = this.fonctionnalite.filter(el => el.label === this.$route.params.fonctionnalite)[0];
						// Remplace le montant par le montant en paramètre de la route
						tmpFonctionnalite.montant = this.$route.params.montant;
						// Retourne l'objet personnalisé
						return tmpFonctionnalite;
					}
					// Retourne l'objet récupéré dans la liste des fonctionnalités
					return this.fonctionnalite.filter(el => el.label === this.$route.params.fonctionnalite)[0];
				}
			}
			// Retourne un objet vide pour évité les erreurs
			return {
				montant: "",
				label: "",
			};
		}
	}
}
</script>

<style>
#paiement {
	padding: 3rem 20% 0 20%;
}

#paiement-body {
	display: flex;
	flex-direction: column;
	align-items: center;
}

#paiement-body-sub {
	width: max-content;
	margin-bottom: 1rem;
}

#paiement-body-button p {
	margin: 0 !important;
	padding: 0 !important;
}

#adresseFacturation>div {
	display: flex;
	flex-direction: column;
	align-items: center;
}

#adresseFacturation>div>div {
	text-align: left;
	min-width: 20rem;
}
</style>