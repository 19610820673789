<template>
  <div id="conteneur">
    <template v-if="checkLoad">
      <div id="Dossier">
        <img
          @click="goToHome()"
          :src="require('../../../assets/images/logoMonPermis.png')"
        />
        <div>
          <h1 id="titreDossier">Dossier</h1>
          <div
            class="heightPiece"
            v-for="item in $store.state.dossier.pieceJustificatives"
            :key="item.id"
          >
            <div
              id="pieceBack"
              @click="selectPiece(item)"
              :class="{ selected: item === pieceSelected }"
            >
              <div class="piece">
                <h2 class="labelFichier">
                  {{ item.typePieceJustificative.label }}
                </h2>
                <div class="descriptionTypePieceJustificativeContainer">
                  <img
                    class="iconInfo"
                    :title="item.typePieceJustificative.description"
                    :src="require('../../../assets/images/iconinfo.png')"
                    @click="
                      openModalDescription(
                        item.typePieceJustificative.description
                      )
                    "
                  />
                </div>
                <template v-if="item.etat"><p id="statut">Accepté</p></template>
                <template v-else-if="item.fichier != null">
                  <template v-if="item.fichier.length === 0"
                    ><p id="statut">Incomplet</p></template
                  >
                  <template
                    v-else-if="item.fichier.slice().reverse()[0].etat === null"
                    ><p id="statut">En cours de traitement</p></template
                  >
                  <template
                    v-else-if="item.fichier.slice().reverse()[0].etat === false"
                    ><p id="statut">Refusé</p></template
                  >
                  <template v-else><p id="statut">Incomplet</p></template>
                </template>
                <template v-else>
                  <template v-if="item.lastFichierEtat === null">
                    <p id="statut">Incomplet</p>
                  </template>
                  <template v-else-if="item.lastFichierEtat.etat === null">
                    <p id="statut">En cours de traitement</p>
                  </template>
                  <template v-else-if="item.lastFichierEtat.etat === false">
                    <p id="statut">Refusé</p>
                  </template>
                  <template v-else>
                    <p id="statut">Incomplet</p>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="depotPieceJointe"
        :class="{
          depotPieceJointeStart:
            (pieceSelected !== null &&
              this.pieceSelected.fichier.length !== 0) ||
            fileList.length !== 0,
        }"
      >
        <template v-if="pieceSelected === null && !pieceOnload">
          <div class="depotPieceJointeMsg">
            Cliquez sur les pièces à gauche<br />
            pour insérer et visualiser<br />
            vos documents
          </div>
        </template>

        <template v-else-if="pieceOnload">
          <Loader />
        </template>

        <template
          v-else-if="
            this.pieceSelected.fichier.length !== 0 || fileList.length !== 0
          "
        >
          <div id="depotPieceJointeHead">
            <h2>{{ pieceSelected.typePieceJustificative.label }}</h2>
          </div>
          <div id="depotPieceJointeMain">
            <p id="titreEnvoi">Vos envois</p>
            <div
              v-for="fichier in pieceSelected.fichier.slice().reverse()"
              :key="fichier.date"
              class="ligne_envoi"
            >
              <div
                @click="selectFichier(fichier)"
                class="pointer"
                :class="{
                  fichierSelectedClass: fichierSelected === fichier,
                }"
              >
                <p>
                  {{
                    fichier.date
                      .replace(/-/g, "/")
                      .replace(/ .{0,}/g, "")
                      .replace(/^....[/]..[/]/g, "") +
                    "/" +
                    fichier.date
                      .replace(/-/g, "/")
                      .replace(/ .{0,}/g, "")
                      .replace(/^....[/]/g, "")
                      .replace(/[/]..$/g, "") +
                    "/" +
                    fichier.date
                      .replace(/-/g, "/")
                      .replace(/ .{0,}/g, "")
                      .replace(/[/]..[/]..$/g, "")
                  }}
                </p>
                <p>
                  <template v-if="fichier.etat === null">
                    En cours de traitement
                  </template>
                  <template v-else-if="fichier.etat === false">
                    Refusé
                  </template>
                  <template v-else> Accepté </template>
                </p>
                <p><i class="fa fa-search"></i></p>
              </div>
            </div>
            <div class="card border-dark mb-3">
              <template v-if="!pieceSelected.etat">
                <template
                  v-if="
                    this.pieceSelected.fichier.length !== 0 &&
                    this.pieceSelected.fichier.slice().reverse()[0].etat ==
                      null &&
                    fichierSelected === null &&
                    !savePieceClick
                  "
                >
                  <div class="piece_valide">Pièce en cours de traitement</div>
                </template>
                <template v-else>
                  <div class="card-header card-header-piece">
                    <template v-if="fichierSelected === null">
                      <div>Nouvel envoi - {{ dateNow() }}</div>
                    </template>
                    <template v-else>
                      <div>
                        Envoi du
                        {{
                          fichierSelected.date
                            .replace(/-/g, "/")
                            .replace(/ .{0,}/g, "")
                            .replace(/^....[/]..[/]/g, "") +
                          "/" +
                          fichierSelected.date
                            .replace(/-/g, "/")
                            .replace(/ .{0,}/g, "")
                            .replace(/^....[/]/g, "")
                            .replace(/[/]..$/g, "") +
                          "/" +
                          fichierSelected.date
                            .replace(/-/g, "/")
                            .replace(/ .{0,}/g, "")
                            .replace(/[/]..[/]..$/g, "")
                        }}
                        -
                        <template v-if="fichierSelected.etat === null">
                          En cours de traitement
                        </template>
                        <template v-else-if="fichierSelected.etat === false">
                          Refusé
                        </template>
                        <template v-else> Accepté </template>
                      </div>
                    </template>
                    <div>
                      <template
                        v-if="
                          !this.pieceSelected.etat &&
                          (this.pieceSelected.fichier.length === 0 ||
                            this.pieceSelected.fichier.slice().reverse()[0]
                              .etat != null)
                        "
                      >
                        <label
                          for="file_input"
                          class="btn btn-primary"
                          :class="{
                            disabled:
                              this.pieceSelected.etat ||
                              (this.pieceSelected.fichier.length !== 0 &&
                                this.pieceSelected.fichier.slice().reverse()[0]
                                  .etat == null),
                          }"
                        >
                          Ajouter
                        </label>
                        <input
                          type="file"
                          id="file_input"
                          name="file_input"
                          @change="addFile()"
                          multiple
                        />
                      </template>
                    </div>
                    <div
                      @click="unselectFichier()"
                      id="unselectFichier"
                      v-if="fichierSelected !== null"
                    >
                      <div><i class="fa fa-times"></i></div>
                    </div>
                  </div>
                  <div class="card-body text-dark">
                    <div
                      v-if="fileList.length !== 0 && fichierSelected === null"
                    >
                      <div v-for="file in fileList" :key="file.file_name">
                        <a
                          :href="file.file_data"
                          :download="file.file_name"
                          target="_blank"
                          class="card-text"
                          >{{ file.file_name }}</a
                        >
                        <div class="cross" @click="delFile(file)">
                          <i class="fa fa-times"></i>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="fichierSelected !== null">
                      <div
                        v-for="file in fichierSelected.fichier"
                        :key="file.file_name"
                      >
                        <a
                          :href="file.file_data"
                          :download="file.file_name"
                          target="_blank"
                          class="card-text"
                          >{{ file.file_name }}</a
                        >
                      </div>
                    </div>
                    <div v-else>
                      Cliquez sur l'un des envois ci-dessus pour le visualiser
                      ou sur AJOUTER pour réaliser un nouvel envoi.
                    </div>
                  </div>
                  <template v-if="fileList.length !== 0">
                    <template v-if="!savePieceClick">
                      <button
                        id="btnSavePiece"
                        type="button"
                        class="btn btn-primary"
                        @click="savePiece()"
                      >
                        Envoyer
                      </button>
                    </template>
                    <template v-else>
                      <button
                        id="btnSavePiece"
                        type="button"
                        class="btn btn-primary"
                        disabled
                      >
                        <i class="fa fa-spinner fa-spin"></i> Envoyer
                      </button>
                    </template>
                  </template>
                </template>
              </template>
              <template v-else>
                <div class="piece_valide">
                  {{ pieceSelected.typePieceJustificative.label }}
                  validé(e)(s)
                </div>
              </template>
            </div>
          </div>
          <div id="depotPieceJointeFoot">
            <textarea
              v-model="pieceSelected.commentaire"
              id="commentaire"
              class="form-control"
              placeholder="Commentaire de la secrétaire..."
              disabled
            />
          </div>
        </template>
        <template v-else>
          <div class="depotPieceJointeMsgTitle">
            <h2>{{ pieceSelected.typePieceJustificative.label }}</h2>
          </div>
          <div class="depotPieceJointeMsg">
            <label for="file_input"
              >Ajouter un fichier
              <img
                id="iconDossier"
                :src="require('../../../assets/images/dossier.png')"
              />
            </label>
            <input
              type="file"
              id="file_input"
              name="file_input"
              @change="addFile()"
              multiple
            />
          </div>
          <div></div>
        </template>
      </div>
    </template>

    <template v-else>
      <Loader />
    </template>
  </div>
  <Description />
</template>

<script>
import Loader from "@/components/Loader.vue";
import Description from "@/components/dossier/Description.vue";

export default {
  name: "Piece",
  components: {
    Loader,
    Description,
  },
  data() {
    return {
      loadDossierFinish: false,
      fileList: [],
      savePieceClick: false,
      pieceSelected: null,
      fichierSelected: null,
      pieceOnload: false,
    };
  },
  mounted() {
    this.loadDossier();
  },
  methods: {
    async loadDossier() {
      try {
        // Charge les types de pièces justificatives pour pouvoir recalculer les pièces de l'utilisateur
        window.api
          .get("/type_piece_justificative", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            // Place dans le store les types de pièce justificatives
            this.$store.state.typePieceJustificative =
              response.data["hydra:member"];
            // Si l'utilisateur a un dossier
            if (this.$store.state.user.dossier !== null) {
              // Charge le dossier pour pouvoir recalculer les pièces de l'utilisateur
              window.api
                .get("/dossiers/" + this.$store.state.user.dossier.id, {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                })
                .then((response) => {
                  // Place le dossier dans le store
                  this.$store.state.dossier = response.data;
                  // Initialisation de typePieces avec les pièces permanente
                  let typePieces =
                    this.$store.state.typePieceJustificative.filter(
                      (el) => el.useCase === "dossier"
                    );
                  // Calcul de l'age
                  let age =
                    new Date().getUTCFullYear() -
                    new Date(
                      this.$store.state.dossier.dateNaissance
                    ).getUTCFullYear();

                  // Suivant des conditions, différents type de pièce sont ajoutés à typePieces

                  // Si l'élève a entre 17 ans inclus et 25 ans exclu
                  if (age >= 17 && age < 25) {
                    Array.prototype.push.apply(
                      typePieces,
                      this.$store.state.typePieceJustificative.filter(
                        (el) => el.useCase === "age"
                      )
                    );
                  }
                  // Si la demande fait suite à une annulation/invalidation de permis
                  if (this.$store.state.dossier.faitSuite) {
                    Array.prototype.push.apply(
                      typePieces,
                      this.$store.state.typePieceJustificative.filter(
                        (el) => el.useCase === "faitSuite"
                      )
                    );
                    // Si l'annulation/invalidation est lié à l'alcool ou la drogue
                    if (this.$store.state.dossier.motifAlcoolStup) {
                      Array.prototype.push.apply(
                        typePieces,
                        this.$store.state.typePieceJustificative.filter(
                          (el) => el.useCase === "faitSuite/oui"
                        )
                      );
                    } else {
                      Array.prototype.push.apply(
                        typePieces,
                        this.$store.state.typePieceJustificative.filter(
                          (el) => el.useCase === "faitSuite/non"
                        )
                      );
                    }
                  }
                  // Mise à jour des pièces relatives à l'utilisateur
                  window.api
                    .post(
                      "/dossier/piece",
                      {
                        dossier: this.$store.state.dossier,
                        typePieces: typePieces,
                      },
                      {
                        headers: {
                          Authorization: "Bearer " + this.$store.state.token,
                        },
                      }
                    )
                    .then((response) => {
                      // Place dans le store le dossier après mise à jour des pièces
                      this.$store.state.dossier = response.data;
                      this.loadDossierFinish = true;
                    })
                    .catch((error) => {
                      // En cas d'échec de mise à jour des pièces, retour au menu home
                      this.$router.push({ name: "Home" });
                      console.log(error);
                    });
                })
                .catch((error) => {
                  // En cas d'échec du chargement du dossier, retour au menu home
                  this.$router.push({ name: "Home" });
                  console.log(error.response.data.message);
                });
            } else {
              // Sinon, retour au menu home
              this.$router.push({ name: "Home" });
            }
          })
          .catch((error) => {
            // En cas d'échec au chargement des types de pièce justificatives, retour au menu home
            this.$router.push({ name: "Home" });
            console.log(error.response.data.message);
          });
      } catch (e) {
        // En cas d'erreur, retour au menu home
        this.$router.push({ name: "Home" });
      }
    },

    dateNow() {
      // Clacul la date actuelle et la formate
      // Initialisation vide
      let dateToReturn = "";
      // Si le jour est inferieur ou égale à 9
      if (new Date().getDate() <= 9) {
        // Alors ajout d'un zéro précédent le jour
        dateToReturn += "0";
      }
      // Ajout du jour et d'un slash entre le jour et le mois
      dateToReturn += new Date().getDate() + "/";
      // Les mois vont de 0 à 11
      // Si le mois est le 9ème ou moins
      if (new Date().getMonth() < 9) {
        // Alors ajout d'un zéro précédent le mois
        dateToReturn += "0";
      }
      // Ajout du mois+1, d'un slash entre le mois et l'année et de l'année
      dateToReturn +=
        new Date().getMonth() + 1 + "/" + new Date().getFullYear();

      // Retourne la date actuelle formatée
      return dateToReturn;
    },

    goToHome() {
      this.$router.push({ name: "Home" });
    },

    openModalDescription(description) {
      // Ouvre le modal des descriptions de la pièce
      this.$store.state.descriptionPiece = description;
    },

    selectPiece(piece) {
      // Séléctionne une pièce
      // Si la pièce est validée, elle n'est pas cliquable
      if (!piece.etat) {
        // Si une pièce est en cours d'enregistrement, l'élève ne peut pas séléctionner une autre pièce
        if (!this.savePieceClick) {
          // Vide la liste des fichiers
          this.fileList = [];
          // Déséléctionne l'envoi potentiellement séléctionné
          this.unselectFichier();
          // Si les envois de la pièce ne sont pas encore chargés
          if (piece.fichier == null) {
            // Alors on les chargent
            // Indique qu'un chargement de pièce a commencé
            this.pieceOnload = true;
            // Chargement de la pièce
            window.api
              .get("/piece_justificatives/" + piece.id, {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              })
              .then((response) => {
                // Récupère l'id de la pièce séléctionnée
                let idPieceSelected =
                  this.$store.state.dossier.pieceJustificatives.findIndex(
                    (el) => el.id === response.data.id
                  );
                // Place les envois de la pièce chargées dans la liste
                this.$store.state.dossier.pieceJustificatives[
                  idPieceSelected
                ].fichier = response.data.fichier;
                // Met à jour la pièce séléctionnée depuis la liste
                this.pieceSelected =
                  this.$store.state.dossier.pieceJustificatives[
                    idPieceSelected
                  ];
                // Indique que le chargement de la pièce est terminé
                this.pieceOnload = false;
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            // Sinon, séléction de la pièce
            this.pieceSelected = piece;
          }
        }
      }
    },

    unselectPiece() {
      // Déséléctionne la pièce
      // Vide la liste des fichiers
      this.fileList = [];
      // Déséléctionne l'envoi potentiellement séléctionné
      this.unselectFichier();
      // Vide la data de la pièce séléctionnée
      this.pieceSelected = null;
    },

    selectFichier(fichier) {
      // Séléctionne l'envoi
      // Remplit la data de l'envoi séléctionné
      this.fichierSelected = fichier;
    },

    unselectFichier() {
      // Déséléctionne l'envoi
      // Vide la data de l'envoi séléctionné
      this.fichierSelected = null;
    },

    addFile() {
      // Ajout de fichier à la fileList
      // Récupère l'élément ayant l'id "file_input"
      let input = document.getElementById("file_input");
      // Boucle les fichiers présent dans l'input récupéré précédemment
      for (let i = 0; i < input.files.length; i++) {
        // Initialise le FileReader
        const reader = new FileReader();
        // Execute quand le reader est load et met un fichier dans fileList
        reader.onloadend = () => {
          this.fileList.push({
            file_name: input.files[i].name,
            file_data: reader.result,
          });
        };
        reader.readAsDataURL(input.files[i]);
      }
      // Désélectionne les envoies
      this.unselectFichier();
    },
    delFile(item) {
      // Retire de la fileList le fichier en paramètre
      this.fileList = this.fileList.filter((el) => el !== item);
    },
    savePiece() {
      // Si les données ont été téléchargées et qu'une sauvegarde n'est pas déjà en cours
      if (this.checkLoad && !this.savePieceClick) {
        // Indique qu'une sauvegarde est en cours
        this.savePieceClick = true;
        let tmpPieceSelectedFichier = this.pieceSelected.fichier;
        // Créé un envoi en ajoutant la fileList
        tmpPieceSelectedFichier.push({
          date: new Date().toISOString().slice(0, 19).replace("T", " "),
          fichier: this.fileList,
          etat: null,
        });
        // Met à jour la pièce
        window.api
          .patch(
            "/piece_justificatives/" + this.pieceSelected.id,
            {
              commentaire: this.pieceSelected.commentaire,
              fichier: tmpPieceSelectedFichier,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            // Update la liste d'envoi de la pièce séléctionnée
            this.pieceSelected.fichier = tmpPieceSelectedFichier;
            // Déséléctionne la pièce
            this.unselectPiece();
            // Indique que la sauvegarde n'est plus en cours
            this.savePieceClick = false;
          })
          .catch((error) => {
            // Indique que la sauvegarde n'est plus en cours
            this.savePieceClick = false;
            console.log(error);
          });
      }
    },
  },
  computed: {
    checkLoad() {
      return this.loadDossierFinish;
    },
  },
};
</script>

<style scoped>
.iconInfo {
  width: 30px;
  height: 30px;
}

.selected {
  background-color: rgb(248, 157, 21, 0.47) !important;
}

.cross {
  display: inline;
  cursor: pointer;
  margin-left: 1rem;
}

.heightPiece {
  cursor: pointer;
  margin-bottom: 1rem;
}

.piece {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.piece > * {
  flex: 3;
  margin: 0;
  align-self: center;
}

.piece > div {
  flex: 1;
}

.piece > p {
  text-align: left;
}

#pieceBack {
  background: rgb(251, 187, 91, 0.2);
  box-shadow: 10px 10px 10px #00000029;
  border-radius: 13px;
  padding: 1rem;
  width: 80%;
  margin-left: 10%;
}

#statut {
  color: white;
  font-size: 1em;
  font-weight: bold;
}

.labelFichier {
  font-size: 1rem;
  width: 40%;
  color: #fbfbfb;
}

#commentaire {
  height: 10rem;
}

#file_input {
  opacity: 0;
  width: 0;
  height: 0;
}

#iconDossier {
  height: 100px;
}

#titreDossier {
  text-align: center;
  font: normal normal 600 76px/103px Galano Grotesque Alt;
  letter-spacing: 0px;
  max-width: 100%;
  color: #282828;
  margin: 2rem 0;
}

#depotPieceJointe {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-start;
}

.depotPieceJointeStart {
  justify-content: flex-start !important;
}

#Dossier {
  position: relative;
  margin: auto;
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: grid;
  background: transparent linear-gradient(145deg, #de6fa8 0%, #fbbb5b 100%) 0%
    0% no-repeat padding-box;
}

/* Works on Chrome, Edge, and Safari */
#Dossier::-webkit-scrollbar {
  width: 15px;
}

#Dossier::-webkit-scrollbar-track {
  background: white;
}

#Dossier::-webkit-scrollbar-thumb {
  background-color: #fbbb5b77;
  border-radius: 20px;
  border: 3px solid white;
}

#Dossier > img {
  height: 5rem;
  margin-top: 1rem;
  margin-left: 1rem;
  cursor: pointer;
  position: absolute;
  z-index: 1;
}

#Dossier > div {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

#Dossier > div > button {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  font-size: 12px;
}

.depotPieceJointeMsgTitle {
  align-self: flex-start;
  padding: 1rem 2rem;
}

.depotPieceJointeMsg > label {
  cursor: pointer;
}

.depotPieceJointeMsg {
  text-align: center;
  font: normal normal 600 30px/41px Galano Grotesque Alt;
  letter-spacing: 0px;
  color: #282828;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#depotPieceJointeHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 2rem;
}

#depotPieceJointeHead > h2 {
  align-self: center;
}

#depotPieceJointeMain {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
}

#titreEnvoi {
  text-align: left;
  text-decoration: underline;
}

.ligne_envoi {
  margin-bottom: 0.5rem;
}

.ligne_envoi > div {
  display: flex;
  flex-direction: row;
  border: 1px solid black;
}

.ligne_envoi > div > p {
  flex: 2;
  margin: 0;
  padding: 0.2rem 0;
  border-right: 1px solid black;
}

.ligne_envoi > div > p:first-child,
.ligne_envoi > div > p:last-child {
  flex: 1;
}

.ligne_envoi > div > p:last-child {
  border-right: none;
}

.piece_valide {
  margin: 5rem 0;
}

#btnSavePiece {
  width: fit-content;
  align-self: flex-end;
  margin: 0 0.5rem 0.5rem 0;
}

#unselectFichier {
  display: flex;
  width: 2.775rem;
  height: 2.775rem;
  cursor: pointer;
}

#unselectFichier > div {
  display: flex;
  flex: 1;
  height: 100%;
  border: 1px solid red;
  border-radius: 0.25rem;
}

#unselectFichier > div > i {
  flex: 1;
  align-self: center;
  color: red;
}

.card-header-piece {
  font-weight: bold;
  display: flex;
}

.card-header-piece > div {
  padding: 0.2rem;
}

.card-header-piece > div:first-child {
  flex: 1;
  align-self: center;
}

.pointer {
  cursor: pointer;
}

#depotPieceJointeFoot {
  padding: 1rem 2rem;
}

.fichierSelectedClass {
  font-weight: bold;
}

#conteneur {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  align-items: center;
}

#blockbas {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#conteneurDepot div {
  border: 2px dotted;
  padding: 15%;
}

/* Mobile Styles */
@media only screen and (max-width: 400px) {
  #Dossier {
    min-width: 80vh;
    min-height: 100vh;
  }

  .labelFichier {
    font-size: 0.8rem;
  }

  #statut {
    font-size: 0.8em;
  }

  #conteneur {
    display: initial;
    grid-template-columns: none;
    justify-content: center;
    align-items: center;
  }

  .iconInfo {
    width: 20px;
    height: 20px;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  #Dossier {
    min-width: 60vh;
    min-height: 100vh;
  }
  #conteneur {
    display: initial;
    grid-template-columns: none;
    justify-content: center;
    align-items: center;
  }

  .labelFichier {
    font-size: 1rem;
    width: 40%;
    color: #fbfbfb;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .labelFichier {
    font-size: 0.9rem;
    width: 40%;
    color: #fbfbfb;
  }
}
</style>
