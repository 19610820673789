<template>
  <div id="conteneurEleve">
    <Toast />
    <div id="eleve" class="container">
      <header id="head">
        <DropdownNav></DropdownNav>
        <router-link to="/home"
          ><i class="pi pi-home me-3" id="iconHome" style="font-size: 2rem"></i
        ></router-link>
        <h1 class="ms-3 me-3" id="titreEleve">Subventions</h1>
        <button @click="changeSubv('cpf')" class="me-3 btnHeader">CPF</button>
        <button @click="changeSubv('pe')" class="me-3 btnHeader">PE</button>
        <button @click="changeSubv('subv')" class="me-3 btnHeader">SUBV</button>
      </header>

      <p>{{ montantSubv }} €</p>
        <DataTable :value="choiceSubv.droits" tableStyle="min-width: 50rem" v-model:selection="selectedItem" @click="onSelected" selectionMode="single">
            <Column field="date" header="Date" sortable style="width: 25%"></Column>
            <Column field="eleve.fullname" header="Nom" sortable style="width: 25%"></Column>
            <Column field="tot" header="Montant" sortable style="width: 25%"></Column>
        </DataTable>
    </div>

    <div id="right">
      <div v-if="this.selectedItem === null" id="styleTitreChoix">
        <p id="titreChoix">Cliquez sur un Eleve</p>
      </div>
      <div v-else class="info">
        {{this.selectedItem.eleve.fullname}}
        <br>
        {{this.selectedItem.eleve.tel}} - {{this.selectedItem.eleve.mail}}
        <br>
        {{this.selectedItem.eleve.agence}}
        <br>
        <Button @click="solde" class="btnViolet">Solde</Button>
        <template v-if="showPaiement == true"><paiement :data="this.selectedItem.eleve" /></template>
      </div>
    </div>
    
  </div>
</template>

<script>
import DropdownNav from "@/components/DropdownNav.vue";
import paiement from "@/components/eleve/Paiement.vue";

export default {
  name: "App",
  components: {
    DropdownNav,
    paiement,
  },
  data() {
    return {
        cpf : [],
        pe : [],
        subv : [],
        choiceSubv : [],
        montantSubv : 0,
        selectedItem: null,
        showPaiement : false,
    }
  },
  mounted() {
    this.getSubv();
  },
  methods: {

    solde() {
      this.showPaiement = true;
      this.data = this.selectedItem;
    },

    onSelected() {
      if (this.selectedItem != null) {
        document.getElementById("right").style.background = "none";
      }
    },


    changeSubv(e){
      this.showPaiement = false;
      this.selectedItem = null;
      document.getElementById("right").style.background = "linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%)";
      if(e == "cpf"){
        this.choiceSubv = this.cpf;
        this.montantSubv = "CPF en attente : " + this.cpf.montant;
      }else if(e == "pe"){
        this.choiceSubv = this.pe;
        this.montantSubv = "PE en attente : " + this.pe.montant;
      }else if(e == "subv"){
        this.choiceSubv = this.subv;
        this.montantSubv = "SUBV en attente : " + this.subv.montant;
      }
    },

    getSubv(){
        if(this.$store.state.user.role.label == 'secretaire'){
            window.api.get("/subv/" + this.$store.state.user.agence.id, {
                headers: {
                    "Authorization": "Bearer " + this.$store.state.token
                }
            }).then(response => {

                this.cpf = response.data.OtherPaiements.CPF;
                this.pe = response.data.OtherPaiements.PE;
                this.subv = response.data.OtherPaiements.SUBV;
                this.choiceSubv = this.cpf;
                this.montantSubv = "CPF en attente : " + this.cpf.montant;
            }).catch(error => {
                console.log(error)
            });
        }else{
            window.api.get("/subv/0", {
                headers: {
                    "Authorization": "Bearer " + this.$store.state.token
                }
            }).then(response => {
                this.cpf = response.data.OtherPaiements.CPF;
                this.pe = response.data.OtherPaiements.PE;
                this.subv = response.data.OtherPaiements.SUBV;
                this.choiceSubv = this.cpf;
                this.montantSubv = this.cpf.montant;
            }).catch(error => {
                console.log(error)
            });
        }

    }
  },
};
</script>

<style scoped>

div.info{
  text-align: left;
  width: 100%;
}

#head {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;
  width: 50%;
}

#titreChoix {
  flex-wrap: wrap;
  font-size: 2rem;
  color: white;
}
#styleTitreChoix {
  background-color: rgb(255, 255, 255, 0.47);
  padding: 1%;
  border-radius: 13px;
  align-self: center;
}
#eleve {
  background-image: url("../../assets/images/fondd.png");
  background-size: cover;
  border-right: 1px solid black;
}
#right {
  width: 100%;
  background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  height: auto;
  justify-content: center !important;
  min-height: 100vh;
  display: flex;
}
#titreEleve {
  font-size: 25pt;
  font-weight: bold;
  color: #2e9ea3;
}
#conteneurEleve {
  display: grid;
  grid-template-columns: 50% 50%;
}

button.btnHeader{
    width: 100px;
    background-color: #2e9ea3;
    color: white;
}


</style>
