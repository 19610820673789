<template>
  <div class="solde">
    <template v-if="checkLoad">

      <div class="modal fade" :id="'modal_montant'" tabindex="-1"
           style="display: none; background-color: rgba(0, 0, 0, 0.25);">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Montant</h5>
              <button class="btn-close" @click="closeModal()"></button>
            </div>
            <div class="modal-body">
              <div>
                <label for="montant" class="form-label">Montant :</label>
                <input v-model="montant" type="number" id="montant" name="montant" class="form-control">
              </div>
              <template v-if="checkMontant">
              </template>
              <BoutonPaiement
                :messageAutre="true"
                @paiement="valider($event)"
              ></BoutonPaiement>
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary" @click="closeModal()">Close</button>
            </div>
          </div>
        </div>
      </div>
      <div class="tab">
        <div class="paiement">
          <div class="header-paiement">
            <h3>{{ eleve.nom }} {{ eleve.prenom }}</h3>
            <router-link v-if="this.$store.state.user.role.label == 'secretaire'" to="/PlanningAgence">
              <Button id="btnTerminer">Retourner au planning</Button>
            </router-link>
            <router-link v-if="this.$store.state.user.role.label == 'eleve'" to="/Calendrier">
                <i
                  class="pi pi-calendar-times me-3 testi"
                  id="iconHome"
                  style="font-size: 2rem"
                ></i>
            </router-link>
          </div>


          <div class="headertab"><p>Paiements {{ paiementSolde }} €</p> <p @click="openModal()">+</p></div>
          <div class="divtable">
            <table>
              <tr>
                <th>Date</th>
                <th>Montant</th>
                <th>Mode</th>
              </tr>
              <tr
                  v-for="paiement in paiements"
                  :key="paiement.id"
              >
                <td>{{ paiement.showdate }}</td>
                <td>{{ paiement.montant }} €</td>
                <td>{{paiement.mode}}</td>
              </tr>
            </table>
          </div>

        </div>
        <div class="rdv">
          <div class="header-rdv">
            <h4>Le solde: {{ montantSolde }} €</h4>
          </div>
          
          <p>Heures {{ rdvSolde }} €</p>
          <div class="divtable">
            <table>
              <thead>
              <tr>
                <th>Date</th>
                <th>Heure</th>
                <th>Nb</th>
                <th>Montant</th>
                <th>Mono</th>
                <th>Autre</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(apointment, i) in apointments" :key="i">

                <tr>
                  <td>{{ apointment.lestart }}</td>
                  <td>{{ apointment.h }} H</td>
                  <td>{{ apointment.heure }} H</td>
                  <td>{{ apointment.montant }}</td>
                  <td v-if="apointment.cancelRdv == 'null'">{{ apointment.moniteur.fullname }}</td>
                  <td v-else>Annulé</td>
                  <td v-if="apointment.cancelRdv == 'null'">{{ apointment.lieu.nom }}</td>
                  <td v-else>{{ apointment.cancelRdv.date }}</td>
                </tr>

              </template>
              </tbody>
            </table>
          </div>

        </div>
      </div>

    </template>

    <template v-else>
      <Loader/>
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Loader from '@/components/Loader.vue';
import BoutonPaiement from "@/components/eleve/BoutonPaiement.vue";

export default {
  name: 'Solde',
  components: {
    Loader,
    BoutonPaiement,
  },
  data() {
    return {
      montant: "",
      apointments: [],
      montantSolde: 0,
      eleve: [],
      rdvSolde: 0,
      paiementSolde: 0,
      paiements : [],
    }
  },
  mounted() {
    this.loadPaiement();
    this.getApointments();
    this.getSolde();
    this.getUser();
    this.getPaiements();
  },
  methods: {

    getPaiements() {
      window.api.get("/eleve/paiement/" + this.$route.params.id, {
        headers: {
          "Authorization": "Bearer " + this.$store.state.token
        },
      }).then(response => {
        this.paiements = response.data.conduite;
        this.totConduite = response.data.totConduite

      }).catch(error => {
        console.log(error)
      })
    },

    getUser() {
      window.api.get("/utilisateur/" + this.$route.params.id,
          {
            headers: {
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(response => {
        this.eleve = response.data.utilisateur[0];
      }).catch(error => {
        console.log(error)
      })
    },

    getSolde() {
      window.api.get("/solde/" + this.$route.params.id, {
        headers: {
          "Authorization": "Bearer " + this.$store.state.token
        },
      }).then(response => {
        this.rdvSolde = response.data.rdvSolde.toLocaleString();
        this.paiementSolde = response.data.paiements.toLocaleString();
        this.montantSolde = response.data.solde.toLocaleString();
        this.apointments = response.data.rdv;
      }).catch(error => {
        console.log(error)
      })
    },

    getApointments() {
      window.api.get("/eleves/" + this.$route.params.id + "/apointments",
          {
            headers: {
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(response => {
        this.apointment = response.data.apointments;
      }).catch(error => {
        console.log(error)
      })
    },

    ...mapActions([]),
    async loadPaiement() {
      try {
        window.api.get("/utilisateur/" + this.$store.state.user.id + "/paiement", {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token
          }
        }).then(response => {
          this.$store.commit("setPaiement", response.data["Paiement"]);
        })
      } catch (e) {
        this.$router.push({name: 'Home'});
      }
    },
    openModal() {
      this.$store.dispatch("startSaveBouton", "autre");
      let mode = "autre";
      let fonctionnalite = "solde";
      let router = this.$router;
      this.$store.dispatch("endSaveBouton", { mode, fonctionnalite, router });
      let modal = document.getElementById('modal_montant');
      modal.style.display = 'block';
      modal.classList.add("show");
    },
    closeModal() {
      let modal = document.getElementById('modal_montant');
      modal.style.display = 'none';
      modal.classList.remove("show");
    },
    valider(mode) {
      if (this.checkMontant) {
        this.$store.dispatch("startSaveBouton", mode);
        let fonctionnalite = "solde";
        let router = this.$router;
        let montantParam = parseFloat(this.montant);
        this.$store.dispatch("endSaveBouton", { mode, fonctionnalite, router, montantParam });
      }
    },
  },
  computed: {
    ...mapGetters([
      "paiement",
    ]),
    checkLoad() {
      return this.paiement !== null;
    },
    checkMontant() {
      if (this.montant !== "") {
        let montantStr = parseFloat(this.montant);
        if (montantStr !== "NaN") {
          if (montantStr > 0) {
            return true;
          }
        }
      }
      return false;
    },
    filterPaiement() {
      if (this.paiement !== null) {
        return this.paiement.filter(el => el.etat === 'payé');
      }
      return [];
    },
    totalPaiement() {
      if (this.paiement !== null) {
        let total = [];
        Object.entries(this.filterPaiement).forEach(([key, val]) => {
          total.push((key * 0) + val.totalAmount / 100) // key * 0 is just to avoid error
        });
        return total.reduce(function (total, num) {
          return total + num
        }, 0);
      }
      return 0;
    },
  }
}
</script>

<style scoped>

div.header-paiement{
  display:flex;
  margin:auto;
  margin-top: 2em;
  width:80%;
  justify-content: space-between;
}

div.header-rdv{
  margin-top: 2em;
}

div.tab {
  display: flex;
}

div.tab table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 80%;
}

div.tab table td, div.tab table th {
  border: 1px solid #ddd;
  padding: 8px;
}

div.tab table tr:nth-child(even){background-color: rgba(255, 105, 199, .1);}

div.tab table tr:hover {background-color: rgba(255, 105, 199, .3);}

div.tab table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #fa8d69;
  color: white;
}

div.tab div.paiement{

  width: 35%;

  min-height: 100vh;

  background-image: url('../../../assets/images/fondd.png');
  background-size: cover;
}

div.tab div.paiement div.headertab{
  display: flex;
  justify-content: center;
  margin-top: 2em;
}

div.tab div.paiement div.headertab button{
  height: 2%;
  background: #2196F3;
  color: white;
}

div.paiement table{
  margin:auto;

}

div.tab div.rdv{
  width: 65%;
  height: auto;
  min-height: 100vh;
  background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  background-color: #f2f2f2;
  background-size: cover;

}

div.tab div.rdv p{
  margin:auto;
  margin-top: 4em;
}

div.tab div.rdv div.divtable{
  height: 77vh;
  overflow: auto;
}

div.tab div.rdv table{
  margin:auto;
  height: 80%;
  
}
</style>