<template>
  <!-- on verifie dans le store que le user n'est pas null 
  donc la personne est connecté ainsi que les roles pour la liste déroulante qui apparaitra à gauche sur la page d'acceuil -->
  <template v-if="this.$store.state.user !== null">
    <template v-if="this.$store.state.user.role.label == 'admin'">
      <div class="dropdown">
        <img class="dropbtn me-3" id="iconNav"
          src="https://www.clipartmax.com/png/full/36-365828_navbar-toggle-icon-menu-hamburger-png-white.png" />

        <div class="dropdown-content">
          <router-link to="/Agence"><a href="#">Agence</a></router-link>
          <router-link to="/Lycee"><a href="#">Lycee</a></router-link>
          <router-link to="/Vehicule"><a href="#">Vehicule</a></router-link>
          <router-link to="/Moniteur"><a href="#">Moniteur</a></router-link>
          <router-link to="/LieuRDV"><a href="#">LieuRDV</a></router-link>
          <router-link to="/LieuEXAM"><a href="#">LieuExam</a></router-link>
          <router-link to="/Eleve"><a href="#">Eleve</a></router-link>
          <router-link to="/admin/code"><a href="#">Code</a></router-link>
          <router-link to="/TrameFront"><a href="#">Trame</a></router-link>

          <router-link to="/Statistique"><a href="#">Statistique</a></router-link>
          <router-link to="/JourneePorteOuverte"><a href="#">Journee porte ouverte</a></router-link>
          <router-link to="/admin/stagecode"><a href="#">Stage de code</a></router-link>
          <router-link to="/Fonctionnalite"><a href="#">Fonctionnalite</a></router-link>
          <router-link to="/admin/formationAac"><a href="#">Formation AAC</a></router-link>
          <router-link to="/OtherPaiementDeleted"><a href="#">Paiements supprimés</a></router-link>
          <a href="#" @click="deconnexion">Déconnexion</a>
        </div>
      </div>
    </template>

    <template v-if="this.$store.state.user.role.label == 'secretaire'">
      <div class="dropdown">
        <img class="dropbtn me-3" id="iconNav"
        src="https://www.clipartmax.com/png/full/36-365828_navbar-toggle-icon-menu-hamburger-png-white.png" />

        <div class="dropdown-content">
          <div class="subMenu">
            <div class="subTitleMenu">
              <a>Paramétrage > </a>
            </div>
            <div class="subMenus">
                <router-link to="/Donnees"><a href="#">Agence</a></router-link>
                <router-link to="/AgenceMoniteur"><a href="#">Moniteurs</a></router-link>
            </div>
          </div>
          <router-link to="/AgenceEleve"><a href="#">Eleve</a></router-link>
          <router-link to="/transfert/eleve"><a href="#">Transferer un élève</a></router-link>
          <router-link to="/agence/dossier"><a href="#">Dossier</a></router-link>
          <div class="subMenu">
            <div class="subTitleMenu">
              <a>Code > </a>
            </div>
            <div class="subMenus">
                <router-link to="/secretaire/code"><a href="#">Code</a></router-link>
                <router-link to="/gestion/examcode"><a href="#">Examen Code</a></router-link>
            </div>
          </div>
          <div class="subMenu">
            <div class="subTitleMenu">
              <a>Conduite > </a>
            </div>
            <div class="subMenus">
                <a>Planning</a>
                <a>Examen Conduite</a>
            </div>
          </div>
          <!--<router-link to="/secretaire/formationAac"><a href="#">Formation AAC</a></router-link>-->
          <router-link to="/OtherPaiementDeleted"><a href="#">Paiements supprimés</a></router-link>
          <a href="#" @click="deconnexion">Déconnexion</a>
        </div>
      </div>
    </template>

    <template v-if="this.$store.state.user.role.label == 'eleve'">
      <div class="dropdown">
        <img class="dropbtn mt-4" id="iconNav"
          src="https://www.clipartmax.com/png/full/36-365828_navbar-toggle-icon-menu-hamburger-png-white.png" />

        <div class="dropdown-content">
          <router-link to="/JourneePorteOuverteEleve"><a href="#">Evénements</a></router-link>
          <router-link v-if="checkOnePaiement('dossier')" :to="{ name: 'Dossier', params: { personalData: 'true' } }"><a
              href="#">Formulaire</a></router-link>
          <router-link to="/CodeFront"><a href="#">Code</a></router-link>
          <a href="#" @click="deconnexion">Déconnexion</a>
        </div>
      </div>
    </template>
  </template>
</template>

<script>
export default {
  name: "DropdownNav",

  methods: {
    deconnexion() {
      this.$store.commit("setUser", null);
      this.$store.commit("setToken", null);
      localStorage.removeItem("user_info");
      localStorage.removeItem("user_access");
    },
  },
};
</script>

<style scoped>
#iconNav {
  width: 35px;
  height: 35px;
}

/* Style The Dropdown Button */
.dropbtn {
  color: white;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: white;
  padding: 6px 10px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  border-radius: 17px;
  background: transparent linear-gradient(131deg, #de6fa8 0%, #fbbb5b 100%) 0% 0% no-repeat padding-box;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
  border-radius: 17px;
  background: transparent linear-gradient(131deg, #de6fa8 0%, #fbbb5b 100%) 0% 0% no-repeat padding-box;
}

.subMenus{
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  
}
.dropdown-content .subMenu:hover .subMenus{
  display: block;
  position: absolute;
  left:100%; 
  border-radius: 17px;
  background: transparent linear-gradient(131deg, #de6fa8 0%, #fbbb5b 100%) 0% 0% no-repeat padding-box;
}

.dropdown-content .subMenu{
  display: inline-flex;
  width: 100%;
}

.dropdown-content .subMenu div.subTitleMenu a{
  color: white;
  padding: 6px 10px;
  text-decoration: none;
  display: block;
}

.dropdown-content .subMenu div.subTitleMenu{
  width: 100%;
}
</style>
