<template>
  <div>
    <h1>RDV PREALABLE</h1>
    <table>
      <thead>
        <tr>
          <th>Eleve</th>
          <th>Fin Formation</th>
          <th>Cours Suivant</th>
          <th>Durée de ce Cours</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, i) in users" :key="i">
          <td>{{ user.nom }} {{ user.prenom }}</td>
          <td>{{ user.DateFinForm }}</td>
          <td v-if="user.rdvp != 'null'">{{ user.rdvp[0].start }}</td>
          <td v-else>{{ user.rdvp }}</td>
          <td v-if="user.rdvp != 'null'">{{ user.rdvp[0].duree }}</td>
          <td v-else>{{ user.rdvp }}</td>
        </tr>
      </tbody>
    </table>
    <h4 v-if="users.length == 0">
      Vous n'avez pas d'élève à ce stage de la formation Aac
    </h4>
  </div>
</template>

<script>
export default {
  props: ["users"],
  data() {
    return {};
  },

  mounted() {
    console.log(this.users);
  },

  methods: {},
};
</script>

<style scoped>
h1 {
  margin-top: 3em;
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 80%;
  margin: auto;
  margin-top: 5em;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: rgba(255, 105, 199, 0.1);
}

table tr:hover {
  background-color: rgba(255, 105, 199, 0.3);
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #e27753;
  color: white;
}
</style>
