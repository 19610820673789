<template>
  <div id="conteneurEleve">
    <Toast />
    <div id="eleve" class="container">
      <header id="head">
        <DropdownNav></DropdownNav>
        <router-link to="/home"
          ><i class="pi pi-home me-3" id="iconHome" style="font-size: 2rem"></i
        ></router-link>
        <h1 class="ms-3" id="titreEleve">Code</h1>
        <button
          class="ms-5 btn btn-primary"
          v-if="!showArchive"
          @click="showArchives"
        >
          Archive
        </button>
        <button
          class="ms-5 btn btn-danger"
          v-if="showArchive"
          @click="showArchives"
        >
          Archive
        </button>

        <button
          class="ms-5 btn btn-primary"
          v-if="!showLivre"
          @click="showLivres"
        >
          {{ studentsLivreCode.length }} Livre à donner
        </button>
        <button
          class="ms-5 btn btn-danger"
          v-if="showLivre"
          @click="showLivres"
        >
          {{ studentsLivreCode.length }} Livre à donner
        </button>

        <button
          class="ms-5 btn btn-primary"
          v-if="!showPrepaCode"
          @click="showPrepaCodes"
        >
          {{ studentsPrepaCode.length }} PrepaCode à donner
        </button>
        <button
          class="ms-5 btn btn-danger"
          v-if="showPrepaCode"
          @click="showPrepaCodes"
        >
          {{ studentsPrepaCode.length }} PrepaCode à donner
        </button>
      </header>

      <div id="tableauEleve">
        <input
          class="search"
          v-model="filters['global'].value"
          placeholder="Rechercher"
        />

        <DataTable
          :value="students"
          sortMode="multiple"
          :rows="7"
          v-model:filters="filters"
          v-model:selection="selectedItem"
          selectionMode="single"
          filterDisplay="menu"
          :rowsPerPageOptions="[7, 10, 20, 50]"
          :paginator="true"
          responsiveLayout="scroll"
          dataKey="id"
          @click="onSelected"
          tableStyle="min-width: 50rem"
          :globalFilterFields="['nom', 'prenom', 'livret', 'prepacode', 'datep']"
        >
          <Column field="datep" header="Date" sortable style="width: 25%" ></Column>
          <Column field="nom" header="Nom" sortable style="width: 25%"></Column>
          
          <Column
            field="prenom"
            header="Prénom"
            sortable
            style="width: 25%"
          ></Column>
          <Column
            field="livret"
            header="Livre de Code"
            sortable
            style="width: 25%"
          >
            <template #body="slotProps">
              <template v-if="slotProps.data.livret == null">
                <button
                  class="btn btn-primary"
                  @click="postLivreCode(slotProps.data)"
                >
                  Livre donné ?
                </button>
              </template>
              <template v-else>
                <button
                  class="btn btn-danger"
                  @click="cancelLivreCode(slotProps.data)"
                >
                  {{ slotProps.data.livret }}
                </button>
              </template>
            </template>
          </Column>
          <Column
            field="prepacode"
            header="Prepacode"
            sortable
            style="width: 25%"
          >
            <template #body="slotProps">
              <template v-if="slotProps.data.prepacode != null">
                {{ slotProps.data.prepacode[0].date }}
              </template>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <div id="right">
      <div v-if="this.selectedItem === null" id="styleTitreChoix">
        <p id="titreChoix">Cliquez sur un Eleve</p>
      </div>

      <template v-if="this.selectedItem != null">
        <template v-if="!showPaiements">
          <div class="headerStudent">
            <div class="leftsss">
              <p>{{ this.selectedItem.nom }} {{ this.selectedItem.prenom }}</p>
              <p>{{ this.selectedItem.tel }} - {{ this.selectedItem.mail }}</p>
            </div>

            <div class="rightsss">
                <template v-if="this.selectedItem.newContract == 'null'">
                  <button class="btn btn-danger">Contrat non crée</button>
                </template>
                <template v-if="this.selectedItem.newContract == 'wait'">
                  <button @click="showContrats()" class="btn btn-warning">Contrat en attente</button>
                </template>
                <template v-if="this.selectedItem.newContract == 'valide'">
                  <button class="btn btn-success">Contrat validé</button>
                </template>
                <br />
                <button class="btn btn-info" @click="showPaiement">SOLDE</button>
            </div>
          </div>

          <div class="contentStudent">
            <h1>PREPACODE</h1>


            <div class="formPrepacode">
                <template v-if="selectedItem.prepacode == null">
                  <input
                    type="text"
                    class="idPrepa"
                    name="identifiant"
                    id="identifiant"
                    v-model="selectedItem.identifiant"
                    placeholder="Identifiant prépacode"
                  />
                </template>

                <template v-else>
                  <input
                    type="text"
                    class="idPrepa"
                    name="identifiant"
                    id="identifiant"
                    v-model="selectedItem.prepacode[0].identifiant"
                    placeholder="Identifiant prépacode"
                  />
                </template>
                <br />
                <input
                  type="password"
                  class="passPrepa"
                  name="passwordPrepa"
                  id="passwordPrepa"
                  v-model="selectedItem.password"
                  placeholder="Password prépacode"
                />
                <br />
                <button class="btn btn-primary" @click="postPrepacode(selectedItem)">VALIDER</button>
                <template v-if="selectedItem.prepacode != null">
                  <button class="btn btn-danger" @click="deletePrepacode(selectedItem)" v-if="selectedItem.prepacode[0].status == 'ok'">SUPPRIMER</button>
                  <button
                    class="btn btn-info"
                    v-if="selectedItem.prepacode[0].status == 'ok'"
                    @click="sendId(selectedItem)"
                  >
                    ENVOYER IDENTIFIANTS
                  </button>
                </template>
            </div>
            

          </div>
        </template>
        <template v-else>
          <paiement :data="selectedItem" />
        </template>

        <Dialog v-model:visible="showMeContract" modal header="Contrat" :style="{ width: '50vw' }">
          <p class="resendMail">Vous n'avez pas reçu le mail ? <button @click="resendMail">Renvoyer le mail</button></p>
          <input type="number" name="code" id="Code" placeholder="Saisir le code du mail" v-model="code"> <button @click="signContract">Signer le contrat</button>
        </Dialog>
      </template>
    </div>
  </div>
</template>

<script>
import { useToast } from "primevue/usetoast";
import { FilterMatchMode } from "primevue/api";
import DropdownNav from "@/components/DropdownNav.vue";
import paiement from "@/components/eleve/Paiement.vue";

export default {
  name: "App",
  components: {
    DropdownNav,
    paiement,
  },
  data() {
    return {
      students: [],
      studentsUnArchive: [],
      studentsArchive: [],
      studentsLivreCode: [],
      studentsPrepaCode: [],
      selectedItem: null,
      showArchive: false,
      showLivre: false,
      showPrepaCode: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      showForm: false,
      showPaiements : false,
      showMeContract : false,
      toast: useToast(),
    };
  },
  mounted() {
    this.getStudents();
  },
  methods: {


    signContract(){
      if(parseInt(this.code) == this.selectedItem.newContractCode){
        window.api
          .patch(
            "/contracts/" + this.selectedItem.newContractId,
            {
              piece: {"status" : "valide", "code" : this.code, "date" : new Date().toLocaleDateString("fr")},
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            this.selectedItem.newContract = "valide";
            this.showMeContract = false;
          })
          .catch((error) => {
            // Indique qu'une pièce n'est plus en cours de validation
            this.isWorkingValider = false;
            console.log(error);
          });
      }
    },

    resendMail(){
      window.api
        .get("/confirm/contract/"+ this.selectedItem.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then(() => {
        })
        .catch((error) => {
          console.log(error);
        });
    },


    showPaiement(){
      this.showPaiements = true;
    },

    showContrats() {
      this.showMeContract = true;
    },


    getStudents() {
      if (this.$store.state.user.role.label == "secretaire") {
        window.api
          .get("/code/user/" + this.$store.state.user.agence.id, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            this.showForm = false;
            this.selectedItem = null;
            this.showArchive = false;
            this.showLivre = false;
            this.showPrepaCode = false;
            this.studentsUnArchive = response.data.student.filter(
              (s) => s.archive == false
            );
            this.studentsArchive = response.data.student.filter(
              (s) => s.archive == true
            );
            this.studentsLivreCode = response.data.student.filter(
              (s) => s.livret == null
            );

            let tabPrepaNull = response.data.student.filter(
              (s) => s.prepacode == null
            );

            this.studentsPrepaCode = response.data.student.filter(
              (s) => s.prepacode != null
            );
            this.studentsPrepaCode = this.studentsPrepaCode.filter(
              (s) => s.prepacode[0].status == null
            );

            tabPrepaNull.forEach((tab) => {
              this.studentsPrepaCode.push(tab);
            });

            this.studentsLivreCode = response.data.student.filter(
              (s) => s.livret == null
            );

            this.students = this.studentsUnArchive;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        window.api
          .get("/code/user/0", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            this.showForm = false;
            this.selectedItem = null;
            this.showArchive = false;
            this.showLivre = false;
            this.showPrepaCode = false;
            this.studentsUnArchive = response.data.student.filter(
              (s) => s.archive == false
            );
            this.studentsArchive = response.data.student.filter(
              (s) => s.archive == true
            );
            this.studentsLivreCode = response.data.student.filter(
              (s) => s.livret == null
            );

            let tabPrepaNull = response.data.student.filter(
              (s) => s.prepacode == null
            );

            this.studentsPrepaCode = response.data.student.filter(
              (s) => s.prepacode != null
            );
            this.studentsPrepaCode = this.studentsPrepaCode.filter(
              (s) => s.prepacode[0].status == null
            );

            tabPrepaNull.forEach((tab) => {
              this.studentsPrepaCode.push(tab);
            });

            this.studentsLivreCode = response.data.student.filter(
              (s) => s.livret == null
            );

            this.students = this.studentsUnArchive;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    postLivreCode(student) {
      const date = new Date();

      if (student.livret == null && student.prepacode == null) {
        window.api
          .post(
            "/prepacodes",
            {
              utilisateur: "/api/utilisateurs/" + student.id,
              livret: date,
              archive : false,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then(() => {
            this.getStudents();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        window.api
          .patch(
            "/prepacodes/" + student.prepacode[0].id,
            {
              livret: date,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            this.getStudents();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    cancelLivreCode(student) {
      window.api
        .patch(
          "/prepacodes/" + student.prepacode[0].id,
          {
            livret: null,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
              "Content-Type": "application/merge-patch+json",
            },
          }
        )
        .then(() => {
          this.getStudents();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    showArchives() {
      if (!this.showArchive) {
        this.showArchive = true;
        this.showLivre = false;
        this.showPrepaCode = false;
        this.students = this.studentsArchive;
      } else {
        this.showArchive = false;
        this.students = this.studentsUnArchive;
      }
    },

    showLivres() {
      if (!this.showLivre) {
        this.showLivre = true;
        this.showArchive = false;
        this.showPrepaCode = false;
        this.students = this.studentsLivreCode;
      } else {
        this.showLivre = false;
        this.students = this.studentsUnArchive;
      }
    },

    showPrepaCodes() {
      if (!this.showPrepaCode) {
        this.showPrepaCode = true;
        this.showArchive = false;
        this.showLivre = false;
        this.students = this.studentsPrepaCode;
      } else {
        this.showPrepaCode = false;
        this.students = this.studentsUnArchive;
      }
    },

    postPrepacode(student) {
      const date = new Date();
      let id = "";
      if (student.prepacode == null) {
        id = student.identifiant;
      } else {
        id = student.prepacode[0].identifiant;
      }

      window.api
        .post(
          "/code/prepacode/" + student.id,
          {
            delete : false,
            identifiant: id,
            password: student.password,
            datePrepaCode : date,
            archive : false
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(() => {
          this.getStudents();
          this.sendId(student);
          this.toast.add({
              severity: "success",
              summary: "ID Prepacode validé",
              life: 5000,
            });

        })
        .catch((error) => {
          console.log(error);
          this.toast.add({
              severity: "error",
              summary: "Une erreur est survenue",
              life: 3000,
            });

        });
    },

    deletePrepacode(student) {
      window.api
        .post(
          "/code/prepacode/" + student.id,
          {
            delete : true,
            identifiant: "null",
            password: "null",
            datePrepaCode : "null",
            archive : false
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(() => {

          this.toast.add({
              severity: "success",
              summary: "ID Prepacode supprimé",
              life: 5000,
            });

          this.getStudents();
        })
        .catch((error) => {

          this.toast.add({
              severity: "error",
              summary: "Une erreur est survenue",
              life: 3000,
            });

          console.log(error);
        });
    },

    sendId(student){
      window.api
          .get("give/prepacode/" + student.id, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then(() => {

            this.toast.add({
              severity: "info",
              summary: "ID Prepacode envoyé par mail",
              detail: "(vérifier les spams)",
              life: 3000,
            });

          })
          .catch((error) => {
            console.log(error);

            this.toast.add({
              severity: "error",
              summary: "Une erreur est survenue",
              life: 3000,
            });

          });
    },

    onSelected() {
      this.showPaiements = false;
      if (this.selectedItem != null) {
        document.getElementById("right").style.background = "none";
      }
      //this.getPrepacodeById();
    },
  },
};
</script>

<style scoped>
div.contentStudent div.formPrepacode {
  margin-top: 2em;
}

div.contentStudent div.formPrepacode input[type="text"] {
  width: 60%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

div.contentStudent div.formPrepacode input[type="password"] {
  width: 60%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 2em;
}

div.headerStudent {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 3em;
  padding: 1em;
}

div.headerStudent div.rightsss button {
  margin-bottom: 1em;
}

.search {
  border: 1px solid black;
  margin-top: 2em;
  margin-bottom: 2em;
}
.btnViolet {
  background: #de6fa8;
  border-radius: 13px;
  border: none;
}
#exaCode {
  display: flex;
  flex-direction: row;
  justify-items: space-around;
}
#exaCode > p {
  font-size: 1.3em;
}
#exaConduite > p {
  font-size: 1.3em;
}
#exaConduite {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
#archive {
  display: flex;
  justify-content: space-between !important;
  margin-bottom: 2%;
}
#archiveBtn {
  background: #07989f;
  border-radius: 11px;
}
#iconHome {
  cursor: pointer;
}
.btnForm {
  background-color: #de6fa8; /* Green */
  border: none;
}
a {
  text-decoration: none;
}
#histo {
  width: 220px;
}
#formModif {
  margin: auto;
  width: 100%;
}
#head {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;
  width: 50%;
}
#add {
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  top: 40px;
}
#titreChoix {
  flex-wrap: wrap;
  font-size: 2rem;
  color: white;
}
#styleTitreChoix {
  background-color: rgb(255, 255, 255, 0.47);
  padding: 1%;
  border-radius: 13px;
  align-self: center;
  margin: 0;
  position: absolute;
  top: 50%;
  margin-left: 18%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  
}
#eleve {
  background-image: url("../../assets/images/fondd.png");
  background-size: cover;
  border-right: 1px solid black;
}
#right {
  width: 100%;
  background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  height: 100vh;
  justify-content: center !important;

}
#titreEleve {
  font-size: 25pt;
  font-weight: bold;
  color: #2e9ea3;
}
#conteneurEleve {
  display: grid;
  grid-template-columns: 50% 50%;
}
#conteneurEleve div {
  justify-content: space-between;
}

input {
  display: inline-block;
  border: thin solid #fafafa;
  color: #444;
  background-color: #fafafa;
  padding: 10px 10px 10px 10px;
}
input:focus {
  border: thin solid #ffaa71;
  border-bottom: solid medium #ffaa71;
  background-color: #fff;
}
</style>
