<template>
  <div id="conteneur">
    <Toast />
    <div id="left" class="container-fluid">
      <header id="head">
        <h1 class="mt-5 ms-3" id="titreLeft">
          <router-link to="/home"
            ><i
              class="pi pi-home me-3"
              id="iconHome"
              style="font-size: 2rem"
            ></i></router-link
          >Evénements
        </h1>
        <div>
          <Button class="btnViolet mt-5" @click="displayEvenements()"
            >Mes Inscriptions</Button
          >
        </div>
      </header>
      <div class="container mt-5">
        <template v-if="this.displayMesEvenements === false">
          <div id="btnContainer">
            <div>
              <label for="start" class="me-2"
                >Choisir une période pour afficher les événements:
              </label>
              <input
                type="date"
                id="start"
                v-model="this.dateForFiltre"
                name="start"
                @change="filtreByDate()"
                min="2022"
              />
              <Button class="btnTurquoise ms-5" @click="refreshJournees()"
                >Réinitialiser</Button
              >
            </div>
          </div>

          <template v-for="journee in this.journees" :key="journee.id">
            <div
              id="journeeConteneur"
              :class="{ selected: journee === journeeSelected }"
              @click="this.getJournee(journee)"
            >
              <p>
                <strong>{{ journee.libelle }}</strong>
              </p>
              <div id="journeeInfo">
                <p><strong>Date : </strong> {{ journee.date }}</p>
                <p>
                  <strong>Horaires: </strong> {{ journee.horaireDebut }} -
                  {{ journee.horaireFin }}
                </p>
                <template v-if="journee.nbrPlace > 0">
                  <p><strong>Places dispo: </strong>{{ journee.nbrPlace }}</p>
                </template>
                <template v-else>
                  <p style="color: red; font-size: 1.3rem">complet</p>
                </template>
              </div>
            </div>
          </template>
        </template>

        <template v-if="this.displayMesEvenements === true">
          <div id="filtreJournee">
            <div class="field-checkbox">
              <Checkbox
                id="binary"
                v-model="checkJourneePasse"
                @click="filtreJourneeaPasse()"
                :binary="true"
                class="me-2 mb-3"
              />
              <label class="mb-3" for="binary">Passé</label>
            </div>

            <div class="field-checkbox">
              <Checkbox
                id="binary"
                v-model="checkJourneeaVenir"
                @click="filtreJourneeaVenir()"
                :binary="true"
                class="me-2 mb-3"
              />
              <label class="mb-3 me-5" for="binary">A Venir</label>
            </div>

            <Button
              class="btnTurquoise"
              @click="this.displayMesEvenements = false"
              >Retour vers tous les événements</Button
            >
          </div>

          <template v-for="journee in this.mesJournees" :key="journee.id">
            <div
              id="journeeConteneur"
              :class="{ selected: journee === journeeSelected }"
              @click="this.getJournee(journee)"
            >
              <p>
                <strong>{{ journee.libelle }}</strong>
              </p>
              <div id="journeeInfo">
                <p><strong>Date : </strong> {{ journee.date }}</p>
                <p>
                  <strong>Horaires: </strong> {{ journee.horaireDebut }} -
                  {{ journee.horaireFin }}
                </p>
                <template v-if="journee.nbrPlace > 0">
                  <p><strong>Places dispo: </strong>{{ journee.nbrPlace }}</p>
                </template>
                <template v-else>
                  <p style="color: red; font-size: 1.3rem">complet</p>
                </template>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>

    <div id="right">
      <div v-if="this.journee == null" id="styleTitreRight">
        <p id="titreRight">Cliquez sur une journée</p>
      </div>

      <template v-if="this.journee != null">
        <div class="container">
          <h2 class="text-center">
            {{ this.journee.libelle }}
          </h2>

          <template v-if="this.inscrit == null">
            <div id="detailJournee" class="mt-4">
              <center>
                <p>
                  L'événement <strong>{{ journee.libelle }}</strong> aura lieu
                  le <strong>{{ journee.date }}</strong> de
                  <strong> {{ journee.horaireDebut }}</strong> à
                  <strong>{{ journee.horaireFin }}</strong>
                </p>

                <p>
                  Détails :
                  {{ journee.description }}
                </p>
              </center>
            </div>
            <div id="choixPlace">
              <label for="horizontalSpinnerButton" class="me-2"
                >Nombre de places souhaitées (max : 3) :
              </label>
              <InputNumber
                id="horizontalSpinnerButton"
                v-model="this.nbrInscrits"
                min="1"
                max="3"
                showButtons
                class="ms-3"
                buttonLayout="horizontal"
                :step="1"
                decrementButtonClass="p-button-danger"
                incrementButtonClass="p-button-success"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
              />
            </div>
            <template v-if="this.journee.nbrPlace <= 0">
              <h2 style="color: red">Journee Complete</h2>
            </template>
            <template v-else-if="this.checkSessionTermine === true">
              <p style="color: red; font-size: 1.3rem">Session terminée</p>
            </template>
            <template v-else>
              <div id="selectionLycee">
                <p><strong>Veuillez Selectionner votre Lycée :</strong></p>
                <Dropdown
                  class="mb-4 ms-5"
                  @change="checkLyceeSelected()"
                  v-model="selectedLycee"
                  :options="this.lycees"
                  optionLabel="nom"
                  placeholder="Selectionnez un Lycée"
                />
              </div>
              <div id="btnContainer">
                <Button
                  class="btnViolet"
                  :disabled="isDisabled"
                  @click="postJourneeReserv()"
                  >S'inscrire</Button
                >
              </div>
            </template>
          </template>

          <template v-else>
            <p style="color: green">
              <strong>Vous êtes inscrit à cet événement !</strong>
            </p>

            <p>
              L'événement <strong>{{ journee.libelle }}</strong> aura lieu le
              <strong>{{ journee.date }}</strong> de
              <strong> {{ journee.horaireDebut }}</strong> à
              <strong>{{ journee.horaireFin }}</strong>
            </p>
            <p>
              Détails :
              {{ journee.description }}
            </p>
            <p>
              Nombre de places réservées :
              {{ journeeReserv.nbrInscrits }}
            </p>
            <p>Lycée sélectionné : {{ journeeReserv.lycee }}</p>
            <template v-if="this.checkSessionTermine === true">
              <p style="color: red; font-size: 1.3rem">Session terminée</p>
            </template>
            <template v-else>
            <label for="horizontal" class="me-2 mb-3"
              >Modifier le nombre de participants (max: 3):
            </label>
            <br />
            <InputNumber
              id="horizontalSpinnerButton"
              v-model="this.journeeReserv.nbrInscrits"
              min="1"
              max="3"
              showButtons
              buttonLayout="horizontal"
              :step="1"
              decrementButtonClass="p-button-danger"
              incrementButtonClass="p-button-success"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
            />

            <div id="btnContainer">
              <Button class="btnViolet mt-5" @click="patchJourneeReserv()"
                >Modifer</Button
              >
              <Button class="btnRouge mt-5" @click="deleteJourneeReserv()"
                >se Retirer</Button
              >
            </div>
            </template>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { useToast } from "primevue/usetoast";
import moment from "moment";
import { mapActions } from "vuex";
import { FilterMatchMode } from "primevue/api";

export default {
  name: "App",

  data() {
    return {
      toast: useToast(),
      selectedItem: null,
      displayMesEvenements: false,
      mesJournees: [],
      journees: [],
      journeesForFiltre: [],
      todayDate: new Date().toLocaleString("en-US"),
      journee: null,
      journeeSelected: null,
      mesJourneesFiltreParDate: [],
      dateForFiltre: "",
      isDisabled: true,
      journeeReserv: null,
      selectedLycee: null,
      libelleStatut: "",
      checkSessionTermine: false,
      checkJourneeaVenir: false,
      checkJourneePasse: false,
      inscrit: null,
      nbrInscrits: null,
      nbrPersDejaInscrit: null,
      filters: {
        nom: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      },
      form: {
        journeeInfoId: null,
        libelle: "",
        description: "",
        horaireDebut: "",
        horaireFin: "",
        date: "",
        nbrPlace: null,
      },
    };
  },

  mounted() {
    this.getJournees();
    this.getLycees();
  },

  methods: {
    ...mapActions(["getLycees"]),

    refreshJournees() {
      this.dateForFiltre = "";
      this.getJournees();
      console.log(this.$store.state.token);
    },

    displayEvenements() {
      this.displayMesEvenements = true;
      this.filterMesJournees();
    },

    filterMesJournees() {
      this.mesJournees = this.journeesForFiltre.filter((jr) =>
        jr.journeeReservations.find(
          (jre) => jre.eleve.id === this.$store.state.user.id
        )
      );
    },

    filtreByDate() {
      let maday = moment(String(this.dateForFiltre)).format("MM-YYYY");
      console.log("madayt", maday);
      this.journees = this.journeesForFiltre.filter(
        (jr) => jr.dateOnlyMonthandYear === maday
      );
    },

    filtreJourneeaVenir() {
      if (this.checkJourneeaVenir == false) {
        this.checkJourneeaVenir = true;
        this.mesJournees = this.mesJournees.filter(
          (jr) =>
            new Date(jr.dateEn).getTime() >= new Date(this.todayDate).getTime()
        );
      } else {
        this.checkJourneeaVenir = false;
        this.getJournees();
        this.filterMesJournees();
        console.log("essaie2", this.mesJournees);
      }
    },

    filtreJourneeaPasse() {
      if (this.checkJourneePasse == false) {
        this.checkJourneePasse = true;
        this.mesJournees.forEach((jr) => {
          console.log("jr", jr);
          console.log("format", new Date(jr.date));
        });
        this.mesJournees = this.mesJournees.filter(
          (jr) =>
            new Date(jr.dateEn).getTime() <= new Date(this.todayDate).getTime()
        );
        console.log("essaie", this.mesJournees);
      } else {
        this.checkJourneePasse = false;
        this.getJournees();
        this.filterMesJournees();
        console.log("essaie2", this.mesJournees);
      }
    },

    getJournee(journee) {
      this.checkSessionTermine = false;
      this.journee = journee;
      this.journeeSelected = journee;
      if (
        moment(String(journee.dateEn)).format("MM-DD-YYYY") <
        moment(String(this.todayDate)).format("MM-DD-YYYY")
      ) {
        this.checkSessionTermine = true;
      }
      this.changeBackground();
      this.checkIfInscrit();
    },

    async patchJourneeReserv() {
      if (
        (confirm("Voulez vous vraiment vous changer le nombre d'inscrits ?") &&
          this.journee.nbrPlace -
            this.journeeReserv.nbrInscrits +
            this.nbrPersDejaInscrit >=
            0) ||
        this.journee.nbrPlace +
          this.nbrPersDejaInscrit -
          this.journeeReserv.nbrInscrits >=
          0
      ) {
        window.api
          .patch(
            "/journee/" + this.journeeReserv.id,
            {
              nbrInscrits: this.journeeReserv.nbrInscrits,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            this.getJournees();
            console.log("completed", response.data);
            this.toast.add({
              severity: "success",
              summary: "changement Réussis",
              detail: "Votre changement à été réalisé avec succès",
              life: 3000,
            });
          })
          .catch((error) => {
            // handle authentication and validation errors here
            console.log("ERRR::", error.response.data);
          });
      } else {
        this.toast.add({
          severity: "success",
          summary: "Reservation Impossible",
          detail: "Il n'y a pas assez de place pour cette Evenement",
          life: 3000,
        });
      }
    },

    checkLyceeSelected() {
      this.isDisabled = false;
    },

    async deleteJourneeReserv() {
      try {
        if (confirm("Voulez vous vraiment vous retirer ?")) {
          window.api
            .delete("journee/" + this.journeeReserv.id, {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            })
            .then(() => {
                this.inscrit = null;
                this.toast.add({
                  severity: "success",
                  summary: "Désinscription",
                  detail: "Votre vous etes été Désinscris avec succès",
                  life: 3000,
                });
                this.getJournees();
            });
        }
      } catch (e) {
        // handle authentication error here
      }
    },

    async getJournees() {
      try {
        window.api
          .get("/Alljournees", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            this.journees = response.data.journees;
            this.journeesForFiltre = response.data.journees;
            this.journees = this.journees.filter(
              (jr) =>
                new Date(moment(String(jr.dateEn)).format("MM-DD-YYYY")) >=
                new Date(moment(String(this.todayDate)).format("MM-DD-YYYY"))
            );
            this.filterMesJournees();
          });

        console.log(this.journees);
      } catch (e) {
        // handle authentication error here
      }
    },

    checkIfInscrit() {
      console.log("journee", this.journee);
      if (
        (this.journee.journeeReservations.length > 0 &&
          console.log("jepassela"),
        this.journee.journeeReservations.find(
          (jr) => jr.eleve.id === this.$store.state.user.id
        ))
      ) {
        if (
          this.journee.journeeReservations.filter(
            (jr) => jr.eleve.id === this.$store.state.user.id
          )
        ) {
          this.inscrit = true;
          this.journeeReserv = this.journee.journeeReservations.find(
            (jr) => jr.eleve.id === this.$store.state.user.id
          );
        }
        console.log("lareserve", this.journeeReserv);
        this.nbrPersDejaInscrit = this.journeeReserv.nbrInscrits;
        console.log("dejainscrit", this.nbrPersDejaInscrit);
      } else {
        this.inscrit = null;
      }
    },

    changeBackground() {
      if (this.journee != null) {
        document.getElementById("right").style.background = "none";
      }
    },

    async postJourneeReserv() {
      if (this.selectedLycee === null) {
        this.toast.add({
          severity: "success",
          summary: "Sélectionnez un lycée !",
          detail: "Sélectionnez un lycée pour vous inscrire",
          life: 3000,
        });
      }
      if (
        confirm("Voulez vous vraiment vous inscrire ?") &&
        this.journee.nbrPlace - this.nbrInscrits >= 0
      ) {
        window.api
          .post(
            "/journee",
            {
              lycee: this.selectedLycee.id,
              eleve: this.$store.state.user.id,
              journeeHoraire: this.journee.id,
              nbrInscrits: this.nbrInscrits,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            console.log("completed", response.data);
            this.getJournees();
            this.journeeReserv = response.data.journee;
            console.log("fddsf", this.journeeReserv);
            this.inscrit = true;
            this.toast.add({
              severity: "success",
              summary: "Inscription Réussis",
              detail: "Votre Inscription à été réalisé avec succès",
              life: 3000,
            });
          })
          .catch((error) => {
            // handle authentication and validation errors here
            console.log("ERRR::", error.response.data);
          });
      } else {
        this.toast.add({
          severity: "success",
          summary: "Reservation Impossible",
          detail: "Il n'y a pas assez de place pour cette Evenement",
          life: 3000,
        });
      }
    },
  },

  computed: {
    lycees() {
      return this.$store.state.lycees;
    },
  },
};
</script>

<style>
#horizontalSpinnerButton {
  width: 5vh !important;
  text-align: center;
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
  color: #ffffff;
  background: #de6fa8;
  border: 1px solid #ffffff;
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
  color: #ffffff;
  background: #07989f;
  border: 1px solid #ffffff;
}
</style>

<style scoped>
#choixPlace {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  margin-top: 4%;
}

#selectionLycee {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  margin-top: 4%;
}

#filtreJournee {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
}

.selected {
  border: 2px solid #de6fa8 !important;
  cursor: pointer;
}

.btnTurquoise {
  background: #07989f;
  border-radius: 13px;
  border: none;
}

#journeeInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Works on Chrome, Edge, and Safari */
#left::-webkit-scrollbar {
  width: 15px;
}

#left::-webkit-scrollbar-track {
  background: white;
}

#left::-webkit-scrollbar-thumb {
  background-color: #fbbb5b77;
  border-radius: 20px;
  border: 3px solid white;
}

#journeeConteneur {
  padding: 1%;
  border: 1px solid #07989f;
  margin-top: 2%;
  border-radius: 10px;
  background: rgb(255, 255, 255);
}

#journeeConteneur:hover {
  border: 1px solid #de6fa8;
  cursor: pointer;
  background: rgb(255, 255, 255);
}

#journeeConteneur > h2,
p {
  text-align: left;
}

#btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#iconHome {
  cursor: pointer;
}

.btnRouge {
  background: #ec2b22;
  border-radius: 13px;
  border: none;
}
.btnViolet {
  background: #de6fa8;
  border-radius: 13px;
  border: none;
}

a {
  text-decoration: none;
}

#formModif {
  margin: auto;
  width: 100%;
}

#head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-around;
  width: 100%;
}

#add {
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  top: 40px;
}

#titreRight {
  flex-wrap: wrap;
  font-size: 2rem;
  color: white;
}

#styleTitreRight {
  background-color: rgb(255, 255, 255, 0.47);
  padding: 1%;
  border-radius: 13px;
}

#left {
  background-image: url("../../assets/images/fondd.png");
  background-size: cover;
  border-right: 1px solid black;
  overflow: auto;
  min-height: 100%;
  max-height: 100vh;
}

#right {
  width: 100%;
  background: linear-gradient(323deg, #fbbb5b 35%, #de6fa8 100%, #07989f 0%);
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#titreLeft {
  font-size: 19pt;
  font-weight: bold;
  color: #2e9ea3;
}

#conteneur {
  display: grid;
  grid-template-columns: 50% 50%;
}

.text-field,
text {
  position: relative;
  margin: 10px 2.5px 20px 2.5px;
}

input {
  display: inline-block;
  border: thin solid #fafafa;
  color: #444;
  background-color: #fafafa;
  padding: 10px 10px 10px 10px;
}

input:focus {
  border: thin solid #ffaa71;
  border-bottom: solid medium #ffaa71;
  background-color: #fff;
}

input:focus ~ label,
input:valid ~ label {
  top: -10px;
  left: 15px;
  font-size: small;
  color: #167794;
  background-color: #fff;
  padding: 0 5px 0 5px;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 1300px) {
  #journeeInfo > p {
    font-size: 0.8em;
  }

  #head {
    flex-direction: column !important;
  }
}

/* Change the background color of the dropdown button when the dropdown content is shown */
/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  #conteneur {
    display: initial;
    grid-template-columns: none;
  }

  #head {
    flex-direction: column !important;
  }
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
  #conteneur {
    display: initial;
    grid-template-columns: none;
  }

  #head {
    flex-direction: column !important;
  }
}
</style>
