<template>
  <div class="container">
    <br />
    <br />
    <br />
    <form @submit.prevent="onSubmit">
      <span class="help is-danger" v-text="errors"></span>

      <div class="container pt-4">

        <label for="examens">Choisissez un Examen </label>
        <select v-model="form.examen" id="examens" name="examens" required>
          <option
            v-for="(item, index) in examens"
            :value="'/api/examens/' + item.id"
            :key="index"
          >
          Date: 
            {{ item.dateStart }}
           /
          Lieu:
            {{ item.lieu }}
           /
          Moniteur:
            {{ item.moniteur }}
          </option>
        </select>
      </div>

      <br />

      <button class="btn btn-primary btn-sm">Ajouter</button>
    </form>
  </div>
</template>


<script>
export default {
  name: "AjoutLyceeModal",
  data() {
    return {
      examens: [],
      form : {
      eleve: "/api/utilisateurs/" + this.$route.params.EleveId,
      examen: null,
      }

    };
  },

  mounted() {
    this.getExamen();
  },

  methods: {
    onSubmit() {
      this.postExamen();
    },



    getExamen() {
      window.api
        .get("/getAllExamens", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.examens = response.data.Examen;
          console.log(response);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },



    async postExamen() {
      window.api
        .post("/examen_demandes", this.form , {

            examen: this.form.examen,
            eleve: "/api/utilisateurs/" + this.EleveId,
     
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.$emit("completed", response.data);
          alert("Votre Examen à ete ajouté");
        })
        .catch((error) => {
          // handle authentication and validation errors here
          console.log("ERRR::", error.response.data);
        });
    },

    print() {
      console.log(this.choixTypeLieu);
    },
  },
};
</script>

